import React, { useState, useEffect } from 'react';
import { Card, Button, Dropdown, Modal } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { jobLiked, jobUnLiked, applyJob, deleteReferJob, 
        employerBoostJob, employerHighlightJob, getSubscriptionPlansById,
        getCreditPointsByUserId
       } from '../apiService';
import { apiRoutes, subscriptionPlansIdConfigs } from '../../config/apiConfig';

const EmployerMyJobCard = ({ job, userData, userToken }) => {
  const [isLiked, setIsLiked] = useState(job.userlikedjob_status);
  const [isLikedCount, setIsLikedCount] = useState(job.joblike_count);
  const [selectedJobId, setSelectedJobId] = useState(null); 
  const [selectedBoostPlanDetails, setSelectedBoostPlanDetails] = useState(null); 
  const [selectedHighlightPlanDetails, setSelectedHighlightPlanDetails] = useState(null); 
  const [showMenu, setShowMenu] = useState(false);
  const [userCreditPoint , setUserCreditPoint] = useState(null);
  const [walletStatusForBoost , setWalletStatusForBoost] = useState(null);
  const [walletStatusForHighlight , setWalletStatusForHighlight] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getCreditPointsByUserId(userData.id, userToken);
        setUserCreditPoint(response);
        const PlanDetailsBoost =  await getSubscriptionPlansById(subscriptionPlansIdConfigs.employerBoostPlanId, userToken);
        setSelectedBoostPlanDetails(PlanDetailsBoost);
        const PlanDetailsHighlight =  await getSubscriptionPlansById(subscriptionPlansIdConfigs.employerHighlightPlanId, userToken);
        setSelectedHighlightPlanDetails(PlanDetailsHighlight);
      } catch (error) {
        console.error('Error fetching User points and Plan points:', error);
      } 
    };
    fetchJobs();
  }, []);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        if ( userCreditPoint && selectedBoostPlanDetails && selectedHighlightPlanDetails ) {
          setWalletStatusForBoost( userCreditPoint.points > selectedBoostPlanDetails.points);
          setWalletStatusForHighlight( userCreditPoint.points > selectedHighlightPlanDetails.points);
        }
      } catch (error) {
        console.error('Error setting flag for insufficient points:', error);
      } 
    };
    fetchJobs();
  }, [ userCreditPoint, selectedBoostPlanDetails, selectedHighlightPlanDetails]);

  // handel Like Starts  
  const handleLikeToggle = () => {
    if (isLiked) {
      jobUnLiked(userData.id, job.id, userToken)
        .then(response => {
          setIsLiked(false);
          setIsLikedCount(isLikedCount -1);
        })
        .catch(error => {
          console.error('Error unliking Job:', error);
        });
    } else {
      const formData = new FormData();
      formData.append("liked_post", job.id);
      formData.append("user_id", userData.id);
      jobLiked(formData, userToken)
        .then(response => {
          setIsLiked(true);
          setIsLikedCount(isLikedCount + 1);
        })
        .catch(error => {
          console.error('Error liking Job:', error);
        });
    }
  };
  // handel Like Ends  

  // show modal for Share Starts  
  const handleShare = (jobId, user_code) => {
    const host = window.location.origin; 
    const jobUrl = `${host}/job/?job_id=${jobId}&user_code=${user_code}`;
    const message = "Click on this link to view this Job:";
  
    if (navigator.share) {
      navigator.share({
        title: 'Check out this job!',
        text: message,
        url: jobUrl
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing:'));
    } else {
      console.log('Error share');
      // const shareLink = document.createElement('a');
      // shareLink.href = jobUrl;
      // shareLink.target = '_blank';
      // shareLink.rel = 'noopener noreferrer';
      // shareLink.style.display = 'none';
      // document.body.appendChild(shareLink);
      // shareLink.click();
      // document.body.removeChild(shareLink);
    }
  };
  // show modal for Share Ends  

  // Delete Post Model Starts 
  const [showDeleteSubmitModal, setShowDeleteSubmitModal] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [hideDeleteSuccessModal, setHideDeleteSuccessModal] = useState(false);
  const handleShowDeleteSubmitModal = (jobId) => {
    setSelectedJobId(jobId); 
    setShowDeleteSubmitModal(true);
  };

  const handleCloseDeleteSubmitModal = () => setShowDeleteSubmitModal(false);
  const handleCloseDeleteSuccessModal = () => setHideDeleteSuccessModal(false);

  const handleShowDeleteSuccessModal = async () => {
    await deleteReferJob(selectedJobId, userToken);
    setShowDeleteSubmitModal(false); 
    setShowDeleteSuccessModal(true); 
  };
  // Delete Post Model Ends

  // Highlight Post Model Starts 
  const [showHighlightSubmitModal, setShowHighlightSubmitModal] = useState(false);
  const [showHighlightSuccessModal, setShowHighlightSuccessModal] = useState(false);
  const [hideHighlightSuccessModal, setHideHighlightSuccessModal] = useState(false);
  const handleShowHighlightSubmitModal = async (jobId) => {
    setSelectedJobId(jobId); 
    setShowHighlightSubmitModal(true);
  };

  const handleCloseHighlightSubmitModal = () => setShowHighlightSubmitModal(false);
  const handleCloseHighlightSuccessModal = () => setHideHighlightSuccessModal(false);

  const handleShowHighlightSuccessModal = async () => {
    const updatedUserData = {
      sub_id: subscriptionPlansIdConfigs.employerHighlightPlanId
    };
    await employerHighlightJob(updatedUserData, userToken);
    setShowHighlightSubmitModal(false); 
    setShowHighlightSuccessModal(true); 
  };
  // Highlight Post Model Ends


  // Boost Post Model Starts 
  const [showBoostSubmitModal, setShowBoostSubmitModal] = useState(false);
  const [showBoostSuccessModal, setShowBoostSuccessModal] = useState(false);
  const [hideBoostSuccessModal, setHideBoostSuccessModal] = useState(false);
  const handleShowBoostSubmitModal = async (jobId) => {
      setSelectedJobId(jobId); 
      setShowBoostSubmitModal(true);
  };

  const handleCloseBoostSubmitModal = () => setShowBoostSubmitModal(false);
  const handleCloseBoostSuccessModal = () => setHideBoostSuccessModal(false);

  const handleShowBoostSuccessModal = async () => {
    const updatedUserData = {
      sub_id: subscriptionPlansIdConfigs.employerBoostPlanId,
      post_boost: job.id
    };
    await employerBoostJob(updatedUserData, userToken);
    setShowBoostSubmitModal(false); 
    setShowBoostSuccessModal(true); 
  };
  // Boost Post Model Ends

  // console.log("userCreditPoint", userCreditPoint && userCreditPoint.points);

  return (
    <>
      <Card>
        <Card.Body>
          <div className='job-card-cont'>
            <div className="job-card">
              <div className="jobby-info-container">
                <div className="jobby-info">
                  <LazyLoadImage
                    className="profile-img-info"
                    src={job.posted_by.profilePic}
                    alt="Profile"
                  />
                  <div>
                    <h1 className="jobby-name">Job By: {job.posted_by.first_name}</h1>
                    <h1 className="company-name">{job.posted_by.company_name}</h1>
                  </div>
                </div>
              </div>
              <div className="apply-job" style={{ display: 'flex', flexDirection: 'column', gap: '21px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}  onClick={() => handleShare(job.id, userData.user_code)}>
                  <LazyLoadImage src="/static/crew/assets/img/icons/Reshare_Forward.svg" alt="Share" />
                  <p className="share-button-name">Share</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '32px' }}>
                  <Button className="like-button btn-primary" type="button" onClick={handleLikeToggle}>
                    <LazyLoadImage
                      src={isLiked ? '/static/crew/assets/img/icons/Heart.svg' : '/static/crew/assets/img/icons/Like_heart.svg'}
                      alt={isLiked ? 'Liked' : 'Like'}
                    />
                    &nbsp; &nbsp;
                    {isLikedCount}
                    &nbsp; &nbsp;
                    {isLiked ? 'Likes' : 'Like'}
                  </Button>
                  <Dropdown show={showMenu} onToggle={() => setShowMenu(!showMenu)} align="end">
                    <Dropdown.Toggle as={Button} variant="secondary" onClick={() => setShowMenu(!showMenu)} style={{
                        background: 'none',
                        border: 'none',
                        padding: '0',
                        margin: '0',
                        cursor: 'pointer'
                    }}>
                    <LazyLoadImage src='/static/crew/assets/img/icons/Vertical_Dots.svg' alt="Menu" style={{ width: '24px', height: '24px', paddingTop: '5px' }} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href={`${apiRoutes.employer_postjob_edit}${job.id}`} className='refer-job-options' >Edit</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShowDeleteSubmitModal(job.id)} className='refer-job-delete-option' >Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Tentative Joining Date:</h1>
                <h1 className="job-details">{job.tentative_joining}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Vessel Type:</h1>
                <h1 className="job-details">{job.vessel_id.name}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">GRT:</h1>
                <h1 className="job-details">{job.GRT}</h1>
              </div>
            </div>
            <div className="job-wages-container">
              <div className="job-wages-container-1">
                {job.Job_Rank_wages.slice(0, Math.floor(job.Job_Rank_wages.length / 2)).map((wage, index) => (
                  <div key={index} className="job-wages">
                    <LazyLoadImage src="/static/crew/assets/img/icons/job_bag.svg" alt="Job Bag" />
                    <p className="job-wages-name">{wage.rank_number.name} - {wage.wages}</p>
                  </div>
                ))}
              </div>
              {job.Job_Rank_wages.slice(Math.floor(job.Job_Rank_wages.length / 2)).length > 0 && (
                <div className="job-wages-container-1">
                  {job.Job_Rank_wages.slice(Math.floor(job.Job_Rank_wages.length / 2)).map((wage, index) => (
                    <div key={index + Math.floor(job.Job_Rank_wages.length / 2)} className="job-wages">
                      <LazyLoadImage src="/static/crew/assets/img/icons/job_bag.svg" alt="Job Bag" />
                      <p className="job-wages-name">{wage.rank_number.name} - {wage.wages}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="requirement-container">
                {job.job_coc && job.job_coc.length > 0 && (
                    <div className="requirement-container-both">
                      <h1 className="requirement-heading">COC Requirement:</h1>
                      <div className="requirement-value">
                          {job.job_coc.map((coc_names, index) => (
                              <React.Fragment key={index}>
                                  <h1 className="requirement-heading">{coc_names.coc_id.name}</h1>
                                  {index !== job.job_coc.length - 1 && <span>|</span>}
                              </React.Fragment>
                          ))}
                      </div>
                    </div>
                )}
                {job.job_cop && job.job_cop.length > 0 && (
                  <div className="requirement-container-both">
                      <h1 className="requirement-heading">COP Requirement:</h1>
                      <div className="requirement-value">
                          {job.job_cop.map((cop_names, index) => (
                              <React.Fragment key={index}>
                                  <h1 className="requirement-heading">{cop_names.cop_id.name}</h1>
                                  {index !== job.job_cop.length - 1 && <span>|</span>}
                              </React.Fragment>
                          ))}
                      </div>
                  </div>
                )}
            </div>

            <div className="requirement-container">
                {job.job_Watch_Keeping && job.job_Watch_Keeping.length > 0 && (
                  <div className="requirement-container-both">
                    <h1 className="requirement-heading">Watch-Keeping Requirement:</h1>
                      <div className="requirement-value">
                          {job.job_Watch_Keeping.map((coc_names, index) => (
                              <React.Fragment key={index}>
                                  <h1 className="requirement-heading">{coc_names.Watch_Keeping_id.name}</h1>
                                  {index !== job.job_Watch_Keeping.length - 1 && <span>|</span>}
                              </React.Fragment>
                          ))}
                      </div>
                  </div>
                )}
                <div className="requirement-container-both">
                { job.mail_info && 
                  <h1 className="requirement-heading">Email: {job.posted_by.email}</h1>
                }
                { job.number_info && 
                  <h1 className="requirement-heading">Mobile: {job.posted_by.number}</h1>
                }
                </div>
            </div>
            <div className="requirement-container-button-section">
              <div className="requirement-container-button-section-left">
                <button className="btn highlight-job-btn" type="button"
                  onClick={() => handleShowHighlightSubmitModal(job.id)}
                  data-bs-target="#modal-apply-job" data-bs-toggle="modal">
                  <img src="/static/crew/assets/img/icons/Send_fill_tilted.svg" alt="Send" /> Highlight Job
                </button>
                <button className="btn boost-job-button"
                  onClick={() => handleShowBoostSubmitModal(job.id)}
                  type="button">
                  <img src="/static/crew/assets/img/icons/Dimond_alt_orange.svg" alt="Diamond" />&nbsp; Boost Job
                </button>
              </div>
                <Button className="btn btn-primary view-applicants-button" 
                href={`${apiRoutes.employer_view_applicants}${job.id}`}
                type="button" data-bs-target="#modal-delete-job" data-bs-toggle="modal">
                  View Applicants&nbsp;&nbsp;<img src="/static/crew/assets/img/arrows/ArrowRightwhite.svg" alt="Arrow Right" />
                </Button>
            </div>
          </div>
        </Card.Body>
      </Card>

      {/* Delete Model Starts  */}
      <Modal show={showDeleteSubmitModal} onHide={handleCloseDeleteSubmitModal} centered  id="modal-delete-job" >
        <Modal.Header >
          <Modal.Title className='modal-title modal-msg-del'><strong>Are you sure?</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p className='modal-para-text'>Are you sure you want to delete this job post?</p>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" className='btn btn-light job-apply-cancel-btn' onClick={handleCloseDeleteSubmitModal}>Cancel</Button>
          <Button variant="primary" className='btn btn-primary job-apply-yes-del-btn' onClick={handleShowDeleteSuccessModal}>Yes</Button>
        </Modal.Footer>
      </Modal>
      <Modal show={showDeleteSuccessModal} onHide={handleCloseDeleteSuccessModal} centered id="modal-applied-successfull" >
        <Modal.Header className="myjob-header">
          <img src="/static/crew/assets/img/icons/double_check.svg" alt="Double check icon" />
        </Modal.Header>
        <Modal.Body className="myjob-body">
          <p>Post deleted successfully!</p>
        </Modal.Body>
        <Modal.Footer className="myjob-footer">
          <Button variant="light" className="myjob-btn-close" href={apiRoutes.emp_home}>
            Close
          </Button>
          <Button variant="primary" className="myjob-btn" href={apiRoutes.employer_my_jobs}>
            My Jobs&nbsp;<img src="/static/crew/assets/img/icons/fi_arrow-right.svg" alt="Arrow right icon" />
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Delete Model Ends */}


      {/* Highlight Model Starts  */}
      <Modal show={showHighlightSubmitModal} onHide={handleCloseHighlightSubmitModal} centered  id="modal-delete-job" >
        <Modal.Header >
          <Modal.Title className='modal-title modal-msg'><strong>Highlight Job Post</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {setWalletStatusForHighlight ?  
          <p className='modal-para-text'>Are you sure you want to use your {selectedHighlightPlanDetails && selectedHighlightPlanDetails.points} credits to Highlight this job post?</p>
          :
          <p className='modal-para-text'>Insufficient credit points!</p>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" className='btn btn-light job-apply-cancel-btn' onClick={handleCloseHighlightSubmitModal}>Cancel</Button>
          {setWalletStatusForHighlight ?  
          <Button variant="primary" className='btn btn-primary job-apply-yes-btn' onClick={handleShowHighlightSuccessModal}>Yes</Button>
          :
          <Button variant="primary" className='btn btn-primary job-apply-yes-btn' href={apiRoutes.get_wallet} >Buy Now</Button>
          }
        </Modal.Footer>
      </Modal>
      <Modal show={showHighlightSuccessModal} onHide={handleCloseHighlightSuccessModal} centered id="modal-applied-successfull" >
        <Modal.Header className="myjob-header" >
          <img src="/static/crew/assets/img/icons/double_check.svg" alt="Double check icon" />
        </Modal.Header>
        <Modal.Body className="myjob-body">
          <p>Post Highlighted successfully!</p>
        </Modal.Body>
        <Modal.Footer className="myjob-footer">
          <Button variant="light" className="myjob-btn-close" 
          href={apiRoutes.emp_home}
          >
            Close
          </Button>
          <Button variant="primary" className="myjob-btn" href={apiRoutes.employer_my_jobs}>
            My Jobs&nbsp;<img src="/static/crew/assets/img/icons/fi_arrow-right.svg" alt="Arrow right icon" />
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Highlight Model Ends */}


      {/* Boost Model Starts  */}
      <Modal show={showBoostSubmitModal} onHide={handleCloseBoostSubmitModal} centered  id="modal-delete-job" >
        <Modal.Header>
          <Modal.Title className='modal-title modal-msg'><strong>Boost Job Post</strong></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          { walletStatusForBoost ?
            <p className='modal-para-text'>Are you sure you want to use your {selectedBoostPlanDetails && selectedBoostPlanDetails.points} credits to boost this job post for 24 hrs?</p>
          :
            <p className='modal-para-text'>Insufficient credit points!</p>
          }
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" className='btn btn-light job-apply-cancel-btn' onClick={handleCloseBoostSubmitModal}>Cancel</Button>
          { walletStatusForBoost ?
          <Button variant="primary" className='btn btn-primary job-apply-yes-btn' onClick={handleShowBoostSuccessModal}>Yes</Button>
            :
          <Button variant="primary" className='btn btn-primary job-apply-yes-btn' href={apiRoutes.get_wallet} >Buy Now</Button>
          }
        </Modal.Footer>
      </Modal>
      <Modal show={showBoostSuccessModal} onHide={handleCloseBoostSuccessModal} centered id="modal-applied-successfull" >
        <Modal.Header className="myjob-header">
          <img src="/static/crew/assets/img/icons/double_check.svg" alt="Double check icon" />
        </Modal.Header>
        <Modal.Body className="myjob-body">
          <p>Post deleted successfully!</p>
        </Modal.Body>
        <Modal.Footer className="myjob-footer">
          <Button variant="light" className="myjob-btn-close" href={apiRoutes.emp_home}>
            Close
          </Button>
          <Button variant="primary" className="myjob-btn" href={apiRoutes.employer_my_jobs}>
            My Jobs&nbsp;<img src="/static/crew/assets/img/icons/fi_arrow-right.svg" alt="Arrow right icon" />
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Boost Model Ends */}


    </>
  );
};

export default EmployerMyJobCard;

import React, { useState } from 'react';
import { apiRoutes } from '../../config/apiConfig';
import { useNavigate } from 'react-router-dom';
import { setIsLoggedIn, getLocalJsonItem } from '../tokenManager';
import { changePasswordDB, handleLogout } from '../apiService';
import { EmailAuthProvider, reauthenticateWithCredential, updatePassword } from 'firebase/auth';
import { auth } from '../firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ChangePassword = () => {
    const navigate = useNavigate();
    const userFBData = getLocalJsonItem("userFirebaseData"); 

    const [currentPassword, setCurrentPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmNewPassword, setConfirmNewPassword] = useState('');
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmNewPassword, setShowConfirmNewPassword] = useState(false);
    const [errors, setErrors] = useState({});

    const toggleShowCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleShowConfirmNewPassword = () => {
        setShowConfirmNewPassword(!showConfirmNewPassword);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!currentPassword) newErrors.currentPassword = 'Current password is required';
        if (!newPassword) newErrors.newPassword = 'New password is required';
        if (!confirmNewPassword) newErrors.confirmNewPassword = 'Confirm new password is required';
        if (newPassword !== confirmNewPassword) newErrors.confirmNewPassword = 'Passwords do not match';
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        } else {
            try {
                setErrors({});
                const user = auth.currentUser;
                const credential = EmailAuthProvider.credential(user.email, currentPassword);
                await reauthenticateWithCredential(user, credential);
                await updatePassword(user, newPassword);
                
                const formData = {"new_password": confirmNewPassword};
                await changePasswordDB(formData, userFBData.idToken);
                toast.success("Password changed.");
                handleLogout();
            } catch (error) {
                // console.log("errorerrorerror", error);
                toast.error("Wrong Password.");
            }
        }
    };

    return (
        <div className="container settings-tab-content-container">
            <div className="settings-tab-mobile-container">
                <div className="settings-tab-mobile-items">
                    <p className="settings-tab-mobile-items-heading">Current Password</p>
                    <div className="settings-tab-password-input-div">
                        <input 
                            type={showCurrentPassword ? 'text' : 'password'} 
                            className="settings-tab-mobile-items-input" 
                            value={currentPassword} 
                            onChange={(e) => setCurrentPassword(e.target.value)} 
                        />
                        <LazyLoadImage 
                            src="/static/crew/assets/img/icons/fi_eye.svg" 
                            alt="Toggle Current Password Visibility" 
                            onClick={toggleShowCurrentPassword} 
                        />
                    </div>
                    {errors.currentPassword && <p className="form-error-text">{errors.currentPassword}</p>}
                </div>
                <div className="settings-tab-mobile-items">
                    <p className="settings-tab-mobile-items-heading">New Password</p>
                    <div className="settings-tab-password-input-div">
                        <input 
                            type={showNewPassword ? 'text' : 'password'} 
                            className="settings-tab-mobile-items-input" 
                            value={newPassword} 
                            onChange={(e) => setNewPassword(e.target.value)} 
                        />
                        <LazyLoadImage 
                            src="/static/crew/assets/img/icons/fi_eye.svg" 
                            alt="Toggle New Password Visibility" 
                            onClick={toggleShowNewPassword} 
                        />
                    </div>
                    {errors.newPassword && <p className="form-error-text">{errors.newPassword}</p>}
                </div>
                <div className="settings-tab-mobile-items">
                    <p className="settings-tab-mobile-items-heading">Confirm New Password</p>
                    <div className="settings-tab-password-input-div">
                        <input 
                            type={showConfirmNewPassword ? 'text' : 'password'} 
                            className="settings-tab-mobile-items-input" 
                            value={confirmNewPassword} 
                            onChange={(e) => setConfirmNewPassword(e.target.value)} 
                        />
                        <LazyLoadImage 
                            src="/static/crew/assets/img/icons/fi_eye.svg" 
                            alt="Toggle Confirm New Password Visibility" 
                            onClick={toggleShowConfirmNewPassword} 
                        />
                    </div>
                    {errors.confirmNewPassword && <p className="form-error-text">{errors.confirmNewPassword}</p>}
                </div>
                <button className="btn btn-primary save-button-mail" type="button" onClick={handleSubmit}>
                    Change Password
                </button>
            </div>
        </div>
    );
};

export default ChangePassword;

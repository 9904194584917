import React from 'react';

const Scripts = () => {
  return (
    <>
      <script src="/static/crew/assets/js/jquery.min.js" ></script>
      <script src="/static/crew/assets/bootstrap/js/bootstrap.min.js" ></script>
    </>
  );
};

export default Scripts;

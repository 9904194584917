import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Head from '../components/layouts/Head';
import NavbarHome from '../components/layouts/NavbarHome';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import NavbarCrew from '../components/layouts/NavbarCrew';
import { getIsLoggedIn } from '../components/tokenManager';
import { Modal, Button } from 'react-bootstrap';
import { checkReferCode, applyReferCode } from '../components/apiService';
import { getLocalJsonItem, getLocalItem } from '../components/tokenManager';

const ReferAndEarnTailScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const ReferAndEarnCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/refer_earn.css" />
    </>
  );
};

const ReferAndEarn = () => {
  const isLoggedIn = getIsLoggedIn(); 
  const userData =  getLocalJsonItem("userData");
  const userToken = getLocalItem("accessToken"); 

  // get status for refer code Starts
  const [checkReferCodeStatus, setCheckReferCodeStatus] = useState(false);
  useEffect(() => {
    const fetchReferCodeStatus = async () => {
        try {
            const res = await checkReferCode(userToken);
             if ( res.msg === "You have already used the refer code." ) {
                 setCheckReferCodeStatus(true);
             }
        } catch (error) {
            console.error('Error fetching refer code status:', error);
        }
    };
    fetchReferCodeStatus();
  }, [userToken]);
  // get status for refer code Ends



  // Modal to enter Code Starts
  const [referralCode, setReferralCode] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleInputChange = (e) => {
      setReferralCode(e.target.value);
  };

  const handleSubmit = async () => {
    try {
        // Apply referral code
        const res = await applyReferCode(referralCode, userToken);
        if (res.data.msg === "You have already used this refer code.") {
          toast.error(res.data.msg);
          setIsModalOpen(false);
        } else
        {
          toast.info("Code applied successfully.");
            setCheckReferCodeStatus(true);
            setIsModalOpen(false);
        }
    } catch (error) {
        // console.error('Error applying referral code:', error);
        toast.info("Referral code does not Exist.");
    }
};

  const openModal = () => {
      setIsModalOpen(true);
  };

  const closeModal = () => {
      setIsModalOpen(false);
  };
  // Modal to enter Code Ends

  // Copy to clipboard Starts
  const copyToClipboard = () => {
    navigator.clipboard.writeText(userData.user_code)
        .then(() => {
            toast.info("Code copied to clipboard");
        })
        .catch((error) => {
            console.error('Error copying code to clipboard:', error);
        });
  };
  // Copy to clipboard Ends

  return (
    <>
        <Head />
        <ToastContainer />
        {!isLoggedIn ?
          <>
            <ReferAndEarnCss />
            <NavbarHome />
          </> 
          :
          <>
            <ReferAndEarnCss />
            <NavbarCrew />
          </>
        }

        <div className="refer-earn-header-top">
            <div className="container refer-earn-container">
                <div className="refer-earn-container-div">
                    <h1 className="refer-earn-container-div-head">Refer And Get 100 Credits</h1>
                    <p className="refer-earn-container-detail">
                        Invite your friends to use Join My Ship web and app &amp; get 100 credit points when your friend uses your referral code after signup.
                    </p>
                </div>
            </div>
        </div>

        <div className="refer-earn-tail-bot">
            <div className="container refer-earn-tail-bot-container">
                <div className="row">
                    <div className="col-md-6 refer-earn-tail-left-container">
                        <div className="refer-earn-tail-left-container-div">
                            <div className="refer-earn-tail-left-container-div-div">
                                <div className="refer-earn-left-container-heading-div">
                                    <p className="refer-earn-left-container-heading-text">Unlock Rewards with Every Referral</p>
                                    <LazyLoadImage className="refer-earn-left-container-heading-img" src="/static/crew/assets/img/icons/red_heart.svg" alt="Red Heart"/>
                                </div>
                                <div className="refer-earn-left-container-list-info">
                                    <div className="refer-earn-left-container-item-info">
                                        <LazyLoadImage className="refer-earn-left-container-item-img" src="/static/crew/assets/img/icons/invite_send.svg" alt="Invite Send"/>
                                        <p className="refer-earn-left-container-item-text">Invite your friends to Join My Ship.</p>
                                    </div>
                                    <div className="refer-earn-left-container-item-info">
                                        <LazyLoadImage className="refer-earn-left-container-item-img" src="/static/crew/assets/img/icons/cart_blue.svg" alt="Cart Blue"/>
                                        <p className="refer-earn-left-container-item-text">Your friend uses your referral code.</p>
                                    </div>
                                    <div className="refer-earn-left-container-item-info">
                                        <LazyLoadImage className="refer-earn-left-container-item-img" src="/static/crew/assets/img/icons/wallet_points.svg" alt="Wallet Points"/>
                                        <p className="refer-earn-left-container-item-text">You get 100 credit points in your wallet.</p>
                                    </div>
                                </div>
                                <div className="refer-earn-left-copy-code-container">
                                    <p className="refer-earn-copy-code-text">{userData.user_code}</p>
                                    <LazyLoadImage className="refer-earn-copy-code-img" onClick={copyToClipboard} src="/static/crew/assets/img/icons/content_copy.svg" alt="Copy Code"/>
                                </div>
                                <button className="btn btn-primary refer-earn-left-share-now-btn" type="button" onClick={copyToClipboard} data-bs-target="#modal-enter-code" data-bs-toggle="modal">Share Now</button>
                                
                                {checkReferCodeStatus === true ? 
                                    <div className="refer-earn-used-refer-code-div">
                                        <LazyLoadImage className="refer-earn-used-refer-code-img" src="/static/crew/assets/img/icons/CheckMarkUsed_green.svg" alt="Check Mark Used"/>
                                        <p className="refer-earn-used-refer-code">Referral code already used</p>
                                    </div>
                                :
                                    <p className="refer-earn-left-footer-text" onClick={openModal}>Have a referral code ?</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 refer-earn-tail-right-container">
                        <div className="refer-earn-tail-right-container-div">
                            <LazyLoadImage className="refer-earn-tail-right-col-img" src="/static/crew/assets/img/images/referal_page_image.svg" alt="Referral Page"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{paddingBottom: '122px', background:'#F5F5F5'}}></div>

        <Modal show={isModalOpen} onHide={closeModal} centered>
            <Modal.Header className='modal-enter-code-header'>
                <Modal.Title id="modal-enter-code-header-text">Enter Referral Code</Modal.Title>
            </Modal.Header>
            <Modal.Body className='modal-enter-code-body'>
                <input
                    type="text"
                    className="form-control modal-enter-code-body-input"
                    value={referralCode}
                    onChange={handleInputChange}
                />
            </Modal.Body>
            <Modal.Footer className='modal-enter-code-footer'>
                <Button variant="primary" className='modal-enter-code-footer-btn' onClick={handleSubmit}>
                    Submit
                </Button>
            </Modal.Footer>
            <div className="text-center mt-3">
                <p className="modal-enter-code-footer-text">Terms & Conditions</p>
            </div>
        </Modal>
        <Footer />
        <Scripts />
        <ReferAndEarnTailScript />
    </>
  );
};

export default ReferAndEarn
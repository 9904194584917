import React from 'react'
import Head from '../components/layouts/Head';
import NavbarHome from '../components/layouts/NavbarHome';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import HomeContent from '../components/layouts/HomeContent';

const HomeTailScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const NavbarCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
    </>
  );
};


const Home = () => {

  return (
    <>
        <Head />
        <NavbarCss />
        <NavbarHome />
        <HomeContent />
        <Footer />
        <Scripts />
        <HomeTailScript />
    </>
  );
};

export default Home
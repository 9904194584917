import React, { useState, useEffect } from 'react';
import { getLocalItem, setLocalItem } from '../components/tokenManager';
import { apiConfigs } from '../config/apiConfig';
import { get, refreshAccessToken } from '../components/apiService';

const TestApp = () => {
  const { refreshToken, get_user_list } = apiConfigs;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLocalItem( "accessToken",apiConfigs.accessToken);
  
      try {
        const accessToken = getLocalItem("accessToken");
        if (!accessToken) {
          throw new Error("Access token not found");
        }
  
        const userData = await get(get_user_list, accessToken);
        setData(userData);
      } catch (error) {
        if (error.data.messages[0].message === "Access token not found") {
          setError('Access token expired');
        } else if (error.data && error.status === 401) {
          try {
            await refreshAccessToken(refreshToken);
            const refreshedAccessToken = getLocalItem("accessToken");
            setLocalItem( "accessToken", refreshedAccessToken);
            const refreshedUserData = await get(get_user_list, refreshedAccessToken);
            setData(refreshedUserData);
          } catch (refreshError) {
            setError(refreshError.message);
          }
        } else {
          setError(error.message);
        }
      }
    };
  
    fetchData();
  
    const tokenExpiryCheckInterval = setInterval(() => {
      const accessToken = getLocalItem("accessToken");
      if (!accessToken) {
        clearInterval(tokenExpiryCheckInterval);
        setError('Access token expired');
      }
    }, 1000 * 30); 
  
    return () => {
      clearInterval(tokenExpiryCheckInterval);
    };
  
  }, []); // Empty dependency array to ensure useEffect runs only once
  
  return (
    <div>
      <h1>API Calls with Authorization in React</h1>
      {error && <p>Error: {error}</p>}
      <ul>
        {data &&
          data.map(item => (
            <li key={item.id}>
              {item.id} - {item.email}
            </li>
          ))}
      </ul>
    </div>
  );
};

export default TestApp;

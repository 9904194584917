import React, { useState  } from 'react';
import { Card, Button, Dropdown, Modal } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { jobLiked, jobUnLiked, applyJob, deleteReferJob } from '../apiService';
import { ApplyJobModal, AppliedSuccessModal } from './FindJobsModal';
import { apiRoutes } from '../../config/apiConfig';


const CrewMyJobReferredCard = ({ job, userData, userToken }) => {
  const [isLikedCount, setIsLikedCount] = useState(job.joblike_count);
  const [isLiked, setIsLiked] = useState(job.userlikedjob_status);
  const [isApplied, setIsApplied] = useState(job.userappliedjob_status);
  const [showMenu, setShowMenu] = useState(false);
  const [selectedRank, setSelectedRank] = useState(null);
  const [selectedRankId, setSelectedRankId] = useState(null);
  const [isConditionTrue, setIsConditionTrue] = useState(true);

  const handleRadioChange = (rankName, rankId) => {
    setSelectedRank(rankName);
    setSelectedRankId(rankId);
  };

  // handel Like Starts  

  const handleLikeToggle = () => {
    if (isLiked) {
      jobUnLiked(userData.id, job.id, userToken)
        .then(response => {
          setIsLiked(false);
          setIsLikedCount(isLikedCount - 1);
        })
        .catch(error => {
          console.error('Error unliking Job:', error);
        });
    } else {
      const formData = new FormData();
      formData.append("liked_post", job.id);
      formData.append("user_id", userData.id);
      jobLiked(formData, userToken)
        .then(response => {
          setIsLiked(true);
          setIsLikedCount(isLikedCount + 1);
        })
        .catch(error => {
          console.error('Error liking Job:', error);
        });
    }
  };
  
  // handel Like Ends  


  // handel apply Starts  
  // show modal for Apply Job Starts  
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setIsApplied(false);
  };

  const handleApply = () => {
    setShowModal(true);
  };

  const handleConfirmApply = async () => {
    setSelectedRankId(job.Job_Rank_wages[0].rank_number.id);

    try {
      const applyJobData = {
        user_id: userData.id,
        rank_id: selectedRankId,
        job_id: job.id,
        sub_id: 12
      };

      await applyJob(applyJobData, userToken);
      setIsApplied(true);
      setShowModal(false);
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error applying for job:', error);
    }

  };
  // show modal for Apply Job Ends  

  // handel apply Ends  

  // Radio button Starts  
  const isRadioEnabled = (rankName) => {
    const condition = rankName === job.posted_by.rank_id.name || rankName === job.posted_by.rank_id.promoted_to.name;
    if (!isConditionTrue && condition) {
      setIsConditionTrue(true);
    }
    return condition;
  };

  // Radio button Ends  
  // console.log("jobjobjobjob", job);


  const [showDeleteSubmitModal, setShowDeleteSubmitModal] = useState(false);
  const [showDeleteSuccessModal, setShowDeleteSuccessModal] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null); // State to store the selected job id

  const handleShowDeleteSubmitModal = (jobId) => {
      setSelectedJobId(jobId); // Set the selected job id
      setShowDeleteSubmitModal(true);
  };

  const handleCloseDeleteSubmitModal = () => setShowDeleteSubmitModal(false);

  const handleShowDeleteSuccessModal = async () => {
      await deleteReferJob(selectedJobId, userToken);
      setShowDeleteSubmitModal(false); 
      setShowDeleteSuccessModal(true); 
  };

  const handleCloseDeleteSuccessModal = () => {
    setShowDeleteSuccessModal(false);
    window.location.reload(); 
  };



  return (
    <>
      <Card>
        <Card.Body>
          <div className='job-card-cont'>
            <div className="job-card">
              <div className="jobby-info-container">
                <div className="jobby-info">
                  <LazyLoadImage
                    className="profile-img-info"
                    src={job.posted_by.profilePic}
                    alt="Profile"
                  />
                  <div>
                    <h1 className="jobby-name">Job By: {job.posted_by.first_name}</h1>
                    <h1 className="company-name">Referred Job</h1>
                  </div>
                </div>
              </div>
              <div className="apply-job" style={{ display: 'flex', flexDirection: 'column', gap: '21px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '32px' }}>
                  <Button className="like-button btn-primary" type="button" onClick={handleLikeToggle}>
                    <LazyLoadImage
                      src={isLiked ? '/static/crew/assets/img/icons/Heart.svg' : '/static/crew/assets/img/icons/Like_heart.svg'}
                      alt={isLiked ? 'Liked' : 'Like'}
                    />
                    &nbsp; &nbsp;
                    {isLikedCount}
                    &nbsp; &nbsp;
                    {isLiked ? 'Likes' : 'Like'}
                  </Button>
                  <Dropdown show={showMenu} onToggle={() => setShowMenu(!showMenu)} align="end">
                    <Dropdown.Toggle as={Button} variant="secondary" onClick={() => setShowMenu(!showMenu)} style={{
                        background: 'none',
                        border: 'none',
                        padding: '0',
                        margin: '0',
                        cursor: 'pointer'
                    }}>
                    <LazyLoadImage src='/static/crew/assets/img/icons/Vertical_Dots.svg' alt="Menu" style={{ width: '24px', height: '24px', paddingTop: '5px' }} />
                    </Dropdown.Toggle>
                    <Dropdown.Menu>
                        <Dropdown.Item href={`${apiRoutes.crew_job_refer_edit}${job.id}`} className='refer-job-options' >Edit</Dropdown.Item>
                        <Dropdown.Item onClick={() => handleShowDeleteSubmitModal(job.id)} className='refer-job-delete-option' >Delete</Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Tentative Joining Date:</h1>
                <h1 className="job-details">{job.tentative_joining}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Vessel Type:</h1>
                <h1 className="job-details">{job.vessel_id.name}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Flag:</h1>
                <h1 className="job-details">{ job.flag_key && job.flag_key.country_name}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Joining Port:</h1>
                <h1 className="job-details">{job.joining_port}</h1>
              </div>
            </div>
            <div className="job-wages-container">
              <div className="job-wages-container-1">
                  <div className="job-wages">
                    <LazyLoadImage src="/static/crew/assets/img/icons/job_bag.svg" alt="Job Bag" />
                    <p className="job-wages-name">{job.Job_Rank_wages[0].rank_number.name} - {job.Job_Rank_wages[0].wages}</p>
                  </div>
              </div>
              {job.Job_Rank_wages.slice(5).length > 0 && (
                <div className="job-wages-container-1">
                  {job.Job_Rank_wages.slice(5).map((wage, index) => (
                    <div key={index + 5} className="job-wages">
                      <input
                        type="radio"
                        name={`rank-${job.id}`}
                        checked={selectedRank === wage.rank_number.name}
                        onChange={() => handleRadioChange(wage.rank_number.name, wage.rank_number.id)}
                        disabled={!isRadioEnabled(wage.rank_number.name)}
                      />
                      <LazyLoadImage src="/static/crew/assets/img/icons/job_bag.svg" alt="Job Bag" />
                      <p className="job-wages-name">{wage.rank_number.name} - {wage.wages}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
              <div className="requirement-container">
                  {job.job_coc && job.job_coc.length > 0 && (
                      <div className="requirement-container-both">
                        <h1 className="requirement-heading">COC Requirement:</h1>
                        <div className="requirement-value">
                            {job.job_coc.map((coc_names, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="requirement-heading">{coc_names.coc_id.name}</h1>
                                    {index !== job.job_coc.length - 1 && <span>|</span>}
                                </React.Fragment>
                            ))}
                        </div>
                      </div>
                  )}
                  {job.job_cop && job.job_cop.length > 0 && (
                    <div className="requirement-container-both">
                        <h1 className="requirement-heading">COP Requirement:</h1>
                        <div className="requirement-value">
                            {job.job_cop.map((cop_names, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="requirement-heading">{cop_names.cop_id.name}</h1>
                                    {index !== job.job_cop.length - 1 && <span>|</span>}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                  )}
              </div>

              <div className="requirement-container">
                  {job.job_Watch_Keeping && job.job_Watch_Keeping.length > 0 && (
                    <div className="requirement-container-both">
                      <h1 className="requirement-heading">Watch-Keeping Requirement:</h1>
                        <div className="requirement-value">
                            {job.job_Watch_Keeping.map((coc_names, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="requirement-heading">{coc_names.Watch_Keeping_id.name}</h1>
                                    {index !== job.job_Watch_Keeping.length - 1 && <span>|</span>}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                  )}
              </div>
          </div>
        </Card.Body>
      </Card>
      <ApplyJobModal show={showModal} handleClose={handleCloseModal} handleConfirmApply={handleConfirmApply} />
      <AppliedSuccessModal show={showSuccessModal} handleClose={() => setShowSuccessModal(false)} />

            <Modal show={showDeleteSubmitModal} onHide={handleCloseDeleteSubmitModal} centered backdrop="static">
                <Modal.Header className="modal-submit-confirm-header">
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-submit-confirm-body">
                    <p>Are you sure you want to delete this job?</p>
                </Modal.Body>
                <Modal.Footer className="modal-submit-confirm-footer" style={{ borderTop: 'none' }}>
                    <Button variant="light" onClick={handleCloseDeleteSubmitModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleShowDeleteSuccessModal}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showDeleteSuccessModal}  centered backdrop="static">
                <Modal.Header className="myjob-header">
                    <LazyLoadImage src="/static/crew/assets/img/icons/double_check.svg" alt="Success" />
                </Modal.Header>
                <Modal.Body className="myjob-body">
                    <div className="myjob-body-container">
                        <p className="myjob-body-para-2">
                            Job Deleted successfully
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className="myjob-footer" style={{ borderTop: 'none' }}>
                    <Button variant="light" onClick={handleCloseDeleteSuccessModal}>
                        Close
                    </Button>
                    {/* <Button variant="primary" href={apiRoutes.crew_my_jobs}>
                        My Jobs&nbsp;<LazyLoadImage src="/static/crew/assets/img/icons/fi_arrow-right.svg" alt="Arrow Right" />
                    </Button> */}
                </Modal.Footer>
            </Modal>

    </>
  );
};

export default CrewMyJobReferredCard;

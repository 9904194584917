import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Scripts from '../components/layouts/Scripts';
import { Container, Button, Accordion, Table } from 'react-bootstrap';
import {  getUserById, parseAuthorityData, parseAuthorityDataAllObj,
            companyFollowed, companyUnFollowed, resumeDownloadNew, profileShortlisted,
            updateUserById
        } from '../components/apiService';
import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';
import { apiRoutes } from '../config/apiConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';


const EmployerViewApplicantProfileScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-dropdown-1.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Text-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Bootstrap-4-Custom-Radio.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Search-Input-responsive.css" />
      {/* <link rel="stylesheet" href="/static/crew/assets/css/createprofile.css" /> */}
      <link rel="stylesheet" href="/static/crew/assets/css/view_applicants.css" />

    </>
  );
};

const EmployerViewApplicantProfileScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const EmployerViewApplicantProfile = () => {
    const userToken = getLocalItem("accessToken"); 
    const userData =  getLocalJsonItem("userData");
    const { profileID, jobId } = useParams();
    const [ userProfileData, setUserProfileData] = useState(null);
    const maritalStatusMapping = {
        1: 'Single',
        2: 'Married',
        3: 'Divorced',
      };

    useEffect(() => {
        const fetchJobs = async () => {
          try {
            const response = await getUserById( profileID, userToken);
            setUserProfileData(response);
          } catch (error) {
            console.error('Error fetching job list:', error);
          } 
        };

        fetchJobs();
    }, [profileID]);

    // Follow Toggle Starts
    const [shortlistStatus, setShortlistStatus] = useState(false);
    const handleShortlistToggle = async (profileID) => {
        if (shortlistStatus) {
            const updatedUserData = {
                shortlisted_status: false
            }
            const updatedUser = await updateUserById(profileID, updatedUserData, userToken);
            if (updatedUser.status === 200) {
                setShortlistStatus(false);
            }
        } else {
            profileShortlisted(profileID, jobId, userToken)
            .then(response => {
                setShortlistStatus(true);
            })
            .catch(error => {
                console.error('Error following User:', error);
            });
        }
    };
    // Follow Toggle Ends


    // resume download Starts
    const handleResumeDownload = (profileID) => {
        const formData = {
            "job_id": profileID,
            "user_id": profileID,
        };
        resumeDownloadNew(formData, userToken)
            .then(response => {
                const fileURL = response.data.fileURL; 
                const link = document.createElement('a');
                link.href = fileURL;
                link.setAttribute('download', 'resume.pdf');
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch(error => {
                toast.error(error.response.data.detail);
                console.error('Error downloading resume:', error);
            });
    };
    // resume download Ends 

    // console.log("userProfileData", userProfileData);
    // console.log("userData", userData);

    return (
        <>
            <EmployerViewApplicantProfileScriptCss />
            <NavbarCrew />
            <ToastContainer />
            {userProfileData ?
                <>
                    <div className="heading-container">
                        <Container>
                            <div style={{ padding: '22px 0px', display: 'flex', flexDirection: 'column', gap: '14px' }}>
                            <h1 className="heading-main">View Applicants</h1>
                            <p className="heading-desc">View and connect with top crew applicants instantly!</p>
                            </div>
                        </Container>
                    </div>
                    <Container className="view-applicants-image-container">
                        <div className="view-applicants-profile-image-container">
                        <LazyLoadImage
                            className="view-applicants-profile-image-container-eye-img"
                            src="/static/crew/assets/img/icons/Eyewhite.svg"
                            alt="Eye Icon"
                        />
                        <LazyLoadImage
                            className="view-applicants-profile-image-container-img-blue-dot"
                            src="/static/crew/assets/img/icons/bluedot.svg"
                            alt="Blue Dot"
                        />
                        <LazyLoadImage
                            className="view-applicants-profile-image-container-img"
                            src={userProfileData.profilePic}
                            alt="Profile"
                        />
                        </div>
                    </Container>
                    <Container className="applicants-basic-details-container">
                        <div className='applicants-basic-details-section'>
                            <div className="applicants-basic-details-left-section">
                                <div className="basic-details-left-heading">
                                    <LazyLoadImage
                                        className="basic-details-left-heading-image"
                                        src="/static/crew/assets/img/icons/editAdd.svg"
                                        alt="Edit Add Icon"
                                    />
                                    <p className="basic-details-left-heading-title">Basic Details</p>
                                </div>
                                <div className="basic-details-left-body">
                                    <div className="basic-details-left-body-details-data">
                                        <div className="basic-details-left-body-details">
                                        <p className="basic-details-left-body-details-title">Name</p>
                                        <p className="basic-details-left-body-details-value">{userProfileData.first_name}</p>
                                        </div>
                                        <div className="basic-details-left-body-details">
                                        <p className="basic-details-left-body-details-title">Rank</p>
                                        <p className="basic-details-left-body-details-value">{userProfileData.rank_id.name}</p>
                                        </div>
                                        <div className="basic-details-left-body-details">
                                        <p className="basic-details-left-body-details-title">Marital Status</p>
                                        <p className="basic-details-left-body-details-value">{maritalStatusMapping[userProfileData.marital_status]}</p>
                                        </div>
                                    </div>
                                    <div className="basic-details-left-body-details-data">
                                        <div className="basic-details-left-body-details">
                                        <p className="basic-details-left-body-details-title">Gender</p>
                                        <p className="basic-details-left-body-details-value">{userProfileData.gender === 1 ? 'Male' : 'Female'}</p>
                                        </div>
                                        <div className="basic-details-left-body-details">
                                        <p className="basic-details-left-body-details-title">DOB</p>
                                        <p className="basic-details-left-body-details-value">{userProfileData.dob}</p>
                                        </div>
                                        <div className="basic-details-left-body-details">
                                        <p className="basic-details-left-body-details-title">Looking for promotion</p>
                                        <p className="basic-details-left-body-details-value">{userProfileData.promotion_applied ? 'Yes' : 'No'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="applicants-basic-details-right-section">
                                <Button
                                    className="btn btn-primary applicants-button-resume-download"
                                    type="button"
                                    onClick={() => handleResumeDownload(profileID)}
                                >
                                    <LazyLoadImage src="/static/crew/assets/img/icons/Download_cloud.svg" alt="Download Icon" />
                                    &nbsp; Resume
                                </Button>
                                { shortlistStatus ?
                                    <Button className="btn applicants-button-shortlist" 
                                        onClick={() => handleShortlistToggle(profileID)}
                                        type="button">
                                        <LazyLoadImage src="/static/crew/assets/img/icons/bookmark_orange.svg" alt="Bookmark Icon" />
                                        &nbsp; Shortlisted
                                    </Button>
                                    :
                                    <Button className="btn applicants-button-shortlist" 
                                        onClick={() => handleShortlistToggle(profileID)}
                                        type="button">
                                        <LazyLoadImage src="/static/crew/assets/img/icons/bookmark_orange.svg" alt="Bookmark Icon" />
                                        &nbsp; Shortlist
                                    </Button>
                                }
                            </div>
                        </div>
                        <div className="applicants-other-details-section">
                            <div className="basic-details-left-heading">
                                <LazyLoadImage className="basic-details-left-heading-image" src="/static/crew/assets/img/icons/Radar2.svg" alt="Radar Icon" />
                                <p className="basic-details-left-heading-title"><strong>Other Details</strong></p>
                            </div>
                            {userProfileData.crew_details_user ?

                                <div className="applicants-other-details-section-details-div">
                                    <div className="applicants-other-details-section-details-info-div-1">
                                        <div className="applicants-other-details-section-details-info-div">
                                            <p className="applicants-other-details-section-details-key">INDOS No</p>
                                            <p className="applicants-other-details-section-details-value">{userProfileData.crew_details_user.INDOS_number}</p> 
                                        </div>
                                        <div className="applicants-other-details-section-details-info-div-2">
                                            <div className="applicants-other-details-section-details-info-div">
                                                <p className="applicants-other-details-section-details-key">Holding Valid US Visa?</p>
                                                <p className="applicants-other-details-section-details-value">{userProfileData.crew_details_user.valid_US_Visa ? 'Yes' : 'No'}</p>
                                            </div>
                                            <div className="applicants-other-details-section-details-info-div">
                                                <p className="applicants-other-details-section-details-key">Visa Valid Till</p>
                                                <p className="applicants-other-details-section-details-value">{userProfileData.crew_details_user.valid_US_Visa_valid_till}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="applicants-other-details-section-details-info-cdc">
                                        <p className="applicants-other-details-section-details-key">CDC / SEAMAN BOOK DETAILS</p>
                                        <div className="applicants-other-details-section-details-info-div-2">
                                            <div className="applicants-other-details-section-details-info-div">
                                                <p className="applicants-other-details-section-details-key">CDC No</p>
                                                <p className="applicants-other-details-section-details-value">{userProfileData.crew_details_user.CDC_seaman_book}</p>
                                            </div>
                                            <div className="applicants-other-details-section-details-info-div">
                                                <p className="applicants-other-details-section-details-key">Issuing Authority</p>
                                                <p className="applicants-other-details-section-details-value">{parseAuthorityData(userProfileData.crew_details_user.CDC_Issuing_Authority)}</p>
                                            </div>
                                            <div className="applicants-other-details-section-details-info-div">
                                                <p className="applicants-other-details-section-details-key">Valid Till</p>
                                                <p className="applicants-other-details-section-details-value">{userProfileData.crew_details_user.CDC_seaman_book_valid_till}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="applicants-other-details-section-details-info-cdc">
                                        <p className="applicants-other-details-section-details-key">PASSPORT DETAILS</p>
                                        <div className="applicants-other-details-section-details-info-div-2">
                                            <div className="applicants-other-details-section-details-info-div">
                                                <p className="applicants-other-details-section-details-key">Passport No</p>
                                                <p className="applicants-other-details-section-details-value">{userProfileData.crew_details_user.Passport_number}</p>
                                            </div>
                                            <div className="applicants-other-details-section-details-info-div">
                                                <p className="applicants-other-details-section-details-key">Issuing Authority</p>
                                                <p className="applicants-other-details-section-details-value">{parseAuthorityData(userProfileData.crew_details_user.Passport_Issuing_Authority) }</p>
                                            </div>
                                            <div className="applicants-other-details-section-details-info-div">
                                                <p className="applicants-other-details-section-details-key">Valid Till</p>
                                                <p className="applicants-other-details-section-details-value">{userProfileData.crew_details_user.Passport_number_valid_till}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                :
                                "No Data"
                            }
                        </div>
                    </Container>
                    {userProfileData.crew_details_user ?
                        <Container>
                            <Accordion defaultActiveKey="0">
                                <Accordion.Item eventKey="0">
                                    <Accordion.Header className='applicants-accordian-btn'>STCW Details</Accordion.Header>
                                    <Accordion.Body>
                                        <div className="table-responsive">
                                            <Table  bordered hover>
                                                <thead>
                                                    <tr >
                                                        <th className='applicants-accordian-head-table-key accordian-table-th'>Issuing Authority</th>
                                                        <th className='applicants-accordian-head-table-key accordian-table-th'>Valid Till</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {parseAuthorityDataAllObj(userProfileData.crew_details_user.STCW_Issuing_Authority).map((stcwObj, index) => (
                                                        (stcwObj.name === "Others" ?
                                                            <tr key={index}>
                                                                <td className='applicants-accordian-head-table-value'>{stcwObj.custom_name}</td>
                                                                <td className='applicants-accordian-head-table-value'>{stcwObj.valid_till}</td>
                                                            </tr>
                                                            :
                                                            <tr key={index}>
                                                                <td className='applicants-accordian-head-table-value'>{stcwObj.name}</td>
                                                                <td className='applicants-accordian-head-table-value'>{stcwObj.valid_till}</td>
                                                            </tr>
                                                        )
                                                    ))}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Accordion.Body>
                                </Accordion.Item>

                                {userProfileData.rank_id.coc &&
                                    <Accordion.Item eventKey="1">
                                        <Accordion.Header className='applicants-accordian-btn'>COC Details</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="table-responsive">
                                                <Table  bordered hover>
                                                    <thead>
                                                        <tr >
                                                            <th className='applicants-accordian-head-table-key accordian-table-th'>Issuing Authority</th>
                                                            <th className='applicants-accordian-head-table-key accordian-table-th'>Valid Till</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {parseAuthorityDataAllObj(userProfileData.crew_details_user.valid_COC_Issuing_Authority).map((stcwObj, index) => (
                                                            (stcwObj.name === "Others" ?
                                                                <tr key={index}>
                                                                    <td className='applicants-accordian-head-table-value'>{stcwObj.custom_name}</td>
                                                                    <td className='applicants-accordian-head-table-value'>{stcwObj.valid_till}</td>
                                                                </tr>
                                                                :
                                                                <tr key={index}>
                                                                    <td className='applicants-accordian-head-table-value'>{stcwObj.name}</td>
                                                                    <td className='applicants-accordian-head-table-value'>{stcwObj.valid_till}</td>
                                                                </tr>
                                                            )
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }
                                {userProfileData.rank_id.cop &&
                                    <Accordion.Item eventKey="2">
                                        <Accordion.Header className='applicants-accordian-btn'>COP Details</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="table-responsive">
                                                <Table  bordered hover>
                                                    <thead>
                                                        <tr >
                                                            <th className='applicants-accordian-head-table-key accordian-table-th'>Issuing Authority</th>
                                                            <th className='applicants-accordian-head-table-key accordian-table-th'>Valid Till</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {parseAuthorityDataAllObj(userProfileData.crew_details_user.valid_COP_Issuing_Authority).map((stcwObj, index) => (
                                                            (stcwObj.name === "Others" ?
                                                                <tr key={index}>
                                                                    <td className='applicants-accordian-head-table-value'>{stcwObj.custom_name}</td>
                                                                    <td className='applicants-accordian-head-table-value'>{stcwObj.valid_till}</td>
                                                                </tr>
                                                                :
                                                                <tr key={index}>
                                                                    <td className='applicants-accordian-head-table-value'>{stcwObj.name}</td>
                                                                    <td className='applicants-accordian-head-table-value'>{stcwObj.valid_till}</td>
                                                                </tr>
                                                            )
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }
                                {userProfileData.rank_id.watch_keeping &&
                                    <Accordion.Item eventKey="3">
                                        <Accordion.Header className='applicants-accordian-btn'>Watch-keeping Details</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="table-responsive">
                                                <Table  bordered hover>
                                                    <thead>
                                                        <tr >
                                                            <th className='applicants-accordian-head-table-key accordian-table-th'>Issuing Authority</th>
                                                            <th className='applicants-accordian-head-table-key accordian-table-th'>Valid Till</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {parseAuthorityDataAllObj(userProfileData.crew_details_user.valid_Watch_keeping_Issuing_Authority).map((stcwObj, index) => (
                                                            (stcwObj.name === "Others" ?
                                                                <tr key={index}>
                                                                    <td className='applicants-accordian-head-table-value'>{stcwObj.custom_name}</td>
                                                                    <td className='applicants-accordian-head-table-value'>{stcwObj.valid_till}</td>
                                                                </tr>
                                                                :
                                                                <tr key={index}>
                                                                    <td className='applicants-accordian-head-table-value'>{stcwObj.name}</td>
                                                                    <td className='applicants-accordian-head-table-value'>{stcwObj.valid_till}</td>
                                                                </tr>
                                                            )
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                }

                            </Accordion>
                        </Container>
                        :
                        ""
                    }
                    
                </>
                :
                <div>Loading...</div>
            }
            <div style={{height: "132px"}}></div>
            <Scripts />
            <EmployerViewApplicantProfileScript />
        </>
    );
};

export default EmployerViewApplicantProfile;

import React, { useEffect, useState  } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavbarCreateProfile from '../components/layouts/NavbarCreateProfile';
import { Dropdown, InputGroup } from 'react-bootstrap';
import { getCDCCountryList, getPassportList, getSTCWList, 
        getUserByToken, getRankById, getCocListByUserType,
        getCopListByUserType, getWatchkeepingListByUserType, 
        formatDate, insertCrewDetails, getCrewDetailsByUserId, 
        updateUserById, formatDateNew, parseDateObject, parseDateObjectByHyphen, updateCrewDetails
        } from '../components/apiService';
import { getIsLoggedIn, getLocalItem, getLocalJsonItem, setLocalJsonItem } from '../components/tokenManager';
import { apiRoutes } from '../config/apiConfig';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { Form } from 'react-bootstrap';

const CrewCreateProfileStep2Css = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Bootstrap-4-Custom-Radio.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/createprofile.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Date-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-dropdown.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Text-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Upload-File.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/JMS-Custom-Button.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/JMS-mobile-number.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />

    </>
  );
};


const CrewCreateProfileStep3 = () => {
    const navigate = useNavigate();
    const userToken = getLocalItem("accessToken"); 
    // const [userData, setUserData] = useState(null);
    const [errorIndosInput, setErrorIndosInput] = useState(null);
    const [errorCDCNumInput, setErrorCDCNumInput] = useState(null);
    const [errorPassportInput, setErrorPassportInput] = useState(null);
    const [errorOtherStcWText, setErrorOtherStcWText] = useState(null);
    const [errorOtherPassport, setErrorOtherPassport] = useState(null);
    const [errorOtherCDC, setErrorOtherCDC] = useState(null);
    const [CrewDDetFetched, setCrewDDetFetched] = useState(null);

    const isLoggedIn = getIsLoggedIn();
    if (!isLoggedIn) {
        navigate(apiRoutes.home);
    }

    useEffect(() => {
        const fetchUserDataAndStoreLocally = async () => {
            try {
                const fetchedUserData = await getUserByToken(userToken);
                setLocalJsonItem("userData", fetchedUserData.data);
                const userData = getLocalJsonItem("userData");
              
            } catch (error) {
                console.error("Error fetching crewDetails data:", error);
            }
        };
        fetchUserDataAndStoreLocally();
    }, [userToken]);
    
    const userData = getLocalJsonItem("userData");

    useEffect(() => {
        const fetchCrewDetailsDataAndStoreLocally = async () => {
            try {
                const fetchedCrewDetailsData = await getCrewDetailsByUserId(userToken, userData.id);
                setLocalJsonItem("crewDetails", fetchedCrewDetailsData.data);
            } catch (error) {
                console.error("Error fetching crewDetails data:", error);
            }
        };
        fetchCrewDetailsDataAndStoreLocally();
    }, [userToken]);

    const crewDetailsData = getLocalJsonItem("crewDetails");
    // console.log("crewDetailsData11111111111111111111", crewDetailsData);

    // useEffect(() => {
    //     const fetchUserDataAndStoreLocally = async () => {
    //         try {
    //             const fetchedUserData = await getUserByToken(userToken);
    //             setLocalJsonItem("userData", fetchedUserData.data);
    //             const userData = getLocalJsonItem("userData");
    //             // setUserData(fetchedUserData);
    //         } catch (error) {
    //             console.error("Error fetching user data:", error);
    //         }
    //     };
    //     fetchUserDataAndStoreLocally();
    // }, [userToken]); 


    const [rankPostList, setRankPostList] = useState([]);
    const [selectedRankPostItems, setSelectedRankPostItems] = useState([]);
    const [searchRankPostQuery, setSearchRankPostQuery] = useState('');
    const [rankPostSelectedAuthority, setRankPostSelectedAuthority] = useState(null);
    const [filteredRankPostList, setFilteredRankPostList] = useState([]);
    const [rankPostListFetched, setrankPostListFetched] = useState(false);

    

    // indosNumber Starts
    const [indosNumber, setIndosNumber] = useState('');
    const handleIndosNumberChange = (event) => {
      const inputValue = event.target.value;
      if (/^[a-zA-Z0-9]*$/.test(inputValue)) {
        setIndosNumber(inputValue);
        setErrorIndosInput(null);
      }else {
        setErrorIndosInput('Only alphanumeric characters are allowed');
      }
    };
    // indosNumber Ends

    // cdcNumber DropDown Satrts
    const [cdcCountryList, setCdcCountryList] = useState([]);
    const [filteredCDCCountryList, setFilteredCDCCountryList] = useState([]);
    const [cdcSelectedAuthority, setCdcSelectedAuthority] = useState(null);
    const [searchCdcCountryQuery, setSearchCdcCountryQuery] = useState('');
    const [cdcNumber, setCdcNumber] = useState('');
    const [cdcNumberDate, setCdcNumberDate] = useState('');
    const [cdcCustomName, setCdcCustomName] = useState('');

    useEffect(() => {
        const fetchCDCCountryList = async () => {
            try {
                const response = await getCDCCountryList();
                setCdcCountryList(response);
                setFilteredCDCCountryList(response); 
            } catch (error) {
                console.error('Error fetching CDC country list:', error);
            }
        };

        fetchCDCCountryList();
    }, []);

    const handleCDCCountrySelect = (cdcCountryItem) => {
        setCdcSelectedAuthority(cdcCountryItem);
        setSearchCdcCountryQuery(cdcCountryItem.name);
    };

    const handleKeyPressCDCOther = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const regex = /^[a-zA-Z0-9]*$/;
        
        if (!regex.test(keyValue)) {
            event.preventDefault();
            setErrorOtherCDC('Only alphanumeric characters are allowed');
        } else {
            setErrorOtherCDC(null);
        }
    };

    const handleCDCSearchInputChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchCdcCountryQuery(query);
        const filteredList = cdcCountryList.filter(item => item.name.toLowerCase().includes(query));
        setFilteredCDCCountryList(filteredList);
    };

    const handleCdcNumberChange = (event) => {
        const inputValue = event.target.value;
        if (/^[a-zA-Z0-9]*$/.test(inputValue)) {
            setCdcNumber(inputValue);
            setErrorCDCNumInput(null);
        } else {
            setErrorCDCNumInput('Only alphanumeric characters are allowed');
        }
    };

    const handlecdcNumberDateChange = (date) => {
        setCdcNumberDate(date);
    };

    const handleCdcCustomNameChange = (event) => {
        const inputValue = event.target.value;
        setCdcCustomName(inputValue);
    };
    // cdcNumber DropDown Ends


    
    


    // passport Satrts
   
    const [passportCountryList, setPassportCountryList] = useState([]);
    const [filteredPassportCountryList, setFilteredPassportCountryList] = useState([]);
    const [passportSelectedAuthority, setPassportSelectedAuthority] = useState(null);
    const [searchPassportCountryQuery, setSearchPassportCountryQuery] = useState('');
    const [passportNumber, setPassportNumber] = useState('');
    const [passportNumberDate, setPassportNumberDate] = useState('');
    const [passportCustomName, setPassportCustomName] = useState('');

    useEffect(() => {
        const fetchPassportCountryList = async () => {
            try {
                const response = await getPassportList();
                setPassportCountryList(response);
                setFilteredPassportCountryList(response); 
            } catch (error) {
                console.error('Error fetching Passport country list:', error);
            }
        };

        fetchPassportCountryList();
    }, []);

    const handlePassportCountrySelect = (passportCountryItem) => {
        setPassportSelectedAuthority(passportCountryItem);
        setSearchPassportCountryQuery(passportCountryItem.name);
    };

    const handleKeyPressPassportOther = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const regex = /^[a-zA-Z0-9]*$/;
        
        if (!regex.test(keyValue)) {
            event.preventDefault();
            setErrorOtherPassport('Only alphanumeric characters are allowed');
        } else {
            setErrorOtherPassport(null);
        }
    };

    const handlePassportSearchInputChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchPassportCountryQuery(query);
        const filteredList = passportCountryList.filter(item => item.name.toLowerCase().includes(query));
        setFilteredPassportCountryList(filteredList);
    };

    const handlePassportNumberChange = (event) => {
        const inputValue = event.target.value;
        if (/^[a-zA-Z0-9]*$/.test(inputValue)) {
            setPassportNumber(inputValue);
            setErrorPassportInput(null);
        } else {
            setErrorPassportInput('Only alphanumeric characters are allowed');
          }
    };

    const handlepassportNumberDateChange = (date) => {
        setPassportNumberDate(date);
    };

    const handlePassportCustomNameChange = (event) => {
        const inputValue = event.target.value;
        setPassportCustomName(inputValue);
    };

    const getSelectedAuthorityJsonDetails = (selectedAuthority, othersCustomName, validTillDate ) => {
        const authority = selectedAuthority ? (selectedAuthority.name === 'Others' ? "Others" : selectedAuthority.name) : null;
        const customName = selectedAuthority ? (selectedAuthority.name === 'Others' ? othersCustomName : null) : null;
        const validTill = validTillDate ? validTillDate.toLocaleDateString('en-GB') : null;

        return {
            issuing_authority: authority,
            custom_name: customName,
            valid_till: validTill
        };
    };

    // passport Ends

    // STCW Starts
    const [sTCWCountryList, setSTCWCountryList] = useState([]);
    const [selectedItems, setSelectedItems] = useState([]);
    const [searchSTCWCountryQuery, setSearchSTCWCountryQuery] = useState('');
    const [sTCWSelectedAuthority, setSTCWSelectedAuthority] = useState(null);
    const [filteredSTCWCountryList, setFilteredSTCWCountryList] = useState([]);
    const [stcwListFetched, setSTCWListFetched] = useState(false);

    useEffect(() => {
        if (!stcwListFetched) {
            const fetchSTCWList = async () => {
                try {
                    const response = await getSTCWList();
                    setSTCWCountryList(response);
                    setFilteredSTCWCountryList(response);
                    setSTCWListFetched(true); // Mark the STCW list as fetched
                } catch (error) {
                    console.error('Error fetching STCW list:', error);
                }
            };
    
            fetchSTCWList();
        }
    }, [ stcwListFetched]);


    useEffect(() => {
        if (stcwListFetched) {
            const fetchSTCWList = async () => {
                try {
                    setFilteredSTCWCountryList(sTCWCountryList);
                } catch (error) {
                    console.error('Error fetching STCW list:', error);
                }
            };
            fetchSTCWList();
        }
    }, [ stcwListFetched, selectedItems]);

    const handleSTCWSearchInputChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchSTCWCountryQuery(query);
        const filteredList = sTCWCountryList.filter(item => item.name.toLowerCase().includes(query));
        setFilteredSTCWCountryList(filteredList);
    };

    const handleCheckboxChange = (event, stcwDetailItem) => {
        const { checked } = event.target;
    
        if (checked) {
            setSelectedItems(prevSelectedItems => [...prevSelectedItems, stcwDetailItem]);
        } else {
            setSelectedItems(prevSelectedItems => prevSelectedItems.filter(item => item.id !== stcwDetailItem.id));
        }
        const query = '';
        setSearchSTCWCountryQuery(query);
        const filteredList = sTCWCountryList.filter(item => item.name.toLowerCase().includes(query));
        setFilteredSTCWCountryList(filteredList);

    };

    const handleSTCWDetailsDateChange = (date, stcwDetailItem) => {
        const updatedItems = selectedItems.map(item => {
            if (item === stcwDetailItem) {
                return { ...item, valid_till: date ? date.toLocaleDateString('en-GB') : null, valid_till_orig: date ? date : null };
            }
            return item;
        });
        setSelectedItems(updatedItems);
    };

    // const handleSTCWDetailsOthersTextChange = (event, stcwDetailItem) => {
    //     const othersName = event.target.value;
    //     const updatedItems = selectedItems.map(item => {
    //         if (item === stcwDetailItem) {
    //             return { ...item, custom_name: othersName ? othersName : null };
    //         }
    //         return item;
    //     });
    //     setSelectedItems(updatedItems);
    // };

    const handleKeyPressSTCWDetails = (event) => {
        const keyCode = event.keyCode || event.which;
        const keyValue = String.fromCharCode(keyCode);
        const regex = /^[a-zA-Z0-9]*$/;
        
        if (!regex.test(keyValue)) {
            event.preventDefault();
            setErrorOtherStcWText('Only alphanumeric characters are allowed');
        }else {
            setErrorOtherStcWText(null);
        }
    };
    
    const handleSTCWDetailsOthersTextChange = (event, stcwDetailItem) => {
        const inputValue = event.target.value;
        const updatedItems = selectedItems.map(item => {
            if (item === stcwDetailItem) {
                return { ...item, custom_name: inputValue || null };
            }
            return item;
        });
        setSelectedItems(updatedItems);
    };
    // const handleSTCWDetailsOthersTextChange = (event, stcwDetailItem) => {
    //     const inputValue = event.target.value;
    //     console.log("Input Value:", inputValue, checkAlphanumeric(inputValue));
        
    //     // Remove non-alphanumeric characters from input value
    //     const sanitizedValue = inputValue.replace(/[^a-zA-Z0-9]/g, '');
    //     console.log("sanitizedValue Value:", sanitizedValue);
    //     inputValue = sanitizedValue;
        
    //     if (checkAlphanumeric(sanitizedValue)) {
    //         const updatedItems = selectedItems.map(item => {
    //             if (item === stcwDetailItem) {
    //                 return { ...item, custom_name: sanitizedValue || null };
    //             }
    //             return item;
    //         });
    //         setSelectedItems(updatedItems);
    //     } else {
    //         console.log("Invalid Input Value:", inputValue);
    //         setErrorOtherStcWText('Only alphanumeric characters are allowed');
    //     }
    // };
    
    // console.log("selectedItems", selectedItems);
    // STCW Ends


    // For USVisa Satrts
    const [selectedUSVisaOption, setselectedUSVisaOption] = useState('');
    const handleUSVisaChange = (event) => {
        setselectedUSVisaOption(event.target.value);
    };

    const [usVisaDate, setUsVisaDate] = useState(null);
    const handleUsVisaDateChange = (date) => {
        setUsVisaDate(date);
    };

    // USVisa Ends
    
    // rank-post Satrts
    const [selectedRankPostsOption, setselectedRankPostsOption] = useState('');
    const handleRankPostsChange = (event) => {
        setselectedRankPostsOption(event.target.value);
    };

    const [detailedRank, setDetailedRank] = useState(null);
    useEffect(() => {
        const fetchPromotedRank = async () => {
            if (userData && userData.rank_id) {
                try {
                    const rank = await getRankById(userData.rank_id.id);
                    // console.log("rankrankrankrank", rank);
                    setDetailedRank(rank);
                    // console.log("detailedRank111111111111111111111", detailedRank);
                } catch (error) {
                    console.error('Error fetching rank:', error);
                }
            } else {
                setDetailedRank(null);
            }
        };
    
        fetchPromotedRank();
    }, []);

    useEffect(() => {
        if (!rankPostListFetched ) {
            const fetchSTCWList = async () => {
                try {
                    if (detailedRank.coc) {
                        const response = await getCocListByUserType(userData.user_type_key);
                        setRankPostList(response);
                        setFilteredRankPostList(response);
                        setrankPostListFetched(true); 
                    } else if (detailedRank.cop) {
                        const response = await getCopListByUserType(userData.user_type_key);
                        setRankPostList(response);
                        setFilteredRankPostList(response);
                        setrankPostListFetched(true); 
                    } else {
                        const response = await getWatchkeepingListByUserType(userData.user_type_key);
                        setRankPostList(response);
                        setFilteredRankPostList(response);
                        setrankPostListFetched(true); 
                    }
                } catch (error) {
                    console.error('Error fetching STCW list:', error);
                }
            };
    
            fetchSTCWList();
        }
    }, [ rankPostListFetched, detailedRank]);

    useEffect(() => {
        if (rankPostListFetched) {
            const fetchSTCWList = async () => {
                try {
                    setFilteredRankPostList(rankPostList);
                } catch (error) {
                    console.error('Error fetching STCW list:', error);
                }
            };
            fetchSTCWList();
        }
    }, [ rankPostListFetched, selectedRankPostItems]);

    const handleRankPostSearchInputChange = (event) => {
        const query = event.target.value.toLowerCase();
        setSearchRankPostQuery(query);
        const filteredList = rankPostList.filter(item => item.name.toLowerCase().includes(query));
        setFilteredRankPostList(filteredList);
    };

    const handleRankPostCheckboxChange = (event, rankpostDetailItem) => {
        const { checked } = event.target;

        if (checked) {
            setSelectedRankPostItems(prevSelectedItems => [...prevSelectedItems, rankpostDetailItem]);
        } else {
            setSelectedRankPostItems(prevSelectedItems => prevSelectedItems.filter(item => item.id !== rankpostDetailItem.id));
        }
        const query = '';
        setSearchRankPostQuery(query);
        const filteredList = rankPostList.filter(item => item.name.toLowerCase().includes(query));
        setFilteredRankPostList(filteredList);

    };

    const handleRankPostDetailsDateChange = (date, rankpostDetailItem) => {
        const updatedItems = selectedRankPostItems.map(item => {
            if (item === rankpostDetailItem) {
                return { ...item, valid_till: date ? date.toLocaleDateString('en-GB') : null, valid_till_orig: date ? date : null };
            }
            return item;
        });
        setSelectedRankPostItems(updatedItems);
    };

    const handleRankPostDetailsOthersTextChange = (event, rankpostDetailItem) => {
        const othersName = event.target.value;
        const updatedItems = selectedRankPostItems.map(item => {
            if (item === rankpostDetailItem) {
                return { ...item, custom_name: othersName ? othersName : null };
            }
            return item;
        });
        setSelectedRankPostItems(updatedItems);
    };

    // const [csrfToken, setCSRFToken] = useState(null);
    // useEffect(() => {
    // const fetchCSRFToken = async () => {
    //     try {
    //     const token = await generateCSRFToken();
    //     setCSRFToken(token);
    //     console.log('CSRF Token:', token);
    //     } catch (error) {
    //     console.error('Error fetching CSRF token:', error);
    //     }
    // };

    // fetchCSRFToken();
    // }, []);
    

    // setting auto filled Starts

    useEffect(() => {
        if (userData.screen_check > 1) {
            const crewDetailsData = getLocalJsonItem("crewDetails");
    
            if (crewDetailsData) {
                
                setIndosNumber(crewDetailsData[0]["INDOS_number"]);

                const CDC_Issuing_AuthorityObject = JSON.parse(crewDetailsData[0]["CDC_Issuing_Authority"]);
                setCdcSelectedAuthority(filteredCDCCountryList.find(country => country.name === CDC_Issuing_AuthorityObject["issuing_authority"]));
                setCdcNumber(crewDetailsData[0]["CDC_seaman_book"]);
                setCdcCustomName(CDC_Issuing_AuthorityObject["custom_name"]);
                setCdcNumberDate(parseDateObject(CDC_Issuing_AuthorityObject["valid_till"]));
                
                const Passport_Issuing_AuthorityObject = JSON.parse(crewDetailsData[0]["Passport_Issuing_Authority"]);
                setPassportSelectedAuthority(passportCountryList.find(country => country.name === Passport_Issuing_AuthorityObject["issuing_authority"]));
                setPassportNumber(crewDetailsData[0]["CDC_seaman_book"]);
                setPassportCustomName(Passport_Issuing_AuthorityObject["custom_name"]);
                setPassportNumberDate(parseDateObject(Passport_Issuing_AuthorityObject["valid_till"]));

                const STCW_Issuing_AuthorityObject = JSON.parse(crewDetailsData[0]["STCW_Issuing_Authority"]);
                STCW_Issuing_AuthorityObject.forEach(item => {
                    const itemDateObj = parseDateObjectByHyphen(item["valid_till"]);
                    item["valid_till"] = formatDateNew(itemDateObj); 
                    item["valid_till_orig"] = itemDateObj; 
                });
                setSelectedItems(STCW_Issuing_AuthorityObject);
                
                if (crewDetailsData[0]["valid_US_Visa"] === true) {
                    setselectedUSVisaOption("1");
                }
                setUsVisaDate(parseDateObjectByHyphen(crewDetailsData[0]["valid_US_Visa_valid_till"]));
                
                if (crewDetailsData[0]["valid_COC_Issuing_Authority"] ) {
                    const valid_COC_Issuing_AuthorityObject = JSON.parse(crewDetailsData[0]["valid_COC_Issuing_Authority"]);
                    valid_COC_Issuing_AuthorityObject.forEach(item => {
                        const itemDateObj = parseDateObjectByHyphen(item["valid_till"]);
                        item["valid_till"] = formatDateNew(itemDateObj); 
                        item["valid_till_orig"] = itemDateObj; 
                    });
                    setselectedRankPostsOption("1");
                    setSelectedRankPostItems(valid_COC_Issuing_AuthorityObject);
                } else if (crewDetailsData[0]["valid_COP_Issuing_Authority"] ) {
                    const valid_COC_Issuing_AuthorityObject = JSON.parse(crewDetailsData[0]["valid_COP_Issuing_Authority"]);
                    valid_COC_Issuing_AuthorityObject.forEach(item => {
                        const itemDateObj = parseDateObjectByHyphen(item["valid_till"]);
                        item["valid_till"] = formatDateNew(itemDateObj); 
                        item["valid_till_orig"] = itemDateObj; 
                    });
                    setselectedRankPostsOption("1");
                    setSelectedRankPostItems(valid_COC_Issuing_AuthorityObject);
                } else if (crewDetailsData[0]["valid_Watch_keeping_Issuing_Authority"] ) {
                    const valid_COC_Issuing_AuthorityObject = JSON.parse(crewDetailsData[0]["valid_Watch_keeping_Issuing_Authority"]);
                    valid_COC_Issuing_AuthorityObject.forEach(item => {
                        const itemDateObj = parseDateObjectByHyphen(item["valid_till"]);
                        item["valid_till"] = formatDateNew(itemDateObj); 
                        item["valid_till_orig"] = itemDateObj; 
                    });
                    setselectedRankPostsOption("1");
                    setSelectedRankPostItems(valid_COC_Issuing_AuthorityObject);
                } 
            }
        }
    }, [userData.screen_check, filteredCDCCountryList, passportCountryList, filteredSTCWCountryList]);
    // setting auto filled Ends


    const handlePreviousClick = () => {
        navigate(apiRoutes.create_profile_1);
    };

    const handleFormSubmit = async (e) => {
        e.preventDefault(); 
        // console.log('CSRF Token:', csrfToken);
        if (userData.screen_check < 2) {
            try {
                const cdcDetails = getSelectedAuthorityJsonDetails(cdcSelectedAuthority, cdcCustomName, cdcNumberDate );
                const passDetails = getSelectedAuthorityJsonDetails(passportSelectedAuthority, passportCustomName, passportNumberDate );
                
                const updatedUserData = {
                    user_id : userData.id,
                    INDOS_number : indosNumber,
                    CDC_seaman_book : cdcNumber,
                    CDC_seaman_book_valid_till : formatDate(cdcNumberDate),
                    CDC_Issuing_Authority : JSON.stringify(cdcDetails),
                    Passport_number : passportNumber,
                    Passport_number_valid_till : formatDate(passportNumberDate),
                    Passport_Issuing_Authority : JSON.stringify(passDetails),
                    STCW_Issuing_Authority : JSON.stringify(selectedItems),
                    valid_US_Visa : selectedUSVisaOption,
                    valid_US_Visa_valid_till: usVisaDate ? formatDate(usVisaDate) : null,
                    _mutable: false
                };
    
                if (detailedRank.coc) {
                    updatedUserData["valid_COC_Issuing_Authority"] = JSON.stringify(selectedRankPostItems);
                } else if (detailedRank.cop) {
                    updatedUserData["valid_COP_Issuing_Authority"] = JSON.stringify(selectedRankPostItems);
                } else if (detailedRank.watch_keeping) {
                    updatedUserData["valid_Watch_keeping_Issuing_Authority"] = JSON.stringify(selectedRankPostItems);
                }
    
                const updatedCrewDetails = await insertCrewDetails( updatedUserData, userToken);
    
                if (updatedCrewDetails.status === 201) {
                    const updatedUserData = {
                        screen_check: 2
                    }
                    await updateUserById(userData.id, updatedUserData, userToken);
                    navigate(apiRoutes.create_profile_3);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        } else {
            try {
                const cdcDetails = getSelectedAuthorityJsonDetails(cdcSelectedAuthority, cdcCustomName, cdcNumberDate );
                const passDetails = getSelectedAuthorityJsonDetails(passportSelectedAuthority, passportCustomName, passportNumberDate );
                
                const updatedUserData = {
                    user_id : userData.id,
                    INDOS_number : indosNumber,
                    CDC_seaman_book : cdcNumber,
                    CDC_seaman_book_valid_till : formatDate(cdcNumberDate),
                    CDC_Issuing_Authority : JSON.stringify(cdcDetails),
                    Passport_number : passportNumber,
                    Passport_number_valid_till : formatDate(passportNumberDate),
                    Passport_Issuing_Authority : JSON.stringify(passDetails),
                    STCW_Issuing_Authority : JSON.stringify(selectedItems),
                    valid_US_Visa : selectedUSVisaOption,
                    valid_US_Visa_valid_till: usVisaDate ? formatDate(usVisaDate) : null,
                    _mutable: false
                };
    
                if (detailedRank.coc) {
                    updatedUserData["valid_COC_Issuing_Authority"] = JSON.stringify(selectedRankPostItems);
                } else if (detailedRank.cop) {
                    updatedUserData["valid_COP_Issuing_Authority"] = JSON.stringify(selectedRankPostItems);
                } else if (detailedRank.watch_keeping) {
                    updatedUserData["valid_Watch_keeping_Issuing_Authority"] = JSON.stringify(selectedRankPostItems);
                }
    
                const updatedCrewDetails = await updateCrewDetails( updatedUserData, userData.id, userToken);
                if (updatedCrewDetails.status === 200) {
                    navigate(apiRoutes.create_profile_3);
                }
            } catch (error) {
                console.error('Error:', error);
            }
        }


    };

    return (
        <>
            {isLoggedIn && (
                <>
                    <CrewCreateProfileStep2Css />
                    <NavbarCreateProfile />
                    <ToastContainer />
                    <div className="heading-container">
                        <div className="container">
                            <div style={{ padding: '22px 0px', display: 'flex', flexDirection: 'column', gap: '14px' }}>
                                {userData.is_verified ? 
                                    <h1 className="heading-main">Edit Profile</h1>
                                    :
                                    <h1 className="heading-main">Create Profile</h1>
                                }
                                <p className="heading-desc">Please fill up this form and click on save and continue to proceed with the sign up process.</p>
                            </div>
                        </div>
                    </div>

                    <Form onSubmit={handleFormSubmit} >

                    <div className="container" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', padding: '0px 0px 24px 0px' }}>
                        <LazyLoadImage className="stepper-img" src="/static/crew/assets/img/icons/Steper2.svg" alt="Stepper" />
                    </div>
                    <div className="container form-profile">
                        <div className="row create-form-row" style={{ justifyContent: 'space-between' }}>
                            <div className="col-md-4" style={{ gap: '28px', display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <Form.Label className="form-label">INDOS Number</Form.Label>
                                    <Form.Control type="text" className="form-text-input" placeholder="INDOS Number (Optional)" value={indosNumber} onChange={handleIndosNumberChange}  />
                                    {errorIndosInput && <p className="form-error-text">{errorIndosInput}</p>}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <Form.Label className="form-label">CDC / Seaman Book Details</Form.Label>
                                    <Dropdown>
                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" aria-expanded="false" data-bs-toggle="dropdown" type="button">
                                            <InputGroup className="mb-3 search-rank-input">
                                                <Form.Control type="text" placeholder={cdcSelectedAuthority ? cdcSelectedAuthority.name : 'Select Issuing Authority'} value={searchCdcCountryQuery} onChange={handleCDCSearchInputChange} required={!cdcNumberDate} />
                                            </InputGroup>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {filteredCDCCountryList.map((cdcCountryItem) => (
                                                <div key={cdcCountryItem.name} className="form-check">
                                                    <input type="radio" value={cdcCountryItem.name} id={cdcCountryItem.name} name="cdcCountryItem" className="form-check-input" onChange={() => handleCDCCountrySelect(cdcCountryItem)} />
                                                    <label className="form-check-label" htmlFor={cdcCountryItem.name}> {cdcCountryItem.name} </label>
                                                </div>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {cdcSelectedAuthority && cdcSelectedAuthority.name !== 'Others' && (
                                        <Form.Group controlId="formDate">
                                            <DatePicker selected={cdcNumberDate} onChange={handlecdcNumberDateChange} 
                                            dateFormat="yyyy-MM-dd" 
                                            placeholderText="Valid Till (dd-mm-yyyy)" className="form-control form-date-input" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={40} minDate={new Date()} 
                                            customInput={<Form.Control type="date" value={formatDateNew(cdcNumberDate)} onChange={(e) => setCdcNumberDate(e.target.value)} />}
                                            required={!cdcNumberDate} />
                                        </Form.Group>
                                    )}

                                    {cdcSelectedAuthority && cdcSelectedAuthority.name === 'Others' && (
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '22px' }}>
                                            <Form.Control type="text" className="other-input-text" placeholder="Others" onKeyPress={handleKeyPressCDCOther} value={cdcCustomName} onChange={handleCdcCustomNameChange} />
                                            <Form.Group controlId="formDate">
                                                <DatePicker selected={cdcNumberDate} onChange={handlecdcNumberDateChange} 
                                                dateFormat="yyyy-MM-dd" 
                                                placeholderText="Valid Till" className="form-control form-date-input" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={40} minDate={new Date()} 
                                                customInput={<Form.Control type="date" value={formatDateNew(cdcNumberDate)} onChange={(e) => setCdcNumberDate(e.target.value)} />}
                                                required={!cdcNumberDate} />
                                            </Form.Group>
                                        </div>
                                    )}
                                    {errorOtherCDC && <p className="form-error-text">{errorOtherCDC}</p>}
                                    <Form.Control type="text" className="form-text-input" placeholder="CDC Number" value={cdcNumber} onChange={handleCdcNumberChange} required={!cdcNumber} />
                                    {errorCDCNumInput && <p className="form-error-text">{errorCDCNumInput}</p>}
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <Form.Label className="form-label">Passport / Seaman Book Details</Form.Label>
                                    <Dropdown>
                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" aria-expanded="false" data-bs-toggle="dropdown" type="button">
                                            <InputGroup className="mb-3 search-rank-input">
                                                <Form.Control type="text" placeholder={passportSelectedAuthority ? passportSelectedAuthority.name : 'Select Issuing Authority'} value={searchPassportCountryQuery} onChange={handlePassportSearchInputChange} required={!passportNumberDate}  />
                                            </InputGroup>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {filteredPassportCountryList.map((passportCountryItem) => (
                                                <div key={passportCountryItem.name} className="form-check">
                                                    <input type="radio" value={passportCountryItem.name} id={passportCountryItem.name} name="passportCountryItem" className="form-check-input" onChange={() => handlePassportCountrySelect(passportCountryItem)} />
                                                    <label className="form-check-label" htmlFor={passportCountryItem.name}> {passportCountryItem.name} </label>
                                                </div>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {passportSelectedAuthority && passportSelectedAuthority.name !== 'Others' && (
                                        <Form.Group controlId="formDate">
                                            <DatePicker selected={passportNumberDate} onChange={handlepassportNumberDateChange} 
                                            dateFormat="yyyy-MM-dd"  
                                            placeholderText="Valid Till (dd-mm-yyyy)" className="form-control form-date-input" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={40} minDate={new Date()} 
                                            customInput={<Form.Control type="date" value={formatDateNew(passportNumberDate)} onChange={(e) => setPassportNumberDate(e.target.value)} />}
                                            required={!passportNumberDate} />
                                        </Form.Group>
                                    )}

                                    {passportSelectedAuthority && passportSelectedAuthority.name === 'Others' && (
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '22px' }}>
                                            <Form.Control type="text" className="other-input-text" placeholder="Others" onKeyPress={handleKeyPressPassportOther} value={passportCustomName} onChange={handlePassportCustomNameChange} />
                                            <Form.Group controlId="formDate">
                                                <DatePicker selected={passportNumberDate} onChange={handlepassportNumberDateChange} 
                                                dateFormat="yyyy-MM-dd"  
                                                placeholderText="Valid Till" className="form-control form-date-input" showYearDropdown scrollableYearDropdown yearDropdownItemNumber={40} minDate={new Date()} 
                                                customInput={<Form.Control type="date" value={formatDateNew(passportNumberDate)} onChange={(e) => setPassportNumberDate(e.target.value)} />}
                                                required={!passportNumberDate} />
                                            </Form.Group>
                                        </div>
                                    )}
                                    {errorOtherPassport && <p className="form-error-text">{errorOtherPassport}</p>}
                                    <Form.Control type="text" className="form-text-input" placeholder="Passport Number" value={passportNumber} onChange={handlePassportNumberChange} required={!passportNumber} />
                                    {errorPassportInput && <p className="form-error-text">{errorPassportInput}</p>}
                                </div>
                            </div>
                            <div className="col-md-4" style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                    <Form.Label className="form-label" style={{ marginBottom: '0' }}>STCW Details</Form.Label>
                                    <Dropdown>
                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" aria-expanded="false" type="button">
                                            <InputGroup className="mb-3 search-rank-input">
                                                <Form.Control type="text" placeholder={sTCWSelectedAuthority ? sTCWSelectedAuthority.name : 'Select Issuing Authority'} value={searchSTCWCountryQuery} onChange={handleSTCWSearchInputChange}  required={selectedItems.length === 0}/>
                                            </InputGroup>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {filteredSTCWCountryList.map(stcwListItem => (
                                                <Dropdown.Item key={stcwListItem.id}>
                                                    <Form.Check type="checkbox" label={stcwListItem.name} checked={selectedItems.some(item => item.id === stcwListItem.id)} onChange={e => handleCheckboxChange(e, stcwListItem)} />
                                                </Dropdown.Item>
                                            ))}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                    {selectedItems.filter(item => item.name !== 'Others').map(item => (
                                            <div key={item.name} style={{ display: 'flex', alignItems: 'center', gap: 'calc(100% - 193px)' }}>
                                                <h1 className="stcw-title">{item.name}</h1>
                                                <Form.Group controlId="formDate">
                                                    <DatePicker   selected={item.valid_till_orig ? item.valid_till_orig : null}  onChange={date => handleSTCWDetailsDateChange(date, item)}    
                                                    dateFormat="yyyy-MM-dd"  
                                                    placeholderText="Valid Till"    className="form-control form-date-input"    showYearDropdown    scrollableYearDropdown    yearDropdownItemNumber={40}    minDate={new Date()}    
                                                    customInput={<Form.Control type="date" value={formatDateNew(item.valid_till_orig ? item.valid_till_orig : null)} onChange={date => handleSTCWDetailsDateChange(date, item)} />}
                                                    required={!item.valid_till}   />
                                                </Form.Group>
                                            </div>
                                        ))}
                                    {selectedItems.some(item => item.name === 'Others') && (
                                        <div style={{ display: 'flex', alignItems: 'center', gap: '22px' }}>
                                            <Form.Control type="text" className="other-input-text"   onKeyPress={handleKeyPressSTCWDetails} onChange={e => handleSTCWDetailsOthersTextChange(e, selectedItems.find(item => item.name === 'Others'))}  placeholder="Others" />
                                            <Form.Group controlId="formDate" >
                                                <DatePicker  selected={selectedItems.find(item => item.name === 'Others')?.valid_till_orig ? selectedItems.find(item => item.name === 'Others').valid_till_orig : null} onChange={date => handleSTCWDetailsDateChange(date, selectedItems.find(item => item.name === 'Others'))}  
                                                dateFormat="yyyy-MM-dd"  
                                                placeholderText="Valid Till"   className="form-control form-date-input"   showYearDropdown   scrollableYearDropdown   yearDropdownItemNumber={40}   minDate={new Date()}   
                                                customInput={<Form.Control type="date" value={selectedItems.find(item => item.name === 'Others')?.valid_till_orig ? selectedItems.find(item => item.name === 'Others').valid_till_orig : null} onChange={date => handleSTCWDetailsDateChange(date, selectedItems.find(item => item.name === 'Others'))} />}
                                                required={!selectedItems.find(item => item.name === 'Others')?.valid_till}  />
                                            </Form.Group>
                                        </div>
                                    )}
                                    {errorOtherStcWText && <p className="form-error-text">{errorOtherStcWText}</p>}

                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '9px' }}>
                                    <label className="form-label">Are you holding a valid US Visa?</label>
                                    <div className="radio-container">
                                        <div className="custom-control custom-radio">
                                            <input type="radio" name="usvisa-radio" id="male" className="custom-control-input" value="0" checked={selectedUSVisaOption === '0'} onChange={handleUSVisaChange} required />
                                            <label className="form-label custom-control-label" htmlFor="healthyes">No</label>
                                        </div>
                                        <div className="custom-control custom-radio">
                                            <input type="radio" name="usvisa-radio" id="female" className="custom-control-input" value="1" checked={selectedUSVisaOption === '1'} onChange={handleUSVisaChange} required />
                                            <label className="form-label custom-control-label" htmlFor="healthyes">Yes</label>
                                        </div>
                                    </div>
                                    {selectedUSVisaOption === '1' && (
                                        <Form.Group controlId="usVisaDate"  style={{width: '100%'}}>
                                            <DatePicker  
                                                selected={usVisaDate ? usVisaDate : null} 
                                                onChange={handleUsVisaDateChange}  
                                                dateFormat="yyyy-MM-dd"  
                                                placeholderText="Valid Till (dd-mm-yyyy)"   
                                                className="form-control form-date-input"   
                                                showYearDropdown   
                                                scrollableYearDropdown   
                                                yearDropdownItemNumber={40}   
                                                minDate={new Date()}   
                                                customInput={<Form.Control type="date" value={formatDateNew(usVisaDate ? usVisaDate : null)} onChange={(e) => setUsVisaDate(e.target.value)} />}
                                                required={!usVisaDate}  
                                            />
                                        </Form.Group>
                                    )}
                                </div>
                            </div>
                            <div className="col-md-4" style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                                { detailedRank && (detailedRank.coc) && (
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '22px' }}>
                                        <Form.Label className="form-label" style={{ marginBottom: '0' }}>Are you holding a valid COC?</Form.Label>
                                        <div className="radio-container">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" name="rankpost-radio" id="male" className="custom-control-input" value="0" checked={selectedRankPostsOption === '0'} onChange={handleRankPostsChange} required />
                                                <label className="form-label custom-control-label" htmlFor="healthyes">No</label>
                                            </div>
                                            <div className="custom-control custom-radio">
                                                <input type="radio" name="rankpost-radio" id="female" className="custom-control-input" value="1" checked={selectedRankPostsOption === '1'} onChange={handleRankPostsChange} required />
                                                <label className="form-label custom-control-label" htmlFor="healthyes">Yes</label>
                                            </div>
                                        </div>
                                        {selectedRankPostsOption === '1' && (
                                            <>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" aria-expanded="false" type="button">
                                                            <InputGroup className="mb-3 search-rank-input">
                                                                <Form.Control type="text" placeholder={rankPostSelectedAuthority ? rankPostSelectedAuthority.name : 'Select Issuing Authority'} value={searchRankPostQuery} onChange={handleRankPostSearchInputChange} required={selectedRankPostItems.length === 0}  />
                                                            </InputGroup>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {filteredRankPostList.map(rankpostListItem => (
                                                                <Dropdown.Item key={rankpostListItem.id}>
                                                                    <Form.Check type="checkbox" label={rankpostListItem.name} checked={selectedRankPostItems.some(item => item.id === rankpostListItem.id)} onChange={e => handleRankPostCheckboxChange(e, rankpostListItem)} />
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    {selectedRankPostItems.filter(item => item.name !== 'Others').map(item => (
                                                            <div key={item.name} style={{ display: 'flex', alignItems: 'center', gap: 'calc(100% - 193px)' }}>
                                                                <h1 className="stcw-title">{item.name}</h1>
                                                                <Form.Group controlId="formDate">
                                                                    <DatePicker   selected={item.valid_till_orig ? item.valid_till_orig : null}  onChange={date => handleRankPostDetailsDateChange(date, item)}    
                                                                    dateFormat="yyyy-MM-dd" 
                                                                    placeholderText="Valid Till"    className="form-control form-date-input"    showYearDropdown    scrollableYearDropdown    yearDropdownItemNumber={40}    minDate={new Date()}   
                                                                    customInput={<Form.Control type="date" value={formatDateNew(item.valid_till_orig ? item.valid_till_orig : null)} onChange={date => handleRankPostDetailsDateChange(date, item)} />}
                                                                    required={!item.valid_till}   />
                                                                </Form.Group>
                                                            </div>
                                                        ))}
                                                    {selectedRankPostItems.some(item => item.name === 'Others') && (
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '22px' }}>
                                                            <Form.Control type="text" className="other-input-text" onChange={e => handleRankPostDetailsOthersTextChange(e, selectedRankPostItems.find(item => item.name === 'Others'))}  placeholder="Others" />
                                                            <Form.Group controlId="formDate" >
                                                                <DatePicker  selected={selectedRankPostItems.find(item => item.name === 'Others')?.valid_till_orig ? selectedRankPostItems.find(item => item.name === 'Others').valid_till_orig : null} onChange={date => handleRankPostDetailsDateChange(date, selectedRankPostItems.find(item => item.name === 'Others'))}  
                                                                dateFormat="yyyy-MM-dd" 
                                                                placeholderText="Valid Till"   className="form-control form-date-input"   showYearDropdown   scrollableYearDropdown   yearDropdownItemNumber={40}   minDate={new Date()}   
                                                                customInput={<Form.Control type="date" value={formatDateNew(selectedRankPostItems.find(item => item.name === 'Others')?.valid_till_orig ? selectedRankPostItems.find(item => item.name === 'Others').valid_till_orig : null)} onChange={date => handleRankPostDetailsDateChange(date, selectedRankPostItems.find(item => item.name === 'Others'))}   />}
                                                                required={!selectedRankPostItems.find(item => item.name === 'Others')?.valid_till}  />
                                                            </Form.Group>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                                { detailedRank && (detailedRank.cop) && (
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '22px' }}>
                                        <Form.Label className="form-label" style={{ marginBottom: '0' }}>Are you holding a valid COP?</Form.Label>
                                        <div className="radio-container">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" name="rankpost-radio" id="male" className="custom-control-input" value="0" checked={selectedRankPostsOption === '0'} onChange={handleRankPostsChange} required />
                                                <label className="form-label custom-control-label" htmlFor="healthyes">No</label>
                                            </div>
                                            <div className="custom-control custom-radio">
                                                <input type="radio" name="rankpost-radio" id="female" className="custom-control-input" value="1" checked={selectedRankPostsOption === '1'} onChange={handleRankPostsChange} required />
                                                <label className="form-label custom-control-label" htmlFor="healthyes">Yes</label>
                                            </div>
                                        </div>
                                        {selectedRankPostsOption === '1' && (
                                            <>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" aria-expanded="false" type="button">
                                                            <InputGroup className="mb-3 search-rank-input">
                                                                <Form.Control type="text" placeholder={rankPostSelectedAuthority ? rankPostSelectedAuthority.name : 'Select Issuing Authority'} value={searchRankPostQuery} onChange={handleRankPostSearchInputChange} required={selectedRankPostItems.length === 0} />
                                                            </InputGroup>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {filteredRankPostList.map(rankpostListItem => (
                                                                <Dropdown.Item key={rankpostListItem.id}>
                                                                    <Form.Check type="checkbox" label={rankpostListItem.name} checked={selectedRankPostItems.some(item => item.id === rankpostListItem.id)} onChange={e => handleRankPostCheckboxChange(e, rankpostListItem)} />
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    {selectedRankPostItems.filter(item => item.name !== 'Others').map(item => (
                                                            <div key={item.name} style={{ display: 'flex', alignItems: 'center', gap: 'calc(100% - 193px)' }}>
                                                                <h1 className="stcw-title">{item.name}</h1>
                                                                <Form.Group controlId="formDate">
                                                                    <DatePicker   selected={item.valid_till_orig ? item.valid_till_orig : null}  onChange={date => handleRankPostDetailsDateChange(date, item)}    
                                                                    dateFormat="yyyy-MM-dd" 
                                                                    placeholderText="Valid Till"    className="form-control form-date-input"    showYearDropdown    scrollableYearDropdown    yearDropdownItemNumber={40}    minDate={new Date()}    
                                                                    customInput={<Form.Control type="date" value={formatDateNew(item.valid_till_orig ? item.valid_till_orig : null)} onChange={date => handleRankPostDetailsDateChange(date, item)} />}
                                                                    required={!item.valid_till}   />
                                                                </Form.Group>
                                                            </div>
                                                        ))}
                                                    {selectedRankPostItems.some(item => item.name === 'Others') && (
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '22px' }}>
                                                            <Form.Control type="text" className="other-input-text" onChange={e => handleRankPostDetailsOthersTextChange(e, selectedRankPostItems.find(item => item.name === 'Others'))}  placeholder="Others" />
                                                            <Form.Group controlId="formDate" >
                                                                <DatePicker  selected={selectedRankPostItems.find(item => item.name === 'Others')?.valid_till_orig ? selectedRankPostItems.find(item => item.name === 'Others').valid_till_orig : null} onChange={date => handleRankPostDetailsDateChange(date, selectedRankPostItems.find(item => item.name === 'Others'))}  
                                                                dateFormat="yyyy-MM-dd" 
                                                                placeholderText="Valid Till"   className="form-control form-date-input"   showYearDropdown   scrollableYearDropdown   yearDropdownItemNumber={40}   minDate={new Date()}   
                                                                customInput={<Form.Control type="date" value={formatDateNew(selectedRankPostItems.find(item => item.name === 'Others')?.valid_till_orig ? selectedRankPostItems.find(item => item.name === 'Others').valid_till_orig : null)} onChange={date => handleRankPostDetailsDateChange(date, selectedRankPostItems.find(item => item.name === 'Others'))}   />}
                                                                required={!selectedRankPostItems.find(item => item.name === 'Others')?.valid_till}  />
                                                            </Form.Group>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )} 
                                { detailedRank && (detailedRank.watch_keeping) && (
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '22px' }}>
                                        <Form.Label className="form-label" style={{ marginBottom: '0' }}>Are you holding a valid Watch Keeping?</Form.Label>
                                        <div className="radio-container">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" name="rankpost-radio" id="male" className="custom-control-input" value="0" checked={selectedRankPostsOption === '0'} onChange={handleRankPostsChange} required />
                                                <label className="form-label custom-control-label" htmlFor="healthyes">No</label>
                                            </div>
                                            <div className="custom-control custom-radio">
                                                <input type="radio" name="rankpost-radio" id="female" className="custom-control-input" value="1" checked={selectedRankPostsOption === '1'} onChange={handleRankPostsChange} required />
                                                <label className="form-label custom-control-label" htmlFor="healthyes">Yes</label>
                                            </div>
                                        </div>
                                        {selectedRankPostsOption === '1' && (
                                            <>
                                                <div style={{ display: 'flex', flexDirection: 'column', gap: '10px' }}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" aria-expanded="false" type="button">
                                                            <InputGroup className="mb-3 search-rank-input">
                                                                <Form.Control type="text" placeholder={rankPostSelectedAuthority ? rankPostSelectedAuthority.name : 'Select Issuing Authority'} value={searchRankPostQuery} onChange={handleRankPostSearchInputChange} required={selectedRankPostItems.length === 0} />
                                                            </InputGroup>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {filteredRankPostList.map(rankpostListItem => (
                                                                <Dropdown.Item key={rankpostListItem.id}>
                                                                    <Form.Check type="checkbox" label={rankpostListItem.name} checked={selectedRankPostItems.some(item => item.id === rankpostListItem.id)} onChange={e => handleRankPostCheckboxChange(e, rankpostListItem)} />
                                                                </Dropdown.Item>
                                                            ))}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                    {selectedRankPostItems.filter(item => item.name !== 'Others').map(item => (
                                                            <div key={item.name} style={{ display: 'flex', alignItems: 'center', gap: 'calc(100% - 193px)' }}>
                                                                <h1 className="stcw-title">{item.name}</h1>
                                                                <Form.Group controlId="formDate">
                                                                    <DatePicker   selected={item.valid_till_orig ? item.valid_till_orig : null}  onChange={date => handleRankPostDetailsDateChange(date, item)}    
                                                                    dateFormat="yyyy-MM-dd" 
                                                                    placeholderText="Valid Till"    className="form-control form-date-input"    showYearDropdown    scrollableYearDropdown    yearDropdownItemNumber={40}    minDate={new Date()}    
                                                                    customInput={<Form.Control type="date" value={formatDateNew(item.valid_till_orig ? item.valid_till_orig : null)} onChange={date => handleRankPostDetailsDateChange(date, item)} />}
                                                                    required={!item.valid_till}   />
                                                                </Form.Group>
                                                            </div>
                                                        ))}
                                                    {selectedRankPostItems.some(item => item.name === 'Others') && (
                                                        <div style={{ display: 'flex', alignItems: 'center', gap: '22px' }}>
                                                            <Form.Control type="text" className="other-input-text" onChange={e => handleRankPostDetailsOthersTextChange(e, selectedRankPostItems.find(item => item.name === 'Others'))}  placeholder="Others" />
                                                            <Form.Group controlId="formDate" >
                                                                <DatePicker  selected={selectedRankPostItems.find(item => item.name === 'Others')?.valid_till_orig ? selectedRankPostItems.find(item => item.name === 'Others').valid_till_orig : null} onChange={date => handleRankPostDetailsDateChange(date, selectedRankPostItems.find(item => item.name === 'Others'))}  
                                                                dateFormat="yyyy-MM-dd" 
                                                                placeholderText="Valid Till"   className="form-control form-date-input"   showYearDropdown   scrollableYearDropdown   yearDropdownItemNumber={40}   minDate={new Date()}   
                                                                customInput={<Form.Control type="date" value={formatDateNew(selectedRankPostItems.find(item => item.name === 'Others')?.valid_till_orig ? selectedRankPostItems.find(item => item.name === 'Others').valid_till_orig : null)} onChange={date => handleRankPostDetailsDateChange(date, selectedRankPostItems.find(item => item.name === 'Others'))}   />}
                                                                required={!selectedRankPostItems.find(item => item.name === 'Others')?.valid_till}  />
                                                            </Form.Group>
                                                        </div>
                                                    )}
                                                </div>
                                            </>
                                        )}
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className="container" style={{ paddingTop: '90px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '42px' }}>
                        <button className="btn previous-btn" onClick={handlePreviousClick} type="button">Previous</button>
                        <button className="btn btn-primary register-btn" type="submit" >
                            <br />
                            <span style={{ color: 'var(--bs-btn-hover-color)', backgroundColor: 'var(--bs-btn-hover-bg)' }}>Save & Continue</span>
                        </button>
                    </div>

                    </Form>

                    <div style={{ height: '132px' }}></div>
                </>
            )}
        </>
    );
};

export default CrewCreateProfileStep3;

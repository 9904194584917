import React from 'react';
import Loader from './Loader';


const LayoutNoToken = ({ children }) => {
  return <>
      <Loader />
      {children}
     </>;
};

export default LayoutNoToken;

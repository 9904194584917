export const handleApiError = (error) => {
  // console.log(error);
  if (error.response && error.response.data && error.response.data.error) {
    switch (error.response.data.error.message) {
      case "EMAIL_NOT_FOUND":
        return 'EMAIL NOT FOUND.';
      case "INVALID_PASSWORD":
        return 'INVALID PASSWORD.';
      default:
        return error.response.data.error.message;
    }
  } else if (error.response && error.response.data && error.response.data.message) {
    switch (error.response.data.message) {
      case "Invalid Token":
        return 'INVALID TOKEN.';
      default:
        return error.response.data.message;
    }
  } else if (error.data && error.data.messages && error.data.messages[0] && error.data.messages[0].message) {
    switch (error.data.messages[0].message) {
      case "Access token not found":
        return 'Access token expired';
      default:
        return error.data.messages[0].message;
    }
  } else {
    return error || 'An unknown error occurred.';
  }
};

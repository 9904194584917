import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Scripts from '../components/layouts/Scripts';
import { Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import {  getCreditPointsByUserId, getRankList, getApplicantsList
        } from '../components/apiService';
import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';
import { apiRoutes } from '../config/apiConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';


const EmployerViewApplicantsScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-dropdown-1.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Text-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Bootstrap-4-Custom-Radio.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Search-Input-responsive.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/cvsearch.css" />

    </>
  );
};

const EmployerViewApplicantsScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const EmployerViewApplicants = () => {
    const userToken = getLocalItem("accessToken"); 
    const userData =  getLocalJsonItem("userData");
    const { jobID } = useParams();
    const [userCreditPoint , setUserCreditPoint] = useState();

    // Rank Dropdown Starts
    const [searchRankQuery, setSearchRankQuery] = useState('');
    const [rankItems, setRankItems] = useState([]);
    const [selectedRankItems, setSelectedRankItems] = useState([]);

    useEffect(() => {
        const fetchJobs = async () => {
          try {
            const response = await getCreditPointsByUserId(userData.id, userToken);
            setUserCreditPoint(response);
            // console.log("rankItems", response);
            const response1 = await getRankList();
            setRankItems(response1);
            // console.log("userCreditPoint", response1);
            // const response2 = await getCountryList();
            // setCountryItems(response2);
            // // console.log("countryItems", response2);

          } catch (error) {
            console.error('Error fetching job list:', error);
          } 
        };
        fetchJobs();
      }, []);

    const handleRankItemClick = (item) => {
        const isSelected = selectedRankItems.includes(item);
        if (isSelected) {
            setSelectedRankItems(selectedRankItems.filter(rank => rank !== item));
        } else {
            setSelectedRankItems([...selectedRankItems, item]);
        }
    };

    const filteredRankItems = rankItems.filter(item =>
        item.name.toLowerCase().includes(searchRankQuery.toLowerCase())
    );

    const handleRankRemove = (itemId) => {
        setSelectedRankItems(selectedRankItems.filter(item => item.id !== itemId));
      };
    // Rank Dropdown Ends 

    const [rankUserList , setRankUserList] = useState([]);
    const [applicationStatusType , setApplicationStatusType] = useState("All");

    const handleDropdownChangeShortList = (eventKey) => {
        setApplicationStatusType(eventKey);
      };
    
    const generateQueryString = (SelectedRankData, shortlisted_statusData) => {
        const queryString = SelectedRankData.map(rank => `rank=${rank.id}`).join('&');
        let queryStringShortList;
        if (shortlisted_statusData !== "All") {
            if (shortlisted_statusData === "Shortlisted") {
                queryStringShortList = `shortlisted_status=${1}`;
            } else {
                queryStringShortList = `shortlisted_status=${0}`;
            }
        } else {
            queryStringShortList = '';
        }
        return queryString + '&' + queryStringShortList;
    };

    useEffect(() => {
        const fetchJobs = async () => {
          try {
            const queryString = '';
            const response = await getApplicantsList( jobID, queryString, userToken);
            const results = response || [];
            setRankUserList(results);
          } catch (error) {
            console.error('Error fetching job list:', error);
          } 
        };

        fetchJobs();
    }, []);

    useEffect(() => {
        const fetchJobs = async () => {
          try {
            const queryString = generateQueryString(selectedRankItems, applicationStatusType);
            const response = await getApplicantsList( jobID, queryString, userToken);
            const results = response || [];
            setRankUserList(results);
          } catch (error) {
            console.error('Error fetching job list:', error);
          } 
        };

        fetchJobs();
    }, [selectedRankItems, applicationStatusType]);

    // console.log("rankUserListrankUserListrankUserList", rankUserList);

    const renderTableRows = () => {
        return rankUserList.map((result, index) => (
            <tr className="cv-search-table-section-row" key={index}>
                <td className="cv-search-table-section-cell">
                    <div className="cv-search-table-section-cell-name">
                        <img className="cv-search-table-section-cell-name-img" src={result.user_id.profilePic} alt="Profile" />
                        <p className="cv-search-table-section-cell-name-title">{result.user_id.first_name}</p>
                    </div>
                </td>
                <td className="cv-search-table-section-cell">
                    {result.rank_id.name}
                </td>
                <td className="cv-search-table-section-cell">{result.user_id.gender === 1 ? 'Male' : 'Female'}</td>
                <td className="cv-search-table-section-cell">{result.user_id.country.country_name}</td>
                <td className="cv-search-table-section-cell">
                    <a href={`${apiRoutes.employer_view_applicant_profile}${result.user_id.id}/${jobID}`}>
                        <div className="cv-search-table-section-cell-action">
                            <img className="cv-search-table-section-cell-action-img" src="/static/crew/assets/img/icons/Eye_orange.svg" alt="View Profile" />
                            <p className="cv-search-table-section-cell-action-title">View Profile</p>
                        </div>
                    </a>
                </td>
            </tr>
        ));
    };


    return (
        <>
            <EmployerViewApplicantsScriptCss />
            <NavbarCrew />
            <ToastContainer />
            <div className="cv-search-header-section">
                <div className="container cv-search-header-container">
                    <div className="cv-search-header-section">
                        <div className="cv-search-header-container-div">
                            <h1 className="cv-search-header-container-title">View Applicants</h1>
                            <p className="cv-search-header-container-text">
                                View and connect with top crew applicants instantly!
                            </p>
                        </div>
                        <div className="float-start float-md-end mt-5 mt-md-0 search-area cv-search-input-box-div">
                            <FontAwesomeIcon icon={faSearch} className="float-start search-icon" />
                            <Dropdown>
                                <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button cv-search-box" variant="none" id="dropdown-basic">
                                    <InputGroup className="mb-3 search-rank-input">
                                        <FormControl
                                            placeholder={(selectedRankItems.length > 0) ? selectedRankItems.map(rank => rank.name).join(', ') : "Select Rank"}
                                            aria-label="Search" aria-describedby="basic-addon2" value={searchRankQuery} onChange={(e) => setSearchRankQuery(e.target.value)} required={!selectedRankItems} />
                                    </InputGroup>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dropdown-scrollable'>
                                    {filteredRankItems.map((item, index) => (
                                        <Dropdown.Item key={index} onClick={() => handleRankItemClick(item)}>
                                            {item.name}
                                        </Dropdown.Item>
                                    ))}
                                    {
                                        filteredRankItems.length === 0 && (
                                            <Dropdown.Item disabled>No items found</Dropdown.Item>
                                        )
                                    }
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                            
                    </div>
                </div>
            </div>
            
            <div className="container cv-searched-items">
                {selectedRankItems.length > 0 && (
                    <div className="selected-rank-container">
                    {selectedRankItems.map((item) => (
                        <div key={item.id} className="selected-rank">
                        <p className="selected-rank-text">{item.name}</p>
                        <LazyLoadImage
                            src="/static/crew/assets/img/icons/icn_close.svg"
                            alt="Close Icon"
                            onClick={() => handleRankRemove(item.id)}
                        />
                        </div>
                    ))}
                    </div>
                )}
            </div>


            {rankUserList && rankUserList.length > 0 ?
                <div className="container cv-search-table-container">
                            
                    <div className="cv-search-table-header-section">
                        <div className="view-applicants-table-heading-div">
                            <div className="view-applicants-table-heading-left">
                                <p className="cv-search-table-head-title">Applications Received</p>
                                <p className="cv-search-table-head-count">
                                    <span>{rankUserList.length}</span>&nbsp;Applications
                                </p>
                            </div>
                            <div className="view-applicants-table-heading-right">
                                <Dropdown  onSelect={handleDropdownChangeShortList} >
                                    <Dropdown.Toggle className="btn dropdown-toggle wallet-type-dropdown-btn currency-dropdown-btn">
                                    {applicationStatusType}&nbsp; &nbsp; 
                                    <span className='downarow-class'></span> 
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        <Dropdown.Item eventKey="Shortlisted">Shortlisted</Dropdown.Item>
                                        <Dropdown.Item eventKey="Not Shortlisted">Not Shortlisted</Dropdown.Item>
                                        <Dropdown.Item eventKey="All">All</Dropdown.Item>
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                        <div className="table-responsive cv-search-table-section">
                            <table className="table">
                                <thead className="cv-search-table-section-header">
                                    <tr className="cv-search-table-section-header-row">
                                        <th className="cv-search-table-section-header-cell">Name</th>
                                        <th className="cv-search-table-section-header-cell">Rank</th>
                                        <th className="cv-search-table-section-header-cell">Gender</th>
                                        <th className="cv-search-table-section-header-cell">Nationality</th>
                                        <th className="cv-search-table-section-header-cell">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="cv-search-table-section-body">
                                    {renderTableRows()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            :
                <div className="container cv-search-no-data-container">
                    <div className="cv-search-no-data-container-div">
                        <img 
                            className="cv-search-no-data-container-div-img" 
                            src="/static/crew/assets/img/images/empty_img.svg" 
                            alt="No Data"
                        />
                        <p className="cv-search-no-data-container-div-text">Sorry! No data found</p>
                    </div>
                </div>
            }
            <div style={{height: "132px"}}></div>
            <Scripts />
            <EmployerViewApplicantsScript />
        </>
    );
};

export default EmployerViewApplicants;

export const apiConfigs = {
    // refreshToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTcxMjY2NTQ1MywiaWF0IjoxNzEyNTc5MDUzLCJqdGkiOiI0OTAzN2VhZDNhODQ0NmVmOWE2NTgyOGQ2YTA4ZTk5YyIsInVzZXJfaWQiOjIxMn0.0_YKBCDF6oj2LnpJUtTCMuDVOYpQrbk0JBKdBKzzjsE',
    // accessToken: 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzEyNTc5Mzc3LCJpYXQiOjE3MTI1NzkwNTMsImp0aSI6ImRiZTY1NzYyZjJhNzRhMDE4NjZlZDc5OGVjMGQ2MjhhIiwidXNlcl9pZCI6MjEyfQ.L7eSvYFOOLCi4Z_eLMS56ic9bY0AMaFa4kDGXjYrAAg',
    HOST_BASE_URL: 'http://localhost:8000',
    LOGIN_API_URL: 'myadmin_api/log_in/',
    REFRESH_LOGIN_API_URL: '/myadmin_api/api/login/refresh/',
    country_list_endpoint: '/employer/country_list',
    check_token_valid: '/myadmin_api/check-token-validity/',
    get_user_by_token: '/crew/get_user_all_info',
    get_user_by_id: '/crew/user_retrieve_new/',
    get_user_list: '/crew/user_list',
    get_rank_list: '/employer/rank_list',
    get_rank_byid: '/employer/rank_retrieve/',
    get_country_list: '/employer/country_list',
    get_state_list: '/employer/state_list',
    crew_create_user: '/crew/user_create',
    update_user_byId: '/crew/user_update/',
    // create_profile_1: '/crew/create-profile/step1',
    // create_profile_2: '/crew/create-profile/step2',
    // create_profile_3: '/crew/create-profile/step3',
    // create_profile_employer: '/employer/create-profile',
    crew_cdc_country_list: '/employer/cdc_list/2',
    crew_passport_country_list: '/employer/passport_list/2',
    crew_stcw_country_list: '/employer/stcw_list/2',
    coc_list_byusertype: '/employer/coc_list/',
    cop_list_byusertype: '/employer/cop_list/',
    watch_keeping_list_byusertype: '/employer/watch_keeping_list/',
    flag_list: '/crew/flag_list/',
    vessels_list: '/employer/vessels_list',
    crew_details_insert: '/crew/crew_details_create',
    crew_details_update: '/crew/crew_details_update/',
    csrftoken_generate: '/crew/get_csrf_token/',
    crew_seaservice_insert: '/crew/sea_services_create_new',
    crew_reference_insert: '/crew/previous_employer_create_new',
    get_crew_details_by_userid: '/crew/crew_details_list/',
    // get_all_verified_job_list: '/employer/newpost_job_list?verified_jobs=1',
    get_all_verified_job_list: '/employer/newpost_job_list',
    company_followed: '/crew/followed_by_create',
    company_unfollowed: '/crew/followed_by_destroy_new/',
    job_liked: '/crew/liked_by_create',
    job_unliked: '/crew/liked_by_destroy_new/',
    apply_job: '/employer/apply_job/',
    share_job: '/job/',
    get_featured_company: "/crew/user_priority_list",
    get_employer_boosted: "/crew/get_boosted_employer_new?page=1&limit=10000000000000000000",
    get_crew_boosted: "/crew/get_boosted_crew_new",
    get_rank_by_jobid: "/employer/rank_list_job_type/",
    get_applied_list_by_userid: "/employer/applled_job_listnew/",
    crew_refer_a_job: "/employer/refer-a-job/",
    crew_refer_a_job_update: "/employer/refer-a-job/update/",
    crew_refer_a_job_get: "/employer/refer-a-job/get/",
    crew_refer_a_job_delete: "/employer/refer-a-job/delete/",
    crew_check_status_job: "/employer/applicants_list/",
    get_subscriptions: "/crew/get_subscripstions",
    get_crew_boosted_plan: "/crew/subscribed_boosted_crew_plan/",
    get_crew_highlighted_plan: "/crew/subscribed_highlight_plan/",
    get_crew_followed_to_by_userid: "/crew/followed_to_list/",
    get_crew_followed_by_userid: "/crew/followed_by_list/",
    get_notification_by_userid: "/crew/notification_list/",
    crew_boosting: "/crew/crew_boosting",
    crew_highlight: "/crew/subscripstions_pay",
    get_credit_point: "/myadmin_api/get_credits/",
    get_wallet_history: "/myadmin_api/get_wallet_history/",
    create_order: "/myadmin_api/api/create_order/",
    capture_payment: "/myadmin_api/api/capture_payment/",
    get_all_subscription_plans: "/myadmin_api/subscription_plans/get/",
    check_refer_code_used: "/crew/check_referred_code/",
    use_refer_code: "/crew/use_referred_code/",
    user_change_password: "/crew/user_change_password/",
    user_change_email: "/crew/user_update/",
    sea_service_record_getlist: "/crew/sea_services_list_new/",
    sea_service_record_delete: "/crew/sea_services_destroy/",
    previous_emmployer_record_getlist: "/crew/previous_employer_list/",
    previous_emmployer_record_delete: "/crew/previous_employer_destroy/",

    employer_post_a_job: "/employer/post-a-job/",
    employer_post_a_job_update: "/employer/post-a-job/update/",
    employer_search_cv: "/crew/search_filter",
    employer_get_applicants_list: "/employer/applicants_list_new/",
    employer_job_boosting: "/crew/employer_boosting",
    employer_job_highlight: "/crew/subscripstions_pay",
    employer_resume_download: "/crew/resume-downloads/",
    employer_shortlisted: "/crew/profile/shortlisted/",


    // Resume Download Api
    resume_download_plans_list: "/crew/pack-plan/",
    resume_download_topup_plan_list: "/crew/topup-plan/",
    resume_download_purchase: "/crew/purchase_pack/",
    resume_download_topup_purchase: "/crew/purchase_topup/",
    
    // Crewing Agent Post Job Api
    job_post_plans_list: "/employer/job-post-plans/",
    job_post_check_free_plan_used: "/employer/check_free_trial_pack/",
    job_post_purchase_plan: "/employer/apply_plan_job_post/",
    job_post_purchase_topup_plan: "/employer/job_post_topup_plan/",
    
    // Purchased Plan List
    purchased_resume_download_plans_list: "/crew/subscribed_pack_plan_new/",
    purchased_resume_download_topup_plans_list: "/crew/subscribed_topup_plan_new/",
    job_post_purchased_plans_list: "/employer/job_post_subscribed_pack_plan_new/",
    job_post_topup_plan_purchased: "/employer/get_job_post_topup_plan/",
    
    sub_users_list: "/crew/manage_user_list/",
    sub_users_create: "/crew/referral_create_user",
    sub_users_delete: "/crew/user_retrieve_destroy/",
    
    get_user_byUserCode : "/crew/get_user_by_link/",
    get_user_activeplanleft : "/crew/active-plans/details/",

    get_subscription_detail_byID: "/myadmin_api/subscription_plans/get/",
    toggle_password_change_flag: "/myadmin_api/toggle-password-change/",
    email_configure_setting: "/myadmin_api/app-version/"

  };
  
  export const apiRoutes = {
    home: '/',
    homecrew: '/crew-home',
    login: '/login',
    forget_password: '/forget-password',
    login_mobile: '/mobile/login',
    register: '/register',
    register_crew: '/register/crew',
    register_crew_confirm_mail: '/register/crew/confirmmail',
    create_profile_1: '/crew/create-profile/step1',
    create_profile_2: '/crew/create-profile/step2',
    create_profile_3: '/crew/create-profile/step3',
    create_find_job: '/crew/find-jobs',
    crew_discover: '/discover',
    crew_job_refer: '/crew/job-refer',
    crew_my_jobs: '/crew/my-jobs',
    crew_job_refer_edit: '/crew/job-refer/edit/', 
    company_jobs: '/company/jobs/',
    job_show: '/jobs/show/',
    get_subscriptions: '/subscription-plans/',
    get_wallet: '/wallet/',
    register_employer: '/register/employer',
    register_employer_mobile: '/register/employer/mobile',
    register_employer_mobile_otp: '/register/employer/mobile/otp',
    register_employer_form: '/register/employer/form',
    register_employer_confirm_mail: '/register/employer/confirmmail',
    create_profile_employer: '/employer/create-profile',
    create_profile_sub_employer: '/sub/employer/create-profile',
    crew_refer_earn: '/refer-and-earn',
    crew_help_support: '/help-support',
    crew_settings: '/settings',
    

    employer_find_job: '/employer/find-jobs',
    employer_discover: '/employer/discover',
    employer_postjob: '/employer/post-job',
    employer_postjob_edit: '/employer/post-job/edit/',
    employer_search_cv: '/employer/cv-search',
    employer_view_applicants: '/employer/view-applicants/',
    employer_my_jobs: '/employer/my-jobs/',
    employer_view_profile: '/employer/view-profile/',
    employer_view_applicant_profile: '/employer/applicant/view-profile/',
    employer_get_subscriptions: "/employer/subscription-plans",
    employer_manage_user: "/employer/manage-users/",



    emp_home: '/employer-home',

    sub_employer_create_byLink: "/new-user/",
    sub_employer_form: "/register/employer/form/",

    cancellation_and_refund: '/cancellation-and-refund/',
    terms_and_conditions: '/terms-and-conditions/',
    privacy_policies: '/privacy-policy/',
  };
  

export const externalLinksConfigs = {
  linkedInLink: 'https://www.linkedin.com/company/joinmyship',
  instagramLink: 'https://www.linkedin.com/company/joinmyship',
  facebookLink: 'https://www.linkedin.com/company/joinmyship',
  playStoreLink: 'https://play.google.com/store/apps/details?id=com.joinmyship.android&hl=en_US',
  appStoreLink: 'https://apps.apple.com/si/app/join-my-ship/id6476459076'
};

export const razorpayConfigs = {
  key: "rzp_live_BbEn6GJzJKOWvT",
  key_secret: "i8q81hq5vM2o7u94NgGafY0h"
};

export const razorpayTestConfigs = {
  key: 'rzp_test_wwDObsaedPI1ni'
};

export const razorpayMinimumAmountConfigs = {
  INR: 500,
  USD: 5
};

export const razorpayMinimumAmountTestConfigs = {
  INR: 1,
  USD: 0.01
};

export const subscriptionPlansIdConfigs = {
  employerBoostPlanId: 30,
  employerHighlightPlanId: 29
};

export const emailConfigsSetting = {
  flag_fetch_id: 3
};

export const discoverConfigsSetting = {
  flag_fetch_id: 4 
};

  // email:  crewingagent@designwaala.33mail.com
  // password: 123456
export const firebaseTokenApiConfigs = {
    get_firebase_token: 'https://identitytoolkit.googleapis.com/v1/accounts:signInWithPassword',
    firebaseData: {
      key: 'AIzaSyDcGN1MMLbdXRLVmNAmFHj7SaSBUVMw36Y',
      returnSecureToken: true
    }
  };
  


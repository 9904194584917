// import { apiConfigs } from '../config/apiConfig'; 
export function getLocalItem(item) {
  return localStorage.getItem(item); 
}

export function getLocalJsonItem(item) {
  const istenJsonString = getLocalItem(item); 
  const userDataLocal = JSON.parse(istenJsonString); 
  return userDataLocal; 
}

export function setLocalItem(key, value) {
  localStorage.setItem(key, value); 
}

export function setLocalJsonItem(key, value) {
  const jsonStringuserData = JSON.stringify(value);
  localStorage.setItem(key, jsonStringuserData); 
}

export function clearLocalItem(key) {
  localStorage.removeItem(key); 
}

export function clearAllLocalItem() {
  localStorage.clear();
}



// export function getAccessToken() {
//   return localStorage.getItem("accessToken"); 
// }

// export function setAccessToken(token) {
//   localStorage.setItem("accessToken", token); 
// }

// export function clearAccessToken() {
//   localStorage.removeItem("accessToken"); 
// }

// export function getAccessCreatedToken() {
//   return localStorage.getItem("accessTokenCreated"); 
// }

// export function setAccessCreatedToken(value) {
//   localStorage.setItem("accessTokenCreated", value); 
// }

// export function clearAccessCreatedToken() {
//   localStorage.removeItem("accessTokenCreated"); 
// }

// export function getRefreshToken() {
//   return localStorage.getItem("refreshToken"); 
// }

// export function setRefreshToken(token) {
//   localStorage.setItem("refreshToken", token); 
// }

// export function clearRefreshToken() {
//   localStorage.removeItem("refreshToken"); 
// }

// export function getRefreshCreatedToken() {
//   return localStorage.getItem("refreshTokenCreated"); 
// }

// export function setRefreshCreatedToken(value) {
//   localStorage.setItem("refreshTokenCreated", value); 
// }

// export function clearRefreshCreatedToken() {
//   localStorage.removeItem("refreshTokenCreated"); 
// }

export function getIsLoggedIn() {
  const isLoggedIn = localStorage.getItem("isLoggedIn");
  return isLoggedIn === "true";
}

export function setIsLoggedIn(value) {
  localStorage.setItem("isLoggedIn", value); 
}


import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { Navbar, Nav, Container, Button } from 'react-bootstrap';
import { getIsLoggedIn } from '../tokenManager';
import { apiRoutes } from '../../config/apiConfig';

const NavbarHomeCss = () => {
  return (
    <>
      <style>
        {`
          .nav-profile {
            display: flex;
            align-items: center;
          }
          .nav-profile .nav-profile-notify {
            margin-right: 1rem;
          }
          .nav-link.active {
            color: var(--Primary-Blue, #407BFF) !important;
          }
          @media (min-width: 992px) {
            .navbar-toggler {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
};

const NavbarHome = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 430);

  // useEffect(() => {
  //   const isLoggedIn = getIsLoggedIn();
    
  //   if (!isLoggedIn) {
  //     navigate(apiRoutes.home);
  //   }
  // }, [navigate]);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'nav-link active' : 'nav-link';
  };

  const checkIfMobileSize = () => {
    setIsMobile(window.innerWidth <= 430);
  };

  useEffect(() => {
    window.addEventListener('resize', checkIfMobileSize);
    return () => {
      window.removeEventListener('resize', checkIfMobileSize);
    };
  }, []);

  return (
    <>
      <NavbarHomeCss />
      <Navbar expand="md" sticky="top" className="navbar-shrink py-3 navbar-light" id="mainNav">
        <Container>
          <Navbar.Brand as={Link} to={apiRoutes.home} className="d-flex align-items-center">
            <LazyLoadImage
              className="navbar-logo"
              src="/static/crew/assets/img/brands/newLogo.png"
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navcol-1"
            aria-expanded={!collapsed}
            onClick={toggleCollapsed}
          >
            <span className="navbar-toggler-icon"></span>
            <span className="visually-hidden">Toggle navigation</span>
          </Navbar.Toggle>
          <Navbar.Collapse id="navcol-1" className={`collapse ${collapsed ? '' : 'show'}`} style={{ paddingLeft: 0, marginLeft: '157px', gap: '10px' }}>
            <Nav className="mx-auto new-navbar-container" style={{ gap: '48px' }}>
              <Nav.Link as={Link} to={apiRoutes.home} className={getNavLinkClass(apiRoutes.home)}>Home</Nav.Link>

              {isMobile && (
                <Nav.Link as={Link} to={apiRoutes.terms_and_conditions} className={getNavLinkClass(apiRoutes.terms_and_conditions)}>Terms & Conditions</Nav.Link>
              )}
              {!isMobile && (
                <>
                  <Nav.Link as={Link} to={apiRoutes.login} className={getNavLinkClass(apiRoutes.login)}>Find Job</Nav.Link>
                  <Nav.Link as={Link} to={apiRoutes.login} className={getNavLinkClass(apiRoutes.login)}>Discover</Nav.Link>
                </>
              )}
              <Nav.Link as={Link} to={apiRoutes.crew_help_support} className={getNavLinkClass(apiRoutes.crew_help_support)}>Contact Us</Nav.Link>

              {!isMobile && (
                <>
                  <Nav.Link as={Link} to={apiRoutes.login} className={getNavLinkClass(apiRoutes.login)}>Login</Nav.Link>
                  <Nav.Link as={Link} to={apiRoutes.register}>
                    <Button className="btn btn-primary shadow register-btn nav-refer-job" role="button">Register</Button>
                  </Nav.Link>
                </>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </>
  );
};

export default NavbarHome;

import React, { useEffect } from 'react';
import Loader from './Loader';
import { refreshAccessToken, isAccessTokenExpired, isRefreshTokenExpired } from '../apiService';
import { getLocalItem } from '../tokenManager';

const Layout = ({ children }) => {
  useEffect(() => {

    if (isRefreshTokenExpired()) {
      if (isAccessTokenExpired()) {
        const refreshTokenData = getLocalItem("refreshToken");
        console.log("Token has expired");
        refreshAccessToken(refreshTokenData);
      } 
    } 
  }, []); 

  return <>
      <Loader />
      {children}
     </>;
};

export default Layout;

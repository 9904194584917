import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiRoutes } from '../config/apiConfig';
import { setLocalItem, getLocalItem, setLocalJsonItem } from '../components/tokenManager';
import { getUserByToken, changePasswordDBNew, loginFunc, isPasswordChangeFunc } from '../components/apiService';
import {auth} from '../components/firebase';
import { sendPasswordResetEmail } from "firebase/auth";

import LoadingAnimation from '../components/layouts/LoaderButton';

const ForgetPasswordCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/login.css" />
    </>
  );
};


const ForgetPassword = () => {
  localStorage.clear();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!email) {
      toast.error("Please enter Mail Id");
      return;
    }

    if (!isChecked) {
      toast.error("Please agree to the Terms of Use and Privacy Policy");
    } else {
      setLoader(true);
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent");
      const ispasswordUpdateFormData = {
        "is_password_change": 1
      };
      const updatedUser = await isPasswordChangeFunc(email, ispasswordUpdateFormData);
      setLoader(false);
      navigate( apiRoutes.login ); 

    }
  };

  const handleButtonClick = () => {
    window.location.href = apiRoutes.login_mobile;
  };
  
  return (
    <>
      {loader && 
        <LoadingAnimation />
      }
      <ForgetPasswordCss />
      <ToastContainer />
      <div className="row d-flex justify-content-center" style={{ background: `url('/static/crew/assets/img/images/login_bg.png') center / cover no-repeat` }}>
        <div className="col-md-6 col-xl-4 signin-login-mail">
          <div className="card" style={{ borderRadius: '0px' }}>
            <div className="card-body text-center d-flex flex-column align-items-center" style={{ padding: '42px 98px', gap: '55px' }}>
              <div className="login-logo-container">
                <LazyLoadImage  className="login-logo" src='/static/crew/assets/img/brands/newLogo.png' alt="Logo" />
                <div>
                  <a href="/">
                    <LazyLoadImage  className="left-arrow" src='/static/crew/assets/img/arrows/ArrowLeft.svg' alt="Left Arrow" />
                  </a>
                </div>
              </div>
              <div style={{ display: 'inline-grid', gap: '54px' }}>
                <div style={{ display: 'inline-grid', gap: '30px' }}>
                  <div style={{ display: 'inline-grid', gap: '19px' }}>
                    <h1 className="login-heading">Forget Password</h1>
                    <div className="login-desc">
                      <p>Please enter your E-mail id to continue</p>
                    </div>
                  </div>
                  <div>
                    <form method="post" data-bs-theme="light" onSubmit={handleSubmit} style={{ gap: '22px', display: 'inline-grid' }}>
                      <div className="mb-3"><label className="form-label email-label">E-mail</label>
                      <input className="form-control input-email" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Please enter your email id" /></div>
                      <div style={{ display: 'inline-grid', gap: '22px' }}>
                        <div style={{ display: 'inline-grid', gap: '28px' }}>
                          <div className="mb-3 policy-container">
                            <input type="checkbox" className="checkbox-box" onChange={handleCheckboxChange} checked={isChecked} />
                            <label className="form-label policy-label">
                              I agree to&nbsp;
                              <a href={apiRoutes.terms_and_conditions} target='_blank'> 
                                Terms of use
                              </a>
                              &nbsp;and&nbsp;
                              <a href={apiRoutes.privacy_policies} target='_blank'> 
                                Privacy Policy
                              </a>&nbsp;
                            </label>
                          </div>
                          <div className="mb-3"><button className="btn btn-primary shadow d-block w-100 sign-in-button"  type="submit">Send Mail</button></div>
                        </div>
                        <div>
                          <p className="signup-para">Have an account?&nbsp;<a className="signup-link" href={apiRoutes.login}><span style={{ color: 'rgb(45, 45, 45)' }}>Login Here</span></a></p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="sign-up-btn">
                  <button className="btn btn-primary" onClick={handleButtonClick} type="button">
                    <LazyLoadImage
                      className="sign-up-btn-img"
                      src='/static/crew/assets/img/icons/Group.svg'
                      alt="Sign Up Button"
                    />
                    Sign In With Mobile
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="copyright-watermark">© Join My Ship 2024</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ForgetPassword;

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

// https://firebase.google.com/docs/web/setup#available-libraries

const firebaseConfig = {
  apiKey: "AIzaSyDcGN1MMLbdXRLVmNAmFHj7SaSBUVMw36Y",
  authDomain: "joinmyship-91b39.firebaseapp.com",
  projectId: "joinmyship-91b39",
  storageBucket: "joinmyship-91b39.appspot.com",
  messagingSenderId: "696639212337",
  appId: "1:696639212337:web:b108da8793a4f68d7bbe10",
  measurementId: "G-KWW9MXPPC3"
};

const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, app };

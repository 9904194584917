import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import { getResumeDownloadPlansList, getResumeDownloadTopUpPlansList, getPostJobPlansList, 
        checkPostJobFreePlansUsed, getPurchasedResumeDownloadPlansList, 
        getPurchasedResumeDownloadTopUpPlansList, getPurchasedJobPostPlansList, 
        getPurchasedJobPostTopUpPlansList, formatPlansDate, getActivePlanLeftData
      } from '../components/apiService';
import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';
import EmployerPlanCard from '../components/layouts/EmployerPlanCards';
import { apiRoutes } from '../config/apiConfig';
import CustomTopUpPlanCards from '../components/layouts/CustomTopUpPlanCards';

const EmployerSubscriptionPlanScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/discover_page.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/subscription.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Text-Input.css" />

    </>
  );
};

const EmployerSubscriptionPlanScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const EmployerSubscriptionPlan = () => {
  const userToken = getLocalItem("accessToken"); 
  const userData =  getLocalJsonItem("userData");
  const [activeTab, setActiveTab] = useState(0);
  const resumeDownloadPageHeaderHeading = "Resume download plan"
  const boostPageHeaderText = "Choose the plan that best suits your needs from our carefully curated options."
  const jobPostPagePlanHeading = "Job Post Plan";
  const highlightPagePlanText = "Choose the plan that best suits your needs from our carefully curated options.";
  const activePageHeaderHeading = "Increase your visibility to potential employers"
  const activePageHeaderText = "Our profile highlighting feature ensures that your crew resume stands out and appears at the top of search results, increasing visibility to potential employers."
  const resumeDownloadPlanText = "Buy and connect with top crew applicants instantly!";
  const TopUpPlanText = "Configure plan based on your needs.";
  

  const handleTabClick = (index) => {
    setActiveTab(index);
  }

  // get plans for Crew boosted Starts
  const [crewBoostedPlans , setCrewBoostedPlans] = useState([]);
  const [resumeDownloadTopUpPlans , setResumeDownloadTopUpPlans] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getResumeDownloadPlansList( userToken);
        setCrewBoostedPlans(response);
        const response1 = await getResumeDownloadTopUpPlansList( userToken);
        setResumeDownloadTopUpPlans(response1);
      } catch (error) {
        console.error('Error fetching Plan list:', error);
      } 
    };
    fetchJobs();
  }, [activeTab === 0]);
  // get plans for Crew boosted Ends

  // get plans for Crew Highlight Starts
  const [crewHighlightPlans , setCrewHighlightPlans] = useState([]);
  const [freePlanUsedCheck , setFreePlanUsedCheck] = useState(null);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getPostJobPlansList(userToken);
        setCrewHighlightPlans(response);
        const response1 = await checkPostJobFreePlansUsed(userToken);
        setFreePlanUsedCheck(response1);
      } catch (error) {
        console.error('Error fetching Plan list:', error);
      } 
    };
    fetchJobs();
  }, [activeTab === 1]);
  // get plans for Crew Highlight Ends
  
  // get plans for Crew boosted Starts
  const [crewBoostedSubscribedPlan , setCrewBoostedSubscribedPlan] = useState();
  const [resumeDownloadTopUpPurchasedPlanList , setResumeDownloadTopUpPurchasedPlanList] = useState();
  const [crewHighlightSubscribedPlan , setCrewHighlightSubscribedPlan] = useState();
  const [postJobTopUpPurchasedPlanList , setPostJobTopUpPurchasedPlanList] = useState();
  const [activeplansLeftData , setActiveplansLeftData] = useState();

  useEffect(() => {
    const fetchJobs = async () => {
      try {

        const response = await getPurchasedResumeDownloadPlansList( userData.id ,userToken);
        setCrewBoostedSubscribedPlan(response);
        const response1 = await getPurchasedResumeDownloadTopUpPlansList( userData.id ,userToken);
        setResumeDownloadTopUpPurchasedPlanList(response1);
        const response2 = await getPurchasedJobPostPlansList( userData.id ,userToken);
        setCrewHighlightSubscribedPlan(response2);
        const response3 = await getPurchasedJobPostTopUpPlansList( userData.id ,userToken);
        setPostJobTopUpPurchasedPlanList(response3);
        const response4 = await getActivePlanLeftData( userData.id ,userToken);
        setActiveplansLeftData(response4);
      } catch (error) {
        console.error('Error fetching Plan list:', error);
      } 
    };
    fetchJobs();
  }, [activeTab === 2]);
  // get plans for Crew boosted Ends
  

// console.log("crewBoostedSubscribedPlan", crewBoostedSubscribedPlan);
// console.log("resumeDownloadTopUpPurchasedPlanList", resumeDownloadTopUpPurchasedPlanList);
// console.log("crewHighlightSubscribedPlan", crewHighlightSubscribedPlan);
// console.log("postJobTopUpPurchasedPlanList", postJobTopUpPurchasedPlanList);
// console.log("activeplansLeftData", activeplansLeftData);

  return (
    <>
        <EmployerSubscriptionPlanScriptCss />
        <NavbarCrew />

        <div className="container">
            <div className="row">
                <div className="col-md-6 subscription-heading-left-container">
                  <div className="subscription-heading-tabs-container">
                      <div className={"subscription-heading-tab"} onClick={() => handleTabClick(0)}>
                          <LazyLoadImage className="subscription-heading-tab-icon" 
                            src=  { activeTab === 0 ? 
                                "/static/crew/assets/img/icons/UserCircle.svg" : 
                                "/static/crew/assets/img/icons/UserCircle_blue.svg"
                              }
                            alt="Boost Profile" />
                          <p className={`subscription-heading-tab-text ${activeTab === 0 ? 'active' : ''}`}>Resume Download Plan</p>
                      </div>
                      { userData.user_type_key === 5 &&
                        <div className={"subscription-heading-tab"} onClick={() => handleTabClick(1)}>
                            <LazyLoadImage className="subscription-heading-tab-icon" 
                              src=  { activeTab === 1 ? 
                                "/static/crew/assets/img/icons/BookmarkSimple_blue.svg" : 
                                "/static/crew/assets/img/icons/BookmarkSimple.svg" 
                              }
                              alt="Highlight Profile" />
                            <p className={`subscription-heading-tab-text ${activeTab === 1 ? 'active' : ''}`}>Job Post Plan</p>
                        </div>
                      }
                      <div className={"subscription-heading-tab"} onClick={() => handleTabClick(2)}>
                          <LazyLoadImage className="subscription-heading-tab-icon" 
                            src=  { activeTab === 2 ? 
                              "/static/crew/assets/img/icons/Stack_blue.svg" : 
                              "/static/crew/assets/img/icons/Stack.svg" 
                            }
                            alt="Active Plans" />
                          <p className={`subscription-heading-tab-text ${activeTab === 2 ? 'active' : ''}`}>Active Plans</p>
                      </div>
                  </div>
                  <div className="subscription-sub-heading-container">
                      <h1 className="subscription-sub-heading">
                            {activeTab === 0 && resumeDownloadPageHeaderHeading}
                            {activeTab === 1 && jobPostPagePlanHeading}
                            {activeTab === 2 && activePageHeaderHeading}
                      </h1>
                      <p className="subscription-sub-heading-details">
                            {activeTab === 0 && boostPageHeaderText}
                            {activeTab === 1 && highlightPagePlanText}
                            {activeTab === 2 && activePageHeaderText}
                      </p>
                  </div>
                </div>
                <div className="col-md-6 subscription-heading-img-container">
                    <LazyLoadImage src="/static/crew/assets/img/images/welcome_abroad_img.svg" alt="Welcome Abroad" />
                </div>
            </div>
        </div>

          {activeTab === 0 && (
              <div className="container subscription-plans-list-container">
                <div className="row subscription-plans-container">
                  {crewBoostedPlans && (
                    crewBoostedPlans.map((planData, index) => (
                        <EmployerPlanCard
                          indexKey={index}
                          key={planData.id} 
                          planName={planData.name}
                          planId={planData.id}
                          credits={planData.price}
                          validity={planData.duration_days}
                          dailyLimit={planData.daily_limit} 
                          supportText="24/7 Critical Support"
                          pagePlanText={resumeDownloadPlanText}
                          planType={"resume-download"}
                          freePlanCheck={'enable'}
                        />
                    ))
                  )}
                  {resumeDownloadTopUpPlans && (
                    resumeDownloadTopUpPlans.map((planData, index) => (
                        <EmployerPlanCard
                          indexKey={index}
                          key={planData.id} 
                          planName={planData.name}
                          planId={planData.id}
                          credits={planData.price}
                          validity={planData.duration_days}
                          dailyLimit={planData.topup_limit} 
                          supportText="24/7 Critical Support"
                          pagePlanText={resumeDownloadPlanText}
                          planType={"resume-download-topup"}
                          freePlanCheck={'enable'}
                        />
                    ))
                  )}
                </div>
            </div>
          )}
          {activeTab === 1 && (
            <div className="container subscription-plans-list-container">
                <div className="row subscription-plans-container">
                  {crewHighlightPlans && (
                    crewHighlightPlans.map((planData, index) => (
                        <EmployerPlanCard
                          indexKey={index}
                          key={planData.id} 
                          planName={planData.name}
                          planId={planData.id}
                          credits={planData.price}
                          validity={planData.duration_days}
                          dailyLimit={planData.monthly_limit} 
                          supportText="24/7 Critical Support"
                          pagePlanText={resumeDownloadPlanText}
                          planType={"Crewing-Agent"}
                          freePlanCheck={freePlanUsedCheck ? "disable" : 'enable'}
                        />
                    ))
                  )}
                  <CustomTopUpPlanCards
                    indexKey="custum_topup"
                    key="custum_topup" 
                    planName="TOP-UP PLAN"
                    planId="custum_topup"
                    credits="0.00"
                    validity="30 days validity"
                    dailyLimit="30" 
                    supportText="24/7 Critical Support"
                    pagePlanText={TopUpPlanText}
                    planType={"Crewing-Agent"}
                    freePlanCheck='enable'
                  />
                </div>
            </div>
          )}
          {activeTab === 2 && (
              <div className="container active-subs-container">
              <div className="active-subs-container-div">
                  <div className="active-subs-header-container">
                      <h1 className="active-subs-header-title">Plans</h1>
                      <h1 className="active-subs-header-title">Status</h1>
                  </div>
                  {crewBoostedSubscribedPlan && crewBoostedSubscribedPlan.length > 0 && (
                      <div>
                          {crewBoostedSubscribedPlan.map((plan, index) => (
                                <div key={index} className="active-subs-list-container-resume-download">
                                    <div className="active-subs-list-item-detail">
                                        <h1 className="active-subs-list-item-title">
                                            Resume - {plan.purchase_pack.name}
                                        </h1>
                                        <div className="active-subs-list-item-info-div">
                                            <ul className="active-subs-list-item-info-div-ul">
                                                <li className="active-subs-list-item-info">{plan.purchase_pack.duration_days} days validity</li>
                                                <li className="active-subs-list-item-info">{plan.purchase_pack.daily_limit} per day</li>
                                                <li className="active-subs-list-item-info">{formatPlansDate(plan.created_at)}</li>
                                                {index === 0 && (
                                                  <li className="active-subs-list-item-info">
                                                    {plan.purchase_pack.daily_limit - activeplansLeftData.resume_download_plan[0].resume_downloads} download left per day
                                                  </li>
                                                )}
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="active-subs-list-item-status">
                                        <LazyLoadImage className="active-subs-list-item-status-img" src="/static/crew/assets/img/icons/CheckCircleGreen.svg" alt="Status Icon" />
                                        <p className="active-subs-list-item-status-text">
                                            Active
                                        </p>
                                    </div>
                                </div>
                          ))}
                      </div>
                  )}

                  {resumeDownloadTopUpPurchasedPlanList && resumeDownloadTopUpPurchasedPlanList.length > 0 && (
                      <div>
                          {resumeDownloadTopUpPurchasedPlanList.map((plan, index) => (
                              <div key={index} className="active-subs-list-container-resume-download">
                                  <div className="active-subs-list-item-detail">
                                      <h1 className="active-subs-list-item-title">
                                          Resume - Top Up - {plan.purchase_top_up.name}
                                      </h1>
                                      <div className="active-subs-list-item-info-div">
                                            <ul className="active-subs-list-item-info-div-ul">
                                              <li className="active-subs-list-item-info">{plan.purchase_top_up.duration_days} days validity</li>
                                              <li className="active-subs-list-item-info">{plan.purchase_top_up.topup_limit} per day</li>
                                              <li className="active-subs-list-item-info">{formatPlansDate(plan.created_at)}</li>
                                              {index === 0 && (
                                                <li className="active-subs-list-item-info">
                                                  {plan.purchase_top_up.topup_limit - activeplansLeftData.resume_download_topup_plan[0].resume_downloads_topup} download left 
                                                </li>
                                              )}
                                            </ul>
                                      </div>
                                  </div>
                                  <div className="active-subs-list-item-status">
                                      <LazyLoadImage className="active-subs-list-item-status-img" src="/static/crew/assets/img/icons/CheckCircleGreen.svg" alt="Status Icon" />
                                      <p className="active-subs-list-item-status-text">
                                          Active
                                      </p>
                                  </div>
                              </div>
                          ))}
                      </div>
                  )}

                  {crewHighlightSubscribedPlan && crewHighlightSubscribedPlan.length > 0 && (
                      <div>
                          {crewHighlightSubscribedPlan.map((plan, index) => (
                              <div key={index} className="active-subs-list-container-resume-download">
                                  <div className="active-subs-list-item-detail">
                                      <h1 className="active-subs-list-item-title">
                                          Job Post - {plan.purchase_pack.name}
                                      </h1>
                                      <div className="active-subs-list-item-info-div">
                                          <ul className="active-subs-list-item-info-div-ul">
                                              <li className="active-subs-list-item-info">{plan.purchase_pack.duration_days} days validity</li>
                                              <li className="active-subs-list-item-info">{plan.purchase_pack.monthly_limit} per day</li>
                                              <li className="active-subs-list-item-info">{formatPlansDate(plan.created_at)}</li>
                                              {index === 0 && (
                                                <li className="active-subs-list-item-info">
                                                  {plan.purchase_pack.monthly_limit - activeplansLeftData.job_post_plan[0].job_posted_count} Job Post left 
                                                </li>
                                              )}
                                          </ul>
                                      </div>
                                  </div>
                                  <div className="active-subs-list-item-status">
                                      <LazyLoadImage className="active-subs-list-item-status-img" src="/static/crew/assets/img/icons/CheckCircleGreen.svg" alt="Status Icon" />
                                      <p className="active-subs-list-item-status-text">
                                          Active
                                      </p>
                                  </div>
                              </div>
                          ))}
                      </div>
                  )}

                  {postJobTopUpPurchasedPlanList && (
                      <div className="active-subs-list-container-resume-download">
                          <div className="active-subs-list-item-detail">
                              <h1 className="active-subs-list-item-title">
                                Job Post - Top Up Plan
                              </h1>
                              <div className="active-subs-list-item-info-div">
                                  <ul className="active-subs-list-item-info-div-ul">
                                      <li className="active-subs-list-item-info">
                                        {postJobTopUpPurchasedPlanList.posts_left ? postJobTopUpPurchasedPlanList.posts_left : 0 } left
                                      </li>
                                      <li className="active-subs-list-item-info">
                                        {formatPlansDate(postJobTopUpPurchasedPlanList.created_at)}
                                      </li>
                                  </ul>
                              </div>
                          </div>
                          <div className="active-subs-list-item-status">
                              <LazyLoadImage className="active-subs-list-item-status-img" src="/static/crew/assets/img/icons/CheckCircleGreen.svg" alt="Status Icon" />
                              <p className="active-subs-list-item-status-text">
                                {postJobTopUpPurchasedPlanList.posts_left > 0 ?
                                    'Active' : 'Not Active'
                                }
                              </p>
                          </div>
                      </div>
                  )}

                  {!crewBoostedSubscribedPlan && !resumeDownloadTopUpPurchasedPlanList && !crewHighlightSubscribedPlan   && (
                      <div className="active-subs-no-list-container">
                          <div className="active-subs-no-list">
                              <p className="active-subs-no-list-text">No active plans found!</p>
                              <a href={apiRoutes.get_wallet}> 
                                <p className="active-subs-no-list-link">Buy Now</p>
                              </a>
                          </div>
                      </div>
                  )}
              </div>
          </div>
          )}
        <div style={{ paddingBottom: '122px' }}></div>
        <Footer />
        <Scripts />
        <EmployerSubscriptionPlanScript />
    </>
  );
};

export default EmployerSubscriptionPlan
import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css'; 
import 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button  } from 'react-bootstrap';
import { getAuth, updateProfile, updateEmail, updatePassword, sendEmailVerification   } from "firebase/auth";
import { auth } from '../components/firebase';
import 'firebase/auth';
import { getLocalItem, setLocalJsonItem } from '../components/tokenManager';
import { crewCreateUser, loginFunc, getUserByToken } from '../components/apiService';
import { apiRoutes } from '../config/apiConfig';

const SignupEmployeeFormCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/login.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/signup.css" />
    </>
  );
};


const SignupEmployeeForm = () => {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };
    const [formData, setFormData] = useState({
        companyName: '',
        companyWebsite: '',
        companyEmail: '',
        password: '',
        confirmPassword: ''
    });
    const [passwordMatch, setPasswordMatch] = useState(true);

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'confirmPassword') {
            setPasswordMatch(value === formData.password);
        }
    };

    // const blockedDomains = ["mail.com", "gmail.com", "yahoo.co.in", "outlook.com", "proton.me", "proton.mail", "ymail.com", "zoho.com", "msn.com", "hotmail.com", "icloud.com"];
    const blockedDomains = ["mail.com", "yahoo.co.in", "outlook.com", "proton.me", "proton.mail", "ymail.com", "zoho.com", "msn.com", "hotmail.com", "icloud.com"];
      
    const isEmailAllowed = (email) => {
        const pattern = blockedDomains.map(domain => `@${domain.replace('.', '\\.')}$`).join('|');
        const regex = new RegExp(pattern, 'i'); 
        return !regex.test(email);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        // console.log("ccccccccccccccc", formData);
        const get_user_type_key = getLocalItem("selectedUser");
        if (get_user_type_key !== 5) {
            if (!isEmailAllowed(formData.companyEmail)) {
                toast.error("Domain not allowed.")
                return; 
            } 
        } 

        const user = auth.currentUser;
        await updateProfile(user, {
            displayName: formData.companyName 
            });

        await updateEmail(user, formData.companyEmail);
        await updatePassword(user, formData.password);
        await sendEmailVerification(user);
        // console.log("user.stsTokenManager", user);
        // console.log("user.stsTokenManager", user.phoneNumber);
        // console.log("user.stsTokenManager.accessToken", user.stsTokenManager.accessToken);

        const userCreateFormData = {
            user_type_key: get_user_type_key,
            screen_check: 0,
            company_name: formData.companyName,
            website: formData.companyWebsite,
            password: formData.password,
            email: formData.companyEmail,
            auth_key: user.stsTokenManager.accessToken,
            username: formData.companyEmail,
            number: user.phoneNumber
        };
        // console.log("userCreateFormData", userCreateFormData);
        await crewCreateUser(userCreateFormData);
        const loginResponse = await loginFunc(formData.companyEmail, formData.password);
        const userToken = getLocalItem("accessToken"); 
        const userData = await getUserByToken(userToken);
        setLocalJsonItem("userData", userData.data);
        navigate(  apiRoutes.register_employer_confirm_mail +`?usermail=${formData.companyEmail}`);
    };

    return (
        <>
            <SignupEmployeeFormCss />
            <ToastContainer />
            <div className="row d-flex justify-content-center" style={{ background: 'url("/static/crew/assets/img/images/login_bg.png") center / cover no-repeat' }}>
            <Container>
                <Row className="justify-content-center">
                    <Col md={6} xl={4} className="signin-login-mail">
                        <Card style={{ borderRadius: '0px' }}>
                            <Card.Body className="text-center d-flex flex-column align-items-center" style={{ padding: '58px 98px', gap: '55px' }}>
                                <div className="login-logo-container">
                                    <LazyLoadImage className="login-logo" src="/static/crew/assets/img/brands/newLogo.png" alt="New Logo" />
                                    <div>
                                        <a href={ apiRoutes.register_employer }>
                                            <LazyLoadImage  className="left-arrow" src='/static/crew/assets/img/arrows/ArrowLeft.svg' alt="Left Arrow" />
                                        </a>
                                    </div>
                                </div>
                                <div style={{ display: 'inline-grid', gap: '54px' }}>
                                    <div style={{ display: 'inline-grid', gap: '30px' }}>
                                        <div style={{ display: 'inline-grid', gap: '19px' }}>
                                            <h1 className="login-heading">Sign Up</h1>
                                            <div className="login-desc">
                                                <p>Please fill the form below to complete the sign up</p>
                                            </div>
                                        </div>
                                        <Form onSubmit={handleSubmit} style={{ gap: '22px', display: 'inline-grid' }}>
                                            <div className="mb-3">
                                                <Form.Label className="form-label email-label">Company Name</Form.Label>
                                                <Form.Control className="form-control input-email" type="text" name="companyName" placeholder="Please enter company name" value={formData.companyName} onChange={handleChange} required={!formData.companyName} />
                                            </div>
                                            <div className="mb-3">
                                                <Form.Label className="form-label email-label">Company Website</Form.Label>
                                                <Form.Control className="form-control input-email" type="text" name="companyWebsite" placeholder="Company website" value={formData.companyWebsite} onChange={handleChange} required={!formData.companyWebsite} />
                                            </div>
                                            <div className="mb-3">
                                                <Form.Label className="form-label email-label">Company Email</Form.Label>
                                                <Form.Control className="form-control input-email" type="email" name="companyEmail" placeholder="email@yourdomain" value={formData.companyEmail} onChange={handleChange} required={!formData.companyEmail} />
                                            </div>
                                            <div className="mb-3" style={{ display: 'inline-grid', gap: '12px' }}>
                                                <div>
                                                    <Form.Label className="form-label email-label">Password</Form.Label>
                                                    <Form.Control className="form-control input-password" type="password" name="password" placeholder="Enter password" value={formData.password} onChange={handleChange} required={!formData.password} />
                                                </div>
                                            </div>
                                            <div className="mb-3" style={{ display: 'inline-grid', gap: '12px' }}>
                                                <div>
                                                    <Form.Label className="form-label email-label">Confirm Password</Form.Label>
                                                    <Form.Control className="form-control input-password" type="password" name="confirmPassword" placeholder="Confirm password" value={formData.confirmPassword} onChange={handleChange} required={!formData.confirmPassword} />
                                                    {!passwordMatch && <p className="text-danger">Passwords do not match</p>}
                                                </div>
                                            </div>
                                            <div style={{ display: 'inline-grid', gap: '22px' }}>
                                                <div style={{ display: 'inline-grid', gap: '28px' }}>
                                                    <div className="mb-3 policy-container">
                                                        <Form.Check type="checkbox" className="mb-3 policy-container" label="I agree to Terms of use and Privacy Policy" checked={isChecked} onChange={handleCheckboxChange} />
                                                    </div>
                                                    <div className="mb-3">
                                                        <Button className="btn btn-primary shadow d-block w-100 sign-in-button" disabled={!isChecked} type="submit">Sign Up</Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <div>
                            <p className="copyright-watermark">© Join My Ship 2024</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    );
};

export default SignupEmployeeForm;

import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate, useLocation } from 'react-router-dom';
import { getIsLoggedIn, setIsLoggedIn, getLocalJsonItem, getLocalItem } from '../tokenManager';
import { Navbar, Nav, Container, Button, Dropdown } from 'react-bootstrap';
import { apiRoutes, discoverConfigsSetting } from '../../config/apiConfig';
import ModalFollowers from './followListModal';
import ModalNotifications from './NotificationListModal';
import { getEmailConfigureSettingFlag, handleLogout } from '../apiService';

const NavbarCrewCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/css/follower.css" />
      <style>
        {`
          .nav-profile {
            display: flex;
            align-items: center;
          }
          .nav-profile .nav-profile-notify {
            margin-right: 1rem;
          }
          .nav-link.active {
            color: var(--Primary-Blue, #407BFF) !important;
          }
          @media (min-width: 992px) {
            .navbar-toggler {
              display: none;
            }
          }
        `}
      </style>
    </>
  );
};

const NavbarCrew = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [collapsed, setCollapsed] = useState(true);
  const isLoggedIn = getIsLoggedIn(); 
  const userToken = getLocalItem("accessToken"); 
  const userData =  getLocalJsonItem("userData");

  if (!isLoggedIn) {
    navigate(apiRoutes.home);
  }

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const toggleModal = () => {
    setIsModalOpen(!isModalOpen);
  };

  const [isNotificationsModalOpen, setIsNotificationsModalOpen] = useState(false);
  const toggleNotificationsModal = () => {
    setIsNotificationsModalOpen(!isNotificationsModalOpen);
  };

  const getNavLinkClass = (path) => {
    return location.pathname === path ? 'nav-link active' : 'nav-link';
  };

  const [isActiveFuntionality, setIsActiveFuntionality] = useState(true);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getEmailConfigureSettingFlag( discoverConfigsSetting.flag_fetch_id ,userToken);
        setIsActiveFuntionality(response);
      } catch (error) {
        console.error('Error fetching data:', error);
      } 
    };
    fetchJobs();
  }, []);


  return (
    <>
      <NavbarCrewCss />
      <Navbar expand="md" sticky="top" className="navbar-shrink py-3 navbar-light" id="mainNav">
        <Container>
          <Navbar.Brand href={apiRoutes.homecrew} className="d-flex align-items-center">
            <LazyLoadImage
              className="navbar-logo"
              src="/static/crew/assets/img/brands/newLogo.png"
              alt="Logo"
            />
          </Navbar.Brand>
          <Navbar.Toggle
            aria-controls="navcol-1"
            aria-expanded={!collapsed}
            onClick={toggleCollapsed}
          >
            <span className="navbar-toggler-icon"></span>
            <span className="visually-hidden">Toggle navigation</span>
          </Navbar.Toggle>
          <Navbar.Collapse id="navcol-1" className={`collapse ${collapsed ? '' : 'show'}`} style={{ paddingLeft: 0, marginLeft: '157px', gap: '10px' }}>
            { userData && userData.user_type_key === 2 ? 
              <Nav className="mx-auto new-navbar-container">
                <Nav.Link href={apiRoutes.homecrew} className={getNavLinkClass(apiRoutes.homecrew)}>Home</Nav.Link>
                <Nav.Link href={apiRoutes.create_find_job} className={getNavLinkClass(apiRoutes.create_find_job)}>Find Job</Nav.Link>
                <Nav.Link href={apiRoutes.crew_my_jobs} className={getNavLinkClass(apiRoutes.crew_my_jobs)}>My Jobs</Nav.Link>
                <Nav.Link onClick={toggleModal} className={getNavLinkClass(apiRoutes.followings)}>Followings</Nav.Link>
                { isActiveFuntionality.is_active &&
                  <>
                    <Nav.Link href={apiRoutes.crew_discover} className={getNavLinkClass(apiRoutes.crew_discover)}>Discover</Nav.Link>
                    <Nav.Item className="new-link-container"><p className="new-link">New</p></Nav.Item>
                  </>
                }
                <Nav.Item className="post-job-button-css">
                  <Button className="btn btn-primary shadow register-btn nav-refer-job" href={apiRoutes.crew_job_refer} role="button">
                    Refer a Job
                  </Button> 
                </Nav.Item>
                <Nav.Link><LazyLoadImage onClick={toggleNotificationsModal} className="nav-profile-notify" src="/static/crew/assets/img/icons/BellRinging_danger.svg" alt="Notifications" /></Nav.Link>
                <Nav className="mx-auto nav-profile" style={{ gap: '4rem', alignItems: 'center' }}>
                  <Nav.Item>
                    <Dropdown align="end" className="nav-profile-dropdown-container">
                      <Dropdown.Toggle as="a" className="nav-link nav-profile-container" id="dropdown-basic">
                        <LazyLoadImage className="nav-profile-img" src={userData.profilePic} alt="Profile" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="image-options-container">
                        <Dropdown.Item href={apiRoutes.create_profile_1} className="image-options-records" 
                          disabled={userData && !userData.is_verified}
                        >
                          <LazyLoadImage src="/static/crew/assets/img/icons/User_light_dropdown.svg" alt="Profile" />
                          Edit Profile
                        </Dropdown.Item>
                        <Dropdown.Item href={apiRoutes.get_wallet} className="image-options-records">
                          <LazyLoadImage src="/static/crew/assets/img/icons/Wallet_alt_light_dropdown.svg" alt="Profile" />
                          Wallet
                        </Dropdown.Item>
                        <Dropdown.Item href={apiRoutes.get_subscriptions} className="image-options-records">
                          <LazyLoadImage src="/static/crew/assets/img/icons/Dimond_alt_light_dropdown.svg" alt="Profile" />
                          Subscription
                        </Dropdown.Item>
                        <Dropdown.Item href={apiRoutes.crew_settings} className="image-options-records">
                          <LazyLoadImage src="/static/crew/assets/img/icons/Gear.svg" alt="Profile" />
                          Settings
                          {userData && !userData.mobile_verified ? (
                            <p className="proile-update-logo">Update</p>
                          ) : null}
                        </Dropdown.Item>
                        <Dropdown.Item href={apiRoutes.crew_refer_earn} className="image-options-records">
                          <LazyLoadImage src="/static/crew/assets/img/icons/refer_earn__dropdown.svg" alt="Profile" />
                          Refer & Earn
                        </Dropdown.Item>
                        <Dropdown.Item href={apiRoutes.crew_help_support} className="image-options-records">
                          <LazyLoadImage src="/static/crew/assets/img/icons/help_feedback_dropdown.svg" alt="Profile" />
                          Help & Feedback
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout} className="image-options-records" style={{ color: "#D72015" }}>
                          <LazyLoadImage src="/static/crew/assets/img/icons/Sign_out_circle_light_dropdown.svg" alt="Profile" />
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Item>
                </Nav>
              </Nav>
              :
              <Nav className="mx-auto new-navbar-container">
                <Nav.Link href={apiRoutes.emp_home} className={getNavLinkClass(apiRoutes.emp_home)}>Home</Nav.Link>
                <Nav.Link href={apiRoutes.employer_find_job} className={getNavLinkClass(apiRoutes.employer_find_job)}>Jobs</Nav.Link>
                <Nav.Link href={apiRoutes.employer_my_jobs} className={getNavLinkClass(apiRoutes.employer_my_jobs)}>My Jobs</Nav.Link>
                <Nav.Link href={apiRoutes.employer_search_cv} className={getNavLinkClass(apiRoutes.employer_search_cv)}>Search CV</Nav.Link>
                { isActiveFuntionality.is_active &&
                  <>
                    <Nav.Link href={apiRoutes.crew_discover} className={getNavLinkClass(apiRoutes.crew_discover)}>Discover</Nav.Link>
                    <Nav.Item className="new-link-container"><p className="new-link">New</p></Nav.Item>
                  </>
                }
                <Nav.Item className="post-job-button-css">
                  <Button className="btn btn-primary shadow register-btn nav-refer-job" 
                  href={apiRoutes.employer_postjob} role="button">
                    Post a Job</Button> 
                </Nav.Item>
                <Nav.Link><LazyLoadImage onClick={toggleNotificationsModal} className="nav-profile-notify" src="/static/crew/assets/img/icons/BellRinging_danger.svg" alt="Notifications" /></Nav.Link>
                <Nav className="mx-auto nav-profile" style={{ gap: '4rem', alignItems: 'center' }}>
                  <Nav.Item>
                    <Dropdown align="end" className="nav-profile-dropdown-container">
                      <Dropdown.Toggle as="a" className="nav-link nav-profile-container" id="dropdown-basic">
                        <LazyLoadImage className="nav-profile-img" src={userData.profilePic} alt="Profile" />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className='image-options-container'>
                        <Dropdown.Item href={userData.is_prime === 2 ? apiRoutes.create_profile_sub_employer : apiRoutes.create_profile_employer  } className='image-options-records' 
                          disabled={userData && !userData.is_verified}
                        >
                          <LazyLoadImage src="/static/crew/assets/img/icons/User_light_dropdown.svg" alt="Profile" />
                          Edit Profile
                        </Dropdown.Item>
                        <Dropdown.Item onClick={toggleModal} className='image-options-records' >
                          <LazyLoadImage src="/static/crew/assets/img/icons/UsersFollowers.svg" alt="Profile" />
                          Followers
                        </Dropdown.Item>
                        {userData.is_prime === 1 && (
                          <Dropdown.Item href={apiRoutes.employer_manage_user} className='image-options-records' >
                            <LazyLoadImage src="/static/crew/assets/img/icons/UserPlusRounded.svg" alt="Profile" />
                            Manage Users
                          </Dropdown.Item>
                        )}
                        <Dropdown.Item href={apiRoutes.get_wallet} className='image-options-records' >
                          <LazyLoadImage src="/static/crew/assets/img/icons/Wallet_alt_light_dropdown.svg" alt="Profile" />
                          Wallet
                        </Dropdown.Item>
                        <Dropdown.Item href={apiRoutes.employer_get_subscriptions} className='image-options-records' >
                          <LazyLoadImage src="/static/crew/assets/img/icons/Dimond_alt_light_dropdown.svg" alt="Profile" />
                          Subscription
                        </Dropdown.Item>
                        <Dropdown.Item href={apiRoutes.crew_refer_earn} className='image-options-records' >
                          <LazyLoadImage src="/static/crew/assets/img/icons/refer_earn__dropdown.svg" alt="Profile" />
                          Refer & Earn
                        </Dropdown.Item>
                        <Dropdown.Item href={apiRoutes.crew_settings} className='image-options-records' >
                          <LazyLoadImage src="/static/crew/assets/img/icons/Gear.svg" alt="Profile" />
                          Settings
                          {userData && !userData.mobile_verified ? (
                            <p className='proile-update-logo'>Update</p>
                          ) : null}
                        </Dropdown.Item>
                        <Dropdown.Item href={apiRoutes.crew_help_support} className='image-options-records' >
                          <LazyLoadImage src="/static/crew/assets/img/icons/help_feedback_dropdown.svg" alt="Profile" />
                          Help & Feedback
                        </Dropdown.Item>
                        <Dropdown.Item onClick={handleLogout} className='image-options-records' style={{color: "#D72015"}} >
                          <LazyLoadImage src="/static/crew/assets/img/icons/Sign_out_circle_light_dropdown.svg" alt="Profile" />
                          Logout
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </Nav.Item>
                </Nav>
              </Nav>
            }
          </Navbar.Collapse>
        </Container>
      </Navbar>
      <ModalFollowers userData={userData} userID={userData.id} userToken={userToken} isModalOpen={isModalOpen} toggleModal={toggleModal} />
      <ModalNotifications userID={userData.id} userToken={userToken} isModalOpen={isNotificationsModalOpen} toggleModal={toggleNotificationsModal} />
    </>
  );
};

export default NavbarCrew;

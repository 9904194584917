import React, { useState, useEffect } from 'react';
import { EmailAuthProvider, reauthenticateWithCredential, updateEmail, sendEmailVerification } from 'firebase/auth';
import { auth } from '../firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { changeEmailDB, handleLogout } from '../apiService';
import { getLocalJsonItem, getLocalItem } from '../tokenManager';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { emailConfigsSetting } from '../../config/apiConfig';
import { getEmailConfigureSettingFlag } from '../apiService';

const ChangeEmail = () => {
    const userData =  getLocalJsonItem("userData");
    const userToken = getLocalItem("accessToken"); 
    const [newEmail, setNewEmail] = useState('');
    const [confirmEmail, setConfirmEmail] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isSubmitted, setIsSubmitted] = useState(false);
    const [errors, setErrors] = useState({});
    const [isActiveFuntionality, setIsActiveFuntionality] = useState(true);

    useEffect(() => {
      const fetchJobs = async () => {
        try {
          const response = await getEmailConfigureSettingFlag( emailConfigsSetting.flag_fetch_id ,userToken);
          setIsActiveFuntionality(response);
        } catch (error) {
          console.error('Error fetching data:', error);
        } 
      };
      fetchJobs();
    }, []);
  

    const togglePasswordVisibility = () => {
        setShowPassword(!showPassword);
    };

    const validateForm = () => {
        const newErrors = {};
        if (!newEmail) newErrors.newEmail = 'New email is required';
        if (!confirmEmail) newErrors.confirmEmail = 'Confirm email is required';
        if (newEmail !== confirmEmail) newErrors.confirmEmail = 'Emails do not match';
        if (!currentPassword) newErrors.currentPassword = 'Current password is required';
        return newErrors;
    };

    const handleSubmit = async () => {
        const formErrors = validateForm();
        if (Object.keys(formErrors).length > 0) {
            setErrors(formErrors);
            return;
        } else {
            setErrors({});
            try {
                const user = auth.currentUser;
                const credential = EmailAuthProvider.credential(user.email, currentPassword);
                await reauthenticateWithCredential(user, credential);
                await updateEmail(user, newEmail);
                await sendEmailVerification(user);
                toast.success("Verification Mail Sent.");
            } catch (error) {
                toast.error("Wrong Password.");
            }
            setIsSubmitted(true);
        }
    };

    const handleRefresh = async (e) => {
        e.preventDefault();
        try {
            const user = auth.currentUser;
            if (user) {
                user.reload();
                if (user.emailVerified) {
                    const formData = new FormData();
                    formData.append("username", confirmEmail);
                    formData.append("email", confirmEmail);
                    await changeEmailDB(userData.id, formData, userToken);
                    toast.success("Email Id Updated.");
                    handleLogout();
                } else {
                    toast.error("Email not verified.");
                }
            } else {
                toast.error("User not found.");
            }
        } catch (error) {
            toast.error("Check email.");
        }
    };

    return (
        <div className="container settings-tab-content-container">
            {isActiveFuntionality.is_active ? (
                !isSubmitted ? (
                    <div className="settings-tab-mobile-container">
                        <div className='current-email-container'>
                            <p className='current-email-heading'>Current Email ID</p>
                            <p className='current-email-text'>{userData.email}</p>
                        </div>
                        <div className="settings-tab-mobile-items">
                            <p className="settings-tab-mobile-items-heading">New E-Mail</p>
                            <div className="settings-tab-mobile-item-input">
                                <LazyLoadImage src="/static/crew/assets/img/icons/Envelope.svg" alt="Envelope Icon" />
                                <input 
                                    type="text" 
                                    className="settings-tab-mobile-items-input" 
                                    value={newEmail}
                                    onChange={(e) => setNewEmail(e.target.value)}
                                />
                            </div>
                            {errors.newEmail && <p className="form-error-text">{errors.newEmail}</p>}
                        </div>
                        <div className="settings-tab-mobile-items">
                            <p className="settings-tab-mobile-items-heading">Confirm New E-Mail</p>
                            <div className="settings-tab-mobile-item-input">
                                <LazyLoadImage src="/static/crew/assets/img/icons/Envelope.svg" alt="Envelope Icon" />
                                <input 
                                    type="text" 
                                    className="settings-tab-mobile-items-input" 
                                    value={confirmEmail}
                                    onChange={(e) => setConfirmEmail(e.target.value)}
                                />
                            </div>
                            {errors.confirmEmail && <p className="form-error-text">{errors.confirmEmail}</p>}
                        </div>
                        <div className="settings-tab-mobile-items">
                            <p className="settings-tab-mobile-items-heading">Current Password</p>
                            <div className="settings-tab-password-input-div">
                                <input 
                                    type={showPassword ? "text" : "password"} 
                                    className="settings-tab-mobile-items-input password-input"
                                    value={currentPassword}
                                    onChange={(e) => setCurrentPassword(e.target.value)}
                                />
                                <LazyLoadImage 
                                    src="/static/crew/assets/img/icons/fi_eye.svg" 
                                    alt="Eye Icon" 
                                    className="eye-icon"
                                    onClick={togglePasswordVisibility} 
                                />
                            </div>
                            {errors.currentPassword && <p className="form-error-text">{errors.currentPassword}</p>}
                        </div>
                        <button className="btn btn-primary save-button-mail" type="button" onClick={handleSubmit}>
                            Save Changes
                        </button>
                    </div>
                ) : (
                    <div className="container confirm-mail-container">
                        <div className="confirm-mail-container-div">
                            <div className="confirm-mail-container-div-head">
                                <p className="confirm-mail-container-div-heading">Please verify your email</p>
                                <p className="confirm-mail-container-div-head-text">This action requires email verification. Please check your inbox and come back here.</p>
                            </div>
                            <div className="confirm-mail-name-div">
                                <LazyLoadImage className="confirm-mail-name-icon" src="/static/crew/assets/img/icons/Letter_blue.svg" alt="Email Icon"/>
                                <h1 className="confirm-mail-name-text">{newEmail}</h1>
                            </div>
                            <div className="confirm-mail-refresh-btn-div">
                                <button className="btn btn-primary confirm-mail-refresh-btn" onClick={handleRefresh} type="button">
                                    Refresh &amp; Continue
                                </button>
                                <h1 className="confirm-mail-refresh-btn-heading">After verification click on refresh to continue</h1>
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <div className="container unavailable-container">
                    <div className="unavailable-container-div">
                        <div className="unavailable-container-top-div">
                            <h1 className="unavailable-container-top-div-head">Unavailable!</h1>
                            <p className="unavailable-container-top-div-detail">Apologies, but the email change functionality is currently unavailable. For further assistance, please contact us.</p>
                        </div>
                        <div className="unavailable-container-bot-div">
                            <div className="unavailable-container-bot-email">
                                <LazyLoadImage className="unavailable-container-bot-email-img" src="/static/crew/assets/img/icons/Letter_blue.svg" alt="Email Icon"/>
                                <p className="unavailable-container-bot-email-text">E-Mail Us</p>
                            </div>
                            <p className="unavailable-container-bot-email-name">support@joinmyship.com</p>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChangeEmail;

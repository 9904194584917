import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { jobLiked, jobUnLiked, applyJob } from '../apiService';
import { ApplyJobModal, AppliedSuccessModal, CheckStatusModal } from './FindJobsModal';

const MyJobCard = ({ job, rankData, isFollowing, onFollowToggle, userData, userToken }) => {
  const [isLiked, setIsLiked] = useState(job.userlikedjob_status);
  const [isApplied, setIsApplied] = useState(job.userappliedjob_status);

  const [selectedRank, setSelectedRank] = useState(rankData.name);
  const [selectedRankId, setSelectedRankId] = useState(rankData.id);
  const [isConditionTrue, setIsConditionTrue] = useState(false);

  const handleRadioChange = (rankName, rankId) => {
    setSelectedRank(rankName);
    setSelectedRankId(rankId);
  };

  // handel Like Starts  

  const handleLikeToggle = () => {
    if (isLiked) {
      jobUnLiked(userData.id, job.id, userToken)
        .then(response => {
          setIsLiked(false);
        })
        .catch(error => {
          console.error('Error unliking Job:', error);
        });
    } else {
      const formData = new FormData();
      formData.append("liked_post", job.id);
      formData.append("user_id", userData.id);
      jobLiked(formData, userToken)
        .then(response => {
          setIsLiked(true);
        })
        .catch(error => {
          console.error('Error liking Job:', error);
        });
    }
  };
  
  // handel Like Ends  


  // handel apply Starts  
  // show modal for Apply Job Starts  
  const [showModal, setShowModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
    setIsApplied(false);
  };

  const handleApply = () => {
    setShowModal(true);
  };

  const handleConfirmApply = async () => {
    // console.log("Confirm apply clicked");
    // setIsApplied(true);
    // setShowModal(false);

    try {
      const applyJobData = {
        user_id: userData.id,
        rank_id: selectedRankId,
        job_id: job.id,
        sub_id: 12
      };

      await applyJob(applyJobData, userToken);
      setIsApplied(true);
      setShowModal(false);
      setShowSuccessModal(true);
    } catch (error) {
      console.error('Error applying for job:', error);
    }

  };
  // show modal for Apply Job Ends  


  // show modal for Share Starts  
  const handleShare = (jobId, user_code) => {
    const host = window.location.origin; 
    const jobUrl = `${host}/job/?job_id=${jobId}&user_code=${user_code}`;
    const message = "Click on this link to view this Job:";
  
    if (navigator.share) {
      navigator.share({
        title: 'Check out this job!',
        text: message,
        url: jobUrl
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing:'));
    } else {
      console.log('Error share');
      // const shareLink = document.createElement('a');
      // shareLink.href = jobUrl;
      // shareLink.target = '_blank';
      // shareLink.rel = 'noopener noreferrer';
      // shareLink.style.display = 'none';
      // document.body.appendChild(shareLink);
      // shareLink.click();
      // document.body.removeChild(shareLink);
    }
  };
  
  // show modal for Share Ends  



  // handel apply Ends  

  // Radio button Starts  
  const isRadioEnabled = (rankName) => {
    const condition = rankName === job.posted_by.rank_id.name || rankName === job.posted_by.rank_id.promoted_to.name;
    if (!isConditionTrue && condition) {
      setIsConditionTrue(true);
    }
    return condition;
  };

  // Radio button Ends  


  // handle show status Starts
  const [showStatusModal, setShowStatusModal] = useState(false);

  const handleStatusOpenModal = () => {
        setShowStatusModal(true);
  };

  const handleStatusCloseModal = () => {
        setShowStatusModal(false);
  };
  // handle show status Ends

  return (
    <>
      <Card>
        <Card.Body>
          <div className='job-card-cont'>
            <div className="job-card">
              <div className="jobby-info-container">
                <div className="jobby-info">
                  <LazyLoadImage
                    className="profile-img-info"
                    src={job.posted_by.profilePic}
                    alt="Profile"
                  />
                  <div>
                    <h1 className="jobby-name">Job By: {job.posted_by.first_name}</h1>
                    <h1 className="company-name">{job.posted_by.company_name}</h1>
                    <div onClick={onFollowToggle}  style={{ display: 'flex', flexDirection: 'row', gap: '10px', alignItems: 'center' }}>
                      {isFollowing ? (
                          <p className="follow-button">UnFollow</p>
                        ) : (
                          <>
                            <LazyLoadImage src="/static/crew/assets/img/icons/plus_sign.svg" alt="Plus Sign" />
                            <p className="follow-button">Follow</p>
                          </>
                        )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="apply-job" style={{ display: 'flex', flexDirection: 'column', gap: '21px' }}>
                <div style={{ display: 'flex', justifyContent: 'flex-end' }}  onClick={() => handleShare(job.id, userData.user_code)}>
                  <LazyLoadImage src="/static/crew/assets/img/icons/Reshare_Forward.svg" alt="Share" />
                  <p className="share-button-name">Share</p>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '32px' }}>
                  <Button className="like-button btn-primary" type="button" onClick={handleLikeToggle}>
                    <LazyLoadImage
                      src={isLiked ? '/static/crew/assets/img/icons/Heart.svg' : '/static/crew/assets/img/icons/Like_heart.svg'}
                      alt={isLiked ? 'Liked' : 'Like'}
                    />
                    &nbsp; &nbsp;
                    {isLiked ? 'Liked' : 'Like'}
                  </Button>
                  {isApplied ? (
                    <button className="btn btn-primary job-appllied" type="button" >
                      Applied
                    </button>
                  ) : (
                    <Button
                      className="apply-button btn-primary"
                      disabled={!isConditionTrue}
                      type="button"
                      data-bs-target="#modal-apply-job"
                      data-bs-toggle="modal"
                      onClick={handleApply}
                    >
                      Apply Now
                    </Button>
                  )}

                </div>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Tentative Joining Date:</h1>
                <h1 className="job-details">{job.tentative_joining}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Vessel Type:</h1>
                <h1 className="job-details">{job.vessel_id.name}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">GRT:</h1>
                <h1 className="job-details">{job.GRT}</h1>
              </div>
            </div>
            <div className="job-wages-container">
              <div className="job-wages-container-1">
                {job.Job_Rank_wages.slice(0, Math.floor(job.Job_Rank_wages.length / 2)).map((wage, index) => (
                  <div key={index} className="job-wages">
                    <input
                      type="radio"
                      name={`rank-${job.id}`}
                      checked={selectedRank === wage.rank_number.name}
                      onChange={() => handleRadioChange(wage.rank_number.name, wage.rank_number.id)}
                      disabled={!isRadioEnabled(wage.rank_number.name)}
                    />
                    <LazyLoadImage src="/static/crew/assets/img/icons/job_bag.svg" alt="Job Bag" />
                    <p className="job-wages-name">{wage.rank_number.name} - {wage.wages}</p>
                  </div>
                ))}
              </div>
              {job.Job_Rank_wages.slice(Math.floor(job.Job_Rank_wages.length / 2)).length > 0 && (
                <div className="job-wages-container-1">
                  {job.Job_Rank_wages.slice(Math.floor(job.Job_Rank_wages.length / 2)).map((wage, index) => (
                    <div key={index + Math.floor(job.Job_Rank_wages.length / 2)} className="job-wages">
                      <input
                        type="radio"
                        name={`rank-${job.id}`}
                        checked={selectedRank === wage.rank_number.name}
                        onChange={() => handleRadioChange(wage.rank_number.name, wage.rank_number.id)}
                        disabled={!isRadioEnabled(wage.rank_number.name)}
                      />
                      <LazyLoadImage src="/static/crew/assets/img/icons/job_bag.svg" alt="Job Bag" />
                      <p className="job-wages-name">{wage.rank_number.name} - {wage.wages}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
            <div className="requirement-container">
                {job.job_coc && job.job_coc.length > 0 && (
                    <div className="requirement-container-both">
                      <h1 className="requirement-heading">COC Requirement:</h1>
                      <div className="requirement-value">
                          {job.job_coc.map((coc_names, index) => (
                              <React.Fragment key={index}>
                                  <h1 className="requirement-heading">{coc_names.coc_id.name}</h1>
                                  {index !== job.job_coc.length - 1 && <span>|</span>}
                              </React.Fragment>
                          ))}
                      </div>
                    </div>
                )}
                {job.job_cop && job.job_cop.length > 0 && (
                  <div className="requirement-container-both">
                      <h1 className="requirement-heading">COP Requirement:</h1>
                      <div className="requirement-value">
                          {job.job_cop.map((cop_names, index) => (
                              <React.Fragment key={index}>
                                  <h1 className="requirement-heading">{cop_names.cop_id.name}</h1>
                                  {index !== job.job_cop.length - 1 && <span>|</span>}
                              </React.Fragment>
                          ))}
                      </div>
                  </div>
                )}
            </div>

            <div className="requirement-container">
                {job.job_Watch_Keeping && job.job_Watch_Keeping.length > 0 && (
                  <div className="requirement-container-both">
                    <h1 className="requirement-heading">Watch-Keeping Requirement:</h1>
                      <div className="requirement-value">
                          {job.job_Watch_Keeping.map((coc_names, index) => (
                              <React.Fragment key={index}>
                                  <h1 className="requirement-heading">{coc_names.Watch_Keeping_id.name}</h1>
                                  {index !== job.job_Watch_Keeping.length - 1 && <span>|</span>}
                              </React.Fragment>
                          ))}
                      </div>
                  </div>
                )}
                <div className="requirement-container-both">
                { job.mail_info && 
                    <h1 className="requirement-heading">Email: {job.posted_by.email}</h1>
                }
                { job.number_info && 
                  <h1 className="requirement-heading">Mobile: {job.posted_by.number}</h1>
                }
                </div>
            </div>
            <div className="check-status-button-container">
                <button className="btn btn-primary check-status-button" onClick={() => handleStatusOpenModal()} type="button">
                    Check Status&nbsp; &nbsp;
                    <LazyLoadImage src="/static/crew/assets/img/arrows/ArrowRightwhite.svg" alt="Arrow Right" />
                </button>
            </div>
          </div>
        </Card.Body>
      </Card>
      <ApplyJobModal show={showModal} handleClose={handleCloseModal} handleConfirmApply={handleConfirmApply} />
      <AppliedSuccessModal show={showSuccessModal} handleClose={() => setShowSuccessModal(false)} />
      <CheckStatusModal show={showStatusModal} handleClose={handleStatusCloseModal} jobId={job.id} userToken={userToken} />

    </>
  );
};

export default MyJobCard;

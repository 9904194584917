import React, { useState } from 'react';
import { subscriptionConfirmModal, subscriptionSuccessModal } from './subscriptionModal';
import { resumeDownloadPurchasePlan, resumeDownloadTopUpPurchasePlan, jobPostPurchasePlan } from '../apiService';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const EmployerPlanCard = ({ indexKey, planName, planId, credits, validity, dailyLimit, profileAsStory, supportText, pagePlanText, userToken, planType, freePlanCheck }) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleCloseConfirmModal = () => setShowConfirmModal(false);
    const handleShowConfirmModal = () => setShowConfirmModal(true);

    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);

    const handleConfirmApply = async () => {
        try {

            if (planType === "resume-download" || planType === "resume-download-topup") {
                const resumeDownloadData = new FormData();
                resumeDownloadData.append('purchase_pack', planId);
                const resumeDownloadTopUpData = new FormData();
                resumeDownloadTopUpData.append('purchase_top_up', planId);
                if (planType === "resume-download") {
                    await resumeDownloadPurchasePlan(resumeDownloadData, userToken);
                } else {
                    await resumeDownloadTopUpPurchasePlan(resumeDownloadTopUpData, userToken);
                }
            } else {
                const jobPostPlanData = new FormData();
                jobPostPlanData.append('purchase_pack', planId);
                if (planType === "Crewing-Agent") {
                    await jobPostPurchasePlan(jobPostPlanData, userToken);
                } 
            }

            handleCloseConfirmModal();
            handleShowSuccessModal();
        } catch (error) {
            console.error('Error applying subscription:', error);
        }
    };


    return (
        <>
            <div className="col-md-3 subscription-plan-one">
                <div className="subscription-plan-part-one">
                    <div className="subscription-plan-part-one-head">
                        <div className="subscription-head-tag-div">
                            <h1 className="subscription-plan-part-one-heading resume-download-plan-card-heading">{planName}</h1>
                            {indexKey === 1 && (
                                <div className='recommended-plan-div resume-download-recommended-plan-text'>
                                    <p className='recommended-plan-text'>Recommendation</p>
                                </div>
                            )}
                        </div>
                        <p className="subscription-plan-part-one-detail">{pagePlanText}</p>
                    </div>
                    <div className="subscription-plan-part-one-credit-container">
                        {(planType === "Crewing-Agent" && planId ===1 ) ?
                                <p className="subscription-plan-part-one-credit-amount">Free Plan</p>
                            :
                            <>
                                <p className="subscription-plan-part-one-credit-amount">{credits}</p>
                                <p className="subscription-plan-part-one-credit-text">Credits</p>
                            </>
                        }
                    </div>
                </div>
                <div className="subscription-plan-part-two">
                    <div className="subscription-plan-part-two-list-item"><LazyLoadImage className="subscription-plan-part-two-item-img" src="/static/crew/assets/img/icons/fi_check_blue.svg" alt="Check Icon" />
                        <p className="subscription-plan-part-two-item-detail">{validity} days validity</p>
                    </div>
                    <div className="subscription-plan-part-two-list-item"><LazyLoadImage className="subscription-plan-part-two-item-img" src="/static/crew/assets/img/icons/fi_check_blue.svg" alt="Check Icon" />
                        <p className="subscription-plan-part-two-item-detail">{dailyLimit} daily downloads</p>
                    </div>
                    <div className="subscription-plan-part-two-list-item"><LazyLoadImage className="subscription-plan-part-two-item-img" src="/static/crew/assets/img/icons/fi_check_blue.svg" alt="Check Icon" />
                        <p className="subscription-plan-part-two-item-detail">{supportText}</p>
                    </div>
                </div>
                <div className="subscription-plan-choose-plan-btn">
                    <button className="btn btn-primary choose-plan-btn" disabled={freePlanCheck === 'disable' && planId ===1} type="button" onClick={handleShowConfirmModal}>
                        Choose Plan&nbsp;&nbsp;
                        <LazyLoadImage src="/static/crew/assets/img/arrows/arrow_right_blue.svg" alt="Arrow Icon" />
                    </button>
                </div>
            </div>
            {subscriptionConfirmModal({
                show: showConfirmModal,
                handleClose: handleCloseConfirmModal,
                handleConfirmApply: handleConfirmApply
            })}
            {subscriptionSuccessModal({
                show: showSuccessModal,
                handleClose: handleCloseSuccessModal
            })}
        </>
    );
}

export default EmployerPlanCard;

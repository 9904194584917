import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getCrewNotificationList } from '../apiService';

const ModalNotifications = ({ userID, userToken, isModalOpen, toggleModal }) => {
    const [notifications, setNotifications] = useState([]);

    useEffect(() => {
        const fetchNotifications = async () => {
            try {
                const notificationsData = await getCrewNotificationList(userID, userToken);
                setNotifications(notificationsData);
            } catch (error) {
                console.error('Error fetching notifications:', error);
            }
        };

        if (isModalOpen) {
            fetchNotifications();
        }
    }, [isModalOpen, userID, userToken]);

    return (
        <Modal show={isModalOpen} onHide={toggleModal} centered size="lg" scrollable>
            <Modal.Header className='modal-following-list-header-container'>
                <Modal.Title className="modal-following-list-title">Notifications</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-following-list-body">
                <div className="modal-notification-list-div">
                    {notifications && notifications.length > 0 ? (
                        notifications.map((notification) => (
                            <div className="modal-notification-list-item" key={notification.id}>
                                <h1 className="modal-notification-list-item-heading">{notification.title}</h1>
                                <h1 className="modal-notification-list-item-text">{notification.data}</h1>
                            </div>
                        ))
                    ) : (
                        <p className='modal-notification-list-no-item-text'>No new notifications, Please check back later.</p>
                    )}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalNotifications;

import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { auth } from '../components/firebase';
import 'firebase/auth';
import { createUserWithEmailAndPassword, sendEmailVerification, updateProfile   } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import { firebaseAuth, crewCreateUser, loginFunc, getUserByToken } from '../components/apiService';
import { apiRoutes } from '../config/apiConfig';
import { setLocalJsonItem } from '../components/tokenManager';

const SignupCrewCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/login.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/signup.css" />
    </>
  );
};


const SignupCrew = () => {
    const navigate = useNavigate();
    const [fullName, setFullName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState(null);
    const [isChecked, setIsChecked] = useState(false);
    const [loader, setLoader] = useState(false);

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
      };

    const handleSignup = async (e) => {
        e.preventDefault();
        if (!isChecked) {
            toast.error("Please agree to the Terms of Use and Privacy Policy");
        } else {
            try {
                setLoader(true);
                const userCredential = await createUserWithEmailAndPassword(auth, email, password);
                await updateProfile(userCredential.user, { displayName: fullName });
                const authKeyFirebase = await firebaseAuth(email, password);
                const userCreateFormData = {
                    user_type_key: 2,
                    first_name: fullName,
                    password: password,
                    email: email,
                    auth_key: authKeyFirebase.data.idToken,
                    username: email
                };
                await crewCreateUser(userCreateFormData);

                const user = userCredential.user;
                await sendEmailVerification(user);

                const loginResponse = await loginFunc(email, password);
                // console.log("loginResponse",loginResponse);
    
                const fetchedUserData = await getUserByToken(loginResponse.data.data.access);
                setLocalJsonItem("userData", fetchedUserData.data);

                setFullName('');
                setEmail('');
                setPassword('');
                setError(null);
                const userEmail = user.email;
                setTimeout(() => {
                    setLoader(false);
                  }, 2000); 
                navigate( apiRoutes.register_crew_confirm_mail + `?usermail=${userEmail}`);
            } catch (error) {
                setError(error.message);
                toast.error(error.message);
            }
        }
    };


    return (
        <>
            <SignupCrewCss />
            <ToastContainer />
            <div className="row d-flex justify-content-center" style={{ background: `url('/static/crew/assets/img/images/login_bg.png') center / cover no-repeat` }}>
                <div className="col-md-6 col-xl-4 signin-login-mail">
                    <div className="card" style={{ borderRadius: '0px' }}>
                        <div className="card-body text-center d-flex flex-column align-items-center" style={{ padding: '58px 98px', gap: '55px' }}>
                            <div className="login-logo-container">
                                <LazyLoadImage className="login-logo" src="/static/crew/assets/img/brands/newLogo.png" alt="Logo" />
                                <div>
                                    <a href={apiRoutes.register } >
                                        <LazyLoadImage  className="left-arrow" src='/static/crew/assets/img/arrows/ArrowLeft.svg' alt="Left Arrow" />
                                    </a>                                
                                </div>
                            </div>
                            <div style={{ display: 'inline-grid', gap: '54px' }}>
                                <div style={{ display: 'inline-grid', gap: '30px' }}>
                                    <div style={{ display: 'inline-grid', gap: '19px' }}>
                                        <h1 className="login-heading">Sign Up</h1>
                                        <div className="login-desc">
                                            <p>Please sign up from your e-mail account</p>
                                        </div>
                                    </div>
                                    <div>
                                        <form method="post" onSubmit={handleSignup} data-bs-theme="light" style={{ gap: '22px', display: 'inline-grid' }}>
                                            <div className="mb-3">
                                                <label className="form-label email-label">Full Name</label>
                                                <input className="form-control input-email" type="text" name="name" value={fullName} onChange={(e) => setFullName(e.target.value)} placeholder="Please enter your full name" />
                                            </div>
                                            <div className="mb-3">
                                                <label className="form-label email-label">E-mail</label>
                                                <input className="form-control input-email" type="email" name="email"  value={email} onChange={(e) => setEmail(e.target.value)}  placeholder="Please enter your email id" />
                                            </div>
                                            <div className="mb-3" style={{ display: 'inline-grid', gap: '12px' }}>
                                                <div>
                                                    <label className="form-label email-label">Password</label>
                                                    <input className="form-control input-password" type="password" name="password" value={password} onChange={(e) => setPassword(e.target.value)} placeholder="Please enter your password" />
                                                </div>
                                            </div>
                                            <div style={{ display: 'inline-grid', gap: '22px' }}>
                                                <div style={{ display: 'inline-grid', gap: '28px' }}>
                                                    <div className="mb-3 policy-container">
                                                        <input type="checkbox" className="checkbox-box" onChange={handleCheckboxChange} checked={isChecked} />
                                                        <label className="form-label policy-label">I agree to Terms of use and Privacy Policy</label>
                                                    </div>
                                                    <div className="mb-3">
                                                        <button className="btn btn-primary shadow d-block w-100 sign-in-button" type="submit">
                                                            {loader ? (
                                                                <>
                                                                <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                                                Signing Up...
                                                                </>
                                                            ) : (
                                                                "Sign Up"
                                                            )}
                                                            
                                                        </button>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="signup-para">
                                                        <span style={{ color: 'rgba(0, 0, 0, 0.898)', backgroundColor: 'rgb(245, 245, 245)' }}>Already have an account?</span>&nbsp;
                                                        <a className="signup-link" href={apiRoutes.login}><span style={{ color: 'rgb(45, 45, 45)' }}>Sign In</span></a>
                                                    </p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="copyright-watermark">© Join My Ship 2024</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SignupCrew;

import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { externalLinksConfigs } from '../../config/apiConfig';
import { Modal, Button } from 'react-bootstrap';


const HomeContent = () => {

  const [activeTab, setActiveTab] = useState('seafarers');
  const [isMobile, setIsMobile] = useState(false);


  const handleTabClick = (tab) => {
    setActiveTab(tab);

    const seafarersSection = document.getElementById("seafarers-section");
    const employersSection = document.getElementById("employers-section");

    if (tab === 'seafarers') {
      seafarersSection.style.display = "flex";
      employersSection.style.display = "none";
    } else {
      seafarersSection.style.display = "none";
      employersSection.style.display = "flex";
    }
  };

  useEffect(() => {
    const seafarerButton = document.getElementById("seafarer-button");
    const employersButton = document.getElementById("employers-button");
    const seafarersSection = document.getElementById("seafarers-section");
    const employersSection = document.getElementById("employers-section");

    seafarersSection.style.display = "flex";
    employersSection.style.display = "none";
    seafarerButton.classList.add("active");
    employersButton.classList.remove("active");
  }, []);


  const UnlockForMobileModal = ({ show, handleClose }) => {
    return (
      <Modal className="modal-unlock-for-mobile-modal" show={show} onHide={handleClose} centered>
        <Modal.Header className="modal-unlock-for-mobile-header">
          <div className="modal-unlock-for-mobile-img-container">
            <div className="modal-unlock-for-mobile-img-div">
              <img
                className="modal-unlock-for-mobile-img-logo"
                src="/static/crew/assets/img/brands/logo_only.svg"
                alt="Logo"
              />
            </div>
          </div>
          <Button variant="close" aria-label="Close" onClick={handleClose}></Button>
        </Modal.Header>
        <Modal.Body className="modal-unlock-for-mobile-body">
          <div className="modal-unlock-for-mobile-body-div">
            <p className="modal-unlock-for-mobile-body-heading">There is more in the app</p>
            <p className="modal-unlock-for-mobile-body-para">Unlock the full Join My Ship experience</p>
          </div>
        </Modal.Body>
        <Modal.Footer className="modal-unlock-for-mobile-footer">
          <div className="modal-unlock-for-mobile-footer-div" style={{ maxWidth: '391px' }}>
            <a rel="canonical"  href="#">
              <img
                src="/static/crew/assets/img/brands/playstore.svg"
                style={{ marginRight: '-1px' }}
                width="155"
                height="34"
                alt="Play Store"
              />
            </a>
            <a rel="canonical"  href="#">
              <img src="/static/crew/assets/img/brands/appstore.svg" alt="App Store" />
            </a>
          </div>
          <Button variant="light" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
    )};

  const [showModal, setShowModal] = useState(false);

  const handleShow = () => setShowModal(true);
  const handleClose = () => setShowModal(false);

  useEffect(() => {
    const checkIfMobile = () => {
      if (window.innerWidth <= 430) {
        setIsMobile(true);
      } else {
        setIsMobile(false);
      }
    };
    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);
    const interval = setInterval(() => {
      if (isMobile) {
        handleShow();
      }
    }, 30000); 
    return () => {
      window.removeEventListener('resize', checkIfMobile);
      clearInterval(interval);
    };
  }, [isMobile]);



  return (
    <>
      <div id="empresa" style={{ paddingBottom: '162px' }}>
        <div className="container">
          <div className="row">
            <div className="col-sm-6 col-md-7 col-lg-7 home-container">
              <h1 className='home-heading-container'>
                <strong>
                  <span className='home-heading-part-1' >ARE YOU A SEAFARER LOOKING FOR EXCITING&nbsp;</span>
                </strong>
                <br />
                <strong>
                  <span className='home-heading-part-2' >JOB OPPORTUNITIES?</span>
                </strong>
              </h1>
              <p className="hero-para">
                <span style={{ color: 'rgb(46, 46, 46)' }}>Look no further! Join My Ship is your gateway to a world of maritime career opportunities. We connect talented seafarers with reputable shipping companies worldwide.</span>
              </p>
              <div className="hero-header" style={{ maxWidth: '391px' }}>
                <a rel="canonical"  href={externalLinksConfigs.playStoreLink}>
                  <LazyLoadImage  src="/static/crew/assets/img/brands/playstore.svg" style={{ marginRight: '-1px' }} width="155" height="34" alt="Play Store" />
                </a>
                <a rel="canonical"  href={externalLinksConfigs.appStoreLink}>
                  <LazyLoadImage  src="/static/crew/assets/img/brands/appstore.svg" alt="App Store" />
                </a>
              </div>
            </div>
            <div className="col-sm-6 col-md-5 col-lg-5">
              <LazyLoadImage  src="/static/crew/assets/img/hero/Herosectionimage.svg" alt="Hero section image" />
            </div>
          </div>
        </div>
      </div>
      <div className="container who-are-we">
        <div className="row">
          <div className="col-md-6 col-xxl-6 who-are-we-image">
            <LazyLoadImage  className="image-1" src="/static/crew/assets/img/images/unsplash_XdIrwH98K_E.png" alt="Shipping Jobs in India" />
            <LazyLoadImage  className="image-2" src="/static/crew/assets/img/images/unsplash_lYi5Qg0xP0.png" alt="Maritime Jobs in India" />
            <LazyLoadImage  className="image-3" src="/static/crew/assets/img/images/unsplash_Esq0ovRYZs.png" alt="Seafarer Recruitment in India" />
          </div>
          <div className="col-md-6 col-xl-6 col-xxl-6 offset-xl-0 offset-xxl-0 who-are-we-description" style={{ fontSize: '33px', marginLeft: 'auto' }}>
            <h2 className="who-are-we-heading-1" style={{ fontSize: '33px', textAlign: 'right' }}>Who Are We?</h2>
            <h2 className="who-are-we-heading-2" style={{ color: '#407bff', fontWeight: 'bold', textAlign: 'right' }}>Navigate, Connect, Thrive</h2>
            <h2 className="who-are-we-para" style={{ fontSize: '16px' }}>At Join My Ship, we are dedicated to revolutionizing the way seafarers connect with maritime job opportunities. Our platform serves as a bridge between talented seafarers and reputable shipping companies worldwide, offering a seamless and transparent job search experience.</h2>
            <div className="who-are-we-desc-bullets">
              <div className="bullet-title-container"><span className="bullets-span-img">
                <LazyLoadImage  src="/static/crew/assets/img/icons/Asset1.svg" alt="Bullet image" /></span>
                <h2 className="bullets-heading">User-Friendly Platform</h2>
              </div>
              <div className="bullet-para-container"><span className="bullets-span-line">
                <LazyLoadImage  src="/static/crew/assets/img/icons/Path8.svg" alt="Bullet line" /></span>
                <h2 className="bullets-para">Our intuitive platform makes job searching, application tracking, and profile management seamless and efficient, saving you time and effort in your job search process.</h2>
              </div>
            </div>
            <div className="who-are-we-desc-bullets">
              <div className="bullet-title-container" style={{ display: 'flex' }}><span className="bullets-span-img">
                <LazyLoadImage  src="/static/crew/assets/img/icons/Layer2.svg" alt="Bullet image" /></span>
                <h2 className="bullets-heading" style={{ fontSize: '16px' }}>Verified Members</h2>
              </div>
              <div className="bullet-para-container" style={{ display: 'flex' }}><span className="bullets-span-line">
                <LazyLoadImage  src="/static/crew/assets/img/icons/Path8.svg" alt="Bullet line" /></span>
                <h2 className="bullets-para" style={{ fontSize: '16px' }}>We ensure that all members on our platform are verified, promoting trust, transparency, and fair employment practices.</h2>
              </div>
            </div>
            <div className="who-are-we-desc-bullets">
              <div className="bullet-title-container" style={{ display: 'flex' }}><span className="bullets-span-img">
                <LazyLoadImage  src="/static/crew/assets/img/icons/Asset2.svg" alt="Bullet image" /></span>
                <h2 className="bullets-heading" style={{ fontSize: '16px' }}>Security and Privacy</h2>
              </div>
              <div className="bullet-para-container" style={{ display: 'flex' }}><span className="bullets-span-line">
                <LazyLoadImage  src="/static/crew/assets/img/icons/Path8.svg" alt="Bullet line" /></span>
                <h2 className="bullets-para" style={{ fontSize: '16px' }}>Your data and personal information are handled with the utmost confidentiality and security measures, ensuring a safe and secure experience on our platform.</h2>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container why-choose" style={{ padding: '0px' }}>
        <div className="why-choose-heading-container">
          <h2 className="why-choose-heading">Why Choose&nbsp;<span style={{ color: '#407bff', fontWeight: 'bold' }}>Join My Ship?</span></h2>
          <h2 className="why-choose-heading-img">
            <LazyLoadImage  src="/static/crew/assets/img/icons/Line5.svg" alt="Line" />
          </h2>
        </div>
        <div className="why-choose-para">
          <h2 className="para-text" style={{ fontSize: '16px' }}>Join My Ship is your ultimate partner in navigating the seas of maritime employment.&nbsp;<br />Sign up today and embark on a rewarding career journey with us!</h2>
          <div className="why-choose-buttons" >
            <button className={`btn btn-primary ${activeTab === 'seafarers' ? 'active' : ''}`} id="seafarer-button" onClick={() => handleTabClick('seafarers')} type="button-sea">Seafarers</button>
            <button className={`btn btn-primary ${activeTab === 'employers' ? 'active' : ''}`} id="employers-button" onClick={() => handleTabClick('employers')} type="button-emp">Employers</button>
          </div>
        </div>
        <div id="seafarers-section" className={`why-choose-boxes ${activeTab === 'seafarers' ? 'active' : ''}`}>
          <div>
            <LazyLoadImage  className="box-image" src="/static/crew/assets/img/icons/jobsuitecase.svg" alt="Extensive Network" />
            <h2 className="box-title" style={{ fontSize: '20px' }}>Extensive Network</h2>
            <h2 className="box-para" style={{ fontSize: '16px' }}>Join My Ship offers access to a vast network of reputable shipping companies, ensuring diverse marine job opportunities tailored to your skills and preferences.</h2>
          </div>
          <div>
            <LazyLoadImage  className="box-image" src="/static/crew/assets/img/icons/employer.svg" alt="Verified Employers" />
            <h2 className="box-title" style={{ fontSize: '20px' }}>Verified Employers</h2>
            <h2 className="box-para" style={{ fontSize: '16px' }}>We ensure that all employers on our platform are verified, promoting trust, transparency, and fair employment practices for seafarers.</h2>
          </div>
          <div>
            <LazyLoadImage  className="box-image" src="/static/crew/assets/img/icons/trackapplication.svg" alt="Application Tracking" />
            <h2 className="box-title" style={{ fontSize: '20px' }}>Application Tracking</h2>
            <h2 className="box-para" style={{ fontSize: '16px' }}>Easily monitor the status of your job applications in real-time, keeping you informed at every stage of the hiring process.</h2>
          </div>
          <div>
            <LazyLoadImage  className="box-image" src="/static/crew/assets/img/icons/Boost.svg" alt="Boost Your Profile" />
            <h2 className="box-title" style={{ fontSize: '20px' }}>Boost Your Profile</h2>
            <h2 className="box-para" style={{ fontSize: '16px' }}>Increase your visibility to potential employers by utilizing our profile boosting feature, ensuring more inquiries and high paying maritime job opportunities.</h2>
          </div>
          <div>
            <LazyLoadImage  className="box-image" src="/static/crew/assets/img/icons/Highlight.svg" alt="Highlight your profile" />
            <h2 className="box-title" style={{ fontSize: '20px' }}>Highlight your profile</h2>
            <h2 className="box-para" style={{ fontSize: '16px' }}>Our profile highlighting feature ensures that your crew resume stands out and appears at the top of search results, increasing visibility to potential employers.</h2>
          </div>
          <div>
            <LazyLoadImage  className="box-image" src="/static/crew/assets/img/icons/mobile.svg" alt="Stay Connected On-the-Go" />
            <h2 className="box-title" style={{ fontSize: '20px' }}>Stay Connected On-the-Go</h2>
            <h2 className="box-para" style={{ fontSize: '16px' }}>Access our comprehensive job portal and career resources anytime, anywhere with our mobile app available on both Android and iOS platforms.</h2>
          </div>
        </div>
        <div id="employers-section" className={`why-choose-boxes ${activeTab === 'employers' ? 'active' : ''}`}>
          <div>
            <LazyLoadImage  className="box-image" src="/static/crew/assets/img/icons/resumesearch.svg" alt="Efficient Candidate Search" />
            <h2 className="box-title" style={{ fontSize: '20px' }}>Efficient Candidate Search</h2>
            <h2 className="box-para" style={{ fontSize: '16px' }}>Utilize advanced search filters to find qualified candidates quickly based on skills, experience, location, and download their resume for further review.</h2>
          </div>
          <div>
            <LazyLoadImage  className="box-image" src="/static/crew/assets/img/icons/crew.svg" alt="Verified Crew Profiles" />
            <h2 className="box-title" style={{ fontSize: '20px' }}>Verified Crew Profiles</h2>
            <h2 className="box-para" style={{ fontSize: '16px' }}>Access a pool of verified seafarer profiles, ensuring credibility and authenticity in your hiring process. Find top-notch talent with confidence on our portal.</h2>
          </div>
          <div>
            <LazyLoadImage  className="box-image" src="/static/crew/assets/img/icons/image59.png" alt="Customized Job Listings" />
            <h2 className="box-title" style={{ fontSize: '20px' }}>Customized Job Listings</h2>
            <h2 className="box-para" style={{ fontSize: '16px' }}>Post ship job opportunities tailored to your specific requirements, including rank, vessel type, COC, COP, and Watchkeeping certificate requirements.</h2>
          </div>
          <div>
            <LazyLoadImage  className="box-image" src="/static/crew/assets/img/icons/Boost.svg" alt="Boost Job Listing" />
            <h2 className="box-title" style={{ fontSize: '20px' }}>Boost Job Listing</h2>
            <h2 className="box-para" style={{ fontSize: '16px' }}>Increase your visibility to potential employers by utilizing our profile boosting feature, ensuring more inquiries and job opportunities come your way.</h2>
          </div>
          <div>
            <LazyLoadImage  className="box-image" src="/static/crew/assets/img/icons/Highlight.svg" alt="Highlight your Job" />
            <h2 className="box-title" style={{ fontSize: '20px' }}>Highlight your Job</h2>
            <h2 className="box-para" style={{ fontSize: '16px' }}>Highlighting a job triggers instant notifications to crew members, keeping them informed about the latest vacancies and increasing visibility for employers.</h2>
          </div>
          <div>
            <LazyLoadImage  className="box-image" src="/static/crew/assets/img/icons/mobile.svg" alt="Stay Connected On-the-Go" />
            <h2 className="box-title" style={{ fontSize: '20px' }}>Stay Connected On-the-Go</h2>
            <h2 className="box-para" style={{ fontSize: '16px' }}>Access our comprehensive job portal and career resources anytime, anywhere with our mobile app available on both Android and iOS platforms.</h2>
          </div>
        </div>
      </div>
      {/* <div className='cloudman-class' style={{ background: `url('/static/crew/assets/img/icons/compass.svg') calc(100% - 20px) 112px / auto no-repeat`, backgroundColor: `var(--Dark-Blue, #173969)` }}> */}
      <div className='cloudman-class' >
        <div className="row" style={{ marginRight: 0 }}>
          <div className="col-md-5 col-lg-5 col-xxl-4 offset-md-0 offset-lg-0 next-adventure-left-col-img" style={{ paddingLeft: '48px' }}>
            <LazyLoadImage  src="/static/crew/assets/img/hero/mansearching.svg" style={{ position: 'relative', top: '-50px' }} width="401" height="295" alt="Man searching image" />
          </div>
          <div className="col-md-7 col-lg-7 col-xl-7 col-xxl-8 next-adventure-right-col">
            <h2 className="right-col-heading" style={{ fontSize: '28px', color: '#ffffff' }}>Looking for a maritime job opportunity?</h2>
            <h2 className="right-col-para" style={{ fontSize: '18px', color: '#ffffff' }}>Download our app on both Android and iOS platforms to access a world of maritime job opportunities.</h2>
            <div className="right-col-img">
              <a rel="canonical"  href={externalLinksConfigs.playStoreLink}>
                <LazyLoadImage  className="playstore-img" src="/static/crew/assets/img/brands/playstore.svg" alt="Play Store logo" />
              </a>
              <a rel="canonical"  href={externalLinksConfigs.appStoreLink}>
                <LazyLoadImage  className="appstore-img" src="/static/crew/assets/img/brands/appstore.svg" alt="App Store logo" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <UnlockForMobileModal show={showModal} handleClose={handleClose} />
    </>
  );
};

export default HomeContent;

import React, { useEffect, useState  } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavbarCreateProfile from '../components/layouts/NavbarCreateProfile';
import { Dropdown, InputGroup, Container, Button, Modal, Form, Row, Col, FormControl } from 'react-bootstrap';
import { getFlagList, getRankList, getVesselList, insertCrewSeaService, 
        insertCrewReference, formatDate, updateUserById, formatDateNew,
        getSeaServiceRecordList, transformSeaServiceRecords, convertDateStringToDate,
        deleteSeaServiceId, getPreviousEmployerRecordList, deletePreviousEmployerId,
        transformPreviousEmployerRecords
        } from '../components/apiService';
import { getIsLoggedIn, getLocalItem, getLocalJsonItem } from '../components/tokenManager';
import { apiRoutes } from '../config/apiConfig';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { parseISO } from 'date-fns';

const CrewCreateProfileStep3Css = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Bootstrap-4-Custom-Radio.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/createprofile.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Date-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-dropdown.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Text-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Upload-File.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/JMS-Custom-Button.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/JMS-mobile-number.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />

    </>
  );
};


const SeaServiceModal = ({ show, onHide, onSave, seaServiceFormData }) => {
    const [formData, setFormData] = useState({
        companyName: '',
        imoNumber: '',
        shipName: '',
        flag: '',
        flag_name: '',
        rank: '',
        rank_name: '',
        vesselType: '',
        vesselType_name: '',
        grt: '',
        signOnDate: '',
        signOffDate: ''
      });
    
      useEffect(() => {
        if (seaServiceFormData) {
          setFormData(seaServiceFormData);
            // setFormData({
            //     ...seaServiceFormData,
            //     signOnDate: seaServiceFormData.signOnDate ? parseISO(seaServiceFormData.signOnDate) : '',
            //     signOffDate: seaServiceFormData.signOffDate ? parseISO(seaServiceFormData.signOffDate) : ''
            // });
        } else {
          setFormData({
            companyName: '',
            imoNumber: '',
            shipName: '',
            flag: '', 
            flag_name: '',
            rank: '',
            rank_name: '',
            vesselType: '',
            vesselType_name: '',
            grt: '',
            signOnDate: '',
            signOffDate: ''
          });
        }
      }, [seaServiceFormData]);
    
    //   const handleInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setFormData({
    //       ...formData,
    //       [name]: value
    //     });
    //   };

    const [errorOtherCompanyName, setErrorOtherCompanyName] = useState(null);
    const [errorOtherImoNum, setErrorOtherImoNum] = useState(null);
    const [errorOthershipName, setErrorOthershipName] = useState(null);
    const [errorOthergrt, setErrorOthergrt] = useState(null);

    const handleInputChangeCompanyName = (e) => {
        const { name, value } = e.target;
        let sanitizedValue = value;
        let error = '';
        const regex = /^[a-zA-Z0-9]*$/; 
        if (!regex.test(value)) {
            error = 'Only alphanumeric characters are allowed';
            sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
        }
        setErrorOtherCompanyName(error);
        setFormData({
            ...formData,
            [name]: sanitizedValue
        });
    };

    const handleInputChangeImoNum = (e) => {
        const { name, value } = e.target;
        let sanitizedValue = value;
        let error = '';
        const regex = /^[0-9]*$/; 
        if (!regex.test(value)) {
            error = 'Only numeric characters are allowed';
            sanitizedValue = value.replace(/[^0-9]/g, '');
        }
        setErrorOtherImoNum(error);
        
        setFormData({
            ...formData,
            [name]: sanitizedValue
        });
    };

    const handleInputChangeShipName = (e) => {
        const { name, value } = e.target;
        let sanitizedValue = value;
        let error = '';
        const regex = /^[a-zA-Z0-9]*$/; 
        if (!regex.test(value)) {
            error = 'Only alphanumeric characters are allowed';
            sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
        }
        setErrorOthershipName(error);
        
        setFormData({
            ...formData,
            [name]: sanitizedValue
        });
    };

    const handleInputChangeGrt = (e) => {
        const { name, value } = e.target;
        let sanitizedValue = value;
        let error = '';
        const regex = /^[0-9]*$/; 
        if (!regex.test(value)) {
            error = 'Only numeric characters are allowed';
            sanitizedValue = value.replace(/[^0-9]/g, '');
        }
        setErrorOthergrt(error);
        setFormData({
            ...formData,
            [name]: sanitizedValue
        });
    };
    
    const handleSubmit = () => {
        onSave(formData);
        setSelectedFlagItem('');
        setSelectedRankItem('');
        setSelectedVesselItem('');
        setFormData({
            companyName: '',
            imoNumber: '',
            shipName: '',
            flag: '', 
            flag_name: '',
            rank: '',
            rank_name: '',
            vesselType: '',
            vesselType_name: '',
            grt: '',
            signOnDate: '',
            signOffDate: ''
          });
        onHide(); 
      };
    
      // Flag Dropdown Starts
      const [searchFlagQuery, setSearchFlagQuery] = useState('');
      const [selectedFlagItem, setSelectedFlagItem] = useState(null);
      const [items, setItems] = useState([]);
      
      useEffect(() => {
        getFlagList().then(result => {
          setItems(result);
        }).catch(error => {
          console.error("Error fetching Flag list:", error);
        });
      }, []); 
    
      const handleFlagItemClick = (item) => {
        setSelectedFlagItem(item);
        setFormData({
          ...formData,
          flag: item.id, 
          flag_name: item.country_name 
        });
        setSearchFlagQuery(''); 
      };
      
      const filteredFlagItems = items.filter(item =>
        item.country_name.toLowerCase().includes(searchFlagQuery.toLowerCase())
      );
    // Flag Dropdown Ends 

    // Rank Dropdown Starts
    const [searchRankQuery, setSearchRankQuery] = useState('');
    const [selectedRankItem, setSelectedRankItem] = useState(null);
    const [itemsRank, setItemsRank] = useState([]);
    
    useEffect(() => {
    getRankList().then(result => {
        setItemsRank(result);
    }).catch(error => {
        console.error("Error fetching Rank list:", error);
    });
    }, []); 

    const handleRankItemClick = (item) => {
    setSelectedRankItem(item);
    setFormData({
        ...formData,
        rank: item.id,
        rank_name: item.name
    });
    setSearchRankQuery(''); 
    };
    
    const filteredRankItems = itemsRank.filter(item =>
    item.name.toLowerCase().includes(searchRankQuery.toLowerCase())
    );
    // Rank Dropdown Ends 


    // Vessel Dropdown Starts
    const [vesselItems, setVesselItems] = useState([]);
    const [selectedVesselItem, setSelectedVesselItem] = useState(null);
    const [searchVesselQuery, setSearchVesselQuery] = useState('');

    useEffect(() => {
    getVesselList().then(result => {
        setVesselItems(result);
    }).catch(error => {
        console.error("Error fetching vessel list:", error);
    });
    }, []);

    const handleVesselSelect = (selectedItem) => {
        setSelectedVesselItem(selectedItem.name);
        setFormData({
            ...formData,
            vesselType: selectedItem.id,
            vesselType_name: selectedItem.name
        });
        setSearchVesselQuery('');
    };

    const handleDateChange = (date, fieldName) => {
        setFormData({
            ...formData,
            [fieldName]: date
        });
    };

    const filteredVesselItems = Object.keys(vesselItems).reduce((filteredItems, parentKey) => {
        const filteredChildren = vesselItems[parentKey].filter(childItem => {
        if (typeof childItem.name === 'string') {
            return childItem.name.toLowerCase().includes(searchVesselQuery.toLowerCase());
        } else if (typeof childItem.name === 'object' && childItem.name.name) {
            return childItem.name.name.toLowerCase().includes(searchVesselQuery.toLowerCase());
        }
        return false;
        });
    
        if (filteredChildren.length > 0) {
        filteredItems[parentKey] = filteredChildren;
        }
    
        return filteredItems;
    }, {});
  
    // Vessel Dropdown Ends


    return (
        <Modal show={show} onHide={onHide} centered fullscreen="xl-down" className='seaservice-modal'>
            <Modal.Header closeButton>
                <Modal.Title>Sea Service Record</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Container className='searservice-container'>
                    <Row>
                        <Col md={6}>
                            <div>
                                <h1 className="sea-service-title"><span style={{ color: 'rgb(45, 45, 45)' }}>Company Name</span></h1>
                                <input type="text" className="form-text-input" placeholder="Enter Company Name" name="companyName" value={formData.companyName} onChange={handleInputChangeCompanyName} />
                                {errorOtherCompanyName && <p className="form-error-text">{errorOtherCompanyName}</p>}
                            </div>
                        </Col>
                        <Col md={6}>
                            <div>
                                <h1 className="sea-service-title">IMO Number</h1>
                                <input type="text" className="form-text-input" placeholder="IMO Number" name="imoNumber" value={formData.imoNumber} onChange={handleInputChangeImoNum} />
                                {errorOtherImoNum && <p className="form-error-text">{errorOtherImoNum}</p>}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div>
                                <h1 className="sea-service-title">Ship Name</h1>
                                <input type="text" className="form-text-input" placeholder="Ship Name" name="shipName" value={formData.shipName} onChange={handleInputChangeShipName} />
                                {errorOthershipName && <p className="form-error-text">{errorOthershipName}</p>}
                            </div>
                        </Col>
                        <Col md={4}>
                            <div>
                                <h1 className="sea-service-title">Flag</h1>
                                <Dropdown>
                                    <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="dropdown-basic">
                                        <InputGroup className="mb-3 search-rank-input">
                                        <FormControl 
                                            placeholder={(selectedFlagItem && selectedFlagItem.country_name) ? selectedFlagItem.country_name : 
                                                (seaServiceFormData && seaServiceFormData.flag_name) ? seaServiceFormData.flag_name :
                                                "Select Flag"} 
                                            aria-label="Search" aria-describedby="basic-addon2" value={searchFlagQuery} onChange={(e) => setSearchFlagQuery(e.target.value)} required={!selectedFlagItem} />
                                        </InputGroup>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {filteredFlagItems.map((item, index) => (
                                        <Dropdown.Item key={index} onClick={() => handleFlagItemClick(item)}>
                                            {item.flag_code} <br></br>
                                            <span style={{ color: 'gray' }}>
                                                {item.country_name}
                                            </span>
                                        </Dropdown.Item>
                                        ))}
                                        {filteredFlagItems.length === 0 && (
                                        <Dropdown.Item disabled>No items found</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div>
                                <h1 className="sea-service-title">Rank</h1>
                                <Dropdown>
                                    <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="dropdown-basic">
                                        <InputGroup className="mb-3 search-rank-input">
                                            <FormControl 
                                            placeholder={(selectedRankItem && selectedRankItem.name) ? selectedRankItem.name : 
                                                (seaServiceFormData && seaServiceFormData.rank_name) ? seaServiceFormData.rank_name :
                                                "Select Rank"} 
                                            
                                            aria-label="Search" aria-describedby="basic-addon2" value={searchRankQuery} onChange={(e) => setSearchRankQuery(e.target.value)} required={!selectedRankItem} />
                                        </InputGroup>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {filteredRankItems.map((item, index) => (
                                        <Dropdown.Item key={index} onClick={() => handleRankItemClick(item)}>
                                            {item.name}
                                        </Dropdown.Item>
                                        ))}
                                        {filteredRankItems.length === 0 && (
                                        <Dropdown.Item disabled>No items found</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div>
                                <h1 className="sea-service-title">Vessel Type</h1>
                                <Dropdown>
                                    <Dropdown.Toggle  className="btn dropdown-toggle dropdown-form-button" variant="none" id="vessel-dropdown">
                                        <InputGroup className="mb-3 search-rank-input">
                                            <FormControl className='search-form-input' 
                                            placeholder={(selectedVesselItem ? selectedVesselItem : 
                                                (seaServiceFormData && seaServiceFormData.vesselType_name) ? seaServiceFormData.vesselType_name :
                                                "Select Vessel")}     
                                            aria-label="Search" aria-describedby="basic-addon2" value={searchVesselQuery} onChange={(e) => setSearchVesselQuery(e.target.value)} required={!selectedVesselItem}  />
                                        </InputGroup>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {Object.keys(filteredVesselItems).map((parentKey, parentIndex) => (
                                            <React.Fragment key={parentIndex}>
                                                <Dropdown.Item disabled className='vessle-type-parent'>
                                                    <b>{parentKey}</b>
                                                </Dropdown.Item>
                                                {filteredVesselItems[parentKey].map((childItem, childIndex) => (
                                                    <Dropdown.Item key={childIndex} onClick={() => handleVesselSelect(childItem)}>
                                                    {childItem.name}
                                                    </Dropdown.Item>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col md={4}>
                            <div>
                                <h1 className="sea-service-title">GRT</h1>
                                <input type="text" className="form-text-input" placeholder="Enter GRT" name="grt" value={formData.grt} onChange={handleInputChangeGrt} />
                                {errorOthergrt && <p className="form-error-text">{errorOthergrt}</p>}
                            </div>
                        </Col>
                        <Col md={4}>
                            <div>
                                <h1 className="sea-service-title">Sign On Date</h1>
                                <Form.Group controlId="formDate">
                                    <DatePicker  selected={formData.signOnDate}  onChange={(date) => handleDateChange(date, 'signOnDate')}  
                                    dateFormat="yyyy-MM-dd" 
                                    placeholderText="dd-mm-yyyy"  className="form-control form-date-input"  showYearDropdown  scrollableYearDropdown  yearDropdownItemNumber={40}  minDate={new Date()}  
                                    customInput={<Form.Control type="date" value={formData.signOnDate} onChange={(date) => handleDateChange(date, 'signOnDate')} />}
                                    required={!formData.signOffDate} />
                                </Form.Group>
                            
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={4}>
                            <div>
                                <h1 className="sea-service-title">Sign Off Date</h1>
                                <Form.Group controlId="formDate">
                                    <DatePicker  selected={formData.signOffDate}  onChange={(date) => handleDateChange(date, 'signOffDate')}  
                                    dateFormat="yyyy-MM-dd" 
                                    placeholderText="dd-mm-yyyy"  className="form-control form-date-input"  showYearDropdown  scrollableYearDropdown  yearDropdownItemNumber={40}  minDate={new Date()}  
                                    customInput={<Form.Control type="date" value={formData.signOffDate} onChange={(date) => handleDateChange(date, 'signOffDate')} />}
                                    required={!formData.signOffDate}  />
                                </Form.Group>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="light" onClick={onHide}>Cancel</Button>
                <Button variant="primary" onClick={handleSubmit}>Submit</Button>
            </Modal.Footer>
        </Modal>
    );
};


const SeaServiceRecord = ({ seaServiceData, onEdit, onRemove }) => {
    const userToken = getLocalItem("accessToken"); 

    const handleEditClick = () => {
        onEdit(seaServiceData);
    };

    const handleRemoveClick = async () => {
        if (seaServiceData.id) {
            await deleteSeaServiceId(seaServiceData.id, userToken);
        }
        onRemove(seaServiceData);
    };

    return (
        <div className="record-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            <p className="record-title">{seaServiceData.companyName}</p>
            <button className="btn btn-primary record-edit-btn" type="button" onClick={handleEditClick}>Edit</button>
            <button className="btn btn-primary record-del-btn" type="button" onClick={handleRemoveClick}>Delete</button>
        </div>
    );
};

const ReferenceModal = ({ show, onHide, onSave, formData, onChange, onSubmit, codeList, onNumberChange, onCodeChange, errorCompany, errorReference, errorContact }) => {
    return (
      <Modal show={show} onHide={onHide} centered className='reference-modal'>
        <Modal.Header closeButton>
          <Modal.Title>Reference from previous employer</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ gap: '22px', display: 'flex', flexDirection: 'column' }}>
          <Container>
            <div>
              <h1 className="sea-service-title">Company Name</h1>
              <input type="text" className="form-text-input" placeholder="Enter Company Name" name="companyName" value={formData.companyName} onChange={onChange} />
                {errorCompany && <p className="form-error-text">{errorCompany}</p>}
            </div>
          </Container>
          <Container>
            <Row>
              <Col md={6}>
                <h1 className="sea-service-title">Reference Name</h1>
                <input type="text" className="form-text-input" placeholder="Reference Name" name="referenceName" value={formData.referenceName} onChange={onChange} />
                {errorReference && <p className="form-error-text">{errorReference}</p>}
              </Col>
              <Col md={6}>
                <h1 className="sea-service-title">Contact Number</h1>
                {/* <div className="number-input-container">
                    <Dropdown className='code-dropdown'>
                        <Dropdown.Toggle variant="primary" id="dropdown-basic" className='code-list'>
                            {formData.code}
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            {codeList.map((code, index) => (
                            <Dropdown.Item key={index} onClick={() => onCodeChange(code)}>{code}</Dropdown.Item>
                            ))}
                        </Dropdown.Menu>
                    </Dropdown>
                    <input type="email" className="input-number" name="contactNumber" placeholder="Number" value={formData.contactNumber} onChange={onChange} />
                </div> */}


                <PhoneInput
                    country={"in"}
                    enableSearch={true}
                    value={formData.contactNumber}
                    name="contactNumber" placeholder="Number"
                    className="phoneinput-library"
                    onChange={onNumberChange}
                    />
                {errorContact && <p className="form-error-text">{errorContact}</p>}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="light" onClick={onHide}>Cancel</Button>
          <Button variant="primary" onClick={onSubmit}>Submit</Button>
        </Modal.Footer>
      </Modal>
    );
  };
  
  const ReferenceRecord = ({ referenceData, onEdit, onRemove }) => {
    const userToken = getLocalItem("accessToken"); 
    const handleEditClick = () => {
      onEdit(referenceData);
    };
  
    const handleRemoveClick = async () => {
        if (referenceData.id) {
            await deletePreviousEmployerId(referenceData.id, userToken);
        }            
        onRemove(referenceData);
    };
  
    return (
      <div className="record-container" style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <p className="record-title">{referenceData.companyName}</p>
        <button className="btn btn-primary record-edit-btn" type="button" onClick={handleEditClick}>Edit</button>
        <button className="btn btn-primary record-del-btn" type="button" onClick={handleRemoveClick}>Delete</button>
      </div>
    );
  };



const CrewCreateProfileStep3 = () => {
    const navigate = useNavigate();
    const userToken = getLocalItem("accessToken"); 
    const isLoggedIn = getIsLoggedIn();
    if (!isLoggedIn) {
        navigate(apiRoutes.home);
    }
    const userData = getLocalJsonItem("userData");


    // Sea service Starts 
    const [seaServiceFormDataList, setSeaServiceFormDataList] = useState([]);
    const [seaServiceModalShow, setSeaServiceModalShow] = useState(false);
    const [selectedSeaServiceData, setSelectedSeaServiceData] = useState(null);

    useEffect(() => {
        getSeaServiceRecordList(userData.id, userToken).then(result => {
            setSeaServiceFormDataList(transformSeaServiceRecords(result));
        }).catch(error => {
          console.error("Error fetching rank list:", error);
        });
    }, []); 

    const handleEditSeaServiceData = (seaServiceData) => {
        setSelectedSeaServiceData(seaServiceData);
        setSeaServiceModalShow(true);
    };

    const handleDeleteSeaServiceData = (seaServiceData) => {
        setSeaServiceFormDataList(prevList => prevList.filter(item => item !== seaServiceData));
    };

    const handleSaveSeaServiceData = (seaServiceData) => {
        if (selectedSeaServiceData) {
            setSeaServiceFormDataList(prevList => prevList.map(item => item === selectedSeaServiceData ? seaServiceData : item));
            setSelectedSeaServiceData(null);
        } else {
            setSeaServiceFormDataList(prevList => [...prevList, seaServiceData]);
        }
        setSeaServiceModalShow(false);
    };

    const handleSeaServiceModalClose = () => {
        setSeaServiceModalShow(false);
    };
    // Sea service Ends 

    // Reference Starts 
    const [referenceModalShow, setReferenceModalShow] = useState(false);
    const [referenceList, setReferenceList] = useState([]);
    const [referenceFormData, setReferenceFormData] = useState({
        companyName: '',
        referenceName: '',
        code: '', 
        contactNumber: ''
    });

    useEffect(() => {
        getPreviousEmployerRecordList(userData.id, userToken).then(result => {
            setReferenceList(transformPreviousEmployerRecords(result));
        }).catch(error => {
          console.error("Error fetching rank list:", error);
        });
    }, []); 

    const [editIndex, setEditIndex] = useState(null); 
    const handleEditReferenceData = (referenceData, index) => {
        setReferenceFormData(referenceData);
        setEditIndex(index);
        setReferenceModalShow(true);
    };

    const handleDeleteReferenceData = (referenceData) => {
        setReferenceList(prevList => prevList.filter(item => item !== referenceData));
    };

    const [errorCompany, setErrorCompany] = useState(null); 
    const [errorReference, setErrorReference] = useState(null); 
    const [errorContact, setErrorContact] = useState(null); 

    const handleSaveReferenceData = () => {
        if (editIndex !== null) {
        const updatedList = referenceList.map((item, index) => index === editIndex ? referenceFormData : item);
        setReferenceList(updatedList);
        setEditIndex(null);
        } else {
        setReferenceList(prevList => [...prevList, referenceFormData]);
        }
        setReferenceModalShow(false);
        setReferenceFormData({
        companyName: '',
        referenceName: '',
        code: '', 
        contactNumber: ''
        });
    };

    const handleReferenceModalClose = () => {
        setReferenceModalShow(false);
        setEditIndex(null);
        setReferenceFormData({
        companyName: '',
        referenceName: '',
        code: '', 
        contactNumber: ''
        });
    };

    // const handleReferenceInputChange = (e) => {
    //     const { name, value } = e.target;
    //     setReferenceFormData({
    //     ...referenceFormData,
    //     [name]: value
    //     });
    // };

    const handleReferenceInputChange = (e) => {
        const { name, value } = e.target;
        let sanitizedValue = value;
        let error = '';
    
        const alphanumericRegex = /^[a-zA-Z0-9]*$/;
        const alphabeticRegex = /^[a-zA-Z]*$/;
        const numericRegex = /^[0-9]*$/;
    
        switch (name) {
            case 'companyName':
                if (!alphanumericRegex.test(value)) {
                    error = 'Only alphanumeric characters are allowed';
                    sanitizedValue = value.replace(/[^a-zA-Z0-9]/g, '');
                }
                break;
            case 'referenceName':
                if (!alphabeticRegex.test(value)) {
                    error = 'Only alphabetic characters are allowed';
                    sanitizedValue = value.replace(/[^a-zA-Z]/g, '');
                }
                break;
            case 'contactNumber':
                if (!numericRegex.test(value)) {
                    error = 'Only numeric characters are allowed';
                    sanitizedValue = value.replace(/[^0-9]/g, '');
                }
                break;
            default:
                break;
        }
    
        switch (name) {
            case 'companyName':
                setErrorCompany(error);
                break;
            case 'referenceName':
                setErrorReference(error);
                break;
            case 'contactNumber':
                setErrorContact(error);
                break;
            default:
                break;
        }
    
        setReferenceFormData({
            ...referenceFormData,
            [name]: sanitizedValue
        });
    };

    const handleCodeChange = (code) => {
        setReferenceFormData(prevFormData => ({
        ...prevFormData,
        code: code
        }));
    };

    const handlePhoneNumberChange = (contactNumber) => {
        setReferenceFormData(prevFormData => ({
        ...prevFormData,
        contactNumber: contactNumber
        }));
    };

    const codeList = ["+91", "+112"]; 

    const handlePreviousClick = () => {
        navigate(apiRoutes.create_profile_2);
    };


    const handleFormSubmit = async (e) => {
        e.preventDefault(); 
        try {
            let isSuccess = true; 
            const userToken = getLocalItem("accessToken"); 
            const seaServicePromises = seaServiceFormDataList.map(async (seaServiceLoopData) => {
                const updatedSeaServiceData = {
                    id: seaServiceLoopData.id,
                    user_id: userData.id,
                    company_name: seaServiceLoopData.companyName,
                    ship_name: seaServiceLoopData.shipName,
                    IMO_number: seaServiceLoopData.imoNumber,
                    Rank_id: seaServiceLoopData.rank,
                    Flag: seaServiceLoopData.flag,
                    GRT: seaServiceLoopData.grt,
                    VesselType: seaServiceLoopData.vesselType,
                    signon_date: convertDateStringToDate(seaServiceLoopData.signOnDate),
                    signoff_date: convertDateStringToDate(seaServiceLoopData.signOffDate),
                    _mutable: false
                };
                try {
                    const responseSeaService = await insertCrewSeaService(updatedSeaServiceData, userToken);
                    if (!responseSeaService.status === 201) {
                        isSuccess = false;
                    }
                } catch (error) {
                    console.error('Error:', error);
                    isSuccess = false;
                }
            });
    
            const referencePromises = referenceList.map(async (referenceData) => {
                const updatedReferenceData = {
                    id: referenceData.id,
                    user_id: userData.id,
                    company_name: referenceData.companyName,
                    reference_name: referenceData.referenceName,
                    contact_number: "+" + referenceData.contactNumber,
                    _mutable: false
                };
    
                try {
                    const responseReference = await insertCrewReference(updatedReferenceData, userToken);
                    if (!responseReference.status === 201) {
                        isSuccess = false;
                    }
                } catch (error) {
                    console.error('Error:', error);
                    isSuccess = false;
                }
            });
    
            await Promise.all([...seaServicePromises, ...referencePromises]); 
            if (isSuccess) {
                const updatedUserData = {
                    screen_check: 3
                }
                await updateUserById(userData.id, updatedUserData, userToken);
                navigate(apiRoutes.homecrew);
            }
    
        } catch (error) {
            console.error('Error:', error);
        }
    };
    

    return (
        <>
            {isLoggedIn && (
                <>
                    <CrewCreateProfileStep3Css />
                    <NavbarCreateProfile />
                    <ToastContainer />
                    <div className="heading-container">
                        <div className="container">
                            <div style={{ padding: '22px 0px', display: 'flex', flexDirection: 'column', gap: '14px' }}>
                                {userData.is_verified ? 
                                    <h1 className="heading-main">Edit Profile</h1>
                                    :
                                    <h1 className="heading-main">Create Profile</h1>
                                }
                                <p className="heading-desc">Please fill up this form and click on save and continue to proceed with the sign up process.</p>
                            </div>
                        </div>
                    </div>

                    <Form onSubmit={handleFormSubmit} >
                        <div className="container" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', padding: '0px 0px 24px 0px' }}>
                            <LazyLoadImage className="stepper-img" src="/static/crew/assets/img/icons/Steper3.svg" alt="Stepper" />
                        </div>
                        <Container >
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '54px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
                                        <h1 className="sea-service-title">Sea Service Record</h1>
                                        <p className="sea-service-desc">Recommended to add at least last 2 vessel service record to get your profile highlighted.</p>
                                    </div>
                                    {seaServiceFormDataList.length > 0 && seaServiceFormDataList.map((seaServiceData, index) => (
                                        <SeaServiceRecord  key={index} seaServiceData={seaServiceData} onEdit={handleEditSeaServiceData} onRemove={handleDeleteSeaServiceData} />
                                    ))}
                                    <div>
                                        <Button className="btn sea-service-btn" onClick={() => setSeaServiceModalShow(true) } type="button" data-toggle="model" data-target="#modal-1" data-bs-target="#seaservice-modal" data-bs-toggle="modal">
                                            <LazyLoadImage src="/static/crew/assets/img/icons/AddCircle.svg" alt="Add a record" /> Add a record
                                        </Button>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '24px' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', gap: '14px' }}>
                                        <h1 className="sea-service-title">Reference from previous employer</h1>
                                        <p className="sea-service-desc">Recommended to at least add your last employer reference to gain trust from the recruiter.</p>
                                    </div>
                                    {referenceList.map((referenceData, index) => (
                                        <ReferenceRecord key={index} referenceData={referenceData} onEdit={() => handleEditReferenceData(referenceData, index)} onRemove={() => handleDeleteReferenceData(referenceData)} />
                                    ))}
                                    <div>
                                        <Button className="btn sea-service-btn" onClick={() => setReferenceModalShow(true)}  type="button" data-bs-target="#reference-modal" data-bs-toggle="modal">
                                            <LazyLoadImage src="/static/crew/assets/img/icons/AddCircle.svg" alt="Add a record" /> Add a record
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        <Container>
                            <div style={{ paddingTop: '90px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end', gap: '42px' }}>
                                <button className="btn previous-btn" onClick={handlePreviousClick} type="button">Previous</button>
                                <button className="btn btn-primary register-btn" type="submit">
                                    <br />
                                    <span style={{ color: 'var(--bs-btn-hover-color)', backgroundColor: 'var(--bs-btn-hover-bg)' }}>Submit</span>
                                </button>
                            </div>
                        </Container>
                    </Form>
                    <div style={{ height: '132px' }}></div>
                    <SeaServiceModal show={seaServiceModalShow} onHide={handleSeaServiceModalClose} onSave={handleSaveSeaServiceData} 
                        seaServiceFormData={selectedSeaServiceData} 

                    />
                    <ReferenceModal show={referenceModalShow} onHide={handleReferenceModalClose} formData={referenceFormData} onChange={handleReferenceInputChange} onSubmit={handleSaveReferenceData} codeList={codeList} onNumberChange={handlePhoneNumberChange} onCodeChange={handleCodeChange} 
                    errorCompany={errorCompany}
                    errorReference={errorReference}
                    errorContact={errorContact}
                    />
                    <div style={{ height: '132px' }}></div>
                </>
            )}
        </>
    );
};

export default CrewCreateProfileStep3;

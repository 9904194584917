import React, { useState, useEffect } from 'react';
import { Modal, Button } from 'react-bootstrap';
import { apiRoutes } from '../../config/apiConfig';
import { checkJobStatus } from '../apiService';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const ApplyJobModal = ({ show, handleClose, handleConfirmApply }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to use your 100 credits?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>Cancel</Button>
        <Button variant="primary" onClick={handleConfirmApply}>Yes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export const DeleteUserModal = ({ show, handleClose, handleConfirmApply }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to delete user?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>Cancel</Button>
        <Button variant="primary" onClick={handleConfirmApply}>Yes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export const AppliedSuccessModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header myjob-header">
          <LazyLoadImage src="/static/crew/assets/img/icons/double_check.svg" alt="Double Check Icon" />
        </div>
        <div className="modal-body myjob-body">
          <p>🎉 Congratulations, You have applied successfully!</p>
        </div>
        <div className="modal-footer myjob-footer">
          <Button className="btn btn-light myjob-btn-close" onClick={handleClose}>
            Close
          </Button>
          <Button className="btn btn-primary myjob-btn" onClick={() => window.location.href = apiRoutes.homecrew}>
            My Jobs&nbsp;<LazyLoadImage src="/static/crew/assets/img/icons/fi_arrow-right.svg" alt="Arrow Right Icon" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};


// CheckStatusModal.js

export const CheckStatusModal = ({ show, handleClose, jobId, userToken }) => {
    const [statusStep2, setStatusStep2] = useState(null);
    const [statusStep3, setStatusStep3] = useState(null);
    const [statusStep4, setStatusStep4] = useState(null);

    useEffect(() => {
      if (show && jobId) {
          const fetchStatusData = async () => {
              try {
                  const response = await checkJobStatus(jobId, userToken); 
                  setStatusStep2(response[0].viewed_status);
                  setStatusStep3(response[0].resume_status);
                  setStatusStep4(response[0].shortlisted_status);
              } catch (error) {
                  console.error('Error fetching status data:', error);
              }
          };

          fetchStatusData();
      }
  }, [show, jobId, userToken]);

    return (
        <Modal show={show} onHide={handleClose} centered >
            <Modal.Header className="modal-status-header">
                <Modal.Title className="modal-status-header">Application Status</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-check-status-body">
                <div className="modal-body-status-containers">
                    <LazyLoadImage src="/static/crew/assets/img/icons/statuscheck_step1_success.svg" alt="Step 1 Success" />
                    <div>
                        <h1 className="status-heading">Job Applied</h1>
                        <h1 className="status-para">You have successfully applied for your job</h1>
                    </div>
                </div>
                <div className="modal-body-status-containers">
                    {statusStep2 ?
                      <LazyLoadImage src="/static/crew/assets/img/icons/statuscheck_step1_success.svg" alt="Step 1 Success" />
                      :
                      <LazyLoadImage src="/static/crew/assets/img/icons/statuscheck_step2.svg" alt="Step 2" />
                    }
                    <div>
                        <h1 className="status-heading">Profile Viewed</h1>
                        <h1 className="status-para">Profile has been viewed by the employer</h1>
                    </div>
                </div>
                <div className="modal-body-status-containers">
                    {statusStep3 ?
                      <LazyLoadImage src="/static/crew/assets/img/icons/statuscheck_step1_success.svg" alt="Step 1 Success" />
                      :
                      <LazyLoadImage src="/static/crew/assets/img/icons/statuscheck_step3.svg" alt="Step 3" />
                    }
                    <div>
                        <h1 className="status-heading">Resume Downloaded</h1>
                        <h1 className="status-para">Resume has been downloaded by the employer</h1>
                    </div>
                </div>
                <div className="modal-body-status-containers">
                    {statusStep4 ?
                      <LazyLoadImage src="/static/crew/assets/img/icons/icons_check_green.svg" alt="Step 4 Success" style={{ marginTop: '-26px' }} />
                      :
                      <LazyLoadImage src="/static/crew/assets/img/icons/statuscheck_step4.svg" alt="Step 4" style={{ marginTop: '-24px' }} />
                    }
                    <div>
                        <h1 className="status-heading">Shortlisted</h1>
                        <h1 className="status-para">Congratulations! Employer has shortlisted your profile</h1>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer className="modal-check-status-footer">
                <Button variant="light" onClick={handleClose} className="modal-status-footer">
                    Close
                </Button>
            </Modal.Footer>
        </Modal>
    );
};


export const ConfirmDownloadModal = ({ show, handleClose, handleConfirmDownload }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>Are you sure you want to download the resume?</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>Cancel</Button>
        <Button variant="primary" onClick={handleConfirmDownload}>Yes</Button>
      </Modal.Footer>
    </Modal>
  );
};

import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css'; 
import 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button  } from 'react-bootstrap';
import { setLocalItem, clearAllLocalItem } from '../components/tokenManager';
import { apiRoutes } from '../config/apiConfig';

const SignupEmployeeCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/login.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/signup.css" />
    </>
  );
};


const SignupEmployee = () => {
    localStorage.clear();
    const navigate = useNavigate();
    const [selectedOption, setSelectedOption] = useState('');
    const [selectedUser, setSelectedUser] = useState('');

    const handleOptionChange = (event) => {
        setSelectedUser(event.target.id);
        setSelectedOption(event.target.value);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        clearAllLocalItem();
        setLocalItem("selectedUser", selectedUser );
        // console.log("selectedOption", selectedOption);
        // console.log("selectedUser", selectedUser);
        navigate(apiRoutes.register_employer_mobile );
    };

    return (
        <>
            <SignupEmployeeCss />
            <ToastContainer />
            <div className="row d-flex justify-content-center" style={{ background: `url('/static/crew/assets/img/images/login_bg.png') center / cover no-repeat` }}>
            <Container>
                <Row className="justify-content-center">
                    <Col md={6} xl={4} className="signin-login-number">
                        <Card style={{ borderRadius: '0px' }}>
                            <Card.Body className="text-center d-flex flex-column align-items-center" style={{ padding: '146px 66px 200px 66px', gap: '55px' }}>
                                <div className="login-logo-container">
                                    <LazyLoadImage className="login-logo" src="/static/crew/assets/img/brands/newLogo.png" alt="New Logo" />
                                    <div>
                                        <a href={apiRoutes.register} >
                                            <LazyLoadImage  className="left-arrow" src='/static/crew/assets/img/arrows/ArrowLeft.svg' alt="Left Arrow" />
                                        </a>
                                    </div>
                                </div>
                                <div style={{ display: 'inline-grid', gap: '54px' }}>
                                    <div style={{ display: 'inline-grid', gap: '30px' }}>
                                        <div style={{ display: 'inline-grid', gap: '19px' }}>
                                            <h1 className="signup-heading">Continue as</h1>
                                            <div className="signup-desc">
                                                <p>Select the option that is most suitable for your <br />company type.</p>
                                            </div>
                                        </div>
                                        <div>
                                            <Form onSubmit={handleSubmit} style={{ gap: '22px', display: 'inline-grid' }}>
                                                <div className="mb-3 user-type-container">
                                                    <Form.Check type="radio" name="userType" id="3" value="itfOwnership" checked={selectedOption === "itfOwnership"} onChange={handleOptionChange} className='form-emp-type-radio'
                                                        label={
                                                            <React.Fragment>
                                                                <LazyLoadImage src="/static/crew/assets/img/icons/company.svg" alt="Company Icon" />
                                                                <div className="user-type-info">
                                                                    <h1 className="user-type-header">ITF / Ownership</h1>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </div>
                                                <div className="mb-3 user-type-container">
                                                    <Form.Check type="radio" name="userType" id="4" value="managementCompany" checked={selectedOption === "managementCompany"} onChange={handleOptionChange} className='form-emp-type-radio'
                                                        label={
                                                            <React.Fragment>
                                                                <LazyLoadImage src="/static/crew/assets/img/icons/company.svg" alt="Company Icon" />
                                                                <div className="user-type-info">
                                                                    <h1 className="user-type-header">Management Company</h1>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </div>
                                                <div className="mb-3 user-type-container">
                                                    <Form.Check type="radio" name="userType" id="5" value="crewingAgent" checked={selectedOption === "crewingAgent"} onChange={handleOptionChange} className='form-emp-type-radio'
                                                        label={
                                                            <React.Fragment>
                                                                <LazyLoadImage src="/static/crew/assets/img/icons/crewingagent.svg" alt="Crewing Agent Icon" />
                                                                <div className="user-type-info">
                                                                    <h1 className="user-type-header">Crewing Agent</h1>
                                                                </div>
                                                            </React.Fragment>
                                                        }
                                                    />
                                                </div>
                                                <div className="mb-3">
                                                    <Button className="btn btn-primary shadow d-block w-100 sign-in-button" type="submit">Continue</Button>
                                                </div>
                                            </Form>

                                        </div>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                        <div>
                            <p className="copyright-watermark">© Join My Ship 2024</p>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
        </>
    );
};

export default SignupEmployee;

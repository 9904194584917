import React from 'react';
import Head from '../components/layouts/Head';
import NavbarHome from '../components/layouts/NavbarHome';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import TermsAndConditionsContent from '../components/layouts/TermsAndConditions';
import NavbarCrew from '../components/layouts/NavbarCrew';
import { getIsLoggedIn } from '../components/tokenManager';

const TermsAndConditionsTailScript = () => {
  return <script src="/static/crew/assets/js/bold-and-bright.js"></script>;
};

const TermsAndConditionsCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/terms-and-conditions.css" />
    </>
  );
};

const TermsAndConditions = () => {
  const isLoggedIn = getIsLoggedIn();

  return (
    <>
      <Head />
      <TermsAndConditionsCss />
      {isLoggedIn ? <NavbarCrew /> : <NavbarHome />}
      <TermsAndConditionsContent />
      <Footer />
      <Scripts />
      <TermsAndConditionsTailScript />
    </>
  );
};

export default TermsAndConditions;

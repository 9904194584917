import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiRoutes } from '../config/apiConfig';
import { setLocalItem, getLocalItem, setLocalJsonItem } from '../components/tokenManager';
import { getUserByToken, changePasswordDBNew, loginFunc, isPasswordChangeFunc } from '../components/apiService';
import {auth} from '../components/firebase';
import LoadingAnimation from '../components/layouts/LoaderButton';

const LoginCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/login.css" />
    </>
  );
};


const Login = () => {
  localStorage.clear();
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isChecked, setIsChecked] = useState(false);
  const [error, setError] = useState(null);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [loader, setLoader] = useState(false);

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  const toggleShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
};

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isChecked) {
      toast.error("Please agree to the Terms of Use and Privacy Policy");
    } else {
      try {
        setLoader(true);
        const loginResponse = await loginFunc(email, password);

        await new Promise((resolve) => setTimeout(resolve, 2000));

        if (loginResponse && loginResponse.data && loginResponse.data.message  === "Login Successfully.") {
          const userToken = getLocalItem("accessToken"); 
          const userData = await getUserByToken(userToken);
          const user = auth.currentUser;
          // setLocalJsonItem("userData", userData.data);

          if (userData.data.is_password_change) {
            const passwordFormData = {
              "new_password": password
            };
            await changePasswordDBNew(passwordFormData ,user.stsTokenManager.accessToken);
            const ispasswordUpdateFormData = {
              "is_password_change": 0
            };
            const updatedUser = await isPasswordChangeFunc(email, ispasswordUpdateFormData);
          }
          
          if (auth.currentUser.emailVerified) {
            if (userData.status == 200) {
              setLocalJsonItem("userData", userData.data);
              if (userData.data.user_type_key === 2) {
                if (userData.data.screen_check === 0) {
                  navigate(apiRoutes.create_profile_1);
                } else if (userData.data.screen_check === 1) {
                  navigate(apiRoutes.create_profile_2);
                } else if (userData.data.screen_check === 2) {
                  navigate(apiRoutes.create_profile_3);
                } else if (userData.data.screen_check === 3) {
                  navigate(apiRoutes.homecrew);
                }
              } else
              if (userData.data.user_type_key > 2) {
                if (userData.data.screen_check === 0) {
                  if (userData.data.is_prime === 2) {
                    navigate( apiRoutes.create_profile_sub_employer ); 
                  } else {
                      navigate( apiRoutes.create_profile_employer ); 
                  }
                } else {
                  navigate(apiRoutes.emp_home);
                }
              }
            } 
          } else {
            if (userData.data.user_type_key === 2) {
                navigate( apiRoutes.register_crew_confirm_mail + `?usermail=${auth.currentUser.email}`);
            } else if (userData.data.user_type_key > 2) {
                navigate(  apiRoutes.register_employer_confirm_mail +`?usermail=${auth.currentUser.email}`);
            }
          }
        } else {
          setLoader(false);
          toast.error(loginResponse);
          setEmail('');
          setPassword('');
        }
      } catch (err) {
        // console.log("errerrerrerrerr", err);
        setLoader(false);
        toast.error("Error: Please try after sometime.");
        setError(err.message);
        // toast.error(err);
        setEmail('');
        setPassword('');
      }
    }
    setLoader(false);
  };

  const handleButtonClick = () => {
    window.location.href = apiRoutes.login_mobile;
  };
  
  return (
    <>
      {/* {loader && 
        <LoadingAnimation />
      } */}
      <LoginCss />
      <ToastContainer />
      <div className="row d-flex justify-content-center" style={{ background: `url('/static/crew/assets/img/images/login_bg.png') center / cover no-repeat` }}>
        <div className="col-md-6 col-xl-4 signin-login-mail">
          <div className="card" style={{ borderRadius: '0px' }}>
            <div className="card-body text-center d-flex flex-column align-items-center" style={{ padding: '42px 98px', gap: '55px' }}>
              <div className="login-logo-container">
                <LazyLoadImage  className="login-logo" src='/static/crew/assets/img/brands/newLogo.png' alt="Logo" />
                <div>
                  <a href="/">
                    <LazyLoadImage  className="left-arrow" src='/static/crew/assets/img/arrows/ArrowLeft.svg' alt="Left Arrow" />
                  </a>
                </div>
              </div>
              <div style={{ display: 'inline-grid', gap: '54px' }}>
                <div style={{ display: 'inline-grid', gap: '30px' }}>
                  <div style={{ display: 'inline-grid', gap: '19px' }}>
                    <h1 className="login-heading">Sign in to your account</h1>
                    <div className="login-desc">
                      <p>Please enter your E-mail id and password to continue using our portal</p>
                    </div>
                  </div>
                  <div>
                    <form method="post" data-bs-theme="light" onSubmit={handleSubmit} style={{ gap: '22px', display: 'inline-grid' }}>
                      <div className="mb-3"><label className="form-label email-label">E-mail</label>
                      <input className="form-control input-email" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Please enter your email id" /></div>
                      <div className="mb-3" style={{ display: 'inline-grid', gap: '12px' }}>
                        <div><label className="form-label email-label">Password</label>

                        <input className="form-control input-password" 
                          type={showNewPassword ? 'text' : 'password'} 
                          name="password" value={password} onChange={(e) => setPassword(e.target.value)} 
                          placeholder="Please enter your password" />
                            <LazyLoadImage className='show-password-eye'
                              src="/static/crew/assets/img/icons/fi_eye.svg" 
                              alt="Toggle New Password Visibility" 
                              onClick={toggleShowNewPassword} 
                              />
                          </div>
                        <div className="mb-3">
                          <label className="form-label forgot-label">
                            <a href={apiRoutes.forget_password} > 
                              Forgot Password ?
                            </a>
                          </label></div>
                      </div>
                      <div style={{ display: 'inline-grid', gap: '22px' }}>
                        <div style={{ display: 'inline-grid', gap: '28px' }}>
                          <div className="mb-3 policy-container">
                            <input type="checkbox" className="checkbox-box" onChange={handleCheckboxChange} checked={isChecked} />
                            <label className="form-label policy-label">
                              I agree to&nbsp;
                              <a href={apiRoutes.terms_and_conditions} target='_blank'> 
                                Terms of use
                              </a>
                              &nbsp;and&nbsp;
                              <a href={apiRoutes.privacy_policies} target='_blank'> 
                                Privacy Policy
                              </a>&nbsp;
                            </label>
                          </div>
                          <div className="mb-3"><button className="btn btn-primary shadow d-block w-100 sign-in-button" disabled={loader} type="submit">
                              {loader ? (
                                <>
                                  <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                                  Signing In...
                                </>
                              ) : (
                                'Sign In'
                              )}
                          </button></div>
                        </div>
                        <div>
                          <p className="signup-para">Don’t have an account?&nbsp;<a className="signup-link" href="/register"><span style={{ color: 'rgb(45, 45, 45)' }}>Sign Up</span></a></p>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="sign-up-btn">
                  <button className="btn btn-primary" onClick={handleButtonClick} type="button">
                    <LazyLoadImage
                      className="sign-up-btn-img"
                      src='/static/crew/assets/img/icons/Group.svg'
                      alt="Sign Up Button"
                    />
                    Sign In With Mobile
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div>
            <p className="copyright-watermark">© Join My Ship 2024</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;

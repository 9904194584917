import React, { useEffect, useState } from 'react';
import { Modal } from 'react-bootstrap';
import { getCrewFollowedToList, getCrewFollowedByList, companyUnFollowed } from '../apiService';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ModalFollowers = ({ userData, userID, userToken, isModalOpen, toggleModal }) => {
    const [followers, setFollowers] = useState([]);
    const [followersCount, setFollowersCount] = useState(0);
    const [unfollowedUsers, setUnfollowedUsers] = useState(new Set());

    const fetchFollowers = async () => {
        try {
            let followersData;
            if (userData && userData.user_type_key === 2 ) {
                followersData = await getCrewFollowedToList(userID, userToken);
            } else {
                followersData = await getCrewFollowedByList(userID, userToken);
            }
            setFollowers(followersData);
            setFollowersCount(followersData.length);
        } catch (error) {
            console.error('Error fetching followers:', error);
        }
    };


    useEffect(() => {
        if (isModalOpen) {
            fetchFollowers();
        }
    }, [isModalOpen, userID, userToken]);


    const handleUnfollow = async (followerID) => {
        try {
            let response;

            if (userData && userData.user_type_key === 2 ) {
                response = await companyUnFollowed(followerID, userID, userToken);
            } else {
                response = await companyUnFollowed( userID, followerID, userToken);
            }
            
            if (response.status === 204) { 
                setUnfollowedUsers(prevSet => new Set(prevSet).add(followerID));
                fetchFollowers();
            } else {
                console.error('Unfollow failed:', response);
            }
        } catch (error) {
            console.error('Error unfollowing user:', error);
        }
    };

    return (
        <Modal show={isModalOpen} onHide={toggleModal} centered size="lg" scrollable>
            <Modal.Header className='modal-following-list-header-container'>
                <Modal.Title className="modal-following-list-title">Followings</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-following-list-body">
                <div className="modal-following-list-body-container">
                    <div className="modal-following-list-body-heading">
                        <LazyLoadImage className="modal-following-list-body-heading-img" src="/static/crew/assets/img/icons/User_follow.svg" alt="Followers Icon" />
                        <p className="modal-following-list-body-heading-text">{followersCount}</p>
                        <p className="modal-following-list-body-heading-text">Followings</p>
                    </div>
                    {followers && followers.map((follower, index) => (
                        <React.Fragment key={index}>
                            {follower.user_id && follower.user_id.id && (
                                <div className="modal-following-list-item">
                                    <div className="modal-following-list-item-details">
                                        <LazyLoadImage 
                                            className="modal-following-list-item-img" 
                                            src={follower.user_id.profilePic || '/static/crew/assets/img/icons/Avatar_img.png'} 
                                            alt="Follower Avatar" 
                                        />
                                        <div className="modal-following-list-item-info">
                                            <h1 className="modal-following-list-item-title">{follower.user_id.first_name}</h1>
                                            <p className="modal-following-list-item-text">{follower.user_id.company_name}</p>
                                        </div>
                                    </div>
                                    <div className="modal-following-list-item-unfollow-container">
                                        <p 
                                            onClick={() => handleUnfollow(follower.user_id.id)}
                                            className="modal-following-list-item-unfollow-text"
                                        >
                                            {unfollowedUsers.has(follower.user_id.id) ? 'Removed' : 'Unfollow'}
                                        </p>
                                    </div>
                                </div>
                            )}
                            {follower.user_followed_by && follower.user_followed_by.id && (
                                <div className="modal-following-list-item">
                                    <div className="modal-following-list-item-details">
                                        <LazyLoadImage 
                                            className="modal-following-list-item-img" 
                                            src={follower.user_followed_by.profilePic || '/static/crew/assets/img/icons/Avatar_img.png'} 
                                            alt="Follower Avatar" 
                                        />
                                        <div className="modal-following-list-item-info">
                                            <h1 className="modal-following-list-item-title">{follower.user_followed_by.first_name}</h1>
                                            <p className="modal-following-list-item-text">{follower.user_followed_by.company_name}</p>
                                        </div>
                                    </div>
                                    <div className="modal-following-list-item-unfollow-container">
                                        <p 
                                            onClick={() => handleUnfollow(follower.user_followed_by.id)}
                                            className="modal-following-list-item-unfollow-text"
                                        >
                                            {unfollowedUsers.has(follower.user_followed_by.id) ? 'Removed' : 'Unfollow'}
                                        </p>
                                    </div>
                                </div>
                            )}
                        </React.Fragment>
                    ))}
                </div>
            </Modal.Body>
        </Modal>
    );
};

export default ModalFollowers;

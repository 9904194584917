import { useState, useEffect } from 'react'; 
import { handleApiError } from '../components/handleApiError';

const ClearLocalStorage = () => {
  const [error, setError] = useState(null);

  useEffect(() => {
    const clearData = async () => {
      try {
        localStorage.clear();
      } catch (error) {
        setError(handleApiError(error));
      }
    };
    clearData();
  }, []); 

  return (
    <div>
      {error ? (
        <p>Error: {error}</p>
      ) : (
        <p>Success! Local Storage is clear.</p>
      )}
    </div>
  );
};

export default ClearLocalStorage;

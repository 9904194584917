import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Scripts from '../components/layouts/Scripts';
import { Container, Modal, Row, Col, Form, Button, Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import { formatDateNew , 
          getFlagList, getVesselList, getRankByJobIdList, getCocListByUserType,
          getCopListByUserType, getWatchkeepingListByUserType, employerEditPostJob, 
          getReferJobById, parseDateObjectByHyphen
        } from '../components/apiService';
import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { apiRoutes } from '../config/apiConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';

const PostJobEditScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/post_job.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-dropdown-1.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Text-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Bootstrap-4-Custom-Radio.css" />

    </>
  );
};

const PostJobEditScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const PostJobEdit = () => {
  const userToken = getLocalItem("accessToken"); 
  const userData =  getLocalJsonItem("userData");
  const [isChecked, setIsChecked] = useState(false);
  const { jobID } = useParams();
  const [autofillData, setAutofillData] = useState('');

  useEffect(() => {
      const fetchData = async () => {
          try {
              const result = await getReferJobById(jobID, userToken);
              setAutofillData(result);
          } catch (error) {
              console.error("Error fetching Job:", error);
          }
      };

      fetchData();
  }, [jobID]);


  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };


    // for grt Starts 
    const [grt, setGrt] = useState('');
    const [errorGrt, setErrorGrt] = useState('');

    const handleGrtChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { 
            setGrt(value);
            setErrorGrt('');
        } else {
            setErrorGrt('GRT must be a number');
        }
    };
    // for grt Ends



    // For MobileNumber Radio Satrts
    const [selectedMobileNumberRadioOption, setSelectedMobileNumberRadioOption] = useState('0');
    const handleMobileNumberRadioChange = (event) => {
        setSelectedMobileNumberRadioOption(event.target.value);
    };
    // For MobileNumber Radio Ends

    // For Email Radio Satrts
    const [selectedEmailRadioOption, setSelectedEmailRadioOption] = useState('0');
    const handleEmailRadioChange = (event) => {
        setSelectedEmailRadioOption(event.target.value);
    };
    // For Email Radio Ends
    

  // For Job Id Satrts
  const [searchJobQuery, setSearchJobQuery] = useState('');
  const [selectedJobItem, setSelectedJobItem] = useState(null);
  const [selectedJobId, setSelectedJobId] = useState(null);

  const jobTypes = [
      { id: 1, name: 'Deck/Navigation' },
      { id: 2, name: 'Engine' },
      { id: 3, name: 'Galley/Kitchen' },
  ];

  const filteredJobItems = jobTypes.filter(job =>
      job.name.toLowerCase().includes(searchJobQuery.toLowerCase())
  );

  const handleJobSelect = (job) => {
          setSelectedJobItem(job.name);
          setSelectedJobId(job.id);
          setSearchJobQuery(''); 
  };
  // For Job Id Ends


  // For Rank Satrts
  const [searchRankQuery, setsearchRankQuery] = useState('');
  const [selectedRankItem, setselectedRankItem] = useState(null);
  const [items, setItems] = useState([]);

  useEffect(() => {
      if (selectedJobItem !== null) {
          getRankByJobIdList(selectedJobId, userToken)
              .then(result => {
                  setItems(result);
              })
              .catch(error => {
                  console.error("Error fetching rank list:", error);
              });
      }
  }, [selectedJobItem]);
  
  const handleRankItemClick = (item) => {
      setselectedRankItem(item);
      setsearchRankQuery(item.name); 
  };

  const filteredRankItems = items.filter(item =>
      item.name.toLowerCase().includes(searchRankQuery.toLowerCase())
  );
  // For Rank Ends

  // For DOB Starts
  const [DOBSelectedDate, setDOBSelectedDate] = useState(null);
  const maxDate = new Date();
  maxDate.setMonth(maxDate.getMonth() + 2, 0); // Move to the last date of next month

  const handleDOBChange = (date) => {
      setDOBSelectedDate(date);
  };
  // For DOB Ends

  // Vessel Dropdown Starts
  const [vesselItems, setVesselItems] = useState([]);
  const [selectedVesselItem, setSelectedVesselItem] = useState(null);
  const [selectedVesselItemId, setSelectedVesselItemId] = useState(null);
  const [searchVesselQuery, setSearchVesselQuery] = useState('');

  useEffect(() => {
  getVesselList().then(result => {
      setVesselItems(result);
  }).catch(error => {
      console.error("Error fetching vessel list:", error);
  });
  }, []);

  const handleVesselSelect = (selectedItem) => {
      setSelectedVesselItem(selectedItem.name);
      setSelectedVesselItemId(selectedItem.id);
      
      setSearchVesselQuery('');
  };

  const filteredVesselItems = Object.keys(vesselItems).reduce((filteredItems, parentKey) => {
      const filteredChildren = vesselItems[parentKey].filter(childItem => {
      if (typeof childItem.name === 'string') {
          return childItem.name.toLowerCase().includes(searchVesselQuery.toLowerCase());
      } else if (typeof childItem.name === 'object' && childItem.name.name) {
          return childItem.name.name.toLowerCase().includes(searchVesselQuery.toLowerCase());
      }
      return false;
      });

      if (filteredChildren.length > 0) {
      filteredItems[parentKey] = filteredChildren;
      }

      return filteredItems;
  }, {});

  // Vessel Dropdown Ends

  // COP Starts

  const [searchCOPQuery, setsearchCOPQuery] = useState('');
  const [selectedCOPItems, setselectedCOPItems] = useState([]);
  const [itemsCOP, setItemsCOP] = useState([]);

  useEffect(() => {
      getCopListByUserType(3).then(result => {
          setItemsCOP(result);
      }).catch(error => {
          console.error("Error fetching COP list:", error);
      });
  }, []); 

  const handleCOPItemClick = (item) => {
      setselectedCOPItems(prevSelectedItems => {
          if (prevSelectedItems.some(selectedItem => selectedItem.id === item.id)) {
              return prevSelectedItems.filter(selectedItem => selectedItem.id !== item.id);
          } else {
              return [...prevSelectedItems, item];
          }
      });
      setsearchCOPQuery(''); 
  };

  const filteredCOPItems = itemsCOP.filter(item =>
      item.name.toLowerCase().includes(searchCOPQuery.toLowerCase())
  );

  // COP Ends

  // COC Starts

  const [searchCOCQuery, setsearchCOCQuery] = useState('');
  const [selectedCOCItems, setselectedCOCItems] = useState([]);
  const [itemsCOC, setItemsCOC] = useState([]);

  useEffect(() => {
      getCocListByUserType(3).then(result => {
          setItemsCOC(result);
      }).catch(error => {
          console.error("Error fetching COC list:", error);
      });
  }, []); 

  const handleCOCItemClick = (item) => {
      setselectedCOCItems(prevSelectedItems => {
          if (prevSelectedItems.some(selectedItem => selectedItem.id === item.id)) {
              return prevSelectedItems.filter(selectedItem => selectedItem.id !== item.id);
          } else {
              return [...prevSelectedItems, item];
          }
      });
      setsearchCOCQuery(''); // Clear search query after selection
  };

  const filteredCOCItems = itemsCOC.filter(item =>
      item.name.toLowerCase().includes(searchCOCQuery.toLowerCase())
  );

  // COC Ends

  // WatchKeeping Starts

  const [searchWatchKeepingQuery, setsearchWatchKeepingQuery] = useState('');
  const [selectedWatchKeepingItems, setselectedWatchKeepingItems] = useState([]);
  const [itemsWatchKeeping, setItemsWatchKeeping] = useState([]);

  useEffect(() => {
      getWatchkeepingListByUserType(3).then(result => {
          setItemsWatchKeeping(result);
      }).catch(error => {
          console.error("Error fetching WatchKeeping list:", error);
      });
  }, []); 

  const handleWatchKeepingItemClick = (item) => {
      setselectedWatchKeepingItems(prevSelectedItems => {
          if (prevSelectedItems.some(selectedItem => selectedItem.id === item.id)) {
              return prevSelectedItems.filter(selectedItem => selectedItem.id !== item.id);
          } else {
              return [...prevSelectedItems, item];
          }
      });
      setsearchWatchKeepingQuery(''); 
  };

  const filteredWatchKeepingItems = itemsWatchKeeping.filter(item =>
      item.name.toLowerCase().includes(searchWatchKeepingQuery.toLowerCase())
  );

  // WatchKeeping Ends

  // Job Expiry Starts
  const jobExpireInDays = [
    { id: 1, name: '1 Days', value: 1 },
    { id: 2, name: '7 Days', value: 7 },
    { id: 2, name: '15 Days', value: 15 },
    { id: 3, name: '30 Days', value: 30 }
  ];
  const [searchJobExpireQuery, setSearchJobExpireQuery] = useState('');
  const [selectedJobExpireItem, setSelectedJobExpireItem] = useState('7 Days');
  const [selectedJobExpire, setSelectedJobExpire] = useState(7);

  const filteredJobExpireItems = jobExpireInDays.filter(job =>
    job.name.toLowerCase().includes(searchJobExpireQuery.toLowerCase())
  );

  const handleJobExpireSelect = (job) => {
    setSelectedJobExpireItem(job.name);
    setSelectedJobExpire(job.value);
    setSearchJobExpireQuery(''); 
  };
  // Job Expiry Ends

  useEffect(() => {
        if (autofillData ) {
            setSelectedVesselItem(autofillData.vessel_id.name);
            setSelectedVesselItemId(autofillData.vessel_id.id);
            setDOBSelectedDate(parseDateObjectByHyphen(autofillData.tentative_joining));

            const expiryItem = jobExpireInDays.find(item => item.value ===  parseInt(autofillData.expiry_in_day, 10));
            setSelectedJobExpireItem(expiryItem.name);
            setSelectedJobExpire(expiryItem.value);
            
            const jobTypeItem = jobTypes.find(item => item.id ===  parseInt(autofillData.Job_Rank_wages[0].rank_number.job_type, 10));
            setSelectedJobItem(jobTypeItem.name)
            setSelectedJobId(autofillData.Job_Rank_wages[0].rank_number.job_type)
            setGrt(autofillData.GRT);

            const simplifiedRankList = autofillData.Job_Rank_wages.map(item => ({
                jobType :jobTypes.find(jobType => jobType.id === parseInt(item.rank_number.job_type, 10)).name,
                rank: item.rank_number.name,
                rankId: item.rank_number.id,
                wages: item.wages
            }));
            setRanks(simplifiedRankList);

            const simplifiedCocList = autofillData.job_coc.map(item => ({
                id: item.coc_id.id,
                name: item.coc_id.name
            }));
            setselectedCOCItems(simplifiedCocList);

            const simplifiedCopList = autofillData.job_cop.map(item => ({
                id: item.cop_id.id,
                name: item.cop_id.name
            }));
            setselectedCOPItems(simplifiedCopList);

            const simplifiedWatchKeepingList = autofillData.job_Watch_Keeping.map(item => ({
                id: item.Watch_Keeping_id.id,
                name: item.Watch_Keeping_id.name
            }));
            setselectedWatchKeepingItems(simplifiedWatchKeepingList);
        }
    }, [autofillData]);


  // modal show Operations Starts
  const [show, setShow] = useState(false);
  const [ranks, setRanks] = useState([]);
  const [wages, setWages] = useState('');
  const [editIndex, setEditIndex] = useState(null);

    // for Wages Starts 
    const [errorWages, setErrorWages] = useState('');

    const handleWagesChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { 
            setWages(value);
            setErrorWages('');
        } else {
            setErrorWages('Wages must be a number');
        }
    };
    // for Wages Ends

  const handleClose = () => {
      setShow(false);
      setEditIndex(null);
      setSelectedJobItem('');
      setselectedRankItem('');
      setWages('');
  };

  const handleShow = () => {
      setsearchRankQuery(''); 
      setSelectedJobItem('');
      setselectedRankItem('');
      setWages('');
      setEditIndex(null);
      setShow(true);
  };

  const handleSave = () => {
      if (selectedJobItem && selectedRankItem ) {
          const newRanks = [...ranks];
          const rankData = { jobType: selectedJobItem, rank: selectedRankItem.name, rankId: selectedRankItem.id, wages };
          if (editIndex !== null) {
              newRanks[editIndex] = rankData;
          } else {
              newRanks.push(rankData);
          }
          setRanks(newRanks);
          setSelectedJobItem('');
          setselectedRankItem('');
          setWages('');
          setEditIndex(null);
          handleClose();
      }
  };

  const handleEdit = (index) => {
      const rankToEdit = ranks[index];
      setSelectedJobItem(rankToEdit.jobType);
    //   setselectedRankItem({ name: rankToEdit.rank });
        setselectedRankItem({ name: rankToEdit.rank, id: rankToEdit.rankId });
      setWages(rankToEdit.wages);
      setEditIndex(index);
      setShow(true);
  };

  const handleDelete = (index) => {
      setRanks(ranks.filter((_, i) => i !== index));
  };

  // modal show Operations Ends



  const [showSubmitModal, setShowSubmitModal] = React.useState(false);
  const [showSuccessModal, setShowSuccessModal] = React.useState(false);

  // const handleShowSubmitModal = () => setShowSubmitModal(true);

  const handleShowSubmitModal = () => {
      if (
        selectedVesselItemId && DOBSelectedDate && selectedJobExpire && grt
        && ranks && ranks.length !== 0

      ) {
        setShowSubmitModal(true);
      } else {
          toast.error("Please fill all required fields.");
      }
    };

  const handleCloseSubmitModal = () => setShowSubmitModal(false);

  const handleShowSuccessModal = async () => {
      const selectedCOCItemsidList = selectedCOCItems.map(item => item.id);
      const selectedCOPItemsidList = selectedCOPItems.map(item => item.id);
      const selectedWatchKeepingItemsidList = selectedWatchKeepingItems.map(item => item.id);
      const selectedRanksidList = ranks.map(item => ({ rankId: item.rankId, wages: item.wages }));
      const updatedUserData = {
            posted_by: userData.id,
            vessel_id: selectedVesselItemId,
            tentative_joining: formatDateNew(DOBSelectedDate),
            expiry_in_day: selectedJobExpire,
            GRT: grt,
            mail_info :  selectedEmailRadioOption,
            number_info :  selectedMobileNumberRadioOption,
            rank_id: selectedRanksidList,
            coc_id: selectedCOCItemsidList,
            cop_id: selectedCOPItemsidList,
            Watch_Keeping_id: selectedWatchKeepingItemsidList
      };

      await employerEditPostJob(updatedUserData, jobID, userToken);
      setShowSubmitModal(false);
      setShowSuccessModal(true);
  };
  const handleCloseSuccessModal = () => {
      setShowSuccessModal(false);
  } 
// crew boosted Data Ends

  return (
    <>
        <PostJobEditScriptCss />
        <NavbarCrew />
        <ToastContainer />

        <div className="post-job-header-section">
            <div className="container post-job-header-container">
                <div className="post-job-header-container-div">
                    <h1 className="post-job-header-container-title">Post a Job</h1>
                    <p className="post-job-header-container-text">Post a job and connect with top applicants instantly!</p>
                </div>
            </div>
        </div>
        <Container className="post-job-body-container">
          <div className="post-job-body-section">
            <Row className="post-job-body-container-details" >
                <Col className="post-job-body-container-details">
                    <div className="post-job-body-container-details-div">
                        <div className="post-job-body-container-details-part-1">
                            <div className="post-job-body-container-details-part-1-head">
                                <LazyLoadImage className="post-job-body-container-details-part-1-head-img" src="/static/crew/assets/img/icons/editAdd.svg" alt="Edit Add Icon" />
                                <h1 className="post-job-body-container-details-part-1-head-title">Vessel Details</h1>
                            </div>
                            <div className="post-job-body-container-details-part-1-body">
                                <div className="post-job-body-details-dropdown">
                                    <p className="post-job-body-details-title">Tentative Joining Date</p>
                                    <Form.Group controlId="formDate" className='tentative-date-form-css'>
                                        <DatePicker
                                            selected={DOBSelectedDate}
                                            onChange={handleDOBChange}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="dd-mm-yyyy"
                                            className="form-control form-date-input "
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={40}
                                            customInput={<Form.Control type="date" value={DOBSelectedDate} onChange={(e) => setDOBSelectedDate(e.target.value)} />}
                                            minDate={new Date()}
                                            maxDate={maxDate}
                                        />
                                    </Form.Group>
                                
                                </div>
                                <div className="post-job-body-details-dropdown">
                                    <p className="post-job-body-details-title">Vessel Type</p>
                                    <Dropdown>
                                      <Dropdown.Toggle  className="btn dropdown-toggle dropdown-form-button post-job-dropdown" variant="none" id="vessel-dropdown">
                                          <InputGroup className="mb-3 search-rank-input">
                                              <FormControl className='search-form-input' 
                                              placeholder={(selectedVesselItem ? selectedVesselItem : 
                                                  "Select Vessel")}     
                                              aria-label="Search" aria-describedby="basic-addon2" value={searchVesselQuery} onChange={(e) => setSearchVesselQuery(e.target.value)} required={!selectedVesselItem}  />
                                          </InputGroup>
                                      </Dropdown.Toggle>
                                      <Dropdown.Menu className='dropdown-scrollable'>
                                          {Object.keys(filteredVesselItems).map((parentKey, parentIndex) => (
                                              <React.Fragment key={parentIndex}>
                                                  <Dropdown.Item disabled className='vessle-type-parent'>
                                                      <b>{parentKey}</b>
                                                  </Dropdown.Item>
                                                  {filteredVesselItems[parentKey].map((childItem, childIndex) => (
                                                      <Dropdown.Item key={childIndex} onClick={() => handleVesselSelect(childItem)}>
                                                      {childItem.name}
                                                      </Dropdown.Item>
                                                  ))}
                                              </React.Fragment>
                                          ))}
                                      </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div className="post-job-body-details-dropdown">
                                    <p className="post-job-body-details-title">GRT</p>
                                    <Form.Control 
                                        type="text" 
                                        className="form-text-input" 
                                        placeholder="Enter GRT" 
                                        value={grt}
                                        onChange={handleGrtChange}
                                    />
                                    {errorGrt && <p className="form-error-text">{errorGrt}</p>}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="post-job-body-container-details-div">
                        <div className="post-job-body-container-details-part-1-head">
                            <LazyLoadImage className="post-job-body-container-details-part-1-head-img" src="/static/crew/assets/img/icons/ship.svg" alt="Ship Icon" />
                            <h1 className="post-job-body-container-details-part-1-head-title"><strong>Crew Requirements</strong></h1>
                        </div>
                        <div className="post-job-body-container-details-part-2-button">
                            <button variant="none" onClick={handleShow} className="post-job-button" data-bs-toggle="modal" data-bs-target="#modal-add-rank">
                                <LazyLoadImage src="/static/crew/assets/img/icons/AddCircle.svg" alt="Add Circle Icon" /> Add new rank
                            </button>
                        </div>
                        <div className="post-job-body-container-details-part-2-data">
                        {ranks.map((rank, index) => (
                            <div className="post-job-body-container-rank-add" key={index}>
                                <p className="post-job-body-container-rank-add-title">{rank.rank}</p>
                                <p className="post-job-body-container-rank-add-title">Wages: ${rank.wages}</p>
                                <div className="post-job-body-container-rank-add-operation">
                                    <div className="post-job-body-container-rank-edit" onClick={() => handleEdit(index)}>
                                        <LazyLoadImage className="post-job-body-container-rank-edit-img" src="/static/crew/assets/img/icons/Edit.svg" alt="Edit Icon" />
                                        <p className="post-job-body-container-rank-edit-title">Edit</p>
                                    </div>
                                    <div className="post-job-body-container-rank-edit" onClick={() => handleDelete(index)}>
                                        <LazyLoadImage className="post-job-body-container-rank-edit-img" src="/static/crew/assets/img/icons/TrashBinMinimalistic.svg" alt="Edit Icon" />
                                        <p className="post-job-body-container-rank-delete-title">Delete</p>
                                    </div>
                                </div>
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="post-job-body-container-details-part-3">
                        <div className="post-job-body-container-details-part-1-head">
                            <LazyLoadImage className="post-job-body-container-details-part-1-head-img" src="/static/crew/assets/img/icons/Radar2.svg" alt="Radar Icon" />
                            <h1 className="post-job-body-container-details-part-1-head-title"><strong>Other Details</strong></h1>
                        </div>
                        <div className="post-job-body-container-details-part-3-body">
                            <div className="post-job-body-details-dropdown" >
                                <p className="post-job-body-details-title">COC Requirements&nbsp;<strong>(Optional)</strong></p>
                                <Dropdown>
                                  <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="dropdown-basic">
                                      <InputGroup className="mb-3 search-rank-input">
                                          <FormControl 
                                              placeholder={(selectedCOCItems.length > 0 && selectedCOCItems.map(item => item.name).join(', ')) || "Select COC"} 
                                              aria-label="Search" 
                                              aria-describedby="basic-addon2" 
                                              value={searchCOCQuery} 
                                              onChange={(e) => setsearchCOCQuery(e.target.value)} 
                                              required={selectedCOCItems.length === 0} 
                                          />
                                      </InputGroup>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {filteredCOCItems.map((item, index) => (
                                            <Dropdown.Item key={index} onClick={() => handleCOCItemClick(item)}>
                                                <div className='cop-item-container'>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={selectedCOCItems.some(selectedItem => selectedItem.id === item.id)} 
                                                        onChange={() => handleCOCItemClick(item)}
                                                    /> 
                                                    <span className='checkbox-item-title'>
                                                      {item.name}
                                                    </span>
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                        {filteredCOCItems.length === 0 && (
                                            <Dropdown.Item disabled>No items found</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="post-job-body-details-dropdown" >
                                <p className="post-job-body-details-title">COP Requirements&nbsp;<strong>(Optional)</strong></p>
                                <Dropdown>
                                  <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="dropdown-basic">
                                      <InputGroup className="mb-3 search-rank-input">
                                          <FormControl 
                                              placeholder={(selectedCOPItems.length > 0 && selectedCOPItems.map(item => item.name).join(', ')) || "Select COP"} 
                                              aria-label="Search" 
                                              aria-describedby="basic-addon2" 
                                              value={searchCOPQuery} 
                                              onChange={(e) => setsearchCOPQuery(e.target.value)} 
                                              required={selectedCOPItems.length === 0} 
                                          />
                                      </InputGroup>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                      {filteredCOPItems.map((item, index) => (
                                          <Dropdown.Item key={index} onClick={() => handleCOPItemClick(item)}>
                                              <div className='cop-item-container'>
                                                  <input 
                                                      type="checkbox" 
                                                      checked={selectedCOPItems.some(selectedItem => selectedItem.id === item.id)} 
                                                      onChange={() => handleCOPItemClick(item)}
                                                  /> 
                                                  <span className='checkbox-item-title'>
                                                    {item.name}
                                                  </span>
                                              </div>
                                          </Dropdown.Item>
                                      ))}
                                      {filteredCOPItems.length === 0 && (
                                          <Dropdown.Item disabled>No items found</Dropdown.Item>
                                      )}
                                  </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div className="post-job-body-details-dropdown" >
                                <p className="post-job-body-details-title">Watch-Keeping&nbsp;<strong>(Optional)</strong></p>
                                <Dropdown>
                                  <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="dropdown-basic">
                                    <InputGroup className="mb-3 search-rank-input">
                                        <FormControl 
                                            placeholder={(selectedWatchKeepingItems.length > 0 && selectedWatchKeepingItems.map(item => item.name).join(', ')) || "Select WatchKeeping"} 
                                            aria-label="Search" 
                                            aria-describedby="basic-addon2" 
                                            value={searchWatchKeepingQuery} 
                                            onChange={(e) => setsearchWatchKeepingQuery(e.target.value)} 
                                            required={selectedWatchKeepingItems.length === 0} 
                                        />
                                    </InputGroup>
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                      {filteredWatchKeepingItems.map((item, index) => (
                                          <Dropdown.Item key={index} onClick={() => handleWatchKeepingItemClick(item)}>
                                              <div className='cop-item-container'>
                                                  <input 
                                                      type="checkbox" 
                                                      checked={selectedWatchKeepingItems.some(selectedItem => selectedItem.id === item.id)} 
                                                      onChange={() => handleWatchKeepingItemClick(item)}
                                                  /> 
                                                    <span className='checkbox-item-title'>
                                                      {item.name}
                                                    </span>
                                              </div>
                                          </Dropdown.Item>
                                      ))}
                                      {filteredWatchKeepingItems.length === 0 && (
                                          <Dropdown.Item disabled>No items found</Dropdown.Item>
                                      )}
                                  </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="post-job-body-container-extraa-details">
                <Col>
                    <div className="post-job-body-container-extraa-details-head">
                        <p className="post-job-body-container-extraa-details-head-title">Do you want to add contact details in the post?</p>
                        <div className="post-job-body-container-extraa-details-head-operation">
                            <div className="post-job-body-container-extraa-details-body" >
                                <p className="post-job-body-container-extraa-details-para-title">Mobile Number</p>
                                <div className="radio-container">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" name="mobilenumber" id="No" className="custom-control-input" value="0" checked={selectedMobileNumberRadioOption === '0'} onChange={handleMobileNumberRadioChange} required />
                                        <label className="form-label custom-control-label" htmlFor="healthyes">No</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" name="mobilenumber" id="Yes" className="custom-control-input" value="1" checked={selectedMobileNumberRadioOption === '1'} onChange={handleMobileNumberRadioChange} required />
                                        <label className="form-label custom-control-label" htmlFor="healthyes">Yes</label>
                                    </div>
                                </div>
                            </div>
                            <div className="post-job-body-container-extraa-details-body" >
                                <p className="post-job-body-container-extraa-details-para-title">E-Mail address</p>
                                <div className="radio-container">
                                    <div className="custom-control custom-radio">
                                        <input type="radio" name="emailradio" id="No" className="custom-control-input" value="0" checked={selectedEmailRadioOption === '0'} onChange={handleEmailRadioChange} required />
                                        <label className="form-label custom-control-label" htmlFor="healthyes">No</label>
                                    </div>
                                    <div className="custom-control custom-radio">
                                        <input type="radio" name="emailradio" id="Yes" className="custom-control-input" value="1" checked={selectedEmailRadioOption === '1'} onChange={handleEmailRadioChange} required />
                                        <label className="form-label custom-control-label" htmlFor="healthyes">Yes</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row className="post-job-body-container-expiry">
                <Col>
                    <p className="post-job-body-container-expiry-head">Job Post Expiry</p>
                    <Dropdown>
                      <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="job-dropdown">
                          <InputGroup className="mb-3 search-rank-input input-group">
                              <FormControl 
                                  className='search-form-input' 
                                  placeholder={selectedJobExpireItem ? selectedJobExpireItem : "Select Job Type"} 
                                  aria-label="Search" 
                                  aria-describedby="basic-addon2" 
                                  value={searchJobExpireQuery} 
                                  onChange={(e) => setSearchJobExpireQuery(e.target.value)} 
                                  required={!selectedJobExpireItem}  
                              />
                          </InputGroup>
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                          {filteredJobExpireItems.map((job, index) => (
                              <Dropdown.Item key={index} onClick={() => handleJobExpireSelect(job)}>
                                  {job.name}
                              </Dropdown.Item>
                          ))}
                      </Dropdown.Menu>
                    </Dropdown>
                </Col>
            </Row>
            <Row className="post-job-body-container-check">
                <Col>
                    <div className="mb-3 post-job-body-container-check-box">
                      <input type="checkbox" className="checkbox-box" onChange={handleCheckboxChange} checked={isChecked} />
                      <label className="form-label post-job-body-container-check-title">
                        I agree to&nbsp;
                        <a href={apiRoutes.terms_and_conditions} target='_blank'> 
                          Terms of use
                        </a>
                        &nbsp;and&nbsp;
                        <a href={apiRoutes.privacy_policies} target='_blank'> 
                          Privacy Policy
                        </a>&nbsp;
                      </label>
                    </div>
                </Col>
            </Row>
          </div>
        </Container>
        <Container className="post-job-body-container-footer-btns">
            <Row className="post-job-body-container-footer-btns-div">
                <Col>
                    <p className="post-job-body-container-footer-btns-div-cancel">Cancel</p>
                </Col>
                <Col>
                    <Button className="btn-primary register-btn" type="button" onClick={handleShowSubmitModal} disabled={!isChecked}>
                        <span style={{ color: 'var(--bs-btn-hover-color)' }}>Submit</span>
                    </Button>
                </Col>
            </Row>
        </Container>
        <Modal show={show} onHide={handleClose} centered>
          <Modal.Body className="modal-add-rank-body">
              <div className="modal-add-rank-body-div">
                  <div className="modal-add-rank-body-item">
                      <p className="modal-add-rank-body-item-title">Job Type</p>
                      <Dropdown>
                          <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="job-dropdown">
                              <InputGroup className="mb-3 search-rank-input input-group">
                                  <FormControl 
                                      className='search-form-input' 
                                      placeholder={selectedJobItem ? selectedJobItem : "Select Job Type"} 
                                      aria-label="Search" 
                                      aria-describedby="basic-addon2" 
                                      value={searchJobQuery} 
                                      onChange={(e) => setSearchJobQuery(e.target.value)} 
                                      required={!selectedJobItem}  
                                  />
                              </InputGroup>
                          </Dropdown.Toggle>
                          <Dropdown.Menu>
                              {filteredJobItems.map((job, index) => (
                                  <Dropdown.Item key={index} onClick={() => handleJobSelect(job)}>
                                      {job.name}
                                  </Dropdown.Item>
                              ))}
                          </Dropdown.Menu>
                      </Dropdown>
                  </div>
                  <div className="modal-add-rank-body-item">
                      <p className="modal-add-rank-body-item-title">Rank</p>
                      <Dropdown>
                          <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="dropdown-basic">
                              <InputGroup className="mb-3 search-rank-input">
                                  <FormControl 
                                      placeholder={(selectedRankItem && selectedRankItem.name) || "Select Rank"} 
                                      aria-label="Search" 
                                      aria-describedby="basic-addon2" 
                                      value={searchRankQuery} 
                                      onChange={(e) => setsearchRankQuery(e.target.value)} 
                                      required={!selectedRankItem} 
                                  />
                              </InputGroup>
                          </Dropdown.Toggle>
                          <Dropdown.Menu className='dropdown-scrollable'>
                              {filteredRankItems.map((item, index) => (
                                  <Dropdown.Item key={index} onClick={() => handleRankItemClick(item)}>
                                      {item.name}
                                  </Dropdown.Item>
                              ))}
                              {filteredRankItems.length === 0 && (
                                  <Dropdown.Item disabled>No items found</Dropdown.Item>
                              )}
                          </Dropdown.Menu>
                      </Dropdown>
                  </div>
                  <div className="modal-add-rank-body-item">
                      <p className="modal-add-rank-body-item-title">Wages ($)</p>
                      <Form.Control type="text" className="form-text-input" placeholder="In USD ($)" value={wages} onChange={handleWagesChange} 
                    //   onChange={(e) => setWages(e.target.value)} 
                      />
                    {errorWages && <p className="form-error-text">{errorWages}</p>}
                  </div>
              </div>
          </Modal.Body>
          <Modal.Footer className="modal-add-rank-footer">
              <Button variant="light" onClick={handleClose} className="modal-add-rank-footer-cancel">
                  Close
              </Button>
              <Button variant="primary" className="modal-add-rank-footer-submit" onClick={handleSave}>
                  Save
              </Button>
          </Modal.Footer>
        </Modal>
        <Modal show={showSubmitModal} onHide={handleCloseSubmitModal} className='modal-post-job' centered backdrop="static">
            <Modal.Header className="modal-submit-confirm-header">
                <Modal.Title>Are you sure?</Modal.Title>
            </Modal.Header>
            <Modal.Body className="modal-submit-confirm-body">
                <p>Are you sure you want to post this job?</p>
            </Modal.Body>
            <Modal.Footer className="modal-submit-confirm-footer">
                <Button variant="light" className='modal-submit-cancel-post-job' onClick={handleCloseSubmitModal}>
                    Cancel
                </Button>
                <Button variant="primary" className='modal-submit-submit-post-job' onClick={handleShowSuccessModal}>
                    Yes
                </Button>
            </Modal.Footer>
        </Modal>
        <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered backdrop="static">
            <Modal.Header className="myjob-header">
                <LazyLoadImage src="/static/crew/assets/img/icons/double_check.svg" alt="Success" />
            </Modal.Header>
            <Modal.Body className="myjob-body">
                <div className="myjob-body-container">
                    <p className="myjob-body-para-1">🎉 Congratulations, Job posted successfully!</p>
                </div>
            </Modal.Body>
            <Modal.Footer className="myjob-footer postjob-model-footer">
                <Button variant="light" 
                href={apiRoutes.emp_home} 
                className='modal-submit-cancel-post-job' onClick={handleCloseSuccessModal}>
                    Close
                </Button>
                <Button variant="primary" href={apiRoutes.employer_my_jobs} className='modal-submit-submit-post-job'>
                    My Jobs&nbsp;<LazyLoadImage src="/static/crew/assets/img/icons/fi_arrow-right.svg" alt="Arrow Right" />
                </Button>
            </Modal.Footer>
        </Modal>
        <div style={{height: "132px"}}></div>
        <Scripts />
        <PostJobEditScript />
    </>
  );
};

export default PostJobEdit
import React, { useState, useEffect } from 'react';
import { Col, Image, Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';
import { apiRoutes } from '../../config/apiConfig';

const JobStatusColumn = ({ userSelectedData, userStatusDataList }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [timerProgress, setTimerProgress] = useState(0);
  const [countdown, setCountdown] = useState(20);
  const [intervalId, setIntervalId] = useState(null);

  useEffect(() => {
    return () => clearInterval(intervalId);
  }, [intervalId]);

  useEffect(() => {
    resetTimer();
  }, [userSelectedData, userStatusDataList]);

  useEffect(() => {
    const interval = setInterval(() => {
      setTimerProgress((prevProgress) => prevProgress + (100 / 20));
      setCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000);
  
    if (countdown === 0) {
      goToNextItem();
    }
  
    return () => clearInterval(interval);
  }, [countdown, userSelectedData, userStatusDataList, setCurrentIndex]);

  const resetTimer = () => {
    clearInterval(intervalId);
    setTimerProgress(0);
    setCountdown(20);
  };


  const goToNextItem = () => {
    if (userStatusDataList.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % userStatusDataList.length);
      setTimerProgress(0);
      setCountdown(20);
    }
  };

  const goToPrevItem = () => {
    if (userStatusDataList.length > 0) {
      setCurrentIndex((prevIndex) => (prevIndex - 1 + userStatusDataList.length) % userStatusDataList.length);
      setTimerProgress(0);
      setCountdown(20);
    }
  };

  const navigate = useNavigate();
  const handleViewJob = () => {
    navigate(`${apiRoutes.job_show}${userSelectedData.id}/${userStatusDataList[currentIndex].post_boost.id}`);
  };

  return (
      <>
        <div className="job-status-container-div">
          <div className="job-status-top-part-container">
            <div className="job-status-top-part">
              <div className="job-status-top-left">
                <Image
                  className="job-status-info-img"
                  src={userSelectedData.profilePic}
                  alt="Profile"
                />
                <div className="job-status-info-container">
                  <p className="job-status-info-name">
                    Job By: {userSelectedData.first_name} {userSelectedData.last_name}
                  </p>
                  <p className="job-status-info-company">{userSelectedData.company_name}</p>
                </div>
              </div>
              <div className="job-status-top-right">
                <button className="btn btn-primary job-status-info-button" onClick={handleViewJob}>
                  View Job
                </button>
              </div>
            </div>
          </div>

          {userStatusDataList[currentIndex] && (
            <div className="job-status-timer-container">
              <div className="job-status-timer" style={{ width: `${timerProgress}%` }}></div>
              {/* <div className="countdown">{countdown}</div> */}
            </div>
          )}

          {userStatusDataList[currentIndex] && (
            <div className="job-status-bottom-part-container">
              <div className="job-status-bottom-part">
                <div className="job-info-1-container">
                  <div className="job-info-1">
                    <p className="job-info-1-heading">Tentative Joining Date:</p>
                    <p className="job-info-1-value">{userStatusDataList[currentIndex].post_boost.tentative_joining}</p>
                  </div>
                  <div className="job-info-1">
                    <p className="job-info-1-heading">Vessel Type:</p>
                    <p className="job-info-1-value">{userStatusDataList[currentIndex].post_boost.vessel_id.name}</p>
                  </div>
                  <div className="job-info-1">
                    <p className="job-info-1-heading">GRT:</p>
                    <p className="job-info-1-value">{userStatusDataList[currentIndex].post_boost.GRT}</p>
                  </div>
                </div>
                {/* <div className="job-info-2-container">
                  <div className="job-info-2-container-left">
                    {userStatusDataList[currentIndex].post_boost.Job_Rank_wages.map((rankWage, rankIndex) => (
                      <div key={rankIndex} className="job-info-profile-wages">
                        <Image src="/static/crew/assets/img/icons/job_bag.svg" />
                        <div className="job-info-profile-wages-container">
                          <p className="job-info-name">{rankWage.rank_number.name}</p>
                          <p className="job-info-hyphen">-</p>
                          <p className="job-info-wages">{rankWage.wages} USD</p>
                        </div>
                      </div>
                    ))}
                  </div>
                </div> */}

              <div className="job-info-2-container">
                  <div className="job-info-2-container-left">
                      {userStatusDataList[currentIndex].post_boost.Job_Rank_wages.slice(0, 5).map((wage, index) => (
                      <div key={index} className="job-info-profile-wages">
                          <LazyLoadImage src="/static/crew/assets/img/icons/job_bag.svg" />
                          <div className="job-info-profile-wages-container">
                              <p className="job-info-name">{wage.rank_number.name} - {wage.wages}</p>
                          </div>
                      </div>
                      ))}
                  </div>

                  {userStatusDataList[currentIndex].post_boost.Job_Rank_wages.slice(5).length > 0 && (
                      <div className="job-info-2-container-left">
                      {userStatusDataList[currentIndex].post_boost.Job_Rank_wages.slice(5).map((wage, index) => (
                          <div key={index + 5} className="job-info-profile-wages">
                              <LazyLoadImage src="/static/crew/assets/img/icons/job_bag.svg" />
                              <div className="job-info-profile-wages-container">
                                  <p className="job-info-name">{wage.rank_number.name} - {wage.wages}</p>
                              </div>
                          </div>
                      ))}
                      </div>
                  )}

              </div>
                <div className="job-info-3-container">
                  {userStatusDataList[currentIndex].post_boost.job_coc && userStatusDataList[currentIndex].post_boost.job_coc.length > 0 && (
                      <div className="job-info-left">
                          <p className="job-info-3-left-header">COC Requirement:</p>
                          <p className="job-info-3-left-value">
                          {userStatusDataList[currentIndex].post_boost.job_coc.map((coc) => coc.coc_id.name).join(' | ')}
                          </p>
                      </div>
                  )}
                  {userStatusDataList[currentIndex].post_boost.job_cop && userStatusDataList[currentIndex].post_boost.job_cop.length > 0 && (
                      <div className="job-info-left">
                          <p className="job-info-3-left-header">COP Requirement:</p>
                          <p className="job-info-3-left-value">
                              {userStatusDataList[currentIndex].post_boost.job_cop.map((cop) => cop.cop_id.name).join(' | ')}
                          </p>
                      </div>
                  )}
              </div>

              <div className="job-info-3-container">
                  {userStatusDataList[currentIndex].post_boost.job_Watch_Keeping && userStatusDataList[currentIndex].post_boost.job_Watch_Keeping.length > 0 && (
                      <div className="job-info-left">
                          <p className="job-info-3-left-header">Watch-Keeping Requirement:</p>
                          <p className="job-info-3-left-value">
                          {userStatusDataList[currentIndex].post_boost.job_Watch_Keeping.map((wk) => wk.Watch_Keeping_id.name).join(' | ')}
                          </p>
                      </div>                
                  )}
                  <div className="job-info-left">
                      <p className="job-info-3-left-header">
                      Email: {userStatusDataList[currentIndex].post_boost.posted_by.email}
                      </p>
                      <p className="job-info-3-left-value">Mobile: {userStatusDataList[currentIndex].post_boost.posted_by.number}</p>
                  </div>
              </div>
              </div>
            </div>
          )}
        </div>

        <div className="status-control-button">
          <button className="btn btn-primary status-control-button-prev" type="button" onClick={goToPrevItem}>
            <LazyLoadImage src="/static/crew/assets/img/arrows/ArrowLeftorange.svg" />
            &nbsp; Previous
          </button>
          <button className="btn btn-primary status-control-button-next" type="button" onClick={goToNextItem}>
            Next&nbsp;&nbsp;
            <LazyLoadImage src="/static/crew/assets/img/arrows/ArrowRightwhite.svg" />
          </button>
        </div>
      </>
  );
};

export default JobStatusColumn;

import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import { Container, Row, Col, Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import { 
        getRankList, companyFollowed, companyUnFollowed, getAllFeaturedCompany,
        getAllAppliedList, getAllVerifiedJobListFilter
      } from '../components/apiService';
import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';
import MyJobCard from '../components/layouts/MyJobCards';
import CrewMyJobCard from '../components/layouts/CrewMyJobCards';
import CrewMyJobReferredCard from '../components/layouts/CrewMyJobReferredCards';

const CrewMyJobsScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />

    </>
  );
};

const CrewMyJobsScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const CrewMyJobs = () => {
  const [activeTab, setActiveTab] = useState('Applied');
  const userToken = getLocalItem("accessToken"); 
  const userData =  getLocalJsonItem("userData");

  // Get Job List Starts
  const [jobList, setJobList] = useState([]);
  const [loading, setLoading] = useState([]);
  // console.log("jobList", jobList);
  // Get Job List Ends


  // Applied Job lIst  Starts
  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const response = await getAllAppliedList(userData.id, userToken);
        setJobList(response);
      } catch (error) {
        console.error('Error fetching job list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);
  // Applied Job lIst  Ends


  // Featured Company List Starts
  const [featuredCompanies, setFeaturedCompanies] = useState([]);
  useEffect(() => {
    const fetchFeaturedCompanies = async () => {
      try {
        const data = await getAllFeaturedCompany(userToken);
        setFeaturedCompanies(data); 
      } catch (error) {
        console.error('Error fetching featured companies:', error);
      }
    };

    fetchFeaturedCompanies(); 
  }, [userToken]); 
  // Featured Company List Ends

  // Follow Toggle Starts
  const [followingStatus, setFollowingStatus] = useState({});
  React.useEffect(() => {
    const initialFollowingStatus = {};
    jobList.forEach(job => {
      if (job.job_id && job.job_id.posted_by) {
        initialFollowingStatus[job.job_id.posted_by.id] = job.job_id.posted_by.userfollow_status;
      }
    });
    setFollowingStatus(initialFollowingStatus);
  }, [jobList]);


  const handleFollowToggle = (job) => {
    const isCurrentlyFollowing = followingStatus[job.posted_by.id];
  
    if (isCurrentlyFollowing) {
      // Call unfollow API
      companyUnFollowed(job.posted_by.id, userData.id, userToken)
        .then(response => {
          setFollowingStatus((prevStatus) => ({
            ...prevStatus,
            [job.posted_by.id]: false
          }));
        })
        .catch(error => {
          console.error('Error unfollowing User:', error);
        });
    } else {
      // Call follow API
      const formData = new FormData();
      formData.append("user_id", job.posted_by.id);
      formData.append("user_followed_by", userData.id);
  
      // console.log("formData", formData);
      companyFollowed(formData, userToken)
        .then(response => {
          setFollowingStatus((prevStatus) => ({
            ...prevStatus,
            [job.posted_by.id]: true
          }));
        })
        .catch(error => {
          console.error('Error following User:', error);
        });
    }
  };
  // Follow Toggle Ends


  // Referred Jobs Jobs Starts
  const [jobListRecommended , setJobListRecommended] = useState([]);
  const [limit, setLimit] = useState(1000000000000);

  const generateQueryStringRecommended = ( crewId) => {
    const empParams = `emp_id=${crewId}`;
    const paginationParams = `limit=${limit}&page=${1}`;
    return `${empParams}&${paginationParams}`;
  };

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const queryString = generateQueryStringRecommended( userData.id, limit);
        const response = await getAllVerifiedJobListFilter(queryString, userToken);
        const results = response.results || [];
        setJobListRecommended(results);
      } catch (error) {
        console.error('Error fetching job list:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchJobs();
  }, []);


  // useEffect(() => {
  //   const fetchJobs = async () => {
  //     setLoading(true);
  //     try {
  //       const response = await getAllAppliedList(userData.id, userToken);
  //       setJobListRecommended(response);
  //     } catch (error) {
  //       console.error('Error fetching job list:', error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchJobs();
  // }, []);

  // console.log("jobListjobListjobList", jobList);
  // console.log("jobListRecommendedjobListRecommended", jobListRecommended);
  // Referred Jobs Jobs Ends

  return (
    <>
        <CrewMyJobsScriptCss />
        <NavbarCrew />

          <div className="header-text-1">
            <Container>
              <h1 className="landing-header">Jobs Posted Or Applied By You</h1>
            </Container>
            
            <Container className="job-tabs">
              <h1
                className={`tab-header ${activeTab === 'Applied' ? 'active' : ''}`}
                onClick={() => setActiveTab('Applied')}
              >
                Applied
              </h1>
              <h1
                className={`tab-header ${activeTab === 'Referred Jobs' ? 'active' : ''}`}
                onClick={() => setActiveTab('Referred Jobs')}
              >
                Referred Jobs
              </h1>
            </Container>
          </div>

          {activeTab === 'Applied' && (
            <>
                <div className="all-jobs-div">
                  <Container className="all-jobs-container">
                      <Row>
                          <Col md={6} className="all-jobs-col-1">
                              <div className="jobs-card-container">
                              {jobList && jobList.length > 0 && jobList.map((job, index) => (
    job.job_id && job.job_id.posted_by? (
      job.job_id && job.job_id.posted_by && job.job_id.posted_by.user_type_key === 2 ? (
            <CrewMyJobCard
                key={job.id || index}
                job={job.job_id}
                isFollowing={followingStatus[job.job_id.posted_by && job.job_id.posted_by.id]}
                onFollowToggle={() => handleFollowToggle(job.job_id)}
                userData={userData}
                userToken={userToken}
            />
        ) : ( job.job_id && job.job_id.posted_by &&
            <MyJobCard
                key={job.id || index}
                job={job.job_id}
                rankData={job.rank_id}
                isFollowing={followingStatus[job.job_id.posted_by && job.job_id.posted_by.id]}
                onFollowToggle={() => handleFollowToggle(job.job_id)}
                userData={userData}
                userToken={userToken}
            />
        )
    ) : null
))}

                                {jobList && jobList.length ===0 && (
                                  <div className="empty-data-container">
                                      <LazyLoadImage className="empty-data-image" src="/static/crew/assets/img/images/empty_img.svg" alt="Empty" />
                                      <h1 className="empty-tab-text">No Data Available!</h1>
                                  </div>
                                )}


                              </div>
                          </Col>
                      </Row>
                  </Container>
              </div>
            </>
          )}
          {activeTab === 'Referred Jobs' && (
            <div className="recommended-div">
              <>
                  <div className="all-jobs-div">
                    <Container className="all-jobs-container">
                        <Row>
                            <Col md={6} className="all-jobs-col-1">
                                <div className="jobs-card-container">
                                  {jobListRecommended && jobListRecommended.length === 0 && (
                                    <div className="empty-data-container">
                                        <LazyLoadImage className="empty-data-image" src="/static/crew/assets/img/images/empty_img.svg" alt="Empty" />
                                        <h1 className="empty-tab-text">No Data Available!</h1>
                                    </div>
                                  )}
                                  {jobListRecommended && jobListRecommended.length > 0 && jobListRecommended.map((job, index) => (
                                      <CrewMyJobReferredCard
                                          key={job.id || index}
                                          job={job}
                                          userData={userData}
                                          userToken={userToken}
                                      />
                                  ))}
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
              </>
            </div>
          )}

        <Footer />
        <Scripts />
        <CrewMyJobsScript />
    </>
  );
};

export default CrewMyJobs
import React from 'react';
import Lottie from 'lottie-react';
import animationData from '../../assets/img/buttonloader.json';

const LoadingAnimationCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/LoadingAnimation.css" />
    </>
  );
};

const LoadingAnimation = () => {
  return (
    <>
      <LoadingAnimationCss />
      <div>
          <div className="blur-background">
            <Lottie className='button-loader' animationData={animationData} loop={true} autoplay={true} />
          </div>
      </div>
    </>
  );
};

export default LoadingAnimation;

import React, { useState } from 'react';
import { subscriptionConfirmModal, subscriptionSuccessModal } from './subscriptionModal';
import { crewBoosting, crewHighlight } from '../apiService';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const PlanCard = ({ indexKey, planName, planId, credits, validity, opportunities, profileAsStory, supportText, pagePlanText, userToken, planType }) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleCloseConfirmModal = () => setShowConfirmModal(false);
    const handleShowConfirmModal = () => setShowConfirmModal(true);

    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);

    const handleConfirmApply = async () => {
        try {
            const crewBoostingData = { sub_id: planId };
            if (planType === "boost") {
                await crewBoosting(crewBoostingData, userToken);
            } else {
                await crewHighlight(crewBoostingData, userToken);
            }
            handleCloseConfirmModal();
            handleShowSuccessModal();
        } catch (error) {
            console.error('Error applying subscription:', error);
        }
    };

    return (
        <div className="col-md-3 subscription-plan-one">
            <div className="subscription-plan-part-one">
                <div className="subscription-plan-part-one-head">
                    <div className="subscription-head-tag-div">
                        <h1 className="subscription-plan-part-one-heading">{planName}</h1>
                        {indexKey === 1 && (
                            <div className='recommended-plan-div'>
                                <p className='recommended-plan-text'>Recommendation</p>
                            </div>
                        )}
                    </div>
                    <p className="subscription-plan-part-one-detail">{pagePlanText}{validity} days.</p>
                </div>
                <div className="subscription-plan-part-one-credit-container">
                    <p className="subscription-plan-part-one-credit-amount">{credits}</p>
                    <p className="subscription-plan-part-one-credit-text">Credits</p>
                </div>
            </div>
            <div className="subscription-plan-part-two">
                <div className="subscription-plan-part-two-list-item"><LazyLoadImage className="subscription-plan-part-two-item-img" src="/static/crew/assets/img/icons/fi_check_blue.svg" alt="Check Icon" />
                    <p className="subscription-plan-part-two-item-detail">{validity} days validity</p>
                </div>
                <div className="subscription-plan-part-two-list-item"><LazyLoadImage className="subscription-plan-part-two-item-img" src="/static/crew/assets/img/icons/fi_check_blue.svg" alt="Check Icon" />
                    <p className="subscription-plan-part-two-item-detail">{opportunities}x more opportunities</p>
                </div>
                <div className="subscription-plan-part-two-list-item"><LazyLoadImage className="subscription-plan-part-two-item-img" src="/static/crew/assets/img/icons/fi_check_blue.svg" alt="Check Icon" />
                    <p className="subscription-plan-part-two-item-detail">{profileAsStory}</p>
                </div>
                <div className="subscription-plan-part-two-list-item"><LazyLoadImage className="subscription-plan-part-two-item-img" src="/static/crew/assets/img/icons/fi_check_blue.svg" alt="Check Icon" />
                    <p className="subscription-plan-part-two-item-detail">{supportText}</p>
                </div>
            </div>
            <div className="subscription-plan-choose-plan-btn">
                <button className="btn btn-primary choose-plan-btn" type="button" onClick={handleShowConfirmModal}>
                    Choose Plan&nbsp;&nbsp;
                    <LazyLoadImage src="/static/crew/assets/img/arrows/arrow_right_blue.svg" alt="Arrow Icon" />
                </button>
            </div>

            {subscriptionConfirmModal({
                show: showConfirmModal,
                handleClose: handleCloseConfirmModal,
                handleConfirmApply: handleConfirmApply
            })}
            {subscriptionSuccessModal({
                show: showSuccessModal,
                handleClose: handleCloseSuccessModal
            })}
        </div>
    );
}

export default PlanCard;

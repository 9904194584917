import { useState, useEffect } from 'react';
import { post } from '../components/apiService';
import { apiConfigs } from '../config/apiConfig';
import { handleApiError } from '../components/handleApiError';
import { setLocalItem, getLocalItem } from '../components/tokenManager';

const LoginTokenWithFirebaseToken = () => {
  const { refreshToken, accessToken, LOGIN_API_URL } = apiConfigs;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const firebaseData = JSON.parse(getLocalItem('userFirebaseData'));
        const firebaseToken =  firebaseData.idToken;
        const userData = await post(LOGIN_API_URL, '', firebaseToken);
        setData(userData);
        setLocalItem(refreshToken, userData.data.refresh);
        setLocalItem(accessToken, userData.data.access);
      } catch (error) {
        setError(handleApiError(error));
      }
    };
  
    fetchData();
  }, []); 

  // return null; 

  return (
    <div>
      <h1>API Calls with Authorization in React</h1>
      {error && <p>Error: {error}</p>}
      {data && (
        <div>
          <p>refresh: {data.data.refresh}</p>
          <p>access ID: {data.data.access}</p>
        </div>
      )}
    </div>
  ); 
};

export default LoginTokenWithFirebaseToken;

import { useState, useEffect } from 'react';
import { post_urlencoded } from '../components/apiService';
import { firebaseTokenApiConfigs } from '../config/apiConfig';
import { handleApiError } from '../components/handleApiError';
import { setLocalItem } from '../components/tokenManager';

// const FirebaseToken = ({ email, password }) => {
const FirebaseToken = () => {
  const email = "crewingagent@designwaala.33mail.com" ;
  const password = "123456";

  const { firebaseData, get_firebase_token } = firebaseTokenApiConfigs;
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        firebaseData['email'] = email;
        firebaseData['password'] = password;
        const userData = await post_urlencoded(get_firebase_token, firebaseData);
        setData(userData);
        const userDataString = JSON.stringify(userData);
        setLocalItem('userFirebaseData', userDataString);
      } catch (error) {
        setError(handleApiError(error));
      }
    };
  
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); 
  
  // return null; 

  return (
    <div>
      <h1>API Calls with Authorization in React</h1>
      {error && <p>Error: {error}</p>}
      {data && (
        <div>
          <p>Kind: {data.kind}</p>
          <p>Local ID: {data.localId}</p>
          <p>Email: {data.email}</p>
          <p>Display Name: {data.displayName}</p>
          <p>ID Token: {data.idToken}</p>
          <p>Refresh Token: {data.refreshToken}</p>
          <p>Expires In: {data.expiresIn}</p>
        </div>
      )}
    </div>
  );
};

export default FirebaseToken;

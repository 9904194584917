import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { apiRoutes } from '../config/apiConfig';

const SignUpCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/login.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/signup.css" />
    </>
  );
};


const Signup = () => {
    localStorage.clear();
    return (
        <>
            <SignUpCss />
            <div className="row d-flex justify-content-center" style={{ background: `url('/static/crew/assets/img/images/login_bg.png') center / cover no-repeat` }}>
                <div className="col-md-6 col-xl-4 signin-login-number">
                    <div className="card" style={{ borderRadius: '0px' }}>
                        <div className="card-body text-center d-flex flex-column align-items-center" style={{ padding: '146px 66px 200px 66px', gap: '55px' }}>
                            <div className="login-logo-container"><LazyLoadImage className="login-logo" src="/static/crew/assets/img/brands/newLogo.png" alt="Logo" />
                                <div>
                                  <a href={apiRoutes.home}>
                                    <LazyLoadImage  className="left-arrow" src='/static/crew/assets/img/arrows/ArrowLeft.svg' alt="Left Arrow" />
                                  </a>
                                </div>
                            </div>
                            <div style={{ display: 'inline-grid', gap: '54px' }}>
                                <div style={{ display: 'inline-grid', gap: '30px' }}>
                                    <div style={{ display: 'inline-grid', gap: '19px' }}>
                                        <h1 className="signup-heading">Continue as</h1>
                                        <div className="signup-desc">
                                            <p>Choose one you’re looking the new jobs or new employee</p>
                                        </div>
                                    </div>
                                    <div>
                                        <form className="number-signin-form" method="post" data-bs-theme="light" style={{ gap: '22px', display: 'inline-grid' }}>
                                            <a href={apiRoutes.register_crew} className="mb-3 user-type-container">
                                                <LazyLoadImage className="user-type-img" src="/static/crew/assets/img/icons/crew2.svg" alt="Crew Icon" />
                                                <div className="user-type-info">
                                                    <h1 className="user-type-header">Crew</h1>
                                                    <p className="user-type-desc">Finding a job here never been easier than before.</p>
                                                </div>
                                            </a>
                                            <a href={apiRoutes.register_employer} className="mb-3 user-type-container">
                                                <LazyLoadImage className="user-type-img" src="/static/crew/assets/img/icons/SVG_EMPLOYER-02.svg" alt="Employer Icon" />
                                                <div className="user-type-info">
                                                    <h1 className="user-type-header">Employer</h1>
                                                    <p className="user-type-desc">Let’s recruit your great candidate faster here.</p>
                                                </div>
                                            </a>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="copyright-watermark">© Join My Ship 2024</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Signup;

import React, { useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';
import { getIsLoggedIn, getLocalJsonItem } from '../tokenManager';
import { apiRoutes } from '../../config/apiConfig';
import { handleLogout } from '../apiService';

const NavbarCreateProfile = () => {
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(true);
  const isLoggedIn = getIsLoggedIn(); 
  const userData =  getLocalJsonItem("userData");


  if (!isLoggedIn) {
    navigate(apiRoutes.home);
  }
  
  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };
  
  // const handleLogout = () => {
  //   setIsLoggedIn(false); 
  //   localStorage.clear();
  //   navigate('/'); 
  // };

  return (
    <nav className="navbar navbar-expand-md sticky-top navbar-shrink py-3 navbar-light" id="mainNav">
      <div className="container">
        { userData && userData.user_type_key === 2 ? 
          <a className="navbar-brand d-flex align-items-center" href={apiRoutes.homecrew}>
            <LazyLoadImage  className="navbar-logo" src="/static/crew/assets/img/brands/newLogo.png" alt="Logo" />
          </a>
          :
          <a className="navbar-brand d-flex align-items-center" href={apiRoutes.emp_home}>
            <LazyLoadImage  className="navbar-logo" src="/static/crew/assets/img/brands/newLogo.png" alt="Logo" />
          </a>
        } 

        <button
          className={`navbar-toggler ${collapsed ? '' : 'collapsed'}`}
          type="button"
          aria-expanded={collapsed ? 'false' : 'true'}
          onClick={toggleCollapsed}
        >
          <span className="navbar-toggler-icon"></span>
          <span className="visually-hidden">Toggle navigation</span>
        </button>
        <div className={`collapse navbar-collapse ${collapsed ? '' : 'show'}`} id="navcol-1" style={{ paddingLeft: 0, marginLeft: '157px' }}>
          <ul className="navbar-nav mx-auto">
            
            {isLoggedIn ? null : (
              <li className="nav-item">
                <a className="nav-link" href="/login">Login</a>
              </li>
            )}
          </ul>
          {isLoggedIn ? (
            <button className="btn btn-primary shadow register-btn" onClick={handleLogout}>Logout</button>
          ) : (
            <a className="btn btn-primary shadow register-btn" href="/register">Register</a>
          )}
        </div>
      </div>
    </nav>
  );
};

export default NavbarCreateProfile;

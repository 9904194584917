import React, { useState, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css'; 
import {auth} from '../components/firebase';
import 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button } from 'react-bootstrap';
import { signInWithPhoneNumber, RecaptchaVerifier, onAuthStateChanged  } from "firebase/auth";
import { apiRoutes } from '../config/apiConfig';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { useParams } from 'react-router-dom';

const SignupSubEmployeeMobileCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/JMS-mobile-number.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/login.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/signup.css" />
    </>
  );
};


const SignupSubEmployeeMobile = () => {
    const navigate = useNavigate();
    const { usercode } = useParams();
    const codeNumbersList = ["+91", "+112", "+11"];
    const [selectedCodeNumber, setSelectedCodeNumber] = useState("+91");
    const [phoneNumber, setphoneNumber] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [verifyScreen, setVerifyScreen] = useState(false);
    const [getResult, setGetResult] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleSelectCodeNumber = (phoneNumber) => {
        setSelectedCodeNumber(phoneNumber);
    };

    const handlePhoneNumberChange = (event) => {
        const inputValue = event.target.value;
        const numericValue = inputValue.replace(/\D/g, '');
        setphoneNumber(numericValue);
    };

    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const handleChange = (index, value) => {
        if (value.match(/^\d*$/)) {
            const newOtp = [...otp];
            newOtp[index] = value.slice(0, 1);
            setOtp(newOtp);
            if (index < otp.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleFocus = (index) => {
        if (index === otp.length - 1 && otp[index] !== '') {
            inputRefs.current[index].blur(); 
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        // console.log("selectedCodeNumber: ", selectedCodeNumber);
        // const fulPhoneNumber = selectedCodeNumber + phoneNumber;
        const fulPhoneNumber = "+"+ phoneNumber;
        // console.log("phoneNumber: ", fulPhoneNumber);

        const recaptcha = new RecaptchaVerifier(auth, 'recaptcha-container', {});
        signInWithPhoneNumber(auth, fulPhoneNumber, recaptcha)
        .then((confirmationResult) => {
            window.confirmationResult = confirmationResult;
            setGetResult(confirmationResult);
            toast.success("OTP sent.");
            setVerifyScreen(true);
            // console.log("Form submitted successfully!");
        }).catch((error) => {
            toast.error(error);
            // console.log("error: ", error);
        });
    };


    const handleSubmitVerify = (event) => {
        event.preventDefault();
        const enteredOtp = otp.join(''); 
        // console.log('Entered OTP:', enteredOtp);
        // console.log('getResultgetResultgetResultgetResult', getResult);
    
        getResult.confirm(enteredOtp)
            .then((result) => {
                const user = result.user;
                // console.log('User:', user);
                onAuthStateChanged(auth, (user) => {
                    if (user) {
                        const uid = user.uid;
                        // console.log('User is Set');
                        toast.success("Veification Successfull.");
                        navigate(apiRoutes.sub_employer_form + usercode);
                    } 
                    // else {
                        // console.log('User is Not Set');
                    // }
                });
            })
            .catch((error) => {
                // console.log('OTP verification error:', error.code );
                if (error.code === "auth/invalid-verification-code") {
                    // console.log('Invalid OTP.' );
                    toast.error("Invalid OTP.")
                } else if (error.code === "auth/code-expired") {
                    // console.log('OTP Expired.' );
                    toast.error("OTP Expired.")
                }
            });
    };

    
    // console.log("verifyScreenverifyScreenverifyScreenverifyScreen", verifyScreen);

    return (
        <>
            <SignupSubEmployeeMobileCss />
            <ToastContainer />
            {!verifyScreen ? 
            <div className="row d-flex justify-content-center" style={{ background: 'url("/static/crew/assets/img/images/login_bg.png") center / cover no-repeat' }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={6} xl={4} className="signin-login-number">
                            <Card style={{ borderRadius: '0px' }}>
                                <Card.Body className="text-center d-flex flex-column align-items-center" style={{ padding: '146px 66px 200px 66px', gap: '55px' }}>
                                    <div className="login-logo-container">
                                        <LazyLoadImage className="login-logo" src="/static/crew/assets/img/brands/newLogo.png" alt="New Logo" />
                                        <div>
                                            <a href={ apiRoutes.register_employer }>
                                                <LazyLoadImage  className="left-arrow" src='/static/crew/assets/img/arrows/ArrowLeft.svg' alt="Left Arrow" />
                                            </a>
                                        </div>
                                    </div>
                                    <div style={{ display: 'inline-grid', gap: '54px' }}>
                                        <div style={{ display: 'inline-grid', gap: '30px' }}>
                                            <div style={{ display: 'inline-grid', gap: '19px' }}>
                                                <h1 className="signup-heading">Sign Up</h1>
                                                <div className="signup-desc">
                                                    <p>Please sign up from your mobile number</p>
                                                </div>
                                            </div>
                                            <Form onSubmit={handleSubmit} style={{ gap: '22px', display: 'inline-grid' }}>
                                                {/* <div className="mb-3 number-label number-label-container">
                                                    <Form.Label className="form-label email-label">Mobile Number</Form.Label>
                                                    <div className="number-input-container number-enter">
                                                        <Dropdown className='dropdown-mobile'>
                                                            <Dropdown.Toggle className="btn btn-primary dropdown-toggle mobile-drop-down" style={{ background: 'none' }}>
                                                                {selectedCodeNumber}
                                                            </Dropdown.Toggle>
                                                            <Dropdown.Menu>
                                                                {codeNumbersList.map((phoneNumber, index) => (
                                                                    <Dropdown.Item key={index} onClick={() => handleSelectCodeNumber(phoneNumber)}>{phoneNumber}</Dropdown.Item>
                                                                ))}
                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                        <Form.Control className="form-control input-number" type="text" name="mobile-num" placeholder="Please enter your mobile no." value={phoneNumber} onChange={handlePhoneNumberChange} required={!phoneNumber} />
                                                    </div>
                                                </div> */}


                                                <PhoneInput
                                                    country={"in"}
                                                    enableSearch={true}
                                                    value={phoneNumber}
                                                    className="phoneinput-library"
                                                    onChange={(phoneNumber) => setphoneNumber(phoneNumber)}
                                                    />
                                                <div style={{ display: 'inline-grid', gap: '22px' }}>
                                                    <div style={{ display: 'inline-grid', gap: '28px' }}>
                                                        <div className="mb-3 policy-container">
                                                            <Form.Check type="checkbox" className="mb-3 policy-container" label="I agree to Terms of use and Privacy Policy" checked={isChecked} onChange={handleCheckboxChange} />
                                                        </div>
                                                        <div className="mb-3">
                                                            <Button className="btn btn-primary shadow d-block w-100 sign-in-button" disabled={!isChecked} type="submit">Send OTP</Button>
                                                            <div id='recaptcha-container'></div>
                                                                
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="signup-para">Already have an account? <a className="signup-link" href={ apiRoutes.login } ><span style={{ color: 'rgb(45, 45, 45)' }}> Sign In</span></a></p>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <div>
                                <p className="copyright-watermark">© Join My Ship 2024</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            :
            <div className="row d-flex justify-content-center" style={{ background: 'url("/static/crew/assets/img/images/login_bg.png") center / cover no-repeat' }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={6} xl={4} className="signin-login-number">
                            <Card style={{ borderRadius: '0px' }}>
                                <Card.Body className="text-center d-flex flex-column align-items-center" style={{ padding: '146px 66px 200px 66px', gap: '55px' }}>
                                    <div className="login-logo-container">
                                        <LazyLoadImage className="login-logo" src="/static/crew/assets/img/brands/newLogo.png" alt="New Logo" />
                                        <div>
                                            <a href={apiRoutes.register_employer_mobile}>
                                                <LazyLoadImage  className="left-arrow" src='/static/crew/assets/img/arrows/ArrowLeft.svg' alt="Left Arrow" />
                                            </a>
                                        </div>
                                    </div>
                                    <div style={{ display: 'inline-grid', gap: '54px' }}>
                                        <div style={{ display: 'inline-grid', gap: '30px' }}>
                                            <div style={{ display: 'inline-grid', gap: '19px' }}>
                                                <h1 className="signup-heading">Verification</h1>
                                                <div className="signup-desc">
                                                    <p>Please enter the OTP received on your mobile</p>
                                                </div>
                                            </div>
                                            <div>
                                                <Form onSubmit={handleSubmitVerify} style={{ gap: '22px', display: 'inline-grid' }}>
                                                    <div className="mb-3 number-label">
                                                        <div className="number-input-container verify-otp">
                                                            {otp.map((digit, index) => (
                                                                <input key={index} ref={(el) => (inputRefs.current[index] = el)} className="form-control otp-mobile-digit" type="text" maxLength={1} value={digit} onChange={(e) => handleChange(index, e.target.value)} onFocus={() => handleFocus(index)} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'inline-grid', gap: '22px' }}>
                                                        <div style={{ display: 'inline-grid', gap: '28px' }}>
                                                            <div className="mb-3"><Button className="btn btn-primary shadow d-block w-100 sign-in-button" type="submit">Verify</Button></div>
                                                        </div>
                                                        <div>
                                                            <p className="signup-para">Already have an account? <a className="signup-link" href={ apiRoutes.login }  ><span style={{ color: 'rgb(45, 45, 45)' }}>Sign In</span></a></p>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <div>
                                <p className="copyright-watermark">© Join My Ship 2024</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            }
        </>
    );
};

export default SignupSubEmployeeMobile;

import React, { useState, useEffect, useRef, Component } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css'; 
import {auth} from '../components/firebase';
import 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button, Dropdown } from 'react-bootstrap';
import { signInWithPhoneNumber, RecaptchaVerifier, onAuthStateChanged  } from "firebase/auth";
import { apiRoutes } from '../config/apiConfig';
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
import { loginFuncWithMobile, getUserByToken } from '../components/apiService';
import { getLocalItem, setLocalJsonItem } from '../components/tokenManager';

const LogInWithMobileCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/JMS-mobile-number.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/login.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/signup.css" />
    </>
  );
};


const LogInWithMobile = () => {
    localStorage.clear();
    const navigate = useNavigate();
    const [phoneNumber, setphoneNumber] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    const [verifyScreen, setVerifyScreen] = useState(false);
    const [getResult, setGetResult] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
    };

    const handleButtonClick = () => {
        window.location.href = apiRoutes.login;
      };

    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);

    const handleFocus = (index) => {
        if (index === otp.length - 1 && otp[index] !== '') {
            inputRefs.current[index].blur(); 
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        if (phoneNumber !== '') {
            const fulPhoneNumber = "+"+ phoneNumber;
            const recaptcha = new RecaptchaVerifier(auth, 'recaptcha-container', {});
            signInWithPhoneNumber(auth, fulPhoneNumber, recaptcha)
            .then((confirmationResult) => {
                window.confirmationResult = confirmationResult;
                setGetResult(confirmationResult);
                toast.success("OTP sent.");
                setVerifyScreen(true);
                // console.log("Form submitted successfully!");
            }).catch((error) => {
                toast.error(error);
                // console.log("error: ", error);
            });
        } else {
            toast.error("Please Enter the Number");
        }
    };


    const handleSubmitVerify = (event) => {
        event.preventDefault();
        const enteredOtp = otp.join(''); 
    
        if (enteredOtp.length < 6 ) {
            toast.error("Please enter the valid verification code.");
            return;
        }
        getResult.confirm(enteredOtp)
            .then((result) => {
                const user = result.user;
                onAuthStateChanged(auth, async  (user) => {
                    if (user) {
                        try {
                            const retData = await loginFuncWithMobile(auth.currentUser);
                            const userToken = getLocalItem("accessToken"); 
                            const userData = await getUserByToken(userToken);
                            setLocalJsonItem("userData", userData.data);
                            if (auth.currentUser.emailVerified) {
                                if (userData.status == 200) {
                                    setLocalJsonItem("userData", userData.data);
                                    if (userData.data.user_type_key === 2) {
                                        if (userData.data.screen_check === 0) {
                                            navigate(apiRoutes.create_profile_1);
                                        } else if (userData.data.screen_check === 1) {
                                            navigate(apiRoutes.create_profile_2);
                                        } else if (userData.data.screen_check === 2) {
                                            navigate(apiRoutes.create_profile_3);
                                        } else if (userData.data.screen_check === 3) {
                                            navigate(apiRoutes.homecrew);
                                        }
                                    } else
                                    if (userData.data.user_type_key > 2) {
                                        if (userData.data.screen_check === 0) {
                                            navigate(apiRoutes.create_profile_employer);
                                        } else {
                                            navigate(apiRoutes.emp_home);
                                        }
                                    }
                                }
                                toast.success("Login Successfull.");
                            } else {
                                if (userData.data.user_type_key === 2) {
                                    navigate( apiRoutes.register_crew_confirm_mail + `?usermail=${auth.currentUser.email}`);
                                } else if (userData.data.user_type_key > 2) {
                                    navigate(  apiRoutes.register_employer_confirm_mail +`?usermail=${auth.currentUser.email}`);
                                }
                            }
                        } catch (error) {
                            // console.log("errorerror", error);
                            toast.error("User Not Found.");
                        }
                    } 
                });
            })
            .catch((error) => {
                // console.log('OTP verification error:', error.code );
                if (error.code === "auth/invalid-verification-code") {
                    // console.log('Invalid OTP.' );
                    toast.error("Invalid OTP.")
                } else if (error.code === "auth/code-expired") {
                    // console.log('OTP Expired.' );
                    toast.error("OTP Expired.")
                }
            });
    };


    const handleChange = (index, value) => {
        value = value.replace(/\D/g, '');
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    // console.log("verifyScreenverifyScreenverifyScreenverifyScreen", verifyScreen);

    return (
        <>
            <LogInWithMobileCss />
            <ToastContainer />
            {!verifyScreen ? 
            <div className="row d-flex justify-content-center" style={{ background: 'url("/static/crew/assets/img/images/login_bg.png") center / cover no-repeat' }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={6} xl={4} className="signin-login-number">
                            <Card style={{ borderRadius: '0px' }}>
                                <Card.Body className="text-center d-flex flex-column align-items-center" style={{ padding: '146px 66px 200px 66px', gap: '55px' }}>
                                    <div className="login-logo-container">
                                        <LazyLoadImage className="login-logo" src="/static/crew/assets/img/brands/newLogo.png" alt="New Logo" />
                                        <div>
                                            <a href={ apiRoutes.register_employer }>
                                                <LazyLoadImage  className="left-arrow" src='/static/crew/assets/img/arrows/ArrowLeft.svg' alt="Left Arrow" />
                                            </a>
                                        </div>
                                    </div>
                                    <div style={{ display: 'inline-grid', gap: '54px' }}>
                                        <div style={{ display: 'inline-grid', gap: '30px' }}>
                                            <div style={{ display: 'inline-grid', gap: '19px' }}>
                                                <h1 className="signup-heading">Sign in to your account</h1>
                                                <div className="signup-desc">
                                                    <p>Please enter your mobile number and OTP to continue using our portal</p>
                                                </div>
                                            </div>
                                            <Form onSubmit={handleSubmit} style={{ gap: '22px', display: 'inline-grid' }}>
                                                <PhoneInput
                                                    country={"in"}
                                                    enableSearch={true}
                                                    value={phoneNumber}
                                                    className="phoneinput-library"
                                                    onChange={(phoneNumber) => setphoneNumber(phoneNumber)}
                                                    />
                                                <div style={{ display: 'inline-grid', gap: '22px' }}>
                                                    <div style={{ display: 'inline-grid', gap: '28px' }}>
                                                        <div className="mb-3 policy-container">
                                                            <input type="checkbox" className="checkbox-box policy-container" checked={isChecked} onChange={handleCheckboxChange} />
                                                            <label className="form-label policy-label">
                                                                I agree to&nbsp;
                                                                <a href={apiRoutes.terms_and_conditions} target='_blank'> 
                                                                    Terms of use
                                                                </a>
                                                                &nbsp;and&nbsp;
                                                                <a href={apiRoutes.privacy_policies} target='_blank'> 
                                                                    Privacy Policy
                                                                </a>&nbsp;
                                                            </label>
                                                        </div>
                                                        <div className="mb-3">
                                                            <Button className="btn btn-primary shadow d-block w-100 sign-in-button" disabled={!isChecked} type="submit">Send OTP</Button>
                                                            <div id='recaptcha-container'></div>
                                                                
                                                        </div>
                                                    </div>
                                                    <div>
                                                        <p className="signup-para">Don’t have an account? <a className="signup-link" href={ apiRoutes.register } ><span style={{ color: 'rgb(45, 45, 45)' }}> Sign Up</span></a></p>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                        <div className="sign-up-btn">
                                            <button className="btn btn-primary" onClick={handleButtonClick} type="button">
                                                <LazyLoadImage
                                                className="sign-up-btn-img"
                                                src='/static/crew/assets/img/icons/Email.svg'
                                                alt="Sign Up Button"
                                                />
                                                Sign In With Email
                                            </button>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <div>
                                <p className="copyright-watermark">© Join My Ship 2024</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            :
            <div className="row d-flex justify-content-center" style={{ background: 'url("/static/crew/assets/img/images/login_bg.png") center / cover no-repeat' }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={6} xl={4} className="signin-login-number">
                            <Card style={{ borderRadius: '0px' }}>
                                <Card.Body className="text-center d-flex flex-column align-items-center" style={{ padding: '146px 66px 200px 66px', gap: '55px' }}>
                                    <div className="login-logo-container">
                                        <LazyLoadImage className="login-logo" src="/static/crew/assets/img/brands/newLogo.png" alt="New Logo" />
                                        <div>
                                            <a href={apiRoutes.register_employer_mobile}>
                                                <LazyLoadImage  className="left-arrow" src='/static/crew/assets/img/arrows/ArrowLeft.svg' alt="Left Arrow" />
                                            </a>
                                        </div>
                                    </div>
                                    <div style={{ display: 'inline-grid', gap: '54px' }}>
                                        <div style={{ display: 'inline-grid', gap: '30px' }}>
                                            <div style={{ display: 'inline-grid', gap: '19px' }}>
                                                <h1 className="signup-heading">Verification</h1>
                                                <div className="signup-desc">
                                                    <p>Please enter the OTP received on your mobile</p>
                                                </div>
                                            </div>
                                            <div>
                                                <Form onSubmit={handleSubmitVerify} style={{ gap: '22px', display: 'inline-grid' }}>
                                                    <div className="mb-3 number-label">
                                                        <div className="number-input-container verify-otp">
                                                            {otp.map((value, index) => (
                                                                <input
                                                                    key={index}
                                                                    className="form-control otp-mobile-digit"
                                                                    type="text"
                                                                    maxLength="1"
                                                                    value={value}
                                                                    onChange={(e) => handleChange(index, e.target.value)}
                                                                    onKeyDown={(e) => handleKeyDown(index, e)}
                                                                    ref={(ref) => (inputRefs.current[index] = ref)}
                                                                />
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'inline-grid', gap: '22px' }}>
                                                        <div style={{ display: 'inline-grid', gap: '28px' }}>
                                                            <div className="mb-3"><Button className="btn btn-primary shadow d-block w-100 sign-in-button" type="submit">Verify</Button></div>
                                                        </div>
                                                        <div>
                                                            <p className="signup-para">Already have an account? <a className="signup-link" href={ apiRoutes.login }  ><span style={{ color: 'rgb(45, 45, 45)' }}>Sign In</span></a></p>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <div>
                                <p className="copyright-watermark">© Join My Ship 2024</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            }
        </>
    );
};

export default LogInWithMobile;

import React, { useEffect  } from 'react';
import { useLocation } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';
import { auth } from '../components/firebase';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { apiRoutes } from '../config/apiConfig';
import {  sendEmailVerification   } from "firebase/auth";
import { getLocalJsonItem } from '../components/tokenManager';

const EmployerConfirmEmailCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/login.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/signup.css" />
    </>
  );
};


const EmployerConfirmEmail = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const userEmail = queryParams.get('usermail');
    const userData =  getLocalJsonItem("userData");

    const resendMail = async () => {
        try {
            await sendEmailVerification(auth.currentUser);
            toast.success("Mail send");
        } catch (error) {
            toast.success("Error sending mail.");
        }
    }

    // const handleRefresh = (e) => {
    //     e.preventDefault();
    //     try {
    //         const user = auth.currentUser;
    //         if (user) {
    //             if (user.emailVerified) {
    //                 navigate('/crew/create-profile/step1'); 
    //             }
    //         }
    //     } catch (error) {
    //         console.error('Error checking email verification status:', error);
    //     }
    // };

    const handleRefresh = (e) => {
        e.preventDefault();
        // console.log("Form submitted");
        try {
            const user = auth.currentUser;
            if (user) {
                user.reload();
                if (user.emailVerified) {
                    if (userData.is_prime === 2) {
                        navigate( apiRoutes.create_profile_sub_employer ); 
                    } else {
                        navigate( apiRoutes.create_profile_employer ); 
                    }
                } else {
                    toast.error("Email not verified.");
                }
            } else {
                toast.error("User not found.");
            }
        } catch (error) {
            toast.error("Check email.");
            // console.error('Error checking email verification status:', error);
        }
    };

    useEffect(() => {
        const handleRefresh1 = async () => {
            if (window.performance && window.performance.navigation.type === window.performance.navigation.TYPE_RELOAD) {
                try {
                    const user = auth.currentUser;
                    if (user) {
                        await user.reload();
                        if (user.emailVerified) {
                            if (userData.is_prime === 2) {
                                navigate( apiRoutes.create_profile_sub_employer ); 
                            } else {
                                navigate( apiRoutes.create_profile_employer ); 
                            }
                        }
                    }
                } catch (error) {
                    toast.error("Check email.");
                    // console.error('Error checking email verification status:', error);
                }
            }
        };

        window.addEventListener('load', handleRefresh1);
        return () => {
            window.removeEventListener('load', handleRefresh1);
        };
    }, [navigate]);

    return (
        <>
            <EmployerConfirmEmailCss />
            <ToastContainer />
            <div className="row d-flex justify-content-center" style={{ background: `url('/static/crew/assets/img/images/login_bg.png') center / cover no-repeat` }}>
                <div className="col-md-6 col-xl-4 signin-login-mail">
                    <div className="card" style={{ borderRadius: '0px' }}>
                        <div className="card-body text-center d-flex flex-column align-items-center" style={{ padding: '56px 66px 207px 66px', gap: '55px' }}>
                            <div className="login-logo-container">
                                <LazyLoadImage className="signup-logo" src="/static/crew/assets/img/icons/mail_gif.gif" alt="Mail GIF" />
                                <div>
                                    <a href={ apiRoutes.register_employer }>
                                        <LazyLoadImage  className="left-arrow" src='/static/crew/assets/img/arrows/ArrowLeft.svg' alt="Left Arrow" />
                                    </a>
                                </div>
                            </div>
                            <div style={{ display: 'inline-grid', gap: '54px' }}>
                                <div style={{ display: 'inline-grid', gap: '55px' }}>
                                    <div style={{ display: 'inline-grid', gap: '19px' }}>
                                        <h1 className="login-heading">Confirm your email</h1>
                                        <div className="login-desc">
                                            <p>This action requires email verification. Please check your inbox and come back here.</p>
                                        </div>
                                    </div>
                                    <div className="email-value">
                                        <LazyLoadImage className="mail-value-logo" src="/static/crew/assets/img/icons/Mail_blue.svg" alt="Mail Blue" />
                                        <p>{userEmail}</p>
                                    </div>
                                    <div>
                                        <form onSubmit={handleRefresh} method="post" data-bs-theme="light" style={{ gap: '22px', display: 'inline-grid' }}>
                                            <div style={{ display: 'inline-grid', gap: '46px' }}>
                                                <div style={{ display: 'inline-grid', gap: '28px' }}>
                                                    <div className="mb-3 refresh-button">
                                                        <button className="btn btn-primary shadow d-block w-100 sign-in-button" type="submit">Refresh & Continue</button>
                                                        <p className="button-para">After verification click on refresh to continue</p>
                                                    </div>
                                                </div>
                                                <div>
                                                    <p className="signup-para">Email not received?&nbsp;<a className="signup-link" onClick={resendMail}><span style={{ color: 'rgb(45, 45, 45)' }}>Resend</span></a></p>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div>
                        <p className="copyright-watermark">© Join My Ship 2024</p>
                    </div>
                </div>
            </div>
        </>
    );
};

export default EmployerConfirmEmail;

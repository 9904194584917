import React, {useEffect} from 'react'
import Head from '../components/layouts/Head';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import HomeContent from '../components/layouts/HomeContent';
import { getLocalItem, setLocalJsonItem } from '../components/tokenManager';
import { getUserByToken } from '../components/apiService';

const HomeTailScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const NavbarEmployerCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
    </>
  );
};

const HomeEmployer = () => {
  return (
    <>
        <Head />
        <NavbarEmployerCss />
        <NavbarCrew />
        <HomeContent />
        <Footer />
        <Scripts />
        <HomeTailScript />
    </>
  );
};

export default HomeEmployer
import React from 'react';
import { Modal, Button } from 'react-bootstrap';
import { apiRoutes } from '../../config/apiConfig';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

export const subscriptionConfirmModal = ({ show, handleClose, handleConfirmApply }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title>Are you sure?</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          Are you sure you want to buy this pack ?
        </p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="light" onClick={handleClose}>Cancel</Button>
        <Button variant="primary" onClick={handleConfirmApply}>Yes</Button>
      </Modal.Footer>
    </Modal>
  );
};

export const subscriptionSuccessModal = ({ show, handleClose }) => {
  return (
    <Modal show={show} onHide={handleClose} centered>
      <div className="modal-content">
        <div className="modal-header myjob-header">
          <LazyLoadImage src="/static/crew/assets/img/icons/double_check.svg" alt="Double Check Icon" />
        </div>
        <div className="modal-body myjob-body">
          <p>
            🎉 Payment Successful!
          </p>
        </div>
        <div className="modal-footer myjob-footer">
          <Button className="btn btn-light myjob-btn-close" onClick={handleClose}>
            Close
          </Button>
          <Button className="btn btn-primary myjob-btn" onClick={() => window.location.href = apiRoutes.homecrew}>
            Home&nbsp;<LazyLoadImage src="/static/crew/assets/img/icons/fi_arrow-right.svg" alt="Arrow Right Icon" />
          </Button>
        </div>
      </div>
    </Modal>
  );
};



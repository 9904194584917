import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast, ToastContainer } from 'react-toastify';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { Form, Button } from 'react-bootstrap';
import { apiRoutes } from '../config/apiConfig';
import NavbarCreateProfile from '../components/layouts/NavbarCreateProfile';
import { Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import { getRankList, getCountryList, getStateList, getRankById, updateUserById, 
        convertDateString, convertDateStringToDate
        } from '../components/apiService';
import { getLocalItem, getLocalJsonItem, getIsLoggedIn } from '../components/tokenManager';
import { differenceInYears } from 'date-fns';

const CrewCreateProfileStep1Css = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Bootstrap-4-Custom-Radio.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/createprofile.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Date-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-dropdown.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Text-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Upload-File.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/JMS-Custom-Button.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/JMS-mobile-number.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />

    </>
  );
};


const CrewCreateProfileStep1 = () => {
    const navigate = useNavigate();
    const isLoggedIn = getIsLoggedIn();
    const userData =  getLocalJsonItem("userData");

    useEffect(() => {
        if (userData.screen_check > 0) {
            setAddressLine1(userData.address_line1 || '');
            setAddressLine2(userData.address_line2 || '');
            setAddressCity(userData.address_city || '');
            setPincode(userData.pincode || '');
            
            if (userData.dob) {
                setDOBSelectedDate(convertDateString(userData.dob));
            }

            if (userData.rank_id) {
                setselectedRankItem(userData.rank_id);
            }
    
            if (userData.country) {
                setSelectedCountryItem(userData.country);
            }
    
            if (userData.state) {
                setSelectedStateItem(userData.state);
            }
    
            if (userData.gender) {
                setselectedGenderOption(userData.gender.toString());
            }
    
            if (userData.marital_status) {
                const selectedMaritalItem = itemsMarital.find(item => item.id === userData.marital_status);
                if (selectedMaritalItem) {
                    setselectedMaritalItem(selectedMaritalItem);
                }
            }
    
            if (userData.promotion_applied) {
                setselectedPromotionRadioOption('1');
            }

            if (userData.profilePic !== "/media/default.png") {
                setselectedProfilePic(userData.profilePic);
                setselectedProfilePicFile(userData.profilePic);
            }

            if (userData.resume !== "/media/default.pdf") {
                const resumeFileName = userData.resume.split("/").pop();
                setSelectedResume(resumeFileName);
                setSelectedResumeFile(userData.resume);
            }
        }
    }, []);


    if (!isLoggedIn) {
        // console.log("!isLoggedIn called", isLoggedIn);
        navigate(apiRoutes.home);
    }

    // For Rank Satrts
    const [searchRankQuery, setsearchRankQuery] = useState('');
    const [selectedRankItem, setselectedRankItem] = useState(null);
    const [items, setItems] = useState([]);
    
    useEffect(() => {
      getRankList().then(result => {
        setItems(result);
      }).catch(error => {
        console.error("Error fetching rank list:", error);
      });
    }, []); 

    const handleRankItemClick = (item) => {
      setselectedRankItem(item);
      setsearchRankQuery(''); 
    };
    
    const filteredRankItems = items.filter(item =>
      item.name.toLowerCase().includes(searchRankQuery.toLowerCase())
    );

    const [promotedRank, setPromotedRank] = useState(null);

    useEffect(() => {
        const fetchPromotedRank = async () => {
            if (selectedRankItem && selectedRankItem.is_promotable) {
                try {
                    const rank = await getRankById(selectedRankItem.promoted_to);
                    setPromotedRank(rank);
                } catch (error) {
                    console.error('Error fetching promoted rank:', error);
                }
            } else {
                setPromotedRank(null);
            }
        };

        fetchPromotedRank();
    }, [selectedRankItem]);

    // For Rank Ends


    // For Country State Satrts
    const [searchCountryQuery, setSearchCountryQuery] = useState('');
    const [selectedCountryItem, setSelectedCountryItem] = useState(null);
    const [countryList, setCountryList] = useState([]);
    
    const [searchStateQuery, setSearchStateQuery] = useState('');
    const [selectedStateItem, setSelectedStateItem] = useState(null);
    const [stateList, setStateList] = useState([]);

    useEffect(() => {
        async function fetchCountryList() {
            try {
                const response = await getCountryList();
                setCountryList(response); 
            } catch (error) {
                console.error('Error fetching country list:', error);
            }
        }
        fetchCountryList();
    }, []);

    useEffect(() => {
        async function fetchStateList() {
            if (selectedCountryItem) {
                try {
                    const response = await getStateList(selectedCountryItem.id);
                    setStateList(response); 
                } catch (error) {
                    console.error('Error fetching state list:', error);
                }
            }
        }
        fetchStateList();
    }, [selectedCountryItem]);

    const handleCountryItemClick = (item) => {
        setSelectedCountryItem(item);
        setSearchCountryQuery('');
        setSelectedStateItem('');
        setSearchStateQuery('');

    };

    const handleStateItemClick = (item) => {
        setSelectedStateItem(item);
        setSearchStateQuery('');
    };

    const filteredCountryItems = countryList.filter(country =>
        country.country_name.toLowerCase().includes(searchCountryQuery.toLowerCase())
    );

    const filteredStateItems = stateList.filter(state =>
        state.state_name.toLowerCase().includes(searchStateQuery.toLowerCase())
    );
    // For Country State Ends

    // For Marital Status Satrts
    const [searchMaritalQuery, setsearchMaritalQuery] = useState('');
    const [selectedMaritalItem, setselectedMaritalItem] = useState(null);
    const itemsMarital = [{ 'id': 1, 'name': 'Single' }, { 'id': 2, 'name': 'Married' }, { 'id': 3, 'name': 'Divorced' }];

    const handleMaritalItemClick = (item) => {
        setselectedMaritalItem(item);
        setsearchMaritalQuery('');
    };

    const filteredMaritalItems = itemsMarital.filter(item =>
        item.name.toLowerCase().includes(searchMaritalQuery.toLowerCase())
    );
    // For Marital Status Ends


    // For Promotion Radio Satrts
    const [selectedPromotionRadioOption, setselectedPromotionRadioOption] = useState('');
    const handlePromotionRadioChange = (event) => {
        setselectedPromotionRadioOption(event.target.value);
    };
    // For Promotion Radio Ends


    // For Address 1 Satrts
    const [addressLine1, setAddressLine1] = useState('');
    const [errorAddress1, seterrorAddress1] = useState('');
  
    const handleAddress1Change = (e) => {
      const value = e.target.value;
      const pattern = /^[a-zA-Z0-9\-\/\., ]*$/;
      if (pattern.test(value) || value === '') {
        setAddressLine1(value);
        seterrorAddress1('');
      } else {
        seterrorAddress1('Only alphanumeric characters, "-", "/", ".", and "," are allowed');
      }
    };
    // For Address 1 Ends

    // For Address 2 Starts
    const [addressLine2, setAddressLine2] = useState('');
    const [errorAddress2, seterrorAddress2] = useState('');
  
    const handleAddress2Change = (e) => {
      const value = e.target.value;
      const pattern = /^[a-zA-Z0-9\-\/\., ]*$/;
      if (pattern.test(value) || value === '') {
        setAddressLine2(value);
        seterrorAddress2('');
      } else {
        seterrorAddress2('Only alphanumeric characters, "-", "/", ".", and "," are allowed');
      }
    };
    // For Address 2 Ends

    // For City Starts
    const [addressCity, setAddressCity] = useState('');
    const handleCityChange = (event) => {
        setAddressCity(event.target.value);
    };
    // For City Ends

    // For Pincode Satrts
    const [pincode, setPincode] = useState('');
    const [errorPincode, seterrorPincode] = useState('');
  
    const handlePincodeChange = (e) => {
      const value = e.target.value;
      const pattern = /^[0-9]*$/;
      if (pattern.test(value) || value === '') {
        setPincode(value);
        seterrorPincode('');
      } else {
        seterrorPincode('Only numbers are allowed');
      }
    };
    // For Pincode Ends


    // For Gender Satrts
    const [selectedGenderOption, setselectedGenderOption] = useState('');
    const handleGenderChange = (event) => {
        setselectedGenderOption(event.target.value);
    };
    // For Gender Ends

    // For DOB Starts
    const [DOBSelectedDate, setDOBSelectedDate] = useState(null);
    const handleDOBChange = (date) => {
        const today = new Date();
        const age = differenceInYears(today, date);
        if (age >= 18) {
          setDOBSelectedDate(date);
        } else {
          toast.error('Age must be at least 18 years');
        }
      };

    // For DOB Ends

    // For Upload Pic Starts
    const [selectedProfilePic, setselectedProfilePic] = useState(null);
    const [selectedProfilePicFile, setselectedProfilePicFile] = useState(null);
    const profilePicRef = useRef(null);

    const handleProfilePicChange = (event) => {
        const file = event.target.files[0];
        setselectedProfilePic(file.name);
        setselectedProfilePicFile(file);
    };

    const handleProfilePicButtonClick = () => {
        profilePicRef.current.click();
    };
    // For Upload Pic Ends

    // For Upload Resume Starts
    const [selectedResume, setSelectedResume] = useState(null);
    const [selectedResumeFile, setSelectedResumeFile] = useState(null);
    const resumeRef = useRef(null);


    const handleResumeChange = (event) => {
        const file = event.target.files[0];
        setSelectedResume(file.name);
        setSelectedResumeFile(file);
    };

    const handleResumeButtonClick = () => {
        resumeRef.current.click();
    };

    const handleRemoveButtonClick = () => {
        setSelectedResume(null);
        resumeRef.current.value = null; 
    };
    // For Upload Resume Ends

    const handleFormSubmit = async (e) => {
        e.preventDefault(); 

        if (!selectedProfilePicFile) {
            toast.error('Profile pic is required');
            return; 
        }
        if (!selectedResumeFile) {
            toast.error('Resume is required');
            return; 
        }

        try {
            const userToken = getLocalItem("accessToken");
            const userId = userData.id;
            
            if (userData.screen_check > 0) {
                const updatedUserData = {
                    // screen_check: 1,
                    address_line1: addressLine1,
                    address_line2: addressLine2,
                    address_city: addressCity,
                    pincode: pincode,
                    dob: convertDateStringToDate(DOBSelectedDate),
                    marital_status: selectedMaritalItem.id,
                    country: selectedCountryItem.id,
                    state: selectedStateItem.id,
                    rank_id: selectedRankItem.id,
                    gender: selectedGenderOption,
                    promotion_applied: selectedPromotionRadioOption
                };

                if (selectedProfilePicFile instanceof File) {
                    updatedUserData.profilePic = selectedProfilePicFile;
                }
                
                if (selectedResumeFile instanceof File) {
                    updatedUserData.resume = selectedResumeFile;
                }
                const updatedUser = await updateUserById(userId, updatedUserData, userToken);
                if (updatedUser.status === 200) {
                    navigate(apiRoutes.create_profile_2);
                }

            } else {
                const updatedUserData = {
                    screen_check: 1,
                    address_line1: addressLine1,
                    address_line2: addressLine2,
                    address_city: addressCity,
                    pincode: pincode,
                    dob: convertDateStringToDate(DOBSelectedDate),
                    marital_status: selectedMaritalItem.id,
                    country: selectedCountryItem.id,
                    state: selectedStateItem.id,
                    rank_id: selectedRankItem.id,
                    gender: selectedGenderOption,
                    profilePic : selectedProfilePicFile,
                    resume : selectedResumeFile,
                    promotion_applied: selectedPromotionRadioOption
                };
                const updatedUser = await updateUserById(userId, updatedUserData, userToken);
                if (updatedUser.status === 200) {
                    navigate(apiRoutes.create_profile_2);
                }
            }

            
        } catch (error) {
            console.error('Error:', error);
            toast.error(error);
        }
    };

    return (
        <>
            {isLoggedIn && (
                <>
                    <CrewCreateProfileStep1Css />
                    <NavbarCreateProfile />
                    <ToastContainer />
                    <div className="heading-container">
                        <div className="container">
                            <div style={{ padding: '22px 0px', display: 'flex', flexDirection: 'column', gap: '14px' }}>
                                {userData.is_verified ? 
                                    <h1 className="heading-main">Edit Profile</h1>
                                    :
                                    <h1 className="heading-main">Create Profile</h1>
                                }
                                <p className="heading-desc">Please fill up this form and click on save and continue to proceed with the sign up process.</p>
                            </div>
                        </div>
                    </div>

                    <Form onSubmit={handleFormSubmit} encType="multipart/form-data">


                    <div className="container" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', padding: '0px 0px 24px 0px' }}>
                        <LazyLoadImage className="stepper-img" src="/static/crew/assets/img/icons/Steper.svg" alt="Stepper" />
                        <div className="profile-image-container">
                        <Form.Group controlId="formFile" className="mb-3">
                            <div className="input-group">
                            <input 
                                id="ProfilePicfileInput" 
                                name='ProfilePicName' 
                                ref={profilePicRef} 
                                type="file" 
                                className="form-control d-none" 
                                onChange={handleProfilePicChange} 
                                accept="image/*" 
                            />
                                <button type="button" onClick={handleProfilePicButtonClick} style={{ background: 'none', border: 'none' }} >
                                    {selectedProfilePicFile && typeof selectedProfilePicFile.startsWith === 'function' && selectedProfilePicFile.startsWith("/media/") ? (
                                        <LazyLoadImage
                                        src={selectedProfilePicFile ? selectedProfilePicFile : "/static/crew/assets/img/icons/User100.svg"}
                                        alt="User"
                                        className="rounded-circle" 
                                        style={{ width: '100px', height: '100px' }} 
                                        />
                                    ) : (
                                        <LazyLoadImage
                                        src={selectedProfilePicFile ? URL.createObjectURL(selectedProfilePicFile) : "/static/crew/assets/img/icons/User100.svg"}
                                        alt="User"
                                        className="rounded-circle" 
                                        style={{ width: '100px', height: '100px' }} 
                                        />
                                    )}
                                </button>
                            </div>
                            <Form.Label className="upload-pic-text">{selectedProfilePic ? "Edit Picture" : "Upload Picture"}</Form.Label>
                        </Form.Group>
                        </div>
                    </div>
                    <div className="container form-profile">
                        <div className="row create-form-row" style={{ justifyContent: 'space-between' }}>
                            <div className="col-md-4" style={{ gap: '28px', display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <label className="form-label">Current rank</label>
                                    <Dropdown>
                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button"  variant="none" id="dropdown-basic">
                                            <InputGroup className="mb-3 search-rank-input">
                                            <FormControl placeholder={(selectedRankItem && selectedRankItem.name) || "Select Rank"} aria-label="Search" aria-describedby="basic-addon2" value={searchRankQuery} onChange={(e) => setsearchRankQuery(e.target.value)} required={!selectedRankItem} />
                                            </InputGroup>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {filteredRankItems.map((item, index) => (
                                            <Dropdown.Item key={index} onClick={() => handleRankItemClick(item)}>
                                                {item.name}
                                            </Dropdown.Item>
                                            ))}
                                            {filteredRankItems.length === 0 && (
                                            <Dropdown.Item disabled>No items found</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div>
                                    <label className="form-label">Country</label>
                                    <Dropdown>
                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="country-dropdown">
                                            <InputGroup className="mb-3 search-rank-input">
                                                <FormControl placeholder={(selectedCountryItem ? selectedCountryItem.country_name : "Select Country")} aria-label="Search" aria-describedby="basic-addon2" value={searchCountryQuery} onChange={(e) => setSearchCountryQuery(e.target.value)} required={!selectedCountryItem} />
                                            </InputGroup>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {filteredCountryItems.map((item, index) => (
                                                <Dropdown.Item key={index} onClick={() => handleCountryItemClick(item)}>
                                                    {item.country_name}
                                                </Dropdown.Item>
                                            ))}
                                            {filteredCountryItems.length === 0 && (
                                                <Dropdown.Item disabled>No items found</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div>
                                    <label className="form-label">Address line 1</label>
                                    <input type="text" className="form-text-input" placeholder="Enter address line 1" value={addressLine1} onChange={handleAddress1Change} required />
                                    {errorAddress1 && <p className="form-error-text">{errorAddress1}</p>}
                                </div>
                                <div>
                                    <label className="form-label">Date of birth</label>
                                    <Form.Group controlId="formDate" >
                                        <DatePicker  
                                            selected={DOBSelectedDate} 
                                            onChange={handleDOBChange} 
                                            dateFormat="yyyy-MM-dd" 
                                            placeholderText="dd-mm-yyyy" 
                                            className="form-control form-date-input" 
                                            showYearDropdown 
                                            scrollableYearDropdown 
                                            yearDropdownItemNumber={40} 
                                            maxDate={new Date()} 
                                            customInput={<Form.Control type="date" value={DOBSelectedDate} onChange={(e) => setDOBSelectedDate(e.target.value)} />}
                                            required={!DOBSelectedDate} 
                                        />
                                    </Form.Group>




                                </div>
                            </div>
                            <div className="col-md-4" style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', gap: '9px' }}>
                                    <label className="form-label">Gender</label>
                                    <div className="radio-container">
                                        <div className="custom-control custom-radio">
                                            <input type="radio" name="gender" id="male" className="custom-control-input" value="1" checked={selectedGenderOption === '1'} onChange={handleGenderChange} required />
                                            <label className="form-label custom-control-label" htmlFor="healthyes">Male</label>
                                        </div>
                                        <div className="custom-control custom-radio">
                                            <input type="radio" name="gender" id="female" className="custom-control-input" value="2" checked={selectedGenderOption === '2'} onChange={handleGenderChange} required />
                                            <label className="form-label custom-control-label" htmlFor="healthyes">Female</label>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <label className="form-label">State</label>
                                    <Dropdown>
                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="state-dropdown">
                                            <InputGroup className="mb-3 search-rank-input">
                                                <FormControl placeholder={(selectedStateItem ? selectedStateItem.state_name : "Select State")} aria-label="Search" aria-describedby="basic-addon2" value={searchStateQuery} onChange={(e) => setSearchStateQuery(e.target.value)} required={!selectedStateItem} />
                                            </InputGroup>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {filteredStateItems.map((item, index) => (
                                                <Dropdown.Item key={index} onClick={() => handleStateItemClick(item)}>
                                                    {item.state_name}
                                                </Dropdown.Item>
                                            ))}
                                            {filteredStateItems.length === 0 && (
                                                <Dropdown.Item disabled>No items found</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                                <div>
                                    <label className="form-label">Address line 2</label>
                                    <input type="text" className="form-text-input" placeholder="Enter address line 2" value={addressLine2} onChange={handleAddress2Change} required />
                                    {errorAddress2 && <p className="form-error-text">{errorAddress2}</p>}
                                </div>
                                <div>
                                    <label className="form-label">Marital Status</label>
                                    <Dropdown>
                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="dropdown-basic">
                                            <InputGroup className="mb-3 search-rank-input">
                                                <FormControl 
                                                placeholder={
                                                    selectedMaritalItem 
                                                    ? selectedMaritalItem.name 
                                                    : (
                                                        userData.marital_status === 1 
                                                        ? 'Single' 
                                                        : (
                                                            userData.marital_status === 2 
                                                            ? 'Married' 
                                                            : (
                                                                userData.marital_status === 3 
                                                                ? 'Divorced' 
                                                                : 'Select Marital Status'
                                                            )
                                                        )
                                                    )
                                                } 
                                                
                                                aria-label="Search" aria-describedby="basic-addon2" value={searchMaritalQuery} onChange={(e) => setsearchMaritalQuery(e.target.value)} required={!selectedMaritalItem} />
                                            </InputGroup>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu>
                                            {filteredMaritalItems.map((item, index) => (
                                                <Dropdown.Item key={index} onClick={() => handleMaritalItemClick(item)}>
                                                    {item.name}
                                                </Dropdown.Item>
                                            ))}
                                            {filteredMaritalItems.length === 0 && (
                                                <Dropdown.Item disabled>No items found</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                                {selectedRankItem && selectedRankItem.is_promotable && (
                                    <div>
                                        <label className="form-label">Are you looking for promotion?</label>
                                        <div className="radio-container">
                                            <div className="custom-control custom-radio">
                                                <input type="radio" name="promotion" id="No" className="custom-control-input" value="0" checked={selectedPromotionRadioOption === '0'} onChange={handlePromotionRadioChange} required />
                                                <label className="form-label custom-control-label" htmlFor="healthyes">No</label>
                                            </div>
                                            <div className="custom-control custom-radio">
                                                <input type="radio" name="promotion" id="Yes" className="custom-control-input" value="1" checked={selectedPromotionRadioOption === '1'} onChange={handlePromotionRadioChange} required />
                                                <label className="form-label custom-control-label" htmlFor="healthyes">Yes</label>
                                            </div>
                                        </div>
                                        {selectedPromotionRadioOption === '1' && (
                                            <div>
                                                <p className='promoted-to-text'>
                                                {selectedRankItem ? 
                                                    selectedRankItem.is_promotable ? 
                                                        promotedRank ? 
                                                            `You would be promoted to ${promotedRank.name}`
                                                            : "No Promotion"
                                                        : "No Promotion."
                                                    : "Please select Your Current Rank."
                                                }
                                                </p>
                                            </div>
                                        )}
                                    </div>
                                    )}
                                <div>
                                    <label className="form-label">City</label>
                                    <input type="text" className="form-text-input" placeholder="Enter city" value={addressCity} onChange={handleCityChange} required />
                                </div>
                                <div>
                                    <label className="form-label">Pincode</label>
                                    <input type="text" className="form-text-input" placeholder="Enter pincode" value={pincode} onChange={handlePincodeChange} required />
                                    {errorPincode && <p className="form-error-text">{errorPincode}</p>}
                                </div>
                                <div>
                                    <Form.Group controlId="formFile" className="mb-3">
                                        <div className="input-group">
                                            <input name="resumeName" ref={resumeRef}  type="file" className="form-control d-none" onChange={handleResumeChange} accept="application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document" />
                                            <button className="upload-btn" type="button" onClick={handleResumeButtonClick} style={{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px', background: 'none' }} >
                                                <LazyLoadImage src="/static/crew/assets/img/icons/upload.svg" alt="User" />
                                                Upload Resume
                                            </button>
                                        </div>
                                    </Form.Group>
                                    {selectedResume ? (
                                        <div className='resume-name'>
                                        <p style={{ margin: '0px' }}>{selectedResume}</p>
                                        <Button variant="none" className='remove-resume' onClick={handleRemoveButtonClick}>Remove</Button>
                                        </div>
                                    ) : (
                                        <p className='resume-help-text'>Supported file formats - doc, docx | Maximum file size - 2 MB</p>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container" style={{ paddingTop: '90px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                        <button className="btn btn-primary register-btn" type="submit" >
                            <br />
                            <span style={{ color: 'var(--bs-btn-hover-color)', backgroundColor: 'var(--bs-btn-hover-bg)' }}>Save & Continue</span>
                        </button>
                    </div>

                    </Form>

                    <div style={{ height: '132px' }}></div>
                </>
            )}
        </>
    );
};

export default CrewCreateProfileStep1;

import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Scripts from '../components/layouts/Scripts';
import { Container, Row, Col, Dropdown, Form, Button, Modal, InputGroup, FormControl } from 'react-bootstrap';
import { formatDateNew , 
        getFlagList, getVesselList, getRankByJobIdList, getCocListByUserType,
        getCopListByUserType, getWatchkeepingListByUserType, crewReferAJob
      } from '../components/apiService';

import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { apiRoutes } from '../config/apiConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const CrewJobReferScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/job_refer.css" /> 
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-dropdown.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Date-Input.css" />
    </>
  );
};

const CrewJobReferScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const CrewJobRefer = () => {
    const userToken = getLocalItem("accessToken"); 
    const userData =  getLocalJsonItem("userData");    

    // For Job Id Satrts
    const [searchJobQuery, setSearchJobQuery] = useState('');
    const [selectedJobItem, setSelectedJobItem] = useState(null);
    const [selectedJobId, setSelectedJobId] = useState(null);

    const jobTypes = [
        { id: 1, name: 'Deck/Navigation' },
        { id: 2, name: 'Engine' },
        { id: 3, name: 'Galley/Kitchen' },
    ];

    const filteredJobItems = jobTypes.filter(job =>
        job.name.toLowerCase().includes(searchJobQuery.toLowerCase())
    );

    const handleJobSelect = (job) => {
            setSelectedJobItem(job.name);
            setSelectedJobId(job.id);
            setSearchJobQuery(''); 
    };
    // For Job Id Ends


    // For Rank Satrts
    const [searchRankQuery, setsearchRankQuery] = useState('');
    const [selectedRankItem, setselectedRankItem] = useState(null);
    const [items, setItems] = useState([]);

    useEffect(() => {
        if (selectedJobItem !== null) {
            getRankByJobIdList(selectedJobId, userToken)
                .then(result => {
                    setItems(result);
                })
                .catch(error => {
                    console.error("Error fetching rank list:", error);
                });
        }
    }, [selectedJobItem]);
    
    const handleRankItemClick = (item) => {
        setselectedRankItem(item);
        setsearchRankQuery(item.name); 
    };

    const filteredRankItems = items.filter(item =>
        item.name.toLowerCase().includes(searchRankQuery.toLowerCase())
    );
    // For Rank Ends

    // For DOB Starts
    const [DOBSelectedDate, setDOBSelectedDate] = useState(null);
    const maxDate = new Date();
    maxDate.setMonth(maxDate.getMonth() + 2, 0); // Move to the last date of next month

    const handleDOBChange = (date) => {
        setDOBSelectedDate(date);
    };
    // For DOB Ends

    // Flag Dropdown Starts
    const [searchFlagQuery, setSearchFlagQuery] = useState('');
    const [selectedFlagItem, setSelectedFlagItem] = useState(null);
    const [flagItems, setFlagItems] = useState([]);

    useEffect(() => {
        getFlagList().then(result => {
            setFlagItems(result);
        }).catch(error => {
            console.error("Error fetching Flag list:", error);
        });
    }, []); 

    const handleFlagItemClick = (item) => {
        setSelectedFlagItem(item);
        setSearchFlagQuery(''); 
    };

    const filteredFlagItems = flagItems.filter(item =>
        item.country_name.toLowerCase().includes(searchFlagQuery.toLowerCase())
    );
    // Flag Dropdown Ends 

    // Vessel Dropdown Starts
    const [vesselItems, setVesselItems] = useState([]);
    const [selectedVesselItem, setSelectedVesselItem] = useState(null);
    const [selectedVesselItemId, setSelectedVesselItemId] = useState(null);
    const [searchVesselQuery, setSearchVesselQuery] = useState('');

    useEffect(() => {
    getVesselList().then(result => {
        setVesselItems(result);
    }).catch(error => {
        console.error("Error fetching vessel list:", error);
    });
    }, []);

    const handleVesselSelect = (selectedItem) => {
        setSelectedVesselItem(selectedItem.name);
        setSelectedVesselItemId(selectedItem.id);
        
        setSearchVesselQuery('');
    };

    const filteredVesselItems = Object.keys(vesselItems).reduce((filteredItems, parentKey) => {
        const filteredChildren = vesselItems[parentKey].filter(childItem => {
        if (typeof childItem.name === 'string') {
            return childItem.name.toLowerCase().includes(searchVesselQuery.toLowerCase());
        } else if (typeof childItem.name === 'object' && childItem.name.name) {
            return childItem.name.name.toLowerCase().includes(searchVesselQuery.toLowerCase());
        }
        return false;
        });

        if (filteredChildren.length > 0) {
        filteredItems[parentKey] = filteredChildren;
        }

        return filteredItems;
    }, {});

    // Vessel Dropdown Ends

    // Joining Ports Starts
    const [joiningPort, setJoiningPort] = useState('');
    const [error, setError] = useState('');
  
    const handleInputChange = (e) => {
      const value = e.target.value;
      const regex = /^[a-zA-Z0-9]*$/; 
  
      if (regex.test(value)) {
        setJoiningPort(value);
        setError('');
      } else {
        setError('Only alphanumeric characters are allowed.');
      }
    };
    // Joining Ports Ends

    // Vessel IMO Number Starts
    const [imoNumber, setIMONumber] = useState('');
    const [errorimoNumber, setErrorimoNumber] = useState('');

    const handleInputChangeimoNumber = (e) => {
        const value = e.target.value;
        const regex = /^[0-9]*$/; // Numeric pattern

        if (regex.test(value)) {
        setIMONumber(value);
        setErrorimoNumber('');
        } else {
            setErrorimoNumber('Only numeric characters are allowed.');
        }
    };
    // Vessel IMO Number Ends

    // Job Expiry Starts
    const jobExpireInDays = [
        { id: 1, name: '1 Days', value: 1 },
        { id: 2, name: '7 Days', value: 7 },
        { id: 2, name: '15 Days', value: 15 },
        { id: 3, name: '30 Days', value: 30 }
    ];
    const [searchJobExpireQuery, setSearchJobExpireQuery] = useState('');
    const [selectedJobExpireItem, setSelectedJobExpireItem] = useState('7 Days');
    const [selectedJobExpire, setSelectedJobExpire] = useState(7);

    const filteredJobExpireItems = jobExpireInDays.filter(job =>
        job.name.toLowerCase().includes(searchJobExpireQuery.toLowerCase())
    );

    const handleJobExpireSelect = (job) => {
        setSelectedJobExpireItem(job.name);
        setSelectedJobExpire(job.value);
        setSearchJobExpireQuery(''); 
    };
    // Job Expiry Ends

    // COP Starts

    const [searchCOPQuery, setsearchCOPQuery] = useState('');
    const [selectedCOPItems, setselectedCOPItems] = useState([]);
    const [itemsCOP, setItemsCOP] = useState([]);

    useEffect(() => {
        getCopListByUserType(3).then(result => {
            setItemsCOP(result);
        }).catch(error => {
            console.error("Error fetching COP list:", error);
        });
    }, []); 
    
    const handleCOPItemClick = (item) => {
        setselectedCOPItems(prevSelectedItems => {
            if (prevSelectedItems.some(selectedItem => selectedItem.id === item.id)) {
                return prevSelectedItems.filter(selectedItem => selectedItem.id !== item.id);
            } else {
                return [...prevSelectedItems, item];
            }
        });
        setsearchCOPQuery(''); 
    };
    
    const filteredCOPItems = itemsCOP.filter(item =>
        item.name.toLowerCase().includes(searchCOPQuery.toLowerCase())
    );

    // COP Ends

    // COC Starts

    const [searchCOCQuery, setsearchCOCQuery] = useState('');
    const [selectedCOCItems, setselectedCOCItems] = useState([]);
    const [itemsCOC, setItemsCOC] = useState([]);

    useEffect(() => {
        getCocListByUserType(3).then(result => {
            setItemsCOC(result);
        }).catch(error => {
            console.error("Error fetching COC list:", error);
        });
    }, []); 

    const handleCOCItemClick = (item) => {
        setselectedCOCItems(prevSelectedItems => {
            if (prevSelectedItems.some(selectedItem => selectedItem.id === item.id)) {
                return prevSelectedItems.filter(selectedItem => selectedItem.id !== item.id);
            } else {
                return [...prevSelectedItems, item];
            }
        });
        setsearchCOCQuery(''); // Clear search query after selection
    };

    const filteredCOCItems = itemsCOC.filter(item =>
        item.name.toLowerCase().includes(searchCOCQuery.toLowerCase())
    );

    // COC Ends
    
    // WatchKeeping Starts

    const [searchWatchKeepingQuery, setsearchWatchKeepingQuery] = useState('');
    const [selectedWatchKeepingItems, setselectedWatchKeepingItems] = useState([]);
    const [itemsWatchKeeping, setItemsWatchKeeping] = useState([]);

    useEffect(() => {
        getWatchkeepingListByUserType(3).then(result => {
            setItemsWatchKeeping(result);
        }).catch(error => {
            console.error("Error fetching WatchKeeping list:", error);
        });
    }, []); 

    const handleWatchKeepingItemClick = (item) => {
        setselectedWatchKeepingItems(prevSelectedItems => {
            if (prevSelectedItems.some(selectedItem => selectedItem.id === item.id)) {
                return prevSelectedItems.filter(selectedItem => selectedItem.id !== item.id);
            } else {
                return [...prevSelectedItems, item];
            }
        });
        setsearchWatchKeepingQuery(''); 
    };

    const filteredWatchKeepingItems = itemsWatchKeeping.filter(item =>
        item.name.toLowerCase().includes(searchWatchKeepingQuery.toLowerCase())
    );

    // WatchKeeping Ends

    const [showSubmitModal, setShowSubmitModal] = React.useState(false);
    const [showSuccessModal, setShowSuccessModal] = React.useState(false);

    // const handleShowSubmitModal = () => setShowSubmitModal(true);

    const handleShowSubmitModal = () => {
        if (
          selectedVesselItemId &&
          DOBSelectedDate &&
          selectedJobExpire &&
          selectedFlagItem &&
          imoNumber &&
          joiningPort &&
          selectedRankItem
        ) {
          setShowSubmitModal(true);
        } else {
            toast.error("Please fill all required fields.");
        }
      };

    const handleCloseSubmitModal = () => setShowSubmitModal(false);

    const handleShowSuccessModal = async () => {
        const selectedCOCItemsidList = selectedCOCItems.map(item => item.id);
        const selectedCOPItemsidList = selectedCOPItems.map(item => item.id);
        const selectedWatchKeepingItemsidList = selectedWatchKeepingItems.map(item => item.id);

        const updatedUserData = {
            posted_by: userData.id,
            vessel_id: selectedVesselItemId,
            tentative_joining: formatDateNew(DOBSelectedDate),
            expiry_in_day: selectedJobExpire,
            flag_key: selectedFlagItem.id,
            vessel_imo: imoNumber,
            joining_port: joiningPort,
            rank_number: selectedRankItem.id,
            coc_id: selectedCOCItemsidList,
            cop_id: selectedCOPItemsidList,
            Watch_Keeping_id: selectedWatchKeepingItemsidList
        };
        
        await crewReferAJob(updatedUserData, userToken);
        setShowSubmitModal(false);
        setShowSuccessModal(true);
    };
    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);
    } 


  // crew boosted Data Ends

  return (
    <>
        <CrewJobReferScriptCss />
        <ToastContainer />
        <NavbarCrew />
        <div className="heading-container">
            <Container>
                <div style={{ padding: '22px 0px', display: 'flex', flexDirection: 'column', gap: '14px' }}>
                    <h1 className="heading-main">Refer a Job</h1>
                    <p className="heading-desc">Refer a job and receive free credits upon approval. *T&amp;C apply.</p>
                </div>
            </Container>
        </div>
        <div></div>
        <Container style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'space-between', padding: '0px 0px 24px 0px' }}>
            <div className="basic-detail-container" style={{ display: 'flex', flexDirection: 'row', width: 'inherit' }}>
            </div>
        </Container>
        <div style={{ display: 'flex', flexDirection: 'column', gap: '48px' }}>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                <Container>
                    <div className="basic-detail-container" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <LazyLoadImage className="basic-detail-img" src="/static/crew/assets/img/icons/basicdetails.svg" alt="Basic Details" />
                        <p className="basicdetail-title">Vessel Details</p>
                    </div>
                </Container>
                <Container className="form-profile">
                    <Row className="create-form-row" style={{ justifyContent: 'space-between' }}>
                        <Col md={4} style={{ gap: '28px', display: 'flex', flexDirection: 'column' }}>
                                <div>
                                    <label className="form-label">Tentative Joining Date</label>
                                    <Form.Group controlId="formDate">
                                        <DatePicker
                                            selected={DOBSelectedDate}
                                            onChange={handleDOBChange}
                                            dateFormat="yyyy-MM-dd"
                                            placeholderText="dd-mm-yyyy"
                                            className="form-control form-date-input"
                                            showYearDropdown
                                            scrollableYearDropdown
                                            yearDropdownItemNumber={40}
                                            customInput={<Form.Control type="date" value={DOBSelectedDate} onChange={(e) => setDOBSelectedDate(e.target.value)} />}
                                            minDate={new Date()}
                                            maxDate={maxDate}
                                        />
                                    </Form.Group>
                                </div>
                                <div>
                                    <label className="form-label">Select Flag</label>
                                    <Dropdown>
                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="dropdown-basic">
                                            <InputGroup className="mb-3 search-rank-input">
                                            <FormControl 
                                                placeholder={(selectedFlagItem && selectedFlagItem.country_name) ? selectedFlagItem.country_name : 
                                                    "Select Flag"} 
                                                aria-label="Search" aria-describedby="basic-addon2" value={searchFlagQuery} onChange={(e) => setSearchFlagQuery(e.target.value)} required={!selectedFlagItem} />
                                            </InputGroup>
                                        </Dropdown.Toggle>
                                        <Dropdown.Menu className='dropdown-scrollable'>
                                            {filteredFlagItems.map((item, index) => (
                                            <Dropdown.Item key={index} onClick={() => handleFlagItemClick(item)}>
                                                {item.flag_code} <br></br>
                                                <span style={{ color: 'gray' }}>
                                                    {item.country_name}
                                                </span>
                                            </Dropdown.Item>
                                            ))}
                                            {filteredFlagItems.length === 0 && (
                                            <Dropdown.Item disabled>No items found</Dropdown.Item>
                                            )}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </div>
                        </Col>
                        <Col md={4} style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                            <div>
                                <label className="form-label">Vessel Type</label>
                                <Dropdown>
                                    <Dropdown.Toggle  className="btn dropdown-toggle dropdown-form-button" variant="none" id="vessel-dropdown">
                                        <InputGroup className="mb-3 search-rank-input">
                                            <FormControl className='search-form-input' 
                                            placeholder={(selectedVesselItem ? selectedVesselItem : 
                                                "Select Vessel")}     
                                            aria-label="Search" aria-describedby="basic-addon2" value={searchVesselQuery} onChange={(e) => setSearchVesselQuery(e.target.value)} required={!selectedVesselItem}  />
                                        </InputGroup>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dropdown-scrollable'>
                                        {Object.keys(filteredVesselItems).map((parentKey, parentIndex) => (
                                            <React.Fragment key={parentIndex}>
                                                <Dropdown.Item disabled className='vessle-type-parent'>
                                                    <b>{parentKey}</b>
                                                </Dropdown.Item>
                                                {filteredVesselItems[parentKey].map((childItem, childIndex) => (
                                                    <Dropdown.Item key={childIndex} onClick={() => handleVesselSelect(childItem)}>
                                                    {childItem.name}
                                                    </Dropdown.Item>
                                                ))}
                                            </React.Fragment>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <Form.Group>
                                <Form.Label className="form-label">Joining Port</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-text-input"
                                    placeholder="Enter Joining Port"
                                    value={joiningPort}
                                    onChange={handleInputChange}
                                    isInvalid={!!error}
                                />
                                <Form.Control.Feedback type="invalid">{error}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                        <Col md={4} style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                            <Form.Group>
                                <Form.Label className="form-label">Vessel IMO Number</Form.Label>
                                <Form.Control
                                    type="text"
                                    className="form-text-input"
                                    placeholder="Enter IMO No."
                                    value={imoNumber}
                                    onChange={handleInputChangeimoNumber}
                                    isInvalid={!!errorimoNumber}
                                />
                                <Form.Control.Feedback type="invalid">{errorimoNumber}</Form.Control.Feedback>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                <Container>
                    <div className="basic-detail-container" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                        <LazyLoadImage className="basic-detail-img" src="/static/crew/assets/img/icons/Radar2.svg" alt="Other Details" />
                        <p className="basicdetail-title">Other Details</p>
                    </div>
                </Container>
                <Container className="form-profile">
                    <Row className="create-form-row" style={{ justifyContent: 'space-between' }}>
                        <Col md={4} style={{ gap: '28px', display: 'flex', flexDirection: 'column' }}>
                            <div>
                                <label className="form-label">Job Type</label>
                                <Dropdown>
                                    <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="job-dropdown">
                                        <InputGroup className="mb-3 search-rank-input input-group">
                                            <FormControl 
                                                className='search-form-input' 
                                                placeholder={selectedJobItem ? selectedJobItem : "Select Job Type"} 
                                                aria-label="Search" 
                                                aria-describedby="basic-addon2" 
                                                value={searchJobQuery} 
                                                onChange={(e) => setSearchJobQuery(e.target.value)} 
                                                required={!selectedJobItem}  
                                            />
                                        </InputGroup>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {filteredJobItems.map((job, index) => (
                                            <Dropdown.Item key={index} onClick={() => handleJobSelect(job)}>
                                                {job.name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div>
                                <label className="form-label">COP</label>
                                <Dropdown>
                                    <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="dropdown-basic">
                                        <InputGroup className="mb-3 search-rank-input">
                                            <FormControl 
                                                placeholder={(selectedCOPItems.length > 0 && selectedCOPItems.map(item => item.name).join(', ')) || "Select COP"} 
                                                aria-label="Search" 
                                                aria-describedby="basic-addon2" 
                                                value={searchCOPQuery} 
                                                onChange={(e) => setsearchCOPQuery(e.target.value)} 
                                                required={selectedCOPItems.length === 0} 
                                            />
                                        </InputGroup>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {filteredCOPItems.map((item, index) => (
                                            <Dropdown.Item key={index} onClick={() => handleCOPItemClick(item)}>
                                                <div className='cop-item-container'>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={selectedCOPItems.some(selectedItem => selectedItem.id === item.id)} 
                                                        onChange={() => handleCOPItemClick(item)}
                                                    /> 
                                                    {item.name}
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                        {filteredCOPItems.length === 0 && (
                                            <Dropdown.Item disabled>No items found</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col md={4} style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                            <div>
                                <label className="form-label">Rank</label>
                                <Dropdown>
                                    <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button"  variant="none" id="dropdown-basic">
                                        <InputGroup className="mb-3 search-rank-input">
                                        <FormControl 
                                        placeholder={(selectedRankItem && selectedRankItem.name) || "Select Rank"} 
                                        aria-label="Search" aria-describedby="basic-addon2" value={searchRankQuery} onChange={(e) => setsearchRankQuery(e.target.value)} required={!selectedRankItem} />
                                        </InputGroup>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu className='dropdown-scrollable'>
                                        {filteredRankItems.map((item, index) => (
                                        <Dropdown.Item key={index} onClick={() => handleRankItemClick(item)}>
                                            {item.name}
                                        </Dropdown.Item>
                                        ))}
                                        {filteredRankItems.length === 0 && (
                                        <Dropdown.Item disabled>No items found</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div>
                                <label className="form-label">WatchKeeping</label>
                                <Dropdown>
                                    <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="dropdown-basic">
                                        <InputGroup className="mb-3 search-rank-input">
                                            <FormControl 
                                                placeholder={(selectedWatchKeepingItems.length > 0 && selectedWatchKeepingItems.map(item => item.name).join(', ')) || "Select WatchKeeping"} 
                                                aria-label="Search" 
                                                aria-describedby="basic-addon2" 
                                                value={searchWatchKeepingQuery} 
                                                onChange={(e) => setsearchWatchKeepingQuery(e.target.value)} 
                                                required={selectedWatchKeepingItems.length === 0} 
                                            />
                                        </InputGroup>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {filteredWatchKeepingItems.map((item, index) => (
                                            <Dropdown.Item key={index} onClick={() => handleWatchKeepingItemClick(item)}>
                                                <div className='cop-item-container'>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={selectedWatchKeepingItems.some(selectedItem => selectedItem.id === item.id)} 
                                                        onChange={() => handleWatchKeepingItemClick(item)}
                                                    /> 
                                                    {item.name}
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                        {filteredWatchKeepingItems.length === 0 && (
                                            <Dropdown.Item disabled>No items found</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                        <Col md={4} style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                            <div>
                                <label className="form-label">COC</label>
                                <Dropdown>
                                    <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="dropdown-basic">
                                        <InputGroup className="mb-3 search-rank-input">
                                            <FormControl 
                                                placeholder={(selectedCOCItems.length > 0 && selectedCOCItems.map(item => item.name).join(', ')) || "Select COC"} 
                                                aria-label="Search" 
                                                aria-describedby="basic-addon2" 
                                                value={searchCOCQuery} 
                                                onChange={(e) => setsearchCOCQuery(e.target.value)} 
                                                required={selectedCOCItems.length === 0} 
                                            />
                                        </InputGroup>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {filteredCOCItems.map((item, index) => (
                                            <Dropdown.Item key={index} onClick={() => handleCOCItemClick(item)}>
                                                <div className='cop-item-container'>
                                                    <input 
                                                        type="checkbox" 
                                                        checked={selectedCOCItems.some(selectedItem => selectedItem.id === item.id)} 
                                                        onChange={() => handleCOCItemClick(item)}
                                                    /> 
                                                    {item.name}
                                                </div>
                                            </Dropdown.Item>
                                        ))}
                                        {filteredCOCItems.length === 0 && (
                                            <Dropdown.Item disabled>No items found</Dropdown.Item>
                                        )}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                            <div>
                                <label className="form-label">Job Expiry</label>
                                <Dropdown>
                                    <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="job-dropdown">
                                        <InputGroup className="mb-3 search-rank-input input-group">
                                            <FormControl 
                                                className='search-form-input' 
                                                placeholder={selectedJobExpireItem ? selectedJobExpireItem : "Select Job Type"} 
                                                aria-label="Search" 
                                                aria-describedby="basic-addon2" 
                                                value={searchJobExpireQuery} 
                                                onChange={(e) => setSearchJobExpireQuery(e.target.value)} 
                                                required={!selectedJobExpireItem}  
                                            />
                                        </InputGroup>
                                    </Dropdown.Toggle>
                                    <Dropdown.Menu>
                                        {filteredJobExpireItems.map((job, index) => (
                                            <Dropdown.Item key={index} onClick={() => handleJobExpireSelect(job)}>
                                                {job.name}
                                            </Dropdown.Item>
                                        ))}
                                    </Dropdown.Menu>
                                </Dropdown>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </div>
        <Container style={{ paddingTop: '90px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <Button className="register-btn" variant="primary" onClick={handleShowSubmitModal}>
                    Submit For Review
                </Button>
            </Container>

            <Modal show={showSubmitModal} onHide={handleCloseSubmitModal} centered backdrop="static">
                <Modal.Header className="modal-submit-confirm-header">
                    <Modal.Title>Are you sure?</Modal.Title>
                </Modal.Header>
                <Modal.Body className="modal-submit-confirm-body">
                    <p>Are you sure you want to submit this job for review?</p>
                </Modal.Body>
                <Modal.Footer className="modal-submit-confirm-footer">
                    <Button variant="light" onClick={handleCloseSubmitModal}>
                        Cancel
                    </Button>
                    <Button variant="primary" onClick={handleShowSuccessModal}>
                        Yes
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSuccessModal} onHide={handleCloseSuccessModal} centered backdrop="static">
                <Modal.Header className="myjob-header">
                    <LazyLoadImage src="/static/crew/assets/img/icons/double_check.svg" alt="Success" />
                </Modal.Header>
                <Modal.Body className="myjob-body">
                    <div className="myjob-body-container">
                        <p className="myjob-body-para-1">🎉 Successfully referred a job!</p>
                        <p className="myjob-body-para-2">
                            Note: Your job is under review by the admins and will take approximately 24 hours to be approved.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className="myjob-footer">
                    <Button variant="light" href={apiRoutes.homecrew} onClick={handleCloseSuccessModal}>
                        Close
                    </Button>
                    <Button variant="primary" href={apiRoutes.crew_my_jobs}>
                        My Jobs&nbsp;<LazyLoadImage src="/static/crew/assets/img/icons/fi_arrow-right.svg" alt="Arrow Right" />
                    </Button>
                </Modal.Footer>
            </Modal>
            <div style={{ height: '132px' }}></div>
        <Scripts />
        <CrewJobReferScript />
    </>
  );
};

export default CrewJobRefer
import React, { useEffect, useState } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Head from '../components/layouts/Head';
import NavbarHome from '../components/layouts/NavbarHome';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import NavbarCrew from '../components/layouts/NavbarCrew';
import { getIsLoggedIn } from '../components/tokenManager';
import { Modal, Button } from 'react-bootstrap';
import { checkReferCode, applyReferCode } from '../components/apiService';
import { getLocalJsonItem, getLocalItem } from '../components/tokenManager';

const CrewHelpSupportTailScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const CrewHelpSupportCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/refer_earn.css" />
    </>
  );
};

const CrewHelpSupport = () => {
  const isLoggedIn = getIsLoggedIn(); 
  const userData =  getLocalJsonItem("userData");
  const userToken = getLocalItem("accessToken"); 

  return (
    <>
        <Head />
        <ToastContainer />
        {!isLoggedIn ?
          <>
            <CrewHelpSupportCss />
            <NavbarHome />
          </> 
          :
          <>
            <CrewHelpSupportCss />
            <NavbarCrew />
          </>
        }
        <div className="refer-earn-header-top">
            <div className="container refer-earn-container">
                <div className="refer-earn-container-div">
                    <h1 className="refer-earn-container-div-head">Help And Support</h1>
                    <p className="refer-earn-container-detail">Need assistance? Our Help and Support team is here to guide you through any questions or issues you may have.</p>
                </div>
            </div>
        </div>
        <div className="help-support-div">
            <div className="container help-support-div-container">
                <div className="row">
                    <div className="col-md-6 help-support-div-container-left">
                        <div className="help-support-left-col-div">
                            <div className="help-support-left-col-top-para">
                                <p className="help-support-left-col-top-para-text">At Join My Ship, we are committed to providing you the best customer service experience. We also look forward to your suggestions and feedback.</p>
                            </div>
                            <div className="help-support-left-col-bot-div">
                                <div className="help-support-left-contact-list">
                                    <div className="help-support-left-contact-list-email">
                                        <LazyLoadImage className="help-support-left-contact-list-email-img" src="/static/crew/assets/img/icons/envelop_blue.svg" alt="Email Icon" />
                                        <p className="help-support-left-contact-list-email-text">Email</p>
                                    </div>
                                    <p className="help-support-left-contact-list-link">support@joinmyship.com</p>
                                </div>
                                <div className="help-support-left-contact-list">
                                    <div className="help-support-left-contact-list-email">
                                        <LazyLoadImage className="help-support-left-contact-list-email-img" src="/static/crew/assets/img/social-media-icons/Linkedin.svg" alt="LinkedIn Icon" />
                                        <p className="help-support-left-contact-list-email-text">LinkedIn</p>
                                    </div>
                                    <p className="help-support-left-contact-list-link">
                                        <a href="https://www.linkedin.com/company/joinmyship" target="_blank" rel="noopener noreferrer" className="help-support-left-contact-list-link">https://www.linkedin.com/company/joinmyship</a>
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-6 help-support-div-container-right">
                        <div className="help-support-div-container-right-div">
                            <LazyLoadImage className="help-support-right-div-img" src="/static/crew/assets/img/images/help_support_image.svg" alt="Help Support Image" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div style={{paddingBottom: '122px', background:'#F5F5F5'}}></div>
        <Footer />
        <Scripts />
        <CrewHelpSupportTailScript />
    </>
  );
};

export default CrewHelpSupport
import axios from 'axios';
import { apiConfigs, apiRoutes, firebaseTokenApiConfigs } from '../config/apiConfig';
import { getLocalItem, setLocalItem, setIsLoggedIn, setLocalJsonItem, getLocalJsonItem } from './tokenManager';
import qs from 'qs';
import { handleApiError } from './handleApiError';
import { auth } from './firebase';
import {  signInWithEmailAndPassword   } from 'firebase/auth';
import { toast, ToastContainer } from 'react-toastify';

const api = axios.create({
  // baseURL: apiConfigs.HOST_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
});

const withAuthCheck = (fn) => async (...args) => {
  const userData =  getLocalJsonItem("userData");

  if (!userData || userData.is_verified === 0) {
    toast.error('User not verified. Please verify your account to proceed.');
    throw new Error('User not verified.');
  }
  return fn(...args);
};


const apiNew = axios.create();

const setAuthHeader = async  (accessToken, isFileData=false, csrfToken=null) => {
  // console.log("setAuthHeader is called", isAccessTokenExpired());
  if (isAccessTokenExpired()) {
    // console.log("isAccessTokenExpired");
    const refreshTokenData = getLocalItem("refreshToken");
    // console.log("isAccessTokenExpired 11111111111111111", refreshTokenData);
    accessToken = await refreshAccessToken(refreshTokenData); 
    // console.log("isAccessTokenExpired 2222222222222222222", accessToken);
  }

  if (accessToken) {
    // console.log("accessToken 1111111111111111111111111", accessToken);
    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
    // console.log("accessToken 2222222222222222222222222", accessToken);
  } 

  if (isFileData) {
    api.defaults.headers.common['Content-Type'] = 'multipart/form-data';
    api.defaults.headers['Content-Type'] = 'multipart/form-data';
  } 

  if (csrfToken) {
      api.defaults.headers.common['X-CSRFToken'] = csrfToken;
  }
};

const setFirebaseAuthHeader = async (accessToken) => {
  // console.log("setFirebaseAuthHeader 111111111111111111111111111", accessToken);

  if (accessToken) {
    // console.log("setFirebaseAuthHeader 222222222222222222222222222", accessToken);
    api.defaults.headers.common['Authorization'] = `Bearer ${accessToken}`;
  } 
  // console.log("setFirebaseAuthHeader 3333333333333333333333333333", accessToken);

  // else {
  //   delete api.defaults.headers.common['Authorization'];
  // }
};


const handleError = (error) => {
  if (error.response) {
    throw error;
  } else if (error.request) {
    throw new Error('No response received from server.');
  } else {
    throw new Error('Error setting up the request.');
  }
};


export const formatDate = (date) => {
  return date.toLocaleDateString("en-GB", {
      day: "2-digit",
      month: "2-digit",
      year: "numeric"
  }).replace(/\//g, '-');
}

export const convertDateString = (dateString) => {
  // Split the input date string
  const [day, month, year] = dateString.split('-');
  // Create a new Date object
  const date = new Date(`${year}-${month}-${day}T00:00:00+05:30`);
  // Format the date to the desired format
  const formattedDateString = date.toString();
  return formattedDateString;
};

const convertToDateString = (date) => {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Months are 0-indexed
  const year = date.getFullYear();
  return `${day}-${month}-${year}`;
};

export const convertDateStringToDate = (dateString) => {
  const date = new Date(dateString);
  const formattedDateString = convertToDateString(date);
  return formattedDateString;
};

export const formatDateNew = (date) => {
  if (!date) return ""; 
  const formattedDate = date.toLocaleDateString("en-GB", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit"
  }).replace(/\//g, '-'); 
  return formattedDate;
};

export const parseDateObject = (dateString) => {
  if (dateString) {
      const parts = dateString.split('/');
      const day = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; 
      const year = parseInt(parts[2], 10);
      return new Date(year, month, day);
  }
  return null; 
}

export const parseDateObjectByHyphen = (dateString) => {
  if (dateString) {
      const parts = dateString.split('-');
      const year = parseInt(parts[0], 10);
      const month = parseInt(parts[1], 10) - 1; 
      const day = parseInt(parts[2], 10);
      return new Date(year, month, day);
  }
  return null; 
}

export const formatPlansDate = (dateString) => {
  const date = new Date(dateString);
  
  const day = date.getUTCDate();
  const year = date.getUTCFullYear();
  
  const monthNames = [
    "Jan", "Feb", "Mar", "Apr", "May", "Jun", 
    "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
  ];
  
  const month = monthNames[date.getUTCMonth()];
  
  return `${day} ${month} ${year}`;
};

export const transformSeaServiceRecords = (data) => {
  const transformed = data.map(record => ({
    id: record.id,
    companyName: record.company_name,
    imoNumber: record.IMO_number,
    shipName: record.ship_name,
    flag: record.Flag.id,
    flag_name: record.Flag.country_name, // Initialize with an empty string
    rank: record.Rank_id.id,
    rank_name: record.Rank_id.name, // Initialize with an empty string
    vesselType: record.VesselType.id,
    vesselType_name: record.VesselType.name, // Initialize with an empty string
    grt: record.GRT,
    signOnDate: convertDateString(record.signon_date),
    signOffDate: convertDateString(record.signoff_date)
  }));
  return transformed;
};

export const transformPreviousEmployerRecords = (data) => {
  const transformed = data.map(record => ({
    id: record.id,
    companyName: record.company_name,
    contactNumber: record.contact_number,
    referenceName: record.reference_name,
    code: ''
  }));
  return transformed;
};

export const parseAuthorityData = (authorityData) => {
  if (authorityData) {
    const data = JSON.parse(authorityData);
    if (data.custom_name) {
      return data.custom_name;
    } else {
      return data.issuing_authority;
    }
  } else {
    return "Not available";
  }
}

export const parseAuthorityDataAllObj = (authorityData) => {
  if (authorityData) {
    const data = JSON.parse(authorityData);
    console.log("datadatadatadatadata", data);
    return data;
  } else {
    return "Not available";
  }
}

export const isAccessTokenExpired = () => {
  var accessTokenCreated = getLocalItem("accessTokenCreated");
  if (accessTokenCreated) {
      var tokenCreationTime = new Date(parseInt(accessTokenCreated, 10)).getTime(); 
      // var tokenLifespan = 5 * 60 * 1000; 
      var tokenLifespan = 2 * 60 * 1000; 
      var currentTime = new Date().getTime();
      var timeDifference = currentTime - tokenCreationTime;
      return timeDifference > tokenLifespan;
  } else {
      return false; 
  }
}

export const isRefreshTokenExpired = () => {
  var refreshTokenCreated = getLocalItem("refreshTokenCreated");
    if (refreshTokenCreated) {
      var tokenCreationTime = new Date(parseInt(refreshTokenCreated, 10)).getTime(); 
      var tokenLifespan = 60 * 60 * 1000; 
      var currentTime = new Date().getTime();
      var timeDifference = currentTime - tokenCreationTime;
      return timeDifference > tokenLifespan;
  } else {
      setIsLoggedIn(false);
      return false; 
  }
}

export const refreshAccessToken = async (refreshToken) => {
  // console.log("refreshAccessToken 111111111111111111111", isRefreshTokenExpired());

  if (isRefreshTokenExpired()) {
    // console.log("refreshAccessToken 2222222222222222222222");
    setIsLoggedIn(false);
    return false;
  } else {
    const formData = new FormData();
    formData.append('refresh', refreshToken);
    // console.log("refreshAccessToken 33333333333333333333333", formData);

    try {
      const response = await api.post(apiConfigs.REFRESH_LOGIN_API_URL, formData);
      // console.log("refreshAccessToken 444444444444444444444444", response);
      const accessToken = response.data.access;
      // console.log("refreshAccessToken 444444444444444444444444", accessToken);
      setLocalItem("accessToken", accessToken);
      setLocalItem("accessTokenCreated", new Date().getTime());
      setIsLoggedIn(true);
      // console.log("refreshAccessToken 5555555555555555555555555", accessToken);
      return accessToken;
    } catch (error) {
      // console.log("refreshAccessToken 666666666666666666", error);
      handleError(error);
      setIsLoggedIn(false);
      return false;
    }
  }
};

export const handleLogout = () => {
  setIsLoggedIn(false); 
  localStorage.clear();
  window.location.href = apiRoutes.home; 
};

export const getTokenInfo = async (refreshToken, accessToken) => {
  const formData = new FormData();
  formData.append('refresh_token', refreshToken);
  formData.append('access_token', accessToken);
  try {
    return await post(apiConfigs.check_token_valid, formData);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};


export const get = async (endpoint, accessToken = null) => {
  try {
    { accessToken && await setAuthHeader(accessToken) };
    const response = await api.get(endpoint);
    return response;
  } catch (error) {
    handleError(error);
  }
};



export const post = async (endpoint, data = null, accessToken = null, isFileData = false, csrfToken = null) => {
  try {
    { accessToken && await setAuthHeader(accessToken, isFileData, csrfToken) };
    const response = await api.post(endpoint, data);
    return response;
  } catch (error) {
    handleError(error);
  }
};

export const post_login = async (endpoint, data = null, accessToken = null, isFileData = false, csrfToken = null) => {
  try {
    { accessToken && await setFirebaseAuthHeader(accessToken) };
    const response = await api.post(endpoint, data);
    return response;
  } catch (error) {
    handleError(error);
  }
};


export const post_urlencoded = async (endpoint, data, accessToken = null) => {
  try {
    { accessToken && setFirebaseAuthHeader(accessToken);}
    const formData = qs.stringify(data);
    const response = await api.post(endpoint, formData, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      }
    });

    return response;
  } catch (error) {
    handleError(error);
  }
};


export const put = async (endpoint, data, accessToken = null, isFileData = false) => {
  try {
    { accessToken && await setAuthHeader(accessToken, isFileData) };
    const response = await api.put(endpoint, data);
    return response;
  } catch (error) {
    handleError(error);
  }
};

export const patch = async (endpoint, data, accessToken = null, isFileData = false) => {
  try {

    // console.log("patch setAuther Start");
    { accessToken && await setAuthHeader(accessToken, isFileData) };
    // console.log("patch setAuther End");
    const response = await api.patch(endpoint, data);
    // console.log("waited for response");
    return response;
  } catch (error) {
    handleError(error);
  }
};

export const del = async (endpoint, accessToken = null) => {
  try {
    { accessToken && await setAuthHeader(accessToken) };
    const response = await api.delete(endpoint);
    return response;
  } catch (error) {
    handleError(error);
  }
};

export const delWithData = async (endpoint, data, accessToken = null) => {
  try {
    { accessToken && await setAuthHeader(accessToken) };
    const response = await api.delete(endpoint, data);
    return response;
  } catch (error) {
    handleError(error);
  }
};



export const firebaseAuth = async (email, password) => {
  try {
    const { firebaseData, get_firebase_token } = firebaseTokenApiConfigs;
    firebaseData['email'] = email;
    firebaseData['password'] = password;
    const userFirebaseLoginToken = await post_urlencoded(get_firebase_token, firebaseData);
    const userDataString = JSON.stringify(userFirebaseLoginToken);
    setLocalItem('userFirebaseData', userDataString);
    const firebaseUserData = JSON.parse(getLocalItem('userFirebaseData'));
    setIsLoggedIn(true);
    // return firebaseUserData.idToken;
    return firebaseUserData;
  } catch (error) {
    setIsLoggedIn(false);
    handleApiError(error);
    throw error;
  }
};

export const loginAuth = async (firebaseToken) => {
  try {
    const userLoginToken = await post_login(apiConfigs.LOGIN_API_URL, '', firebaseToken);
    setLocalItem("refreshToken", userLoginToken.data.data.refresh);
    setLocalItem("refreshTokenCreated", new Date().getTime());
    setLocalItem("accessToken", userLoginToken.data.data.access);
    setLocalItem("accessTokenCreated", new Date().getTime());
    setIsLoggedIn(true);
    setAuthHeader(userLoginToken.data.data.access)
    return userLoginToken.data.data.access;
  } catch (error) {
    handleApiError(error);
    setIsLoggedIn(false);
    throw error;
  }
};


export const loginFunc = async (email, password) => {
  try {
    const { firebaseData, get_firebase_token } = firebaseTokenApiConfigs;
    firebaseData['email'] = email;
    firebaseData['password'] = password;
    const userFirebaseLoginToken = await post_urlencoded(get_firebase_token, firebaseData);
    // signInWithEmailAndPassword(email, password)
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
          const user = userCredential.user;
      })
      .catch((error) => {
          console.error('Error signing in:', error);
      });
    setLocalJsonItem("userFirebaseData", userFirebaseLoginToken.data);
    // const userDataString = JSON.stringify(userFirebaseLoginToken.data);
    // setLocalItem('userFirebaseData', userDataString);
    const firebaseUserData = JSON.parse(getLocalItem('userFirebaseData'));
    const firebaseToken =  firebaseUserData.idToken;
    const userLoginToken = await post(apiConfigs.LOGIN_API_URL, '', firebaseToken);
    setLocalItem("refreshToken", userLoginToken.data.data.refresh);
    setLocalItem("refreshTokenCreated", new Date().getTime());
    setLocalItem("accessToken", userLoginToken.data.data.access);
    setLocalItem("accessTokenCreated", new Date().getTime());

    setIsLoggedIn(true);
    setAuthHeader(userLoginToken.data.data.access)
    
    return userLoginToken;
  } catch (error) {
    // console.log("errorerrorerrorerrorerror",error);
      setIsLoggedIn(false);
      return handleApiError(error);
  }
};


export const loginFuncWithMobile = async (signedInUserData) => {
  try {
    const fbData = {
      "refreshToken" : signedInUserData.stsTokenManager.refreshToken,
      "idToken" : signedInUserData.stsTokenManager.accessToken,
      "email" : signedInUserData.email,
      "displayName" : signedInUserData.displayName,
      "localId" : signedInUserData.reloadUserInfo.localId
    }
    setLocalJsonItem("userFirebaseData", fbData);
    const firebaseUserData = JSON.parse(getLocalItem('userFirebaseData'));
    const firebaseToken =  firebaseUserData.idToken;
    const userLoginToken = await post(apiConfigs.LOGIN_API_URL, '', firebaseToken);
    setLocalItem("refreshToken", userLoginToken.data.data.refresh);
    setLocalItem("refreshTokenCreated", new Date().getTime());
    setLocalItem("accessToken", userLoginToken.data.data.access);
    setLocalItem("accessTokenCreated", new Date().getTime());

    setIsLoggedIn(true);
    setAuthHeader(userLoginToken.data.data.access)
    return userLoginToken;
  } catch (error) {
    // console.log("errorerrorerrorerrorerror",error);
      setIsLoggedIn(false);
      return handleApiError(error);
  }
};


export const getRankList = async () => {
  try {
    const rankkListRes = await get(apiConfigs.get_rank_list);
    return rankkListRes.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getRankByJobIdList = async (jobID, userToken) => {
  try {
    const rankkListRes = await get(apiConfigs.get_rank_by_jobid + jobID);
    return rankkListRes.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getCountryList = async () => {
  try {
    const countryListRes = await get(apiConfigs.get_country_list);
    return countryListRes.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getStateList = async (countryId) => {
  try {
    const stateListRes = await get(apiConfigs.get_state_list + "/"+countryId);
    return stateListRes.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};


export const crewCreateUser = async (userData) => {
  try {
    const userCreateRes = await post(apiConfigs.crew_create_user, userData);
    return userCreateRes.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getUserByToken = async (userToken) => {
  try {
    const userDataRes = await get(apiConfigs.get_user_by_token, userToken);
    return userDataRes;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getCrewDetailsByUserId = async (userToken, userId) => {
  try {
    const CrewDetailsRes = await get(apiConfigs.get_crew_details_by_userid + userId, userToken);
    return CrewDetailsRes;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};


export const updateUserById = async (userId, userData, userToken) => {
  try {
    const updateUserRes = await patch(apiConfigs.update_user_byId + userId, userData, userToken, true);
    return updateUserRes;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getCDCCountryList = async () => {
  try {
    const CDCCountryRes = await get(apiConfigs.crew_cdc_country_list);
    return CDCCountryRes.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getPassportList = async () => {
  try {
    const passportRes = await get(apiConfigs.crew_passport_country_list);
    return passportRes.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};


export const getSTCWList = async () => {
  try {
    const stcwRes = await get(apiConfigs.crew_stcw_country_list);
    return stcwRes.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getRankById = async (rankId) => {
  try {
    const res = await get(apiConfigs.get_rank_byid + rankId);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getCocListByUserType = async (userType) => {
  try {
    const res = await get(apiConfigs.coc_list_byusertype + userType);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getCopListByUserType = async (userType) => {
  try {
    const res = await get(apiConfigs.cop_list_byusertype + userType);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getWatchkeepingListByUserType = async (userType) => {
  try {
    const res = await get(apiConfigs.watch_keeping_list_byusertype + userType);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const insertCrewDetails = async (crewData, userToken, csrfToken=null) => {
  try {
    const insertCrewDetailsRes = await post(apiConfigs.crew_details_insert, crewData, userToken, false, csrfToken);
    return insertCrewDetailsRes;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const updateCrewDetails = async (crewData, userId, userToken, csrfToken=null) => {
  try {
    const updateCrewDetailsRes = await patch(apiConfigs.crew_details_update + userId, crewData, userToken, false, csrfToken);
    return updateCrewDetailsRes;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const generateCSRFToken = async () => {
  try {
    const res = await get(apiConfigs.csrftoken_generate);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getFlagList = async () => {
  const userToken = getLocalItem("accessToken"); 
  try {
    const res = await get(apiConfigs.flag_list, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getVesselList = async () => {
  const userToken = getLocalItem("accessToken"); 
  try {
    const res = await get(apiConfigs.vessels_list, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const insertCrewSeaService = async (seaserviceData, userToken, csrfToken=null) => {
  try {
    const res = await post(apiConfigs.crew_seaservice_insert, seaserviceData, userToken, false);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const insertCrewReference = async (referenceData, userToken, csrfToken=null) => {
  try {
    return await post(apiConfigs.crew_reference_insert, referenceData, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getAllVerifiedJobList = async (userToken) => {
  try {
    const res = await get(apiConfigs.get_all_verified_job_list, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getAllVerifiedJobListFilter = async (queryString, userToken) => {
  try {
    const res = await get(apiConfigs.get_all_verified_job_list + "?" + queryString, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const companyFollowed = async (referenceData1, userToken, csrfToken=null) => {
  try {
    return await post(apiConfigs.company_followed , referenceData1, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const companyUnFollowed = async (UserId, UserFollowedById, userToken, csrfToken=null) => {
  try {
    return await del(apiConfigs.company_unfollowed + UserId + "/" + UserFollowedById + "/", userToken);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const jobLiked = async (jobLikedData1, userToken, csrfToken=null) => {
  try {
    return await post(apiConfigs.job_liked , jobLikedData1, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const jobUnLiked = async (UserId, UserLikesById, userToken, csrfToken=null) => {
  try {
    return await del(apiConfigs.job_unliked + UserId + "/" + UserLikesById + "/", userToken);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const applyJob = async (applyJobData, userToken, csrfToken=null) => {
  try {
    return await post(apiConfigs.apply_job + "?sub_id=" + applyJobData["sub_id"] , applyJobData, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getAllFeaturedCompany = async (userToken) => {
  try {
    const res = await get(apiConfigs.get_featured_company , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getAllEmployerBoosted = async ( userToken) => {
  try {
    const res = await get(apiConfigs.get_employer_boosted, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getAllCrewBoosted = async ( userToken) => {
  try {
    const res = await get(apiConfigs.get_crew_boosted, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getAllAppliedList = async ( userid, userToken) => {
  try {
    const res = await get(apiConfigs.get_applied_list_by_userid + userid, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const employerPostAJob = withAuthCheck(async (employerPostAJobData, userToken) => {
  try {
    const res = await post(apiConfigs.employer_post_a_job, employerPostAJobData, userToken);
    return res;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
});

export const employerEditPostJob = async (employerEditPostJobData, postJobId, userToken, csrfToken=null) => {
  try {
    const res = await patch(apiConfigs.employer_post_a_job_update + postJobId, employerEditPostJobData, userToken, false);
    return res;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const crewReferAJob = async (crewReferJobData, userToken) => {
  try {
    const res = await post(apiConfigs.crew_refer_a_job, crewReferJobData, userToken);
    return res;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getReferJobById = async ( referJobId, userToken) => {
  try {
    const res = await get(apiConfigs.crew_refer_a_job_get + referJobId, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const editReferJob = async (referJobData, referJobId, userToken, csrfToken=null) => {
  try {
    const res = await patch(apiConfigs.crew_refer_a_job_update + referJobId, referJobData, userToken, false);
    return res;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const deleteReferJob = async (jobId, userToken, csrfToken=null) => {
  try {
    return await del(apiConfigs.crew_refer_a_job_delete + jobId , userToken);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getUserById = async ( userId, userToken) => {
  try {
    const res = await get(apiConfigs.get_user_by_id + userId, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const checkJobStatus = async ( jobId, userToken) => {
  try {
    const res = await get(apiConfigs.crew_check_status_job + jobId, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getSubscriptionPlans = async ( planType, userToken) => {
  try {
    const res = await get(apiConfigs.get_subscriptions + "?plan_type=" + planType, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getCrewBoostedPlan = async ( crewDetId, userToken) => {
  try {
    const res = await get(apiConfigs.get_crew_boosted_plan + crewDetId + "/", userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getCrewHighlightPlan = async ( userID, userToken) => {
  try {
    const res = await get(apiConfigs.get_crew_highlighted_plan + userID + "/", userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getCrewFollowedToList = async ( userID, userToken) => {
  try {
    const res = await get(apiConfigs.get_crew_followed_to_by_userid + userID , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getCrewFollowedByList = async ( userID, userToken) => {
  try {
    const res = await get(apiConfigs.get_crew_followed_by_userid + userID , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getCrewNotificationList = async ( userID, userToken) => {
  try {
    const res = await get(apiConfigs.get_notification_by_userid + userID + "/", userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const crewBoosting = async (crewBoostingData, userToken, csrfToken=null) => {
  try {
    return await post_urlencoded(apiConfigs.crew_boosting , crewBoostingData, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const crewHighlight = async (crewHighlightData, userToken, csrfToken=null) => {
  try {
    return await post_urlencoded(apiConfigs.crew_highlight , crewHighlightData, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getCreditPointsByUserId = async ( userID, userToken) => {
  try {
    const res = await get(apiConfigs.get_credit_point + userID + "/", userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getWalletHistoryByUserId = async (userID, queryString, userToken) => {
  try {
    let res; // Use let instead of const
    if (queryString !== null) {
      res = await get(apiConfigs.get_wallet_history + userID + "?" + queryString , userToken);
    } else {
      res = await get(apiConfigs.get_wallet_history + userID , userToken);
    }
    return res.data;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const createOrder = async (createOrderData, userToken) => {
  try {
    const res = await post(apiConfigs.create_order, createOrderData, userToken);
    return res;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const callBackConfirmPayment = async ( orderId, callBackConfirmPaymentData, userToken) => {
  try {
    const res = await patch(apiConfigs.capture_payment + orderId + "/", callBackConfirmPaymentData, userToken);
    return res;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getAllSubscriptionsPlan = async (  userToken) => {
  try {
    const res = await get(apiConfigs.get_all_subscription_plans , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const checkReferCode = async ( userToken) => {
  try {
    const res = await get(apiConfigs.check_refer_code_used , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const applyReferCode = async (referredCode, userToken) => {
  try {
    const res = await post(apiConfigs.use_refer_code + referredCode + "/", userToken);
    return res;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const changePasswordDB = async ( changePasswordData, firebaseUserToken) => {
  try {
    const res = await post(apiConfigs.user_change_password, changePasswordData, firebaseUserToken);
    return res;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const changePasswordDBNew = async ( changePasswordData, firebaseUserToken) => {
  try {
    const res = await post_login(apiConfigs.user_change_password, changePasswordData, firebaseUserToken);
    return res;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const changeEmailDB = async ( userId, emailData, firebaseUserToken) => {
  try {
    const res = await patch(apiConfigs.user_change_email + userId, emailData, firebaseUserToken);
    return res;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getSeaServiceRecordList = async ( userID, userToken) => {
  try {
    const res = await get(apiConfigs.sea_service_record_getlist + userID , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const deleteSeaServiceId = async (seaServiceId, userToken, csrfToken=null) => {
  try {
    return await del(apiConfigs.sea_service_record_delete + seaServiceId + "/" , userToken);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getPreviousEmployerRecordList = async ( userID, userToken) => {
  try {
    const res = await get(apiConfigs.previous_emmployer_record_getlist + userID , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const deletePreviousEmployerId = async (previousEmployerId, userToken, csrfToken=null) => {
  try {
    return await del(apiConfigs.previous_emmployer_record_delete + previousEmployerId + "/" , userToken);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const searchCV = async (queryString, userToken) => {
  try {
    const res = await get(apiConfigs.employer_search_cv + "?" + queryString, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getApplicantsList = async ( jobId, queryString, userToken) => {
  try {
    const res = await get(apiConfigs.employer_get_applicants_list + jobId + "?" + queryString, userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const employerBoostJob = async (employerBoostJobData, userToken) => {
  try {
    const res = await post(apiConfigs.employer_job_boosting + "?sub_id=" + employerBoostJobData["sub_id"], employerBoostJobData, userToken);
    return res;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const employerHighlightJob = async (employerHighlightJobData, userToken) => {
  try {
    const res = await post(apiConfigs.employer_job_highlight + "?sub_id=" + employerHighlightJobData["sub_id"], employerHighlightJobData, userToken);
    return res;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getSubscriptionPlansById = async ( planId, userToken) => {
  try {
    const res = await get(apiConfigs.get_subscription_detail_byID + planId + '/', userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const resumeDownloadNew = async (resumeDownloadData, userToken, csrfToken=null) => {
  try {
    return await post(apiConfigs.employer_resume_download , resumeDownloadData, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const profileShortlisted = async (profileID, jobIdData, userToken, csrfToken=null) => {
  try {
    return await get(apiConfigs.employer_shortlisted + profileID + '/' + jobIdData, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getResumeDownloadPlansList = async ( userToken) => {
  try {
    const res = await get(apiConfigs.resume_download_plans_list , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getResumeDownloadTopUpPlansList = async ( userToken) => {
  try {
    const res = await get(apiConfigs.resume_download_topup_plan_list , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const resumeDownloadPurchasePlan = async (resumeDownloadPurchasePlanData, userToken, csrfToken=null) => {
  try {
    return await post(apiConfigs.resume_download_purchase , resumeDownloadPurchasePlanData, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const resumeDownloadTopUpPurchasePlan = async (resumeDownloadTopUpPurchasePlanData, userToken, csrfToken=null) => {
  try {
    return await post(apiConfigs.resume_download_topup_purchase , resumeDownloadTopUpPurchasePlanData, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getPostJobPlansList = async ( userToken) => {
  try {
    const res = await get(apiConfigs.job_post_plans_list , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const checkPostJobFreePlansUsed = async ( userToken) => {
  try {
    const res = await get(apiConfigs.job_post_check_free_plan_used , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const jobPostPurchasePlan = async (jobPostPurchasePlanData, userToken, csrfToken=null) => {
  try {
    return await post(apiConfigs.job_post_purchase_plan , jobPostPurchasePlanData, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const jobPostTopUpPurchasePlan = async (jobPostTopUpPurchasePlanData, userToken, csrfToken=null) => {
  try {
    return await post(apiConfigs.job_post_purchase_topup_plan , jobPostTopUpPurchasePlanData, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getPurchasedResumeDownloadPlansList = async ( userId, userToken) => {
  try {
    const res = await get(apiConfigs.purchased_resume_download_plans_list + userId + '/' , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getPurchasedResumeDownloadTopUpPlansList = async ( userId, userToken) => {
  try {
    const res = await get(apiConfigs.purchased_resume_download_topup_plans_list + userId + '/', userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getPurchasedJobPostPlansList = async ( userId, userToken) => {
  try {
    const res = await get(apiConfigs.job_post_purchased_plans_list + userId + '/', userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getPurchasedJobPostTopUpPlansList = async ( userId, userToken) => {
  try {
    const res = await get(apiConfigs.job_post_topup_plan_purchased + userId + '/', userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getSubUsersList = async ( userId, userToken) => {
  try {
    const res = await get(apiConfigs.sub_users_list + userId , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const createSubEmployer = async (createSubEmployerData, userToken, csrfToken=null) => {
  try {
    return await post(apiConfigs.sub_users_create , createSubEmployerData, userToken, false);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const deleteSubEmployerById = async (SubEmployerById, subUsersData, userToken, csrfToken=null) => {
  try {
    return await delWithData(apiConfigs.sub_users_delete + SubEmployerById, subUsersData ,userToken);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getUserByUserCode = async ( userCode) => {
  try {
    const res = await get(apiConfigs.get_user_byUserCode + userCode + "/");
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getActivePlanLeftData = async ( userId, userToken) => {
  try {
    const res = await get(apiConfigs.get_user_activeplanleft + userId + '/' , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const getEmailConfigureSettingFlag = async ( idFlag, userToken) => {
  try {
    const res = await get(apiConfigs.email_configure_setting + idFlag + '/' , userToken);
    return  res.data ;
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

export const isPasswordChangeFunc = async (userEmail, passwordChangeData) => {
  try {
    return await apiNew.post(apiConfigs.toggle_password_change_flag + userEmail + "/", passwordChangeData);
  } catch (error) {
    handleApiError(error);
    throw error;
  }
};

import React, { useState } from 'react';
import { Card, Button } from 'react-bootstrap';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { jobLiked, jobUnLiked } from '../apiService';

const EmployerReferalJobCards = ({ job, isFollowing, onFollowToggle, userData, userToken }) => {
  const [isLiked, setIsLiked] = useState(job.userlikedjob_status);

  // handel Like Starts  
  const handleLikeToggle = () => {
    if (isLiked) {
      jobUnLiked(userData.id, job.id, userToken)
        .then(response => {
          setIsLiked(false);
        })
        .catch(error => {
          console.error('Error unliking Job:', error);
        });
    } else {
      const formData = new FormData();
      formData.append("liked_post", job.id);
      formData.append("user_id", userData.id);
      jobLiked(formData, userToken)
        .then(response => {
          setIsLiked(true);
        })
        .catch(error => {
          console.error('Error liking Job:', error);
        });
    }
  };
  // handel Like Ends  

  // show modal for Share Starts  
  const handleShare = (jobId, user_code) => {
    const host = window.location.origin; 
    const jobUrl = `${host}/job/?job_id=${jobId}&user_code=${user_code}`;
    const message = "Click on this link to view this Job:";
  
    if (navigator.share) {
      navigator.share({
        title: 'Check out this job!',
        text: message,
        url: jobUrl
      })
      .then(() => console.log('Successful share'))
      .catch((error) => console.log('Error sharing:'));
    } else {
      console.log('Error share');
      // const shareLink = document.createElement('a');
      // shareLink.href = jobUrl;
      // shareLink.target = '_blank';
      // shareLink.rel = 'noopener noreferrer';
      // shareLink.style.display = 'none';
      // document.body.appendChild(shareLink);
      // shareLink.click();
      // document.body.removeChild(shareLink);
    }
  };
  // show modal for Share Ends  

  // console.log("jobjobjobjob", job);


  return (
    <>
      <Card>
        <Card.Body>
          <div className='job-card-cont'>
            <div className="job-card">
              <div className="jobby-info-container">
                <div className="jobby-info">
                  <LazyLoadImage
                    className="profile-img-info"
                    src={job.posted_by.profilePic}
                    alt="Profile"
                  />
                  <div>
                    <h1 className="jobby-name">Job By: {job.posted_by.first_name}</h1>
                    <h1 className="company-name">Referred Job</h1>
                  </div>
                </div>
              </div>
              <div className="apply-job" style={{ display: 'flex', flexDirection: 'row', gap: '21px' }}>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '32px' }}>
                  <Button className="like-button btn-primary" type="button" onClick={handleLikeToggle}>
                    <LazyLoadImage
                      src={isLiked ? '/static/crew/assets/img/icons/Heart.svg' : '/static/crew/assets/img/icons/Like_heart.svg'}
                      alt={isLiked ? 'Liked' : 'Like'}
                    />
                    &nbsp; &nbsp;
                    {isLiked ? 'Liked' : 'Like'}
                  </Button>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', gap: '32px' }}>
                  <Button className="share-button btn-primary" type="button"  onClick={() => handleShare(job.id, userData.user_code)}>
                    Share
                  </Button>
                </div>
              </div>
            </div>
            <div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Tentative Joining Date:</h1>
                <h1 className="job-details">{job.tentative_joining}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Vessel Type:</h1>
                <h1 className="job-details">{job.vessel_id.name}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Flag:</h1>
                <h1 className="job-details">{ job.flag_key && job.flag_key.country_name}</h1>
              </div>
              <div style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                <h1 className="job-details">Joining Port:</h1>
                <h1 className="job-details">{job.joining_port}</h1>
              </div>
            </div>
            <div className="job-wages-container">
              <div className="job-wages-container-1">
                  <div className="job-wages">
                    <LazyLoadImage src="/static/crew/assets/img/icons/job_bag.svg" alt="Job Bag" />
                    <p className="job-wages-name" >{job.Job_Rank_wages[0].rank_number.name} - {job.Job_Rank_wages[0].wages}</p>
                  </div>
              </div>
            </div>
              <div className="requirement-container">
                  {job.job_coc && job.job_coc.length > 0 && (
                      <div className="requirement-container-both">
                        <h1 className="requirement-heading">COC Requirement:</h1>
                        <div className="requirement-value">
                            {job.job_coc.map((coc_names, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="requirement-heading">{coc_names.coc_id.name}</h1>
                                    {index !== job.job_coc.length - 1 && <span>|</span>}
                                </React.Fragment>
                            ))}
                        </div>
                      </div>
                  )}
                  {job.job_cop && job.job_cop.length > 0 && (
                    <div className="requirement-container-both">
                        <h1 className="requirement-heading">COP Requirement:</h1>
                        <div className="requirement-value">
                            {job.job_cop.map((cop_names, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="requirement-heading">{cop_names.cop_id.name}</h1>
                                    {index !== job.job_cop.length - 1 && <span>|</span>}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                  )}
              </div>
              <div className="requirement-container">
                  {job.job_Watch_Keeping && job.job_Watch_Keeping.length > 0 && (
                    <div className="requirement-container-both">
                      <h1 className="requirement-heading">Watch-Keeping Requirement:</h1>
                        <div className="requirement-value">
                            {job.job_Watch_Keeping.map((coc_names, index) => (
                                <React.Fragment key={index}>
                                    <h1 className="requirement-heading">{coc_names.Watch_Keeping_id.name}</h1>
                                    {index !== job.job_Watch_Keeping.length - 1 && <span>|</span>}
                                </React.Fragment>
                            ))}
                        </div>
                    </div>
                  )}
              </div>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default EmployerReferalJobCards;

import React, { useState, useEffect } from 'react';

const DemoApi = () => {
  const [data, setData] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isFetched, setIsFetched] = useState(false); 

  useEffect(() => {
    if (!isFetched) {
      const fetchData = async () => {
        try {
          const response = await fetch('https://reqres.in/api/users?page=2');
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();
          setData(jsonData.data);
          setIsLoading(false);
          setIsFetched(true); 
        } catch (error) {
          setError(error);
          setIsLoading(false);
        }
      };

      fetchData();
    }
  }, [isFetched]); 

  if (isLoading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <div>
      {/* Render your data here */}
      {data && (
        <ul>
          {data.map(item => (
            <li key={item.id}>
              {item.first_name} {item.last_name}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default DemoApi;

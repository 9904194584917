import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Scripts from '../components/layouts/Scripts';
import { Container, Row, Col, Button, Dropdown } from 'react-bootstrap';
import { getCreditPointsByUserId, getWalletHistoryByUserId,
        createOrder, callBackConfirmPayment , getAllSubscriptionsPlan
      } from '../components/apiService';
import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';
// import { razorpayConfigs, razorpayTestConfigs, apiRoutes, razorpayMinimumAmountTestConfigs } from '../config/apiConfig';
import { razorpayConfigs, apiRoutes, razorpayMinimumAmountConfigs } from '../config/apiConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const CrewWalletScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      {/* <link rel="stylesheet" href="/static/crew/assets/css/discover_page.css" /> */}
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-dropdown-1.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/wallet.css" />
    </>
  );
};

const CrewWalletScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const CrewWallet = () => {
  const userToken = getLocalItem("accessToken"); 
  const userData =  getLocalJsonItem("userData");
  const [activeTab, setActiveTab] = useState('Jobs');

  // get list for empoyer boosted Starts
  const [userCreditPoint , setUserCreditPoint] = useState();
  const [userWalletHistory , setUserWalletHistory] = useState();
  const [allsubsPlan , setallsubsPlan] = useState(null);
  const [INRConversionPlan , setINRConversionPlan] = useState();
  const [USDConversionPlan , setUSDConversionPlan] = useState();
  const [userCurrCreditPoints , setuserCurrCreditPoints] = useState(0);
  

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getCreditPointsByUserId(userData.id, userToken);
        setUserCreditPoint(response);
        const response1 = await getWalletHistoryByUserId(userData.id, userToken);
        setUserWalletHistory(response1);
        const subsPlan = await getAllSubscriptionsPlan( userToken);
        setallsubsPlan(subsPlan);

      } catch (error) {
        console.error('Error fetching job list:', error);
      } 
    };
    fetchJobs();
  }, []);


  useEffect(() => {
    const fetchJobs = async () => {
      try {
        if (allsubsPlan) {
          setINRConversionPlan(allsubsPlan.filter(plan => plan.is_type_key === 11));
          setUSDConversionPlan(allsubsPlan.filter(plan => plan.is_type_key === 12));
          setuserCurrCreditPoints(userCreditPoint.points);
        }
      } catch (error) {
        console.error('Error fetching job list:', error);
      } 
    };
    fetchJobs();
  }, [allsubsPlan]);
  // get list for empoyer boosted Ends

  // Wallet History Select type Starts
  const [walletType, setWalletType] = useState(null);

  const handleDropdownChange = (eventKey) => {
    setWalletType(eventKey);
  };

  const generateQueryStringWallet = ( walletTypeId) => {
    if (walletTypeId !== "2" &&  walletTypeId !== null) {
      const walletTypeParams = `wallet_type=${walletTypeId}`;
      return `${walletTypeParams}`;
    } else {
      return null;
    }
  };

  const formatDateWallet = (dateString) => {
    const options = { month: 'short', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' };
    const formattedDate = new Date(dateString).toLocaleString('en-US', options);
    return formattedDate;
  };

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const queryString = generateQueryStringWallet(walletType); 
        const response1 = await getWalletHistoryByUserId(userData.id, queryString, userToken);
        setUserWalletHistory(response1);
      } catch (error) {
        console.error('Error fetching job list:', error);
      } 
    };
    fetchJobs();
  }, [walletType]);
  // Wallet History Select type Ends

  // Convertion Starts
  const [inputValueDigit, setInputValueDigit] = useState(0);
  const [inputValueConversion, setInputValueConversion] = useState(0);
  const [inputValueConversionError, setInputValueConversionError] = useState();
  // const handleInputChangeConversion = (event) => {
  //   setInputValueDigit(event.target.value);

  // };


  const handleInputChangeConversion = (event) => {
    const { value } = event.target;
    
    if (selectedCurrencyCode === 'INR') {
        // console.log("valuevaluevalue", value);
        if (parseFloat(value) < razorpayMinimumAmountConfigs.INR) {
          setInputValueDigit(value);
          setInputValueConversion(value * INRConversionPlan[0].points );
          setInputValueConversionError("Please add a minimum of ₹500 or $5.");
        } else {
            setInputValueDigit(value);
            setInputValueConversion(value * INRConversionPlan[0].points );
            setInputValueConversionError("");
        }
    } else if (selectedCurrencyCode === 'USD') {
        if (parseFloat(value) < razorpayMinimumAmountConfigs.USD) {
          setInputValueDigit(value);
          setInputValueConversion(value * USDConversionPlan[0].points );
          setInputValueConversionError("Please add a minimum of ₹500 or $5.");
        } else {
            setInputValueDigit(value);
            setInputValueConversion(value * USDConversionPlan[0].points );
            setInputValueConversionError("");
        }
    }
  };


  const [selectedCurrency, setSelectedCurrency] = useState('INR (₹)');
  const [selectedCurrencyCode, setSelectedCurrencyCode] = useState('INR');
  const handleConversionDropdownChange = (eventKey, event) => {
    const { text, dataset: { value } } = event.target;
    setSelectedCurrency(text);
    setSelectedCurrencyCode(value);
    setInputValueDigit(0);
    setInputValueConversion(0);
    setInputValueConversionError("");
  };
  // Convertion Ends


  // Info Box Starts
  const [showInfo, setShowInfo] = useState(false);
  const toggleInfo = () => {
    setShowInfo(!showInfo);
  };
  // Info Box Emds

  // Payment Gateway Starts

  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
        document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async (e) => {
    e.preventDefault();
    if (isNaN(parseInt(inputValueDigit)) || parseInt(inputValueDigit) === 0 || inputValueDigit === '') {
        toast.error("Please enter a amount first");
        return; 
    }
    const orderData = new FormData(); 
    orderData.append("amount", parseInt(inputValueDigit)); 
    orderData.append("currency", selectedCurrencyCode); 
    // const orderObj = await createOrder(orderData, userToken); 

    await createOrder(orderData, userToken).then(
      (orderObj) => {
        // console.log("orderObjorderObj", orderObj);
        // console.log("orderObjorderObj", orderObj.status);
        // console.log("orderObjorderObj", orderObj.data);

      if (orderObj.status === 201) {
        const options = {
          "key": razorpayConfigs.key, 
          // "key": razorpayTestConfigs.key, 
          "amount": parseInt(orderObj.data.amount), 
          "currency": orderObj.data.currency,
          "name": "Join My Ship", 
          "description": "Test Transaction",
          "image": "https://joinmyship.com/static/crew/assets/img/brands/newLogo.png",
          "order_id": orderObj.data.razorpay_order_id, 
          "callback_url": apiRoutes.get_wallet ,
          "prefill": {
              "name": userData.first_name, 
              "email": userData.email,
              "contact": userData.number
          },
          "notes": {
              "address": "Razorpay Corporate Office"
          },
          "theme": {
              "color": "#3399cc"
          },
          "handler": function (response) {
            const callBackData = new FormData();
            callBackData.append("order_id", response.razorpay_order_id);
            callBackData.append("payment_id", response.razorpay_payment_id);
    
            // console.log("Payment ID:", response.razorpay_payment_id);
            // console.log("Order ID:", response.razorpay_order_id);
            // console.log("Signature:", response.razorpay_signature);
    
            const fetchCreditPoints = async () => {
              try {
                const creditPoints = await callBackConfirmPayment(orderObj.data.id, callBackData, userToken);
                // console.log("Credit Points:", creditPoints);
                toast.success("Payment Successful");
                setuserCurrCreditPoints(userCurrCreditPoints + inputValueConversion);
              } catch (error) {
                toast.error("Payment Failed");
                console.error("Error fetching credit points:", error);
              }
            };
            fetchCreditPoints();
          },
          "modal": {
            "ondismiss": function() {
              console.log("Payment popup closed");
            }
          }
        };
        const rzp1 = new window.Razorpay(options);
        rzp1.open();
      }

    });


  };
  // Payment Gateway Emds


  return (
    <>
        <CrewWalletScriptCss />
        <ToastContainer />
        <NavbarCrew />
        <div className="container wallet-header-container">
          <div className="wallet-header-div">
            <h1 className="wallet-header-heading">My Wallet</h1>
            <p className="wallet-header-detail">Top up your wallet with credits to make seamless purchases across our web and app platforms.&nbsp;<br />We accept all major cards and banks for your convenience.</p>
          </div>
        </div>
        <Container className="wallet-credits-container">
          <Row>
            <Col md={6} className="wallet-credits-column-container">
              <div className="wallet-credits-column-div">
                <h1 className="wallet-credits-column-div-heading">Available Credits</h1>
                <div className="wallet-credits-column-points-div">
                  <LazyLoadImage className="wallet-credits-column-points-img" src="/static/crew/assets/img/icons/Coin_Icon.svg" alt="Coin Icon" />
                  <h1 className="wallet-credits-column-credits">{userCurrCreditPoints}</h1>
                </div>
              </div>
              <div className="wallet-credits-details-div">
                <h1 className="wallet-credits-details-heading">Points to remember</h1>
                <div className="wallet-credits-details-list">
                  <div className="wallet-credits-details-item-div">
                    <LazyLoadImage className="wallet-credits-details-item-img" src="/static/crew/assets/img/icons/double_Checks.svg" alt="Double Checks Icon" />
                    <p className="wallet-credits-details-item-info">Credits once added will not be refunded.</p>
                  </div>
                  <div className="wallet-credits-details-item-div">
                    <LazyLoadImage className="wallet-credits-details-item-img" src="/static/crew/assets/img/icons/double_Checks.svg" alt="Double Checks Icon" />
                    <p className="wallet-credits-details-item-info">Credit points can not be transferred to your bank accounts.</p>
                  </div>
                  <div className="wallet-credits-details-item-div">
                    <LazyLoadImage className="wallet-credits-details-item-img" src="/static/crew/assets/img/icons/double_Checks.svg" alt="Double Checks Icon" />
                    <p className="wallet-credits-details-item-info">Credit points can be used to buy subscription plans.</p>
                  </div>
                  <div className="wallet-credits-details-item-div">
                    <LazyLoadImage className="wallet-credits-details-item-img" src="/static/crew/assets/img/icons/double_Checks.svg" alt="Double Checks Icon" />
                    <p className="wallet-credits-details-item-info">In case of any queries kindly contact us at support@joinmyship.com</p>
                  </div>
                </div>
              </div>
            </Col>
            <Col md={6} className="wallet-credits-column2-container">
              <div className="wallet-credits-column2-div">
                <h1 className="wallet-credits-column2-div-heading">Add Credits</h1>
                <div className="credits-add-div">
                  <div className="credits-add-input">
                    <input type="text" className="credits-add-input-text" value={inputValueDigit ? inputValueDigit : ''}  onChange={handleInputChangeConversion} />
                    <div className="input-divider"></div>
                      <Dropdown className="currency-dropdown" onSelect={handleConversionDropdownChange}>
                        <Dropdown.Toggle className="btn dropdown-toggle currency-dropdown-btn">
                          {selectedCurrency}&nbsp; &nbsp; 
                          <span className='downarow-class'></span> 
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                          <Dropdown.Item eventKey="INR (₹)" data-value="INR">INR (₹)</Dropdown.Item>
                          <Dropdown.Item eventKey="US ($)" data-value="USD">US ($)</Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                  </div>
                  {inputValueConversionError && <p className="form-error-text">{inputValueConversionError}</p>}
                  <div className="credits-add-info-container">
                    <h1 className="credits-add-info-heading">Credits to add</h1>
                    <div className="credits-conversion-container">
                      <LazyLoadImage className="credits-conversion-img" src="/static/crew/assets/img/icons/Coin_Icon.svg" alt="Coin Icon" />
                      <h1 className="credits-conversion-rate">{inputValueConversion}</h1>
                    </div>
                    <div className="credits-conversion-info">
                      <h1 className="credits-conversion-info-text">Conversion ratio</h1>
                        {USDConversionPlan && INRConversionPlan && (
                          <div className="tooltip-info">
                            <LazyLoadImage className="credits-conversion-info-img" 
                            src="/static/crew/assets/img/icons/QuestionCircle.svg" alt="Question Circle Icon" 
                            />
                            <span className="tooltiptext">
                              <p className="tooltiptext-info">
                                ${USDConversionPlan[0].days_active} equals {USDConversionPlan[0].points} Credits.
                              </p>
                              <p className="tooltiptext-info">
                                ₹{INRConversionPlan[0].days_active} equals {INRConversionPlan[0].points} Credits.
                              </p>
                            </span>
                          </div>
                        )}
                    </div>
                  </div>
                  <p className="credits-add-info-help-text">You can add a minimum of ₹500 or $5.</p>
                </div>
                <div className="credits-add-div-button" onClick={handlePayment} type="button">
                  Add Now&nbsp;&nbsp; <LazyLoadImage src="/static/crew/assets/img/arrows/ArrowRightwhite.svg" alt="Arrow Icon" />
                </div>
              </div>
            </Col>
          </Row>
        </Container>
        <Container className="wallet-history-container">
          <div className="wallet-history-container-div">
            <div className="wallet-history-container-div-head">
              <h1 className="wallet-history-container-head-text">Recent Transactions</h1>
              <div className="dropdown currency-dropdown wallet-history-container-head-dropdown">
              <Dropdown onSelect={handleDropdownChange}>
                <Dropdown.Toggle className="btn dropdown-toggle wallet-type-dropdown-btn currency-dropdown-btn">
                  All&nbsp; &nbsp; 
                  <span className='downarow-class'></span> 
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  <Dropdown.Item eventKey="0">Credit</Dropdown.Item>
                  <Dropdown.Item eventKey="1">Debit</Dropdown.Item>
                  <Dropdown.Item eventKey="2">All</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
              </div>
            </div>
            <div className="table-responsive wallet-table-container">
              <table className="table">
                <thead>
                  <tr className="wallet-history-table-head">
                    <th className="wallet-history-container-list-head-item">ORDER</th>
                    <th className="wallet-history-container-list-head-item">DATE</th>
                    <th className="wallet-history-container-list-head-item">STATUS</th>
                    <th className="wallet-history-container-list-head-item">AMOUNT</th>
                  </tr>
                </thead>
                <tbody>
                  {userWalletHistory && userWalletHistory.map((historyItem, index) => (
                    <tr key={index}>
                      <td className="wallet-history-item">{historyItem.subscription_name}</td>
                      <td className="wallet-history-item-date">{formatDateWallet(historyItem.created_at)}</td>

                      {historyItem.operation_type ? 
                        <td className={`wallet-history-item-debit`}>"Debit"</td>
                        :
                        <td className={`wallet-history-item-credit`}>"Credit"</td>
                      }
                      <td className="wallet-history-item-date">{historyItem.point_used}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </Container>
        <div style={{ paddingBottom: '122px' }}></div>;
        <Scripts />
        <CrewWalletScript />
    </>
  );
};

export default CrewWallet
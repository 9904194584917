import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { useNavigate } from 'react-router-dom';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import { Container, Row, Col } from 'react-bootstrap';
import { getAllVerifiedJobListFilter,  
        getCrewFollowedToList
      } from '../components/apiService';
import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';
import { apiRoutes } from '../config/apiConfig';
import EmployerMyJobCard from '../components/layouts/EmployerMyJobCards';

const EmployerMyJobsScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/employer_myjobs.css" />

    </>
  );
};

const EmployerMyJobsScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const EmployerMyJobs = () => {
  const userToken = getLocalItem("accessToken"); 
  const userData =  getLocalJsonItem("userData");

  // Get Job List Starts
  const [jobList, setJobList] = useState([]);
  const [nextPageUrl, setNextPageUrl] = useState(null);
  const [prevPageUrl, setPrevPageUrl] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [loading, setLoading] = useState(false);
  const [limit, setLimit] = useState(3);

  // console.log("jobList", jobList);
  // Get Job List Ends

  // Featured Company List Starts
  const [featuredCompanies, setFeaturedCompanies] = useState([]);

  useEffect(() => {
    const fetchFeaturedCompanies = async () => {
      try {
        const data = await getCrewFollowedToList( userData.id ,userToken);
        setFeaturedCompanies(data); 
      } catch (error) {
        console.error('Error fetching featured companies:', error);
      }
    };

    fetchFeaturedCompanies(); 
  }, [userToken]); 
  // Featured Company List Ends

  // Generate query string Starts
  const generateQueryString = (userId, pageData) => {
    const userParams = `emp_id=${userId}`;
    const paginationParams = `limit=${limit}&page=${pageData}`;
    return `${userParams}&${paginationParams}`;
  };

  useEffect(() => {
    const fetchJobs = async () => {
      setLoading(true);
      try {
        const queryString = generateQueryString(userData.id,  currentPage);
        const response = await getAllVerifiedJobListFilter( queryString, userToken);
        
        const results = response.results || [];
        setJobList(results);
        setNextPageUrl(response.next || null);
        setPrevPageUrl(response.previous || null);
        
        const count = response.count || 0;
        setTotalPages(count > 0 ? Math.ceil(count / limit) : 1);
      } catch (error) {
        console.error('Error fetching job list:', error);
      } finally {
        setLoading(false);
      }
    };
    fetchJobs();
  }, [userToken]);
  // Generate query string Ends

  // Pagination Starts
  const handlePageChange = async (pageNum) => {
    setLoading(true);
    try {
      const queryString = generateQueryString(userData.id, pageNum);
      const response = await getAllVerifiedJobListFilter( queryString, userToken);
      setJobList(response.results || []);
      setNextPageUrl(response.next || null);
      setPrevPageUrl(response.previous || null);
      setCurrentPage(pageNum >= totalPages ? totalPages : pageNum); 
    } catch (error) {
      console.error('Error fetching page of jobs:', error);
    } finally {
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   const initialFollowingStatus = {};
  //   jobList.forEach(job => {
  //     initialFollowingStatus[job.posted_by.id] = job.posted_by.userfollow_status;
  //   });
  //   setFollowingStatus(initialFollowingStatus);
  // }, [jobList]);

  const renderPagination = () => {
    const pages = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          disabled={i === currentPage}
          className="btn pagination-digits-button"
          onClick={() => handlePageChange(i)}
        >
          {i}
        </button>
      );
    }
    return pages;
  };
  // Pagination Ends


  const navigate = useNavigate();

  const handleCompanyClick = (companyId) => {
    navigate(`${apiRoutes.company_jobs}${companyId}`);
  };

  // console.log("jobListjobListjobList", jobList);

  return (
    <>
        <EmployerMyJobsScriptCss />
        <NavbarCrew />
        <div className="my-jobs-container-head-div">
          <div className="container my-jobs-container">
            <div className="my-jobs-head-container">
              <h1 className="my-jobs-head-container-title">Jobs Posted By You</h1>
            </div>
          </div>
        </div>

            <>
                <div className="all-jobs-div">
                  <Container className="all-jobs-container">
                      <Row>
                          <Col md={6} className="all-jobs-col-1">
                              <div className="jobs-card-container">
                                {jobList && jobList.map((job, index) => (
                                        <EmployerMyJobCard
                                            key={job.id || index}
                                            job={job}
                                            // rankData={job.posted_by.rank_id}
                                            // isFollowing={followingStatus[job.posted_by && job.posted_by.id]}
                                            // onFollowToggle={() => handleFollowToggle(job.id)}
                                            userData={userData}
                                            userToken={userToken}
                                        />
                                ))}

                                {jobList && jobList.length ===0 && (
                                  <div className="empty-data-container">
                                      <LazyLoadImage className="empty-data-image" src="/static/crew/assets/img/images/empty_img.svg" alt="Empty" />
                                      <h1 className="empty-tab-text">No Data Available!</h1>
                                  </div>
                                )}
                              </div>
                          </Col>
                          <Col md={6} className="all-jobs-col-2">
                            <div className="other-column-container">
                              <div className="filter-container">
                                <div className="filter-col-header filter-col-header-myjobs">
                                  <LazyLoadImage src="/static/crew/assets/img/icons/BookmarkSimple.svg" alt="Filter Icon" />
                                  <h1 className="heading-filter">Saved Profiles</h1>
                                </div>
                                {featuredCompanies.map((company, index) => (
                                  company.user_id ? (
                                    <div 
                                      key={index} 
                                      onClick={() => handleCompanyClick(company.user_id)} 
                                      className="featured-company-names-div"
                                    >
                                      <img 
                                        className="feature-company-info-img" 
                                        src={company.user_id.profilePic || ""} 
                                        alt="Profile" 
                                      />
                                      <div className="featured-company-info-div">
                                        <h1 className="feature-company-info-name">{company.user_id.first_name}</h1>
                                        <p className="feature-company-info-rank">{company.user_id.rank.name}</p>
                                      </div>
                                    </div>
                                  ) : null
                                ))}
                              </div>
                            </div>
                          </Col>
                      </Row>
                  </Container>
              </div>

              {jobList && jobList.length !== 0 && (
                <div className="pagination-container">
                  <button className="btn pagination-prev-button"  
                    disabled={!prevPageUrl}
                    onClick={() => handlePageChange(currentPage - 1)}>
                    <LazyLoadImage  src="/static/crew/assets/img/arrows/arrow_right_blue.svg"  />
                  </button>
                  {renderPagination()}
                  <button className="btn pagination-next-button" 
                    disabled={!nextPageUrl}
                    onClick={() => handlePageChange(currentPage + 1)}>
                    <LazyLoadImage  src="/static/crew/assets/img/arrows/arrow_right_blue.svg"  />
                  </button>
                </div>
              )}

            </>
        <Footer />
        <Scripts />
        <EmployerMyJobsScript />
    </>
  );
};

export default EmployerMyJobs
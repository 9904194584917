import React, { useState } from 'react';
import { subscriptionConfirmModal, subscriptionSuccessModal } from './subscriptionModal';
import { jobPostTopUpPurchasePlan } from '../apiService';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';


const CustomTopUpPlanCards = ({ indexKey, planName, planId, credits, validity, dailyLimit, profileAsStory, supportText, pagePlanText, userToken, planType, freePlanCheck }) => {
    const [showConfirmModal, setShowConfirmModal] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);

    const handleCloseConfirmModal = () => setShowConfirmModal(false);
    const handleShowConfirmModal = () => setShowConfirmModal(true);

    const handleCloseSuccessModal = () => setShowSuccessModal(false);
    const handleShowSuccessModal = () => setShowSuccessModal(true);

    // For jobPostNumber Satrts
    const [jobPostNumber, setjobPostNumber] = useState('');
    const [jobPostPointCalculate, setJobPostPointCalculate] = useState(0.00);
    const [errorjobPostNumber, seterrorjobPostNumber] = useState('');

    const handleJobPostNumberChange = (e) => {
        const value = e.target.value;
        const pattern = /^[0-9]*$/;
        if (pattern.test(value)) {
            setjobPostNumber(value);
            setJobPostPointCalculate(value ? value * 100 : 0);
            seterrorjobPostNumber('');
        } else {
            seterrorjobPostNumber('Only numbers are allowed');
        }
    };
    // For jobPostNumber Ends


    const handleConfirmApplyNew = async () => {
        try {
            const jobPostPlanTopUpData = new FormData();
            jobPostPlanTopUpData.append('post_purchased', jobPostNumber);
            jobPostPlanTopUpData.append('points_used', jobPostPointCalculate);
            await jobPostTopUpPurchasePlan(jobPostPlanTopUpData, userToken);
            setjobPostNumber('')
            setJobPostPointCalculate(0.00)
            handleCloseConfirmModal();
            handleShowSuccessModal();
        } catch (error) {
            console.error('Error applying subscription:', error);
        }
    };


    return (
        <>
            <div className="col-md-3 subscription-plan-one">
                <div className="subscription-plan-part-one">
                    <div className="subscription-plan-part-one-head custom-postjob-topup-head">
                        <div className="subscription-head-tag-div">
                            <h1 className="subscription-plan-part-one-heading resume-download-plan-card-heading">{planName}</h1>
                        </div>
                        <p className="subscription-plan-part-one-detail">{pagePlanText}</p>
                        <input type="text" className="form-text-input" placeholder="Enter no. of job posts" 
                            value={jobPostNumber} 
                            onChange={handleJobPostNumberChange} 
                            required />
                        {errorjobPostNumber && <p className="form-error-text">{errorjobPostNumber}</p>}
                    </div>

                    <div className="subscription-plan-part-one-credit-container">
                        {(planType === "Crewing-Agent" && planId ===1 ) ?
                                <p className="subscription-plan-part-one-credit-amount">Free Plan</p>
                            :
                            <>
                                <p className="subscription-plan-part-one-credit-amount">{jobPostPointCalculate}</p>
                                <p className="subscription-plan-part-one-credit-text">Credits</p>
                            </>
                        }
                    </div>
                </div>
                <div className="subscription-plan-part-two">
                    <div className="subscription-plan-part-two-list-item"><LazyLoadImage className="subscription-plan-part-two-item-img" src="/static/crew/assets/img/icons/fi_check_blue.svg" alt="Check Icon" />
                        <p className="subscription-plan-part-two-item-detail">{validity}</p>
                    </div>
                    <div className="subscription-plan-part-two-list-item"><LazyLoadImage className="subscription-plan-part-two-item-img" src="/static/crew/assets/img/icons/fi_check_blue.svg" alt="Check Icon" />
                        <p className="subscription-plan-part-two-item-detail">{supportText}</p>
                    </div>
                </div>
                <div className="subscription-plan-choose-plan-btn">
                    <button className="btn btn-primary choose-plan-btn" disabled={freePlanCheck === 'disable' && planId ===1} type="button" onClick={handleShowConfirmModal}>
                        Choose Plan&nbsp;&nbsp;
                        <LazyLoadImage src="/static/crew/assets/img/arrows/arrow_right_blue.svg" alt="Arrow Icon" />
                    </button>
                </div>
            </div>
            {subscriptionConfirmModal({
                show: showConfirmModal,
                handleClose: handleCloseConfirmModal,
                handleConfirmApply: handleConfirmApplyNew
            })}
            {subscriptionSuccessModal({
                show: showSuccessModal,
                handleClose: handleCloseSuccessModal
            })}
        </>
    );
}

export default CustomTopUpPlanCards;

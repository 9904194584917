import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const maritalStatusMap = {
  1: 'Single',
  2: 'Married',
  3: 'Divorced'
};

const ProfileStatusColumn = ({userSelectedData, userStatusDataList}) => {

    const cdcData = JSON.parse(userStatusDataList.CDC_Issuing_Authority);
    const CDCissuingAuthority = cdcData.custom_name || cdcData.issuing_authority;

    const PassportData = JSON.parse(userStatusDataList.Passport_Issuing_Authority);
    const PassportissuingAuthority = PassportData.custom_name || PassportData.issuing_authority;

    const stcwData = JSON.parse(userStatusDataList.STCW_Issuing_Authority);
    const stcwissuingAuthorities = stcwData.map(item => item.custom_name || item.issuing_authority).join(', ');

    const COCData = JSON.parse(userStatusDataList.valid_COC_Issuing_Authority);
    const COPData = JSON.parse(userStatusDataList.valid_COP_Issuing_Authority);
    const watchKeepingData = JSON.parse(userStatusDataList.valid_Watch_keeping_Issuing_Authority);

    const COCDataAuthority = COCData && COCData.length > 0 ? COCData.map(item => item.custom_name || item.issuing_authority).filter(authority => authority).join(', ') : '';
    const COPDataAuthority = COPData && COPData.length > 0 ? COPData.map(item => item.custom_name || item.issuing_authority).filter(authority => authority).join(', ') : '';
    const watchKeepingDataAuthority = watchKeepingData && watchKeepingData.length > 0 ? watchKeepingData.map(item => item.custom_name || item.issuing_authority).filter(authority => authority).join(', ') : '';

    return (
          <>
            <div className="job-status-all-container">
                <div className="job-status-container-div">
                    <div className="job-status-top-part-container">
                        <div className="job-status-top-part">
                            <div className="job-status-top-left">
                                <LazyLoadImage className="job-status-info-img" src={userSelectedData.profilePic} alt="Avatar" />
                                <div className="job-status-info-container">
                                    <p className="job-status-info-name">{userSelectedData.first_name}</p>
                                    <p className="job-status-info-company">{userSelectedData.rank_id.name}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="profile-status-bottom-part-container">
                        <div className="job-status-bottom-part">
                            <div className="profile-basic-details-container">
                                <LazyLoadImage src="/static/crew/assets/img/icons/User_light_orange.svg" alt="Basic Details Icon" />
                                <p className="profile-basic-details-header">Basic Details</p>
                            </div>
                            <div className="profile-basic-info-container">
                                <div className="profile-basic-info-container-left">
                                    <div className="profile-basic-info-header-container">
                                        <p className="profile-basic-info-header">Name:</p>
                                        <p className="profile-basic-info-header">Gender:</p>
                                        <p className="profile-basic-info-header">Date Of Birth:</p>
                                    </div>
                                    <div className="profile-basic-info-header-container">
                                        <p className="profile-basic-info-header">{userSelectedData.first_name}</p>
                                        <p className="profile-basic-info-header">{userSelectedData.gender === 1? "Male": "Female"}</p>
                                        <p className="profile-basic-info-header">{userSelectedData.dob}</p>
                                    </div>
                                </div>
                                <div className="profile-basic-info-container-left">
                                    <div className="profile-basic-info-header-container">
                                        <p className="profile-basic-info-header">Marital Status:</p>
                                        <p className="profile-basic-info-header">Nationality:</p>
                                        <p className="profile-basic-info-header">Passport:</p>
                                    </div>
                                    <div className="profile-basic-info-value-container">
                                        <p className="profile-basic-info-header">{maritalStatusMap[userSelectedData.marital_status]}</p>
                                        <p className="profile-basic-info-header">{userSelectedData.country.country_name}</p>
                                        <p className="profile-basic-info-header">{PassportissuingAuthority}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="job-status-bottom-part">
                            <div className="profile-basic-details-container">
                                <LazyLoadImage src="/static/crew/assets/img/icons/job_bag.svg" alt="Professional Details Icon" />
                                <p className="profile-basic-details-header">Professional Details</p>
                            </div>
                            <div className="profile-basic-info-container">
                                <div className="profile-basic-info-container-left">
                                    <div className="profile-basic-info-header-container">
                                        <p className="profile-basic-info-header">Rank:</p>
                                        <p className="profile-basic-info-header">CDC:</p>
                                        <p className="profile-basic-info-header">STCW:</p>
                                    </div>
                                    <div className="profile-basic-info-header-container">
                                        <p className="profile-basic-info-header">{userSelectedData.rank_id.name}</p>
                                        <p className="profile-basic-info-header">{CDCissuingAuthority}</p>
                                        <p className="profile-basic-info-header">{stcwissuingAuthorities}</p>
                                    </div>
                                </div>
                                <div className="profile-basic-info-container-left">
                                    <div className="profile-basic-info-header-container">
                                      {COCDataAuthority && (
                                        <p className="profile-basic-info-header">COC:</p>
                                      )}
                                      {COPDataAuthority && (
                                        <p className="profile-basic-info-header">COP:</p>
                                      )}
                                      {watchKeepingDataAuthority && (
                                        <p className="profile-basic-info-header">Watch Keeping:</p>
                                      )}
                                    </div>
                                    <div className="profile-basic-info-header-container">
                                      {COCDataAuthority && (
                                        <p className="profile-basic-info-header">{COCDataAuthority}</p>
                                      )}
                                      {COPDataAuthority && (
                                        <p className="profile-basic-info-header">{COPDataAuthority}</p>
                                      )}
                                      {watchKeepingDataAuthority && (
                                        <p className="profile-basic-info-header">{watchKeepingDataAuthority}</p>
                                      )}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
          </>
    );
};

export default ProfileStatusColumn;

import React from 'react'
import Head from '../components/layouts/Head';
import NavbarHome from '../components/layouts/NavbarHome';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import NavbarCrew from '../components/layouts/NavbarCrew';
import { getIsLoggedIn } from '../components/tokenManager';
import { apiRoutes } from '../config/apiConfig';

const NotFoundPageTailScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const NotFoundPageCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/404.css" />
    </>
  );
};

const NotFoundPage = () => {
  const isLoggedIn = getIsLoggedIn();

  const handleGoBack = () => {
    window.history.back();
  };

  const handleContactUs = () => {
    window.location.href = apiRoutes.crew_help_support; // Navigate to the contact page

  };


  return (
    <>
      <Head />
      <NotFoundPageCss />
      {!isLoggedIn ? <NavbarHome /> : <NavbarCrew />}

      <div className="notfound-container">
        <div className="container notfound-container-2-col">
          <div className="row">
            <div className="col-md-7 notfound-container-left-col">
              <div className="notfound-container-left">
                <div className="notfound-container-left-content">
                  <h1 className="notfound-container-left-content-head">Oops! Page not found</h1>
                  <p className="notfound-container-left-content-body">
                    Something went wrong. It looks like your requested page could not be found. It seems like the link is broken or the page has been removed.
                  </p>
                </div>
                <div className="notfound-container-left-btn">
                  <button className="btn btn-primary notfound-container-goback-btn" type="button" onClick={handleGoBack}>
                    <img src="/static/crew/assets/img/arrows/ArrowLeftWhite.svg" alt="Go Back" />&nbsp;Go back
                  </button>
                  <button className="btn btn-primary notfound-container-contact-btn" type="button" onClick={handleContactUs}>
                    Contact Us
                  </button>
                </div>
              </div>
            </div>
            <div className="col-md-5">
              <img src="/static/crew/assets/img/images/404.jpeg" alt="404 Not Found" />
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scripts />
      <NotFoundPageTailScript />
    </>
  );
};

export default NotFoundPage;

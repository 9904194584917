import React, { useState, useEffect } from 'react';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import Head from '../components/layouts/Head';
import NavbarHome from '../components/layouts/NavbarHome';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import NavbarCrew from '../components/layouts/NavbarCrew';
import { getIsLoggedIn } from '../components/tokenManager';
import { getLocalJsonItem, getLocalItem } from '../components/tokenManager';
import ChangeEmail from '../components/layouts/changeEmail';
import ChangeMobileNumber from '../components/layouts/changeMobileNumber';
import ChangePassword from '../components/layouts/changePassword';

const CrewSettingsTailScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const CrewSettingsCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/settings.css" />
    </>
  );
};

const CrewSettings = () => {
  const isLoggedIn = getIsLoggedIn(); 
  const userData =  getLocalJsonItem("userData");
  const userToken = getLocalItem("accessToken"); 
  const [activeTab, setActiveTab] = useState('mobile');

  const renderContent = () => {
      switch (activeTab) {
          case 'mobile':
              return <ChangeMobileNumber />;
          case 'email':
              return <ChangeEmail />;
          case 'password':
              return <ChangePassword />;
          default:
              return null;
      }
  };

  return (
    <>
        <Head />
        <ToastContainer />
        {!isLoggedIn ?
          <>
            <CrewSettingsCss />
            <NavbarHome />
          </> 
          :
          <>
            <CrewSettingsCss />
            <NavbarCrew />
          </>
        }
        <div className="container wallet-header-container setting-div">
                <div className="wallet-header-div">
                    <h1 className="wallet-header-heading">Settings</h1>
                    <p className="wallet-header-detail">
                        Easily update your email, password, and mobile number to keep your account secure and up-to-date. Make changes anytime to ensure your information stays current.
                    </p>
                </div>
            </div>

            <div className="container settings-tab-container">
                <div className="settings-tab-container-div">
                    <div 
                        className={`settings-tab-divisions ${activeTab === 'mobile' ? 'active' : ''}`} 
                        onClick={() => setActiveTab('mobile')}
                    >
                        {activeTab === 'mobile' ? 
                        <LazyLoadImage className="settings-tab-divisions-img" src="/static/crew/assets/img/icons/iPhone_blue.svg" alt="Change Mobile Number" />
                        : 
                        <LazyLoadImage className="settings-tab-divisions-img" src="/static/crew/assets/img/icons/iPhone.svg" alt="Change Mobile Number" />
                        }
                        <p className={`settings-tab-divisions-text ${activeTab === 'mobile' ? 'active' : ''}`}>Update Mobile Number</p>
                    </div>
                    <div 
                        className={`settings-tab-divisions ${activeTab === 'email' ? 'active' : ''}`} 
                        onClick={() => setActiveTab('email')}
                    >
                        {activeTab === 'email' ? 
                          <LazyLoadImage className="settings-tab-divisions-img" src="/static/crew/assets/img/icons/Letter.svg" alt="Change Email" />
                         : 
                          <LazyLoadImage className="settings-tab-divisions-img" src="/static/crew/assets/img/icons/Letter_grey.svg" alt="Change Email" />
                         }
                        <p className={`settings-tab-divisions-text ${activeTab === 'email' ? 'active' : ''}`}>Update Email</p>
                    </div>
                    <div 
                        className={`settings-tab-divisions ${activeTab === 'password' ? 'active' : ''}`} 
                        onClick={() => setActiveTab('password')}
                    >
                        {activeTab === 'password' ? 
                          <LazyLoadImage className="settings-tab-divisions-img" src="/static/crew/assets/img/icons/LockPassword_blue.svg" alt="Change Password" />
                         : 
                          <LazyLoadImage className="settings-tab-divisions-img" src="/static/crew/assets/img/icons/LockPassword.svg" alt="Change Password" />
                         }

                        <p className={`settings-tab-divisions-text ${activeTab === 'password' ? 'active' : ''}`}>Update Password</p>
                    </div>
                </div>
            </div>
            <div className="container settings-tab-content-container">
                {renderContent()}
            </div>
            <div style={{paddingBottom: '122px' }}></div>
        <Footer />
        <Scripts />
        <CrewSettingsTailScript />
    </>
  );
};

export default CrewSettings
import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import { getSubscriptionPlans, getCrewBoostedPlan, getCrewHighlightPlan
      } from '../components/apiService';
import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';
import PlanCard from '../components/layouts/PlanCards';
import { apiRoutes } from '../config/apiConfig';

const CrewSubscriptionPlanScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/discover_page.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/subscription.css" />
    </>
  );
};

const CrewSubscriptionPlanScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const CrewSubscriptionPlan = () => {
  const userToken = getLocalItem("accessToken"); 
  const userData =  getLocalJsonItem("userData");
  const [activeTab, setActiveTab] = useState(0);
  const boostplantype = 3
  const highlightplantype = 1
  const highlightPageHeaderText = "Our profile highlighting feature ensures that your crew resume stands out and appears at the top of search results, increasing visibility to potential employers.";
  const boostPageHeaderText = "Increase your visibility to potential employers by utilizing our profile boosting ensuring more inquiries and job opportunities come your way."
  const activePageHeaderText = "Increase your visibility to potential employers by utilizing our profile boosting ensuring more inquiries and job opportunities come your way."
  const highlightPagePlanText = "Enhance your profile visibility by highlighting it for ";
  const boostPagePlanText = "Enhance your profile visibility by boosting it for ";
  const opportunitiesList = {
    0 : 5,
    1 : 10,
    2 : 15,
    3 : 20,
    4 : 25,
    5 : 25,
    6 : 25,
    7 : 25,
    8 : 25,
    9 : 25,
    10 : 25
  }

  const handleTabClick = (index) => {
    setActiveTab(index);
  }

  // get plans for Crew boosted Starts
  const [crewBoostedPlans , setCrewBoostedPlans] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getSubscriptionPlans( boostplantype ,userToken);
        setCrewBoostedPlans(response);
      } catch (error) {
        console.error('Error fetching Plan list:', error);
      } 
    };
    fetchJobs();
  }, [activeTab === 0]);
  // get plans for Crew boosted Ends

  // get plans for Crew Highlight Starts
  const [crewHighlightPlans , setCrewHighlightPlans] = useState([]);

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getSubscriptionPlans( highlightplantype ,userToken);
        setCrewHighlightPlans(response);
      } catch (error) {
        console.error('Error fetching Plan list:', error);
      } 
    };
    fetchJobs();
  }, [activeTab === 1]);
  // get plans for Crew Highlight Ends
  
  // get plans for Crew boosted Starts
  const [crewBoostedSubscribedPlan , setCrewBoostedSubscribedPlan] = useState();
  const [crewHighlightSubscribedPlan , setCrewHighlightSubscribedPlan] = useState();

  useEffect(() => {
    const fetchJobs = async () => {
      try {
        const response = await getCrewBoostedPlan( userData.crew_details_user.id ,userToken);
        setCrewBoostedSubscribedPlan(response);
        const response1 = await getCrewHighlightPlan( userData.id ,userToken);
        setCrewHighlightSubscribedPlan(response1);
      } catch (error) {
        console.error('Error fetching Plan list:', error);
      } 
    };
    fetchJobs();
  }, [activeTab === 2]);
  // get plans for Crew boosted Ends
  
  return (
    <>
        <CrewSubscriptionPlanScriptCss />
        <NavbarCrew />

        <div className="container">
            <div className="row">
                <div className="col-md-6 subscription-heading-left-container">
                  <div className="subscription-heading-tabs-container">
                      <div className={"subscription-heading-tab"} onClick={() => handleTabClick(0)}>
                          <LazyLoadImage className="subscription-heading-tab-icon" 
                            src=  { activeTab === 0 ? 
                                "/static/crew/assets/img/icons/UserCircle.svg" : 
                                "/static/crew/assets/img/icons/UserCircle_blue.svg"
                              }
                            alt="Boost Profile" />
                          <p className={`subscription-heading-tab-text ${activeTab === 0 ? 'active' : ''}`}>Boost Profile</p>
                      </div>
                      <div className={"subscription-heading-tab"} onClick={() => handleTabClick(1)}>
                          <LazyLoadImage className="subscription-heading-tab-icon" 
                            src=  { activeTab === 1 ? 
                              "/static/crew/assets/img/icons/BookmarkSimple_blue.svg" : 
                              "/static/crew/assets/img/icons/BookmarkSimple.svg" 
                            }
                            alt="Highlight Profile" />
                          <p className={`subscription-heading-tab-text ${activeTab === 1 ? 'active' : ''}`}>Highlight Profile</p>
                      </div>
                      <div className={"subscription-heading-tab"} onClick={() => handleTabClick(2)}>
                          <LazyLoadImage className="subscription-heading-tab-icon" 
                            src=  { activeTab === 2 ? 
                              "/static/crew/assets/img/icons/Stack_blue.svg" : 
                              "/static/crew/assets/img/icons/Stack.svg" 
                            }
                            alt="Active Plans" />
                          <p className={`subscription-heading-tab-text ${activeTab === 2 ? 'active' : ''}`}>Active Plans</p>
                      </div>
                  </div>
                  <div className="subscription-sub-heading-container">
                      <h1 className="subscription-sub-heading">Increase your visibility to potential employers</h1>
                      <p className="subscription-sub-heading-details">
                            {activeTab === 0 && boostPageHeaderText}
                            {activeTab === 1 && highlightPageHeaderText}
                            {activeTab === 2 && activePageHeaderText}
                      </p>
                  </div>
                </div>
                <div className="col-md-6 subscription-heading-img-container">
                    <LazyLoadImage src="/static/crew/assets/img/images/welcome_abroad_img.svg" alt="Welcome Abroad" />
                </div>
            </div>
        </div>

          {activeTab === 0 && (
              <div className="container subscription-plans-list-container">
                <div className="row subscription-plans-container">
                  {crewBoostedPlans && (
                    crewBoostedPlans.map((planData, index) => (
                        <PlanCard
                          indexKey={index}
                          key={planData.id} 
                          planName={planData.plan_name.plan_name}
                          planId={planData.plan_name.id}
                          credits={planData.points}
                          validity={planData.days_active}
                          opportunities={opportunitiesList[index] || 0} 
                          profileAsStory="Profile as story"
                          supportText="24/7 Critical Support"
                          pagePlanText={boostPagePlanText}
                          planType={"boost"}
                        />
                    ))
                  )}
                </div>
            </div>
          )}
          {activeTab === 1 && (
            <div className="container subscription-plans-list-container">
                <div className="row subscription-plans-container">
                  {crewHighlightPlans && (
                    crewHighlightPlans.map((planData, index) => (
                        <PlanCard
                          indexKey={index}
                          key={planData.id} 
                          planName={planData.plan_name.plan_name}
                          planId={planData.plan_name.id}
                          credits={planData.points}
                          validity={planData.days_active}
                          opportunities={opportunitiesList[index] || 0} 
                          profileAsStory="Top of search results"
                          supportText="24/7 Critical Support"
                          pagePlanText={highlightPagePlanText}
                          planType={"highlight"}
                        />
                    ))
                  )}
                </div>
            </div>
          )}
          {activeTab === 2 && (
              <div className="container active-subs-container">
              <div className="active-subs-container-div">
                  <div className="active-subs-header-container">
                      <h1 className="active-subs-header-title">Plans</h1>
                      <h1 className="active-subs-header-title">Status</h1>
                  </div>
                  {crewHighlightSubscribedPlan && crewHighlightSubscribedPlan[0] && (
                    <div className="active-subs-list-container">
                        <div className="active-subs-list-item-detail">
                            <h1 className="active-subs-list-item-title">
                              Profile Highlight
                            </h1>
                            <div className="active-subs-list-item-info-div">
                                {/* <p className="active-subs-list-item-info"> days validity</p>
                                <p className="active-subs-list-item-info">•</p> */}
                                <p className="active-subs-list-item-info">{crewHighlightSubscribedPlan[0].days_active} days remaining</p>
                            </div>
                        </div>
                        <div className="active-subs-list-item-status">
                            <LazyLoadImage className="active-subs-list-item-status-img" src="/static/crew/assets/img/icons/CheckCircleGreen.svg" alt="Status Icon" />
                            <p className="active-subs-list-item-status-text">
                              Active
                            </p>
                        </div>
                    </div>
                  )}
                  {crewBoostedSubscribedPlan  && (
                    <div className="active-subs-list-container">
                        <div className="active-subs-list-item-detail">
                            <h1 className="active-subs-list-item-title">Profile Boosting</h1>
                            <div className="active-subs-list-item-info-div">
                                {/* <p className="active-subs-list-item-info">7 days validity</p>
                                <p className="active-subs-list-item-info">•</p> */}
                                <p className="active-subs-list-item-info">{crewBoostedSubscribedPlan.days_active} days remaining</p>
                            </div>
                        </div>
                        <div className="active-subs-list-item-status">
                            <LazyLoadImage className="active-subs-list-item-status-img" src="/static/crew/assets/img/icons/CheckCircleGreen.svg" alt="Status Icon" />
                            <p className="active-subs-list-item-status-text">
                              {crewBoostedSubscribedPlan.is_active ? 
                              "Active" : "Not Active"
                              }
                            </p>
                        </div>
                    </div>
                  )}  
                  {(!crewHighlightSubscribedPlan || !crewHighlightSubscribedPlan[0]) && !crewBoostedSubscribedPlan && (
                      <div className="active-subs-no-list-container">
                          <div className="active-subs-no-list">
                              <p className="active-subs-no-list-text">No active plans found!</p>
                              <a href={apiRoutes.get_wallet}> 
                                <p className="active-subs-no-list-link">Buy Now</p>
                              </a>
                          </div>
                      </div>
                  )}
              </div>
          </div>
          )}
        <div style={{ paddingBottom: '122px' }}></div>
        <Footer />
        <Scripts />
        <CrewSubscriptionPlanScript />
    </>
  );
};

export default CrewSubscriptionPlan
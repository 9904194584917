import React from 'react';


const HomeCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/css/dh-row-titile-text-image-right-1.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
    </>
  );
};



const Head = () => {
  return (
    <>
      <meta charSet="utf-8" />
      <meta name="viewport" content="width=device-width, initial-scale=1.0, shrink-to-fit=no" />
      <title>Join My Ship</title>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <HomeCss />
    </>
  );
};

export default Head;

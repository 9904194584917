import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Scripts from '../components/layouts/Scripts';
import { Container, Modal, Row, Col, Form, Button, Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import { formatDateNew , getCreditPointsByUserId, getRankList, searchCV,
        getCountryList
        } from '../components/apiService';
import { getLocalItem, getLocalJsonItem, setLocalJsonItem } from '../components/tokenManager';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { apiRoutes } from '../config/apiConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';


const CvSearchScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-dropdown-1.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Text-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Bootstrap-4-Custom-Radio.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Search-Input-responsive.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/cvsearch.css" />

    </>
  );
};

const CvSearchScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const CvSearch = () => {
    const userToken = getLocalItem("accessToken"); 
    const userData =  getLocalJsonItem("userData");
    let rankItemMapped =  getLocalJsonItem("rankMapping");
    let countryItemMapped =  getLocalJsonItem("countryMapping");


    // const [userCreditPoint , setUserCreditPoint] = useState();
    // Rank Dropdown Starts
    const [searchRankQuery, setSearchRankQuery] = useState('');
    const [rankItems, setRankItems] = useState([]);
    const [countryItems, setCountryItems] = useState([]);
    const [selectedRankItem, setSelectedRankItem] = useState(null);
    const [rankMapping, setRankMapping] = useState({});

    useEffect(() => {
        const fetchJobs = async () => {
          try {
            // const response = await getCreditPointsByUserId(userData.id, userToken);
            // setUserCreditPoint(response);
            const response1 = await getRankList();
            setRankItems(response1);
            const response2 = await getCountryList();
            setCountryItems(response2);

          } catch (error) {
            console.error('Error fetching job list:', error);
          } 
        };
        fetchJobs();
      }, []);

    useEffect(() => {
        const fetchJobs = async () => {
            try {
                if (!rankItemMapped || Object.keys(rankItemMapped).length === 0) {
                    const transformedData = rankItems.reduce((acc, curr) => {
                        acc[curr.id] = curr;
                        return acc;
                    }, {});
                    setLocalJsonItem("rankMapping", transformedData);
                    setRankMapping(transformedData);
                }
                if (!countryItemMapped || Object.keys(countryItemMapped).length === 0) {
                    const transformedCountryData = countryItems.reduce((acc, curr) => {
                        acc[curr.id] = curr;
                        return acc;
                    }, {});
                    setLocalJsonItem("countryMapping", transformedCountryData);
                    setCountryItems(transformedCountryData);
                }
            } catch (error) {
                console.error('Error fetching job list:', error);
            }
        };
        fetchJobs();
    }, [rankItems, countryItems]);

    const handleRankItemClick = (item) => {
        setSelectedRankItem(item);
        setSearchRankQuery(''); 
    };

    const filteredRankItems = rankItems.filter(item =>
        item.name.toLowerCase().includes(searchRankQuery.toLowerCase())
    );
    // Rank Dropdown Ends 

    const [limit, setLimit] = useState(3);
    const [rankUserList , setRankUserList] = useState([]);
    const [nextPageUrl , setNextPageUrl] = useState(null);
    const [prevPageUrl , setPrevPageUrl] = useState(null);
    const [currentPage , setCurrentPage] = useState(1);
    const [totalPages , setTotalPages] = useState(1);

    const generateQueryString = (currentRankName, pageData) => {
        const search_typeParams = "search_type=2";
        const rankParams = `search_key=${currentRankName}`;
        const paginationParams = `limit=${limit}&page=${pageData}`;
        return `${search_typeParams}&${rankParams}&${paginationParams}`;
    };

    useEffect(() => {
        const fetchJobs = async () => {
            rankItemMapped =  getLocalJsonItem("rankMapping");
            countryItemMapped =  getLocalJsonItem("countryMapping");
          try {
            const queryString = generateQueryString( selectedRankItem && selectedRankItem.name, currentPage);
            const response = await searchCV(queryString, userToken);
            const results = response.results || [];
            setRankUserList(results);
            setNextPageUrl(response.next || null);
            setPrevPageUrl(response.previous || null);
            
            const count = response.count || 0;
            setTotalPages(count > 0 ? Math.ceil(count / limit) : 1);
          } catch (error) {
            console.error('Error fetching job list:', error);
          } 
        };

        fetchJobs();
    }, [selectedRankItem]);


    const handlePageChange = async (pageNum) => {
        try {
            const queryString = generateQueryString(selectedRankItem && selectedRankItem.name, pageNum);
            const response = await searchCV(queryString, userToken);
            setRankUserList(response.results || []);
            setNextPageUrl(response.next || null);
            setPrevPageUrl(response.previous || null);
            setCurrentPage(pageNum >= totalPages ? totalPages : pageNum); 
        } catch (error) {
          console.error('Error fetching page of jobs:', error);
        } 
      };

    const renderPagination = () => {
        const pages = [];
        for (let i = 1; i <= totalPages; i++) {
          pages.push(
            <button
              key={i}
              disabled={i === currentPage}
              className="btn pagination-digits-button"
              onClick={() => handlePageChange(i)}
            >
              {i}
            </button>
          );
        }
        return pages;
      };

    const renderTableRows = () => {
        const start = (currentPage - 1) * limit;
        const end = start + limit;
        return rankUserList.map((result, index) => (
            <tr className="cv-search-table-section-row" key={index}>
                <td className="cv-search-table-section-cell">
                    <div className="cv-search-table-section-cell-name">
                        <img className="cv-search-table-section-cell-name-img" src={result.profilePic} alt="Profile" />
                        <p className="cv-search-table-section-cell-name-title">{result.first_name}</p>
                    </div>
                </td>
                <td className="cv-search-table-section-cell">
                    {rankItemMapped[result.rank_id].name}
                </td>
                <td className="cv-search-table-section-cell">{result.gender === 1 ? 'Male' : 'Female'}</td>
                <td className="cv-search-table-section-cell">{countryItemMapped[result.country].country_name}</td>
                <td className="cv-search-table-section-cell">
                    <a href={`${apiRoutes.employer_view_profile}${result.id}`}>
                        <div className="cv-search-table-section-cell-action">
                            <img className="cv-search-table-section-cell-action-img" src="/static/crew/assets/img/icons/Eye_orange.svg" alt="View Profile" />
                            <p className="cv-search-table-section-cell-action-title">View Profile</p>
                        </div>
                    </a>
                </td>
            </tr>
        ));
    };

    // console.log("rankUserListrankUserList", rankUserList);

    return (
        <>
            <CvSearchScriptCss />
            <NavbarCrew />
            <ToastContainer />
            <div className="cv-search-header-section">
                <div className="container cv-search-header-container">
                    <div className="cv-search-header-section">
                        <div className="cv-search-header-container-div">
                            <h1 className="cv-search-header-container-title">Search CV</h1>
                            <p className="cv-search-header-container-text">
                                Search resume and connect with top crew applicants instantly!
                            </p>
                        </div>
                        <div className="float-start float-md-end mt-5 mt-md-0 search-area cv-search-input-box-div">
                            <FontAwesomeIcon icon={faSearch} className="float-start search-icon" />
                            <Dropdown>
                                <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button cv-search-box" variant="none" id="dropdown-basic">
                                    <InputGroup className="mb-3 search-rank-input">
                                    <FormControl 
                                        placeholder={(selectedRankItem && selectedRankItem.name) ? selectedRankItem.name : 
                                            "Select Rank"} 
                                        aria-label="Search" aria-describedby="basic-addon2" value={searchRankQuery} onChange={(e) => setSearchRankQuery(e.target.value)} required={!selectedRankItem} />
                                    </InputGroup>
                                </Dropdown.Toggle>
                                <Dropdown.Menu className='dropdown-scrollable'>
                                    {filteredRankItems.map((item, index) => (
                                        <Dropdown.Item key={index} onClick={() => handleRankItemClick(item)}>
                                            {item.name}
                                        </Dropdown.Item>
                                    ))}
                                    {
                                        filteredRankItems.length === 0 && (
                                        <Dropdown.Item disabled>No items found</Dropdown.Item>
                                    )}
                                </Dropdown.Menu>
                            </Dropdown>
                        </div>
                    </div>
                </div>
            </div>
            
            {rankUserList && rankUserList.length > 0 ?
                <div className="container cv-search-table-container">
                    <div className="cv-search-table-header-section">
                        <div className="cv-search-table-heading-div">
                            <p className="cv-search-table-head-title">{selectedRankItem && selectedRankItem.name}</p>
                            <p className="cv-search-table-head-count">
                                <span>{rankUserList.length}</span>&nbsp;Search Results
                            </p>
                        </div>
                        <div className="table-responsive cv-search-table-section">
                            <table className="table">
                                <thead className="cv-search-table-section-header">
                                    <tr className="cv-search-table-section-header-row">
                                        <th className="cv-search-table-section-header-cell">Name</th>
                                        <th className="cv-search-table-section-header-cell">Rank</th>
                                        <th className="cv-search-table-section-header-cell">Gender</th>
                                        <th className="cv-search-table-section-header-cell">Nationality</th>
                                        <th className="cv-search-table-section-header-cell">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="cv-search-table-section-body">
                                    {renderTableRows()}
                                </tbody>
                            </table>
                        </div>
                        {rankUserList && rankUserList.length > 0 && 
                            <nav className="cv-search-pagination-section">
                                <ul className="pagination cv-search-pagination-section-ul">
                                    <button className="btn pagination-prev-button"  
                                        disabled={!prevPageUrl}
                                        onClick={() => handlePageChange(currentPage - 1)}>
                                        <LazyLoadImage  src="/static/crew/assets/img/arrows/arrow_right_blue.svg"  />
                                    </button>
                                    {renderPagination()}
                                    <button className="btn pagination-next-button" 
                                        disabled={!nextPageUrl}
                                        onClick={() => handlePageChange(currentPage + 1)}>
                                        <LazyLoadImage  src="/static/crew/assets/img/arrows/arrow_right_blue.svg"  />
                                    </button>
                                </ul>
                            </nav>
                        } 
                    </div>
                </div>
            :
                <div className="container cv-search-no-data-container">
                    <div className="cv-search-no-data-container-div">
                        <img 
                            className="cv-search-no-data-container-div-img" 
                            src="/static/crew/assets/img/images/empty_img.svg" 
                            alt="No Data"
                        />
                        <p className="cv-search-no-data-container-div-text">Sorry! No data found</p>
                    </div>
                </div>
            }
            <div style={{height: "132px"}}></div>
            <Scripts />
            <CvSearchScript />
        </>
    );
};

export default CvSearch
import React, { useState, useRef } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import 'react-lazy-load-image-component/src/effects/blur.css'; 
import 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { Container, Row, Col, Card, Form, Button  } from 'react-bootstrap';
import { getLocalItem } from '../components/tokenManager';
import { auth } from '../components/firebase';
import 'firebase/auth';
import { apiRoutes } from '../config/apiConfig';

const SignupEmployeeMobileVerifyCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/login.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/signup.css" />
    </>
  );
};


const SignupEmployeeMobileVerify = () => {
    const navigate = useNavigate();
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const handleChange = (index, value) => {
        if (value.match(/^\d*$/)) {
            const newOtp = [...otp];
            newOtp[index] = value.slice(0, 1);
            setOtp(newOtp);
            if (index < otp.length - 1) {
                inputRefs.current[index + 1].focus();
            }
        }
    };

    const handleFocus = (index) => {
        if (index === otp.length - 1 && otp[index] !== '') {
            inputRefs.current[index].blur(); 
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        const enteredOtp = otp.join(''); 
        // console.log('Entered OTP:', enteredOtp);
        const getverificationId = getLocalItem("verificationId") ; 

        // console.log('window.confirmationResult', getverificationId);
        // console.log('window.confirmationResult', typeof getverificationId);

        const verifyOTP = async (otpDig) => {
            try {
                const credential = await auth.signInWithCredential(
                    auth.PhoneAuthProvider.credential(getverificationId, otpDig)
                  );
                // const credential = await signInWithPhoneNumber(auth, getverificationId, otpDig);
                // console.log('OTP verification successful:', credential);
            } catch (error) {
                console.error('OTP verification error:', error);
            }
        };

        verifyOTP(enteredOtp);

        // window.confirmationResult.confirm(enteredOtp).then((result) => {
        //     const user = result.user;
        //     console.log("result: ", result);
        //   }).catch((error) => {
        //     console.log("error: ", error);
        //     toast.error(error);
        //   });
        // navigate('/register/employer/form');

    };

    return (
        <>
            <SignupEmployeeMobileVerifyCss />
            <ToastContainer />
            <div className="row d-flex justify-content-center" style={{ background: 'url("/static/crew/assets/img/images/login_bg.png") center / cover no-repeat' }}>
                <Container>
                    <Row className="justify-content-center">
                        <Col md={6} xl={4} className="signin-login-number">
                            <Card style={{ borderRadius: '0px' }}>
                                <Card.Body className="text-center d-flex flex-column align-items-center" style={{ padding: '146px 66px 200px 66px', gap: '55px' }}>
                                    <div className="login-logo-container">
                                        <LazyLoadImage className="login-logo" src="/static/crew/assets/img/brands/newLogo.png" alt="New Logo" />
                                        <div>
                                            <a href="/register/employer/mobile">
                                                <LazyLoadImage  className="left-arrow" src='/static/crew/assets/img/arrows/ArrowLeft.svg' alt="Left Arrow" />
                                            </a>
                                        </div>
                                    </div>
                                    <div style={{ display: 'inline-grid', gap: '54px' }}>
                                        <div style={{ display: 'inline-grid', gap: '30px' }}>
                                            <div style={{ display: 'inline-grid', gap: '19px' }}>
                                                <h1 className="signup-heading">Verification</h1>
                                                <div className="signup-desc">
                                                    <p>Please enter the OTP received on your mobile</p>
                                                </div>
                                            </div>
                                            <div>
                                                <Form onSubmit={handleSubmit} style={{ gap: '22px', display: 'inline-grid' }}>
                                                    <div className="mb-3 number-label">
                                                        <div className="number-input-container verify-otp">
                                                            {otp.map((digit, index) => (
                                                                <input key={index} ref={(el) => (inputRefs.current[index] = el)} className="form-control otp-mobile-digit" type="text" maxLength={1} value={digit} onChange={(e) => handleChange(index, e.target.value)} onFocus={() => handleFocus(index)} />
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div style={{ display: 'inline-grid', gap: '22px' }}>
                                                        <div style={{ display: 'inline-grid', gap: '28px' }}>
                                                            <div className="mb-3"><Button className="btn btn-primary shadow d-block w-100 sign-in-button" type="submit">Verify</Button></div>
                                                        </div>
                                                        <div>
                                                            <p className="signup-para">Already have an account? <a className="signup-link" href={ apiRoutes.login } ><span style={{ color: 'rgb(45, 45, 45)' }}>Sign In</span></a></p>
                                                        </div>
                                                    </div>
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </Card.Body>
                            </Card>
                            <div>
                                <p className="copyright-watermark">© Join My Ship 2024</p>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </>
    );
};

export default SignupEmployeeMobileVerify;

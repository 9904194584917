import React from 'react'
import Head from '../components/layouts/Head';
import NavbarHome from '../components/layouts/NavbarHome';
import Footer from '../components/layouts/Footer';
import Scripts from '../components/layouts/Scripts';
import CancellationAndRefundContent from '../components/layouts/CancellationAndRefund';
import NavbarCrew from '../components/layouts/NavbarCrew';
import { getIsLoggedIn } from '../components/tokenManager';

const CancellationAndRefundTailScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const CancellationAndRefundCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/terms-and-conditions.css" />
    </>
  );
};

const CancellationAndRefund = () => {
  const isLoggedIn = getIsLoggedIn(); 

  return (
    <>
        <Head />
        {!isLoggedIn ?
          <>
            <CancellationAndRefundCss />
            <NavbarHome />
          </> 
          :
          <>
            <CancellationAndRefundCss />
            <NavbarCrew />
          </>
        }
        <CancellationAndRefundContent />
        <Footer />
        <Scripts />
        <CancellationAndRefundTailScript />
    </>
  );
};

export default CancellationAndRefund
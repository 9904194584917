import React, { useEffect, useState, useRef  } from 'react';
import { useNavigate } from 'react-router-dom';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { toast, ToastContainer } from 'react-toastify';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-toastify/dist/ReactToastify.css';
import { Container, Row, Col, Form, Button, Dropdown, InputGroup, FormControl } from 'react-bootstrap';
import NavbarCreateProfile from '../components/layouts/NavbarCreateProfile';
import { getCountryList, getStateList, updateUserById, getUserByToken } from '../components/apiService';
import { getLocalItem, getLocalJsonItem, setLocalJsonItem, getIsLoggedIn } from '../components/tokenManager';
import { apiRoutes } from '../config/apiConfig';

const EmployerCreateProfileCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Bootstrap-4-Custom-Radio.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/createprofile.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Date-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-dropdown.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Text-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Upload-File.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/JMS-Custom-Button.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/JMS-mobile-number.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/login.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/signup.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />

    </>
  );
};


const EmployerCreateProfile = () => {
    const navigate = useNavigate();
    const isLoggedIn = getIsLoggedIn();
    const userToken = getLocalItem("accessToken"); 
    const userData =  getLocalJsonItem("userData");

    // const isLoggedIn = true;
    if (!isLoggedIn) {
        navigate('/');
    }

    const [formDataBasic, setFormDataBasic] = useState({
        firstName: userData.first_name,
        lastName: userData.last_name,
        companyName: userData.company_name,
        website: userData.website,
        designation: userData.designation,
        gender: userData.gender.toString(),
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                const userToken = getLocalItem("accessToken"); 
                const userData = await getUserByToken(userToken);
                setLocalJsonItem("userData", userData.data);
            } catch (error) {
                console.error("Error :", error);
            }
        };
        fetchData();
    }, []);

    // For Upload Pic Starts
    const [selectedProfilePic, setselectedProfilePic] = useState( userData && userData.profilePic);
    const [selectedProfilePicFile, setselectedProfilePicFile] = useState(userData && userData.profilePic);
    const profilePicRef = useRef(null);

    const handleProfilePicChange = (event) => {
        const file = event.target.files[0];
        setselectedProfilePic(file.name);
        setselectedProfilePicFile(file);
    };

    const handleProfilePicButtonClick = () => {
        profilePicRef.current.click();
    };
    // For Upload Pic Ends

    // For Basic Details Starts
    const [errorFirstName, setErrorFirstName] = useState('');
    const [errorLastName, setErrorLastName] = useState('');
    const [errorCompanyName, setErrorCompanyName] = useState('');
    const [errorWebsite, setErrorWebsite] = useState('');
    const [errorDesignation, setErrorDesignation] = useState('');

    const handleChangeBasicDetails = (e) => {
        const { name, value } = e.target;
        let filteredValue = value;
        let error = '';
    
        if (name === "firstName") {
            const pattern = /^[a-zA-Z]+$/;
            if (!pattern.test(value)) {
                setErrorFirstName('Only alphabetic characters are allowed');
                filteredValue = value.replace(/^[a-zA-Z]+$/g, '');
                return; 
            } else {
                setErrorFirstName('');
            }
        }

        if (name === "lastName") {
            const pattern = /^[a-zA-Z]+$/;
            if (!pattern.test(value)) {
                setErrorLastName('Only alphabetic characters are allowed');
                filteredValue = value.replace(/^[a-zA-Z]+$/g, '');
                return; 
            } else {
                setErrorLastName('');
            }
        }


        if (name === "companyName") {
            const pattern = /^[a-zA-Z0-9\-\/\., ]*$/;
            if (!pattern.test(value)) {
                setErrorCompanyName('Only alphanumeric characters, "-", "/", ".", and "," are allowed');
                filteredValue = value.replace(/[^a-zA-Z0-9\-\/\., ]/g, ''); 
            } else {
                setErrorCompanyName('');
            }
        }
        
        if (name === "designation") {
            const pattern = /^[a-zA-Z0-9\-\/\., ]*$/;
            if (!pattern.test(value)) {
                setErrorDesignation('Only alphanumeric characters, "-", "/", ".", and "," are allowed');
                filteredValue = value.replace(/[^a-zA-Z0-9\-\/\., ]/g, ''); 
            } else {
                setErrorDesignation('');
            }
        }

        setFormDataBasic({
            ...formDataBasic,
            [name]: filteredValue,
        });
    };
    // For Basic Details Ends


    // For Communication Details Starts
    const [formDataCommunication, setFormDataCommunication] = useState({
        country: '',
        state: '',
        addressLine1: userData.address_line1,
        addressLine2: userData.address_line2,
        city: userData.address_city,
        pincode: userData.pincode,
    });

    const [errorPincode, seterrorPincode] = useState('');
    const [errorAddress1, seterrorAddress1] = useState('');
    const [errorAddress2, seterrorAddress2] = useState('');

    // For Country State Satrts
    const [searchCountryQuery, setSearchCountryQuery] = useState('');
    const [selectedCountryItem, setSelectedCountryItem] = useState(userData.country);
    const [countryList, setCountryList] = useState([]);
    const [searchStateQuery, setSearchStateQuery] = useState('');
    const [selectedStateItem, setSelectedStateItem] = useState(userData.state);
    const [stateList, setStateList] = useState([]);

    useEffect(() => {
        async function fetchCountryList() {
            try {
                const response = await getCountryList();
                setCountryList(response); 
            } catch (error) {
                console.error('Error fetching country list:', error);
            }
        }
        fetchCountryList();
    }, []);

    useEffect(() => {
        async function fetchStateList() {
            if (selectedCountryItem) {
                try {
                    const response = await getStateList(selectedCountryItem.id);
                    setStateList(response); 
                } catch (error) {
                    console.error('Error fetching state list:', error);
                }
            }
        }
        fetchStateList();
    }, [selectedCountryItem]);

    const handleCountryItemClick = (item) => {
        setSelectedCountryItem(item);
        setSearchCountryQuery('');
        setSelectedStateItem('');
        setSearchStateQuery('');

    };

    const handleStateItemClick = (item) => {
        setSelectedStateItem(item);
        setSearchStateQuery('');
    };

    const filteredCountryItems = countryList.filter(country =>
        country.country_name.toLowerCase().includes(searchCountryQuery.toLowerCase())
    );

    const filteredStateItems = stateList.filter(state =>
        state.state_name.toLowerCase().includes(searchStateQuery.toLowerCase())
    );
    // For Country State Ends

    const handleChangeCommunication = (e) => {
        const { name, value } = e.target;
        let filteredValue = value;
        let error = '';
    
        if (name === "pincode") {
            const pattern = /^[0-9]*$/;
            if (!pattern.test(value)) {
                seterrorPincode('Only numbers are allowed');
                return; // Exit early if the value contains non-digit characters
            } else {
                filteredValue = value.replace(/\D/g, '');
                seterrorPincode('');
            }
        }
    
        if (name === "addressLine1") {
            const pattern = /^[a-zA-Z0-9\-\/\., ]*$/;
            if (!pattern.test(value)) {
                error = 'Only alphanumeric characters, "-", "/", ".", and "," are allowed';
                filteredValue = value.replace(/[^a-zA-Z0-9\-\/\., ]/g, ''); 

            }
            seterrorAddress1(error);
        }
    
        if (name === "addressLine2") {
            const pattern = /^[a-zA-Z0-9\-\/\., ]*$/;
            if (!pattern.test(value)) {
                error = 'Only alphanumeric characters, "-", "/", ".", and "," are allowed';
                filteredValue = value.replace(/[^a-zA-Z0-9\-\/\., ]/g, ''); 
            }
            seterrorAddress2(error);
        }
    
        setFormDataCommunication({
            ...formDataCommunication,
            [name]: filteredValue,
        });
    };

    // For Communication Details Ends


    const handleFormSubmit = async (event) => {
        event.preventDefault();
        if (userData.screen_check === 0) {
            if (!(selectedProfilePicFile instanceof File)) {
                toast.error("Please upload image.")
                return;
            }
        }

        const userId = userData.id;
        const updatedUserData = {
            first_name : formDataBasic.firstName,
            last_name : formDataBasic.lastName,
            company_name : formDataBasic.companyName,
            website : formDataBasic.website,
            gender : formDataBasic.gender,
            designation : formDataBasic.designation,
            country : selectedCountryItem.id,
            state : selectedStateItem.id,
            pincode : formDataCommunication.pincode,
            address_line1 : formDataCommunication.addressLine1,
            address_line2 : formDataCommunication.addressLine2 ,
            address_city : formDataCommunication.city,
            screen_check : 4
        };

        if (selectedProfilePicFile instanceof File) {
            updatedUserData.profilePic = selectedProfilePicFile;
        }
        const updatedUser = await updateUserById(userId, updatedUserData, userToken);
        const userDataNew = await getUserByToken(userToken);
        setLocalJsonItem("userData", userDataNew.data);
        navigate(apiRoutes.emp_home);

    };

    return (
        <>
            {isLoggedIn && (
                <>
                    <EmployerCreateProfileCss />
                    <NavbarCreateProfile />
                    <ToastContainer />
                    <div className="heading-container">
                        <div className="container">
                            <div style={{ padding: '22px 0px', display: 'flex', flexDirection: 'column', gap: '14px' }}>
                                {userData.is_verified ? 
                                    <h1 className="heading-main">Edit Profile</h1>
                                    :
                                    <h1 className="heading-main">Create Profile</h1>
                                }
                                <p className="heading-desc">Please fill up this form and click on save and continue to proceed with the sign up process.</p>
                            </div>
                        </div>
                    </div>
                    <Form onSubmit={handleFormSubmit} encType="multipart/form-data">
                        
                        <Container style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap', justifyContent: 'space-between', padding: '0px 0px 24px 0px' }}>
                            <div className="container" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap', justifyContent: 'flex-end', padding: '0px 0px 24px 0px' }}>
                                <LazyLoadImage className="stepper-img" src="/static/crew/assets/img/icons/Steper.svg" alt="Stepper" style={{ display: 'none'}} />
                                <div className="profile-image-container">
                                <Form.Group controlId="formFile" className="mb-3">
                                    <div className="input-group">
                                        <input  id="ProfilePicfileInput"  name='ProfilePicName'  ref={profilePicRef}  type="file"  className="form-control d-none"  onChange={handleProfilePicChange}  accept="image/*"  />
                                        <button type="button" onClick={handleProfilePicButtonClick} style={{ background: 'none', border: 'none' }} >

                                    {selectedProfilePicFile && typeof selectedProfilePicFile.startsWith === 'function' && selectedProfilePicFile.startsWith("/media/") ? (
                                        <LazyLoadImage
                                        src={selectedProfilePicFile ? selectedProfilePicFile : "/static/crew/assets/img/icons/User100.svg"}
                                        alt="User"
                                        className="rounded-circle" 
                                        style={{ width: '100px', height: '100px' }} 
                                        />
                                    ) : (
                                        <LazyLoadImage
                                        src={selectedProfilePicFile ? URL.createObjectURL(selectedProfilePicFile) : "/static/crew/assets/img/icons/User100.svg"}
                                        alt="User"
                                        className="rounded-circle" 
                                        style={{ width: '100px', height: '100px' }} 
                                        />
                                    )}

                                        </button>
                                    </div>
                                    <Form.Label className="upload-pic-text">{selectedProfilePic ? "Edit Picture" : "Upload Picture"}</Form.Label>
                                </Form.Group>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', gap: '48px' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                                    <div className="container">
                                        <div className="basic-detail-container" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                            <LazyLoadImage className="basic-detail-img" src="/static/crew/assets/img/icons/basicdetails.svg" alt="Basic Details Icon" />
                                            <p className="basicdetail-title">Basic Details</p>
                                        </div>
                                    </div>
                                    <div className="container form-profile">
                                        <Row className="create-form-row" style={{ justifyContent: 'space-between' }}>
                                            <Col md={4} style={{ gap: '28px', display: 'flex', flexDirection: 'column' }}>
                                                <div>
                                                    <Form.Label className="form-label">First Name</Form.Label>
                                                    <Form.Control type="text" className="form-text-input" placeholder="Enter first name" name="firstName" value={formDataBasic.firstName} onChange={handleChangeBasicDetails} required={!formDataBasic.firstName} />
                                                    {errorFirstName && <p className="form-error-text">{errorFirstName}</p>}
                                                </div>
                                                <div>
                                                    <Form.Label className="form-label">Company Name</Form.Label>
                                                    <Form.Control type="text" className="form-text-input" placeholder="Enter company name" name="companyName" value={formDataBasic.companyName} onChange={handleChangeBasicDetails} required={!formDataBasic.companyName} />
                                                    {errorCompanyName && <p className="form-error-text">{errorCompanyName}</p>}
                                                </div>
                                            </Col>
                                            <Col md={4} style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                                                <div>
                                                    <Form.Label className="form-label">Last Name</Form.Label>
                                                    <Form.Control type="text" className="form-text-input" placeholder="Enter last name" name="lastName" value={formDataBasic.lastName} onChange={handleChangeBasicDetails} required={!formDataBasic.lastName} />
                                                    {errorLastName && <p className="form-error-text">{errorLastName}</p>}
                                                </div>
                                                <div>
                                                    <Form.Label className="form-label">Website</Form.Label>
                                                    <Form.Control type="text" className="form-text-input" placeholder="www.yourdomain.com" name="website" value={formDataBasic.website} onChange={handleChangeBasicDetails} required={!formDataBasic.website} />
                                                </div>
                                            </Col>
                                            <Col md={4} style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                                                <div>
                                                    <Form.Label className="form-label">Designation</Form.Label>
                                                    <Form.Control type="text" className="form-text-input" placeholder="Enter designation" name="designation" value={formDataBasic.designation} onChange={handleChangeBasicDetails} required={!formDataBasic.designation} />
                                                    {errorDesignation && <p className="form-error-text">{errorDesignation}</p>}
                                                </div>
                                                <div>
                                                    <Form.Label className="form-label">Gender</Form.Label>
                                                    <div className="radio-container">
                                                        <Form.Check type="radio" id="male" label="Male" className="custom-control-input" name="gender" value="1" checked={formDataBasic.gender === "1"} onChange={handleChangeBasicDetails}  required />
                                                        <Form.Check type="radio" id="female" label="Female" className="custom-control-input" name="gender" value="2" checked={formDataBasic.gender === "2"} onChange={handleChangeBasicDetails}  required />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', gap: '32px' }}>
                                    <div className="container">
                                        <div className="basic-detail-container" style={{ display: 'flex', flexDirection: 'row', gap: '10px' }}>
                                            <LazyLoadImage className="basic-detail-img" src="/static/crew/assets/img/icons/Radar2.svg" alt="Communication Address Icon" />
                                            <p className="basicdetail-title">Communication Address</p>
                                        </div>
                                    </div>
                                    <div className="container form-profile">
                                        <Row className="create-form-row" style={{ justifyContent: 'space-between' }}>
                                            <Col md={4} style={{ gap: '28px', display: 'flex', flexDirection: 'column' }}>
                                                <div>
                                                    <Form.Label className="form-label">Country</Form.Label>
                                                    <Dropdown>
                                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="country-dropdown">
                                                            <InputGroup className="mb-3 search-rank-input">
                                                                <FormControl placeholder={(selectedCountryItem ? selectedCountryItem.country_name : "Select Country")} aria-label="Search" aria-describedby="basic-addon2" value={searchCountryQuery} onChange={(e) => setSearchCountryQuery(e.target.value)} required={!selectedCountryItem} />
                                                            </InputGroup>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {filteredCountryItems.map((item, index) => (
                                                                <Dropdown.Item key={index} onClick={() => handleCountryItemClick(item)}>
                                                                    {item.country_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                            {filteredCountryItems.length === 0 && (
                                                                <Dropdown.Item disabled>No items found</Dropdown.Item>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                <div>
                                                    <Form.Label className="form-label">Address Line 1</Form.Label>
                                                    <Form.Control type="text" className="form-text-input" placeholder="Enter address line 1" name="addressLine1" value={formDataCommunication.addressLine1} onChange={handleChangeCommunication} required={!formDataCommunication.addressLine1} />
                                                    {errorAddress1 && <p className="form-error-text">{errorAddress1}</p>}
                                                </div>
                                            </Col>
                                            <Col md={4} style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                                                <div>
                                                    <Form.Label className="form-label">State</Form.Label>
                                                    <Dropdown>
                                                        <Dropdown.Toggle className="btn dropdown-toggle dropdown-form-button" variant="none" id="state-dropdown">
                                                            <InputGroup className="mb-3 search-rank-input">
                                                                <FormControl placeholder={(selectedStateItem ? selectedStateItem.state_name : "Select State")} aria-label="Search" aria-describedby="basic-addon2" value={searchStateQuery} onChange={(e) => setSearchStateQuery(e.target.value)} required={!selectedStateItem} />
                                                            </InputGroup>
                                                        </Dropdown.Toggle>
                                                        <Dropdown.Menu>
                                                            {filteredStateItems.map((item, index) => (
                                                                <Dropdown.Item key={index} onClick={() => handleStateItemClick(item)}>
                                                                    {item.state_name}
                                                                </Dropdown.Item>
                                                            ))}
                                                            {filteredStateItems.length === 0 && (
                                                                <Dropdown.Item disabled>No items found</Dropdown.Item>
                                                            )}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                                <div>
                                                    <Form.Label className="form-label">Address Line 2</Form.Label>
                                                    <Form.Control type="text" className="form-text-input" placeholder="Enter address line 2" name="addressLine2" value={formDataCommunication.addressLine2} onChange={handleChangeCommunication} required={!formDataCommunication.addressLine2} />
                                                    {errorAddress2 && <p className="form-error-text">{errorAddress2}</p>}
                                                </div>
                                            </Col>
                                            <Col md={4} style={{ display: 'flex', flexDirection: 'column', gap: '28px' }}>
                                                <div>
                                                    <Form.Label className="form-label">City</Form.Label>
                                                    <Form.Control type="text" className="form-text-input" placeholder="Enter city" name="city" value={formDataCommunication.city} onChange={handleChangeCommunication} required={!formDataCommunication.city} />
                                                </div>
                                                <div>
                                                    <Form.Label className="form-label">Pincode</Form.Label>
                                                    <Form.Control type="text" className="form-text-input" placeholder="Enter pincode" name="pincode" value={formDataCommunication.pincode} onChange={handleChangeCommunication} required={!formDataCommunication.pincode} />
                                                    {errorPincode && <p className="form-error-text">{errorPincode}</p>}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </div>
                        </Container>
                        

                        <Container style={{ paddingTop: '90px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                            <Button className="btn btn-primary register-btn" type="submit">
                                <br />
                                <span style={{ color: 'var(--bs-btn-hover-color)', backgroundColor: 'var(--bs-btn-hover-bg)' }}>Submit</span>
                            </Button>
                        </Container>
                    </Form>
                    <div style={{ height: '132px' }}></div>
                </>
            )}
        </>
    );
};

export default EmployerCreateProfile;

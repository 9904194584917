import React, { useState, useEffect } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import NavbarCrew from '../components/layouts/NavbarCrew';
import Scripts from '../components/layouts/Scripts';
import { Modal, Button, InputGroup, FormControl } from 'react-bootstrap';
import {  getSubUsersList, deleteSubEmployerById, createSubEmployer
        } from '../components/apiService';
import { getLocalItem, getLocalJsonItem } from '../components/tokenManager';
import { apiRoutes } from '../config/apiConfig';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router-dom';
import { DeleteUserModal } from '../components/layouts/FindJobsModal';

const ManageUsersScriptCss = () => {
  return (
    <>
      <link rel="stylesheet" href="/static/crew/assets/bootstrap/css/bootstrap.min.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/commoncss.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/home.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/crew_landing.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-dropdown-1.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Custom-Text-Input.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Bootstrap-4-Custom-Radio.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/Search-Input-responsive.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/view_applicants.css" />
      <link rel="stylesheet" href="/static/crew/assets/css/manage_user.css" />

    </>
  );
};

const ManageUsersScript = () => {
  return (
    <>
      <script src="/static/crew/assets/js/bold-and-bright.js" ></script>
    </>
  );
};

const ManageUsers = () => {
    const userToken = getLocalItem("accessToken"); 
    const userData =  getLocalJsonItem("userData");
    const { jobID } = useParams();
    const [subUserList , setSubUserList] = useState();
    const [msgDisplay, setMsgDisplay] = useState('');
    

    useEffect(() => {
        const fetchJobs = async () => {
          try {
            const response = await getSubUsersList(userData.id, userToken);
            setSubUserList(response);
          } catch (error) {
            console.error('Error fetching job list:', error);
          } 
        };
        fetchJobs();
      }, []);

    const [showInvite, setShowInvite] = useState(false);
    const [showSuccess, setShowSuccess] = useState(false);
    const [isValidEmail, setIsValidEmail] = useState(false);
    const [mailInvited, setMailInvited] = useState('');
    const [loader, setLoader] = useState(false);

      const handleShowInvite = () => setShowInvite(true);
      const handleCloseInvite = () => {
            setShowInvite(false);
            setMailInvited('');
            setIsValidEmail(true);
        }

      const handleShowSuccess = async () => {
        if (isValidEmail && mailInvited !== '') {
            setLoader(true);

            // Perform the invite action
            // console.log(`Inviting: ${mailInvited}`);
            const subUsersData = new FormData();
            subUsersData.append('email', mailInvited);
            await createSubEmployer(subUsersData, userToken);
            setMsgDisplay("Invitation sent successfully!");
            // Clear the input and close the modal
            handleCloseInvite();
            setShowSuccess(true);
            setTimeout(() => {
                setLoader(false);
              }, 2000); 

        } else {
            if( mailInvited !== '') {
                toast.error("Please enter valid Mail Id");
            }
        }
      } 



        const [showModal, setShowModal] = useState(false);
        const [itemIdToDelete, setItemIdToDelete] = useState(null);

        const handleDeleteBtnClick = (id) => {
            setItemIdToDelete(id);
            setShowModal(true); // Show the modal when delete button is clicked
        };

        const handleCloseModal = () => {
            setShowModal(false);
            setItemIdToDelete(null); // Clear the item ID on close
        };

        const handleConfirmApply = () => {
            if (itemIdToDelete) {
            // Perform the delete action here, e.g., call an API to delete the item
            handleDeleteBtn(itemIdToDelete);
            }
            setShowModal(false); // Close the modal after deletion
        };

      const handleDeleteBtn = async (userid) => {
            const firebaseUserData = JSON.parse(getLocalItem('userFirebaseData'));
            const firebaseToken =  firebaseUserData.idToken;
            const subUsersData = new FormData();
            subUsersData.append('auth_key', firebaseToken);
            await deleteSubEmployerById( userid, subUsersData ,userToken);
            setMsgDisplay("User deleted successfully!");
            handleCloseInvite();
            setShowSuccess(true);
      } 
    //   const handleCloseSuccess = () => setShowSuccess(false);
    const handleCloseSuccess = () => {
        setShowSuccess(false);
        window.location.reload();
    };


    // mail check started
    const handleChange = (e) => {
        const email = e.target.value;
        setMailInvited(email);
        setIsValidEmail(validateEmail(email));
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(email);
    };      

    // mail check Ends

  
    console.log("subUserList", subUserList);

    const renderTableRows = () => {
        if (subUserList && subUserList.length > 0) {
            return subUserList.map((result, index) => (
                <tr className="cv-search-table-section-row" key={index}>
                    <td className="cv-search-table-section-cell">
                        <div className="cv-search-table-section-cell-name">
                            <img className="cv-search-table-section-cell-name-img" src={result.profilePic} alt="Profile" />
                            <p className="cv-search-table-section-cell-name-title">{result.first_name}</p>
                        </div>
                    </td>
                    <td className="cv-search-table-section-cell">
                        {result.email}
                    </td>
                    <td className="cv-search-table-section-cell">{result.is_verified === 1 ? 'Active' : 'Onboarding Pending'}</td>
                    <td className="cv-search-table-section-cell">
                            {/* <div className="cv-search-table-section-cell-action">
                                <img className="cv-search-table-section-cell-action-img" src="/static/crew/assets/img/icons/TrashBinMinimalistic.svg" alt="View Profile" />
                                <p className="cv-search-table-section-cell-delete-title">Delete</p>
                            </div> */}
                            <button
                                className="cv-search-table-section-cell-action-btn"
                                onClick={() => handleDeleteBtnClick(result.id)}
                            >
                                <img
                                    className="cv-search-table-section-cell-action-img"
                                    src="/static/crew/assets/img/icons/TrashBinMinimalistic.svg"
                                    alt="Delete"
                                />
                                <p className="cv-search-table-section-cell-delete-title">Delete</p>
                            </button>
                    </td>
                </tr>
            ));
        }

    };


    return (
        <>
            <ManageUsersScriptCss />
            <NavbarCrew />
            <ToastContainer />
            <div className="cv-search-header-section">
                <div className="container cv-search-header-container">
                    <div className="cv-search-header-section">
                        <div className="cv-search-header-container-div">
                            <h1 className="cv-search-header-container-title">Manage Users</h1>
                            <p className="cv-search-header-container-text">
                                View and add new members of your organization
                            </p>
                        </div>
                    </div>
                </div>
            </div>

            {userData  ?
                <div className="container cv-search-table-container">
                    <div className="cv-search-table-header-section">
                        <div className="view-applicants-table-heading-div">
                            <div className="view-applicants-table-heading-left sub-user-manage-heading">
                                <p className="cv-search-table-head-title">
                                    Add employers to your organization
                                </p>
                                <p className="cv-search-table-head-count">
                                    You can add a maximum of 3 users
                                </p>
                            </div>

                            <div className="view-applicants-table-heading-right">
                                <button className="btn view-applicants-invite-new-user-btn" 
                                onClick={handleShowInvite}
                                >
                                    <img src="/static/crew/assets/img/icons/plus_square.svg" alt="Invite new user" /> Invite new user
                                </button>
                            </div>
                            
                        </div>
                        <div className="table-responsive cv-search-table-section">
                            <table className="table">
                                <thead className="cv-search-table-section-header">
                                    <tr className="cv-search-table-section-header-row">
                                        <th className="cv-search-table-section-header-cell">Name</th>
                                        <th className="cv-search-table-section-header-cell">E-Mail</th>
                                        <th className="cv-search-table-section-header-cell">Status</th>
                                        <th className="cv-search-table-section-header-cell">Action</th>
                                    </tr>
                                </thead>
                                <tbody className="cv-search-table-section-body">
                                    {renderTableRows()}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            :
                <div className="container cv-search-no-data-container">
                    <div className="cv-search-no-data-container-div">
                        <img 
                            className="cv-search-no-data-container-div-img" 
                            src="/static/crew/assets/img/images/empty_img.svg" 
                            alt="No Data"
                        />
                        <p className="cv-search-no-data-container-div-text">Sorry! No data found</p>
                    </div>
                </div>
            }
            
            <Modal show={showInvite} onHide={handleCloseInvite} centered>
                <Modal.Body className="modal-invite-new-employer-body">
                    <div className="modal-invite-new-employer-div">
                        <div className="modal-invite-new-employer-img-div">
                            <img className="modal-invite-new-employer-img" src="/static/crew/assets/img/icons/teammate.svg" alt="Invite" />
                        </div>
                        <div className="modal-invite-new-employer-input-div">
                            <p className="modal-invite-new-employer-input-div-text"><strong>Invite new users</strong></p>
                            <p className="modal-invite-new-employer-input-div-para"><strong>Send invitation links to team members</strong></p>
                            <div className="form-group mb-3 modal-invite-new-employer-input-box">
                                <InputGroup className="modal-invite-new-employer-input-box-group">
                                    <InputGroup.Text className="bg-white InputBorder modal-invite-new-employer-icon-span">
                                        <img className="modal-invite-new-employer-icon-img" src="/static/crew/assets/img/icons/Email_grey.svg" alt="Email" />
                                    </InputGroup.Text>
                                    <FormControl
                                        className="InputBorder modal-invite-new-employer-email-input"
                                        type="email"
                                        value={mailInvited}
                                        placeholder="Enter email address"
                                        onChange={handleChange}
                                        isInvalid={!isValidEmail}
                                    />
                                </InputGroup>
                            </div>
                        </div>
                    </div>
                </Modal.Body>
                <Modal.Footer className="modal-invite-new-employer-footer">
                    <Button className="btn btn-light myjob-btn-close" onClick={handleCloseInvite}>
                        Close
                    </Button>
                    <Button className="btn btn-primary myjob-btn" onClick={handleShowSuccess} disabled={loader}>
                        {loader ? (
                            <>
                            <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>

                            Inviting...
                            </>
                        ) : (
                            "Invite"
                        )}
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={showSuccess} onHide={handleCloseSuccess} centered>
                <div className="modal-content">
                    <div className="modal-header myjob-header">
                    <LazyLoadImage src="/static/crew/assets/img/icons/double_check.svg" alt="Double Check Icon" />
                    </div>
                    <div className="modal-body myjob-body">
                    <p>
                        {msgDisplay}
                    </p>
                    </div>
                    <div className="modal-footer myjob-footer">
                    <Button className="btn btn-light myjob-btn-close" 
                    onClick={handleCloseSuccess}
                    // onClick={() => window.location.href = apiRoutes.emp_home}
                    >
                        Close
                    </Button>
                    <Button className="btn btn-primary myjob-btn" onClick={() => window.location.href = apiRoutes.emp_home}>
                        Home&nbsp;<LazyLoadImage src="/static/crew/assets/img/icons/fi_arrow-right.svg" alt="Arrow Right Icon" />
                    </Button>
                    </div>
                </div>
            </Modal>

            <DeleteUserModal
                show={showModal}
                handleClose={handleCloseModal}
                handleConfirmApply={handleConfirmApply}
            />
            
            <div style={{height: "132px"}}></div>
            <Scripts />
            <ManageUsersScript />

        </>
    );
};

export default ManageUsers;

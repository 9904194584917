import React, { useState, useRef } from 'react';
import PhoneInput from "react-phone-input-2";
import { auth } from '../../components/firebase';
import { signInWithPhoneNumber, RecaptchaVerifier, PhoneAuthProvider, signInWithCredential, updatePhoneNumber  } from "firebase/auth";
import { getLocalJsonItem, getLocalItem } from '../tokenManager';
import { changeEmailDB, handleLogout } from '../apiService';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';


const ChangeMobileNumber = () => {
    const navigate = useNavigate();
    const userData =  getLocalJsonItem("userData");
    const userToken = getLocalItem("accessToken"); 
    const [mobileNumber, setMobileNumber] = useState('');
    const [isOtpSectionVisible, setIsOtpSectionVisible] = useState(false);
    const [otp, setOtp] = useState(['', '', '', '', '', '']);
    const [confirmationResult, setConfirmationResult] = useState(null);
    const inputRefs = useRef([]);
    const [verificationId, setVerificationId] = useState('');
    const [errorNumber, setErrorNumber] = useState('');

    // const handlePhoneChange = (value) => {
    //     const sanitizedValue = value.replace(/\D/g, '');
    //     setMobileNumber(sanitizedValue);
    // };

    const handlePhoneChange = (value) => {
        const sanitizedValue = value.replace(/\D/g, '');
        if (sanitizedValue.length < 5) {
            setErrorNumber('Please enter a valid phone number');
        } else {
            setErrorNumber('');
        }
    

        setMobileNumber(sanitizedValue);
      };

    const handleMobileVerify = (event) => {
        event.preventDefault();

        if (mobileNumber !== '') {
            const fulPhoneNumber = "+"+ mobileNumber;

            const recaptcha = new RecaptchaVerifier(auth, 'recaptcha-container', {});
            signInWithPhoneNumber(auth, fulPhoneNumber, recaptcha)
            .then((result) => {
                    setVerificationId(result.verificationId);
                    setConfirmationResult(result);
                    setIsOtpSectionVisible(true);
            }).catch((error) => {
                toast.error(error);
                // console.log("error: ", error);
            });
        } else {
            toast.error("Please Enter the Number");
        }

    };

    const handleOtpVerify = async () => {
        const otpCode = otp.join('');
        const credential = PhoneAuthProvider.credential(verificationId, otpCode);
        
        if (otpCode.length < 6 ) {
            toast.error("Please enter the valid verification code.");
            return;
        }
        try {
            await updatePhoneNumber(auth.currentUser, credential);
            const formData = new FormData();
            formData.append("number", mobileNumber);
            await changeEmailDB(userData.id, formData, userToken);
            toast.success("Mobile Number Updated.");
            handleLogout();
        } catch (error) {
            if ( error.message === "Firebase: Error (auth/invalid-verification-code).") {
                toast.error("Invalid Verification code.");
            } else {
                toast.error("Mobile Number not Updated.");
            }
        }
    };
    
    const handleResendOtp = () => {
        handleMobileVerify(); 
    };

    const handleChange = (index, value) => {
        value = value.replace(/\D/g, '');
        const newOtp = [...otp];
        newOtp[index] = value;
        setOtp(newOtp);

        if (value && index < otp.length - 1) {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleKeyDown = (index, e) => {
        if (e.key === 'Backspace' && !otp[index] && index > 0) {
            inputRefs.current[index - 1].focus();
        }
    };

    return (
        <div className="container">
            <div id="recaptcha-container"></div>
            {!isOtpSectionVisible ? (
                <div className="mobile-container-tab">
                    {userData.number && (
                        <div className='current-number-container'>
                            <p className='current-email-heading'>Current Mobile No.</p>
                            <p className='current-email-text'>{userData.number}</p>
                        </div>
                    )}
                    <div className="mobile-container-tab-div">
                        <div className="mobile-container-tab-div-top">
                            <p className="mobile-container-tab-div-top-title">Mobile Number</p>
                            <PhoneInput
                                country={"in"}
                                enableSearch={true}
                                value={mobileNumber}
                                className="phoneinput-library"
                                onChange={handlePhoneChange}
                            />
                            {errorNumber && <p className="form-error-text">{errorNumber}</p>}

                        </div>
                        <button 
                            className="btn btn-primary mobile-container-tab-verify-btn" 
                            type="button"
                            onClick={handleMobileVerify}
                        >
                            Verify
                        </button>
                    </div>
                </div>
            ) : (
                <div className="container">
                    <div className="mobile-container-tab-div">
                        <div className="mobile-container-tab-div-top">
                            <p className="mobile-container-tab-div-top-title">Enter OTP received on your mobile number</p>
                            <div className="otp-input-container">
                                {otp.map((value, index) => (
                                    <input
                                        key={index}
                                        type="text"
                                        maxLength="1"
                                        value={value}
                                        onChange={(e) => handleChange(index, e.target.value)}
                                        onKeyDown={(e) => handleKeyDown(index, e)}
                                        ref={(ref) => (inputRefs.current[index] = ref)}
                                    />
                                ))}
                            </div>
                        </div>
                        <div className="mobile-container-tab-verify-btn-div">
                            <button 
                                className="btn btn-primary mobile-container-tab-verify-btn" 
                                type="button"
                                onClick={handleOtpVerify}
                            >
                                Verify OTP
                            </button>
                            <button 
                                className="btn btn-primary mobile-container-tab-resend-btn" 
                                type="button"
                                onClick={handleResendOtp}
                            >
                                Resend
                            </button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ChangeMobileNumber;
